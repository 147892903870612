import {Axios} from "axios";

export interface CatalogDto {
    id: number;
    code: string;
    name: string;
}

export interface CountryDto extends CatalogDto {

}

export class CountryApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(countryId: string): Promise<CountryDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get(`/countries/${countryId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findAll(): Promise<CountryDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get<CountryDto[]>("/countries")
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}