import {Grid} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {OrganizationCpmsStatisticsDto} from "../../../client/organization/OrganizationApiClient";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import TextField from "../../../components/field/TextField";
import SectionHeader from "../../../components/section/SectionHeader";
import {OrganizationTranslKey} from "../OrganizationTranslKey";
import {CpmsStatusFormatter} from "../../contact/cpms/CpmsStatusFormatter";

export interface OrganizationCpmsStatisticsPanelProps {
    organizationId : string
}

const OTHER_STATUS_NAME = "OTHER";

const DEFAULT_CPMS_STATISTICS : OrganizationCpmsStatisticsDto = {
    statuses: {},
    referringPhysician: 0,
    panelLead: 0,
    panelMember: 0
}

export default function OrganizationCpmsStatisticsPanel(props: Readonly<OrganizationCpmsStatisticsPanelProps>) {
    const {organizationId} = props;
    const {organizationClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    const [cpmsStatistics, setCpmsStatistics] = useState(DEFAULT_CPMS_STATISTICS);


    useEffect(() => {
        organizationClient.computeOrganizationCpmsStatistics(organizationId)
                .then((cpmsStatistics) => {
                    setCpmsStatistics(cpmsStatistics);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));

    }, [organizationId, organizationClient, t, snackbar]);

    const sortStatusesWithOtherLast = (statuses : { [key: string]: number }): [string, number][] => {
        return Object.entries(statuses)
                .sort(([keyA], [keyB]) => {
                    if (keyA === OTHER_STATUS_NAME) return 1;
                    if (keyB === OTHER_STATUS_NAME) return -1;
                    return keyA.localeCompare(keyB);
                });
    };

    const sortedStatuses = sortStatusesWithOtherLast(cpmsStatistics.statuses);

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SectionHeader title={t(OrganizationTranslKey.CPMS_ROLES)} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                        label={t(OrganizationTranslKey.PANEL_LEAD_COUNT)}
                                        value={cpmsStatistics.panelLead.toString()}
                                        readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                        label={t(OrganizationTranslKey.PANEL_MEMBER_COUNT)}
                                        value={cpmsStatistics.panelMember.toString()}
                                        readOnly={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <TextField
                                        label={t(OrganizationTranslKey.REFERRED_PHYSICIAN_COUNT)}
                                        value={cpmsStatistics.referringPhysician.toString()}
                                        readOnly={true}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    {sortedStatuses.length > 0 &&
                        <Grid item xs={12}>
                            <SectionHeader title={t(OrganizationTranslKey.STATUSES)}/>
                            <Grid container spacing={2}>
                                {sortedStatuses.map(([key, value]) => (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                                            <TextField

                                                    label={CpmsStatusFormatter.translateCpmsStatus(key, t)}
                                                    value={value.toString()}
                                                    readOnly={true}
                                            />
                                        </Grid>
                                ))}
                            </Grid>
                        </Grid>}
                </Grid>
            </>
    );
}
