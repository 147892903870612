import {UserRoleDto, UserRoleType} from "../../../client/user/UserApiClient";
import {TranslationFunction} from "../../../i18n/i18n";
import {UserTranslKey} from "../UserTranslKey";


export class UserRoleFormatter {

    static formatUserRoleType(userRoleType: UserRoleType, t: TranslationFunction): string {
        const translKey = UserTranslKey.USER_ROLE_TYPE_NAMESPACE + userRoleType;
        return t(translKey);
    }

    static formatUserRole(role: UserRoleDto | undefined, t: TranslationFunction): string {
        if (!role) {
            return "";
        }

        return UserRoleFormatter.formatUserRoleType(role.userRoleType, t);
    }

    static formatUserRoles(roles: UserRoleDto[] | undefined, t: TranslationFunction): string | null {
        if (!roles || roles.length === 0) {
            return null;
        }
        return roles.map(role => UserRoleFormatter.formatUserRole(role, t)).join(", ");
    }


}
