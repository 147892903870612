import {Grid} from "@mui/material";
import {DiseaseFilterForm} from "./DiseaseFilterForm";
import {DiseaseDto, DiseaseFilterDto} from "../../../client/disease/DiseaseApiClient";
import {useCallback, useEffect, useRef, useState} from "react";
import DiseaseGrid from "../DiseaseGrid";
import ButtonBar from "../../../components/button/ButtonBar";
import {SearchButton} from "../../../common/button/CommonButtons";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import DiseaseTreeGrid from "../DiseaseTreeGrid";

enum SearchPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}

export interface DiseaseSearchPanelProps {
    onSelected: (disease?: DiseaseDto) => void;
    onRowDoubleClick?: (disease: DiseaseDto) => void;
    treeMode?: boolean;
}

export default function DiseaseSearchPanel(props: DiseaseSearchPanelProps) {
    const {onSelected, onRowDoubleClick, treeMode} = props;
    const {t} = useTranslation();

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    const [state, setState] = useState<SearchPanelState>(SearchPanelState.INITIALIZED);
    const [filter, setFilter] = useState<DiseaseFilterDto>({});
    const [diseases, setDiseases] = useState<DiseaseDto[]>([]);

    const selectedDiseaseRef = useRef<DiseaseDto>();

    function onFilterChanged(updatedFilter: DiseaseFilterDto) {
        setFilter(updatedFilter);
    }

    const search = useCallback(() => {
        setState(SearchPanelState.SEARCH_IN_PROGRESS);
        diseaseClient.findByFilter(filter)
                .then((diseases) => {
                    setDiseases(diseases);
                })
                .catch((error) => {
                    snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                })
                .finally(() => setState(SearchPanelState.IDLE));
    }, [filter, diseaseClient, snackbar, t]);

    useEffect(() => {
        if (state === SearchPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    function onSelectionChange(diseaseId?: string) {
        let disease = diseaseId
            ? diseases.find((disease) => disease.id === diseaseId)
            : undefined;
        selectedDiseaseRef.current = disease;
        onSelected(disease);
    }

    const content = treeMode ?
        <Grid item xs={12}>
            {state === SearchPanelState.IDLE &&
                <DiseaseTreeGrid diseases={diseases}
                                 onSelectionChange={onSelectionChange}
                                 onRowDoubleClick={onRowDoubleClick}
                />
            }
        </Grid> :
        <>
            <Grid item xs={12}>
                <DiseaseFilterForm filter={filter}
                                   onChange={onFilterChanged}/>
            </Grid>
            <Grid item xs={12}>
                <ButtonBar>
                    <SearchButton onClick={search} inProgress={state === SearchPanelState.SEARCH_IN_PROGRESS}/>
                </ButtonBar>
            </Grid>
            <Grid item xs={12}>
                {state === SearchPanelState.IDLE &&
                    <DiseaseGrid diseases={diseases}
                                 onSelectionChange={onSelectionChange}
                                 onRowDoubleClick={onRowDoubleClick}
                    />
                }
            </Grid>
        </>;


    return (
        <Grid container spacing={2}>
            {content}
        </Grid>
    );

}
