import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {
    DiseaseCreateDto,
    DiseaseDto,
    DiseaseHierarchyLevel
} from "../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import TextField from "../../components/field/TextField";
import SelectField from "../../components/field/SelectField";
import {formatHierarchyLevel} from "./DiseaseHierarchyLevelFormatter";
import DiseaseSearchDialog from "./search/DiseaseSearchDialog";
import {compareHierarchyLevel} from "./DiseaseHierarchyLevelComparator";
import TextFieldWithMore from "../../components/field/TextFieldWithMore";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {DiseaseTranslKey} from "./DiseaseTranslKey";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

interface DiseaseCreateModel {
    name: string;
    abbreviation: string;
    hierarchyLevel: DiseaseHierarchyLevel;
    orphacode: string;
    description: string;
    parent?: DiseaseDto;
}

const DEFAULT_DISEASE_CREATE_MODEL: DiseaseCreateModel = {
    name: "",
    abbreviation: "",
    hierarchyLevel: DiseaseHierarchyLevel.DISEASE,
    orphacode: "",
    description: "",
    parent: undefined
};

export interface DiseaseCreateDialogProps {
    open: boolean;
    onClose: (created: boolean) => void;
    initialParent?: DiseaseDto;
}

export default function DiseaseCreateDialog(props: DiseaseCreateDialogProps) {
    const {open, onClose, initialParent} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_DISEASE_CREATE_MODEL);

    const [parentErrorMessage, setParentErrorMessage] = useState<string>();
    const [parentSearchDialogOpen, setParentSearchDialogOpen] = useState(false);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open) {
            setModel(DEFAULT_DISEASE_CREATE_MODEL);
            setDialogState(CreateDialogState.IDLE);
        } else {
            setModel({...DEFAULT_DISEASE_CREATE_MODEL, parent: initialParent});
        }
    }, [open, initialParent]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const create = normalize(model);
            diseaseClient.createDisease(create)
                    .then((disease) => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(true);
                    })
                    .catch((error) => {
                        if (error.response?.data?.code === "CONFLICT") {
                            snackbar.warning(t(CommonTranslKey.CONTAINS_VALIDATION_ERRORS));
                        } else if (error.response?.data?.code === "PARENT_ID_MUST_BE_PROVIDED") {
                            snackbar.error(t(DiseaseTranslKey.PARENT_MUST_BE_PROVIDED), error);
                        } else {
                            snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        }
                        setDialogState(CreateDialogState.IDLE);
                    });
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (model.name.trim().length === 0) {
            isValid = false;
        }
        if (model.parent && compareHierarchyLevel(model.hierarchyLevel, model.parent.hierarchyLevel) >= 0) {
            isValid = false;
            if (!parentErrorMessage) {
                setParentErrorMessage(t(DiseaseTranslKey.PARENT_LEVEL_MUST_BE_HIGHER));
            }
        } else {
            if (parentErrorMessage) {
                setParentErrorMessage(undefined);
            }
        }

        return isValid;
    }

    function normalize(model: DiseaseCreateModel): DiseaseCreateDto {
        const name = model.name.trim();
        const abbreviation = model.abbreviation.trim();
        const orphacode = model.orphacode?.trim();
        const description = model.description;
        return {
            name: name,
            abbreviation: (abbreviation && abbreviation.length > 0) ? abbreviation : undefined,
            hierarchyLevel: model.hierarchyLevel,
            orphacode: (orphacode && orphacode.length > 0) ? orphacode : undefined,
            description: (description && description.length > 0) ? description : undefined,
            parentId: model.parent?.id
        };
    }

    function clearParent() {
        setModel({...model, parent: undefined});
    }

    function showParentSearchDialog() {
        setParentSearchDialogOpen(true);
    }

    function onParentSearchDialogClosed(parent?: DiseaseDto) {
        setParentSearchDialogOpen(false);
        if (parent) {
            setModel({...model, parent: parent});
        }
    }

    const hierarchyLevelOptions = Object.keys(DiseaseHierarchyLevel).map((level) => ({
        value: level as DiseaseHierarchyLevel,
        label: formatHierarchyLevel(level as DiseaseHierarchyLevel)
    }));

    return (
            <>
                <Dialog open={open} onClose={() => onClose(false)} maxWidth={"md"}>
                    <DialogTitle title={t(DiseaseTranslKey.CREATE_NEW_DISEASE)}/>
                    <DialogContent>
                        <Grid container mt={0} spacing={2}>
                            <Grid item xs={12} md={8}>
                                <TextField label={t(DiseaseTranslKey.NAME)}
                                           required={true}
                                           value={model.name}
                                           onChange={(value) => setModel({...model, name: value})}/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label={t(DiseaseTranslKey.ABBREVIATION)}
                                           value={model.abbreviation}
                                           onChange={(value) => setModel({...model, abbreviation: value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField label={t(DiseaseTranslKey.ORPHACODE)}
                                           value={model.orphacode}
                                           onChange={(value) => setModel({...model, orphacode: value})}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectField label={t(DiseaseTranslKey.LEVEL)}
                                             required={true}
                                             value={model.hierarchyLevel}
                                             onChange={(value) => setModel({
                                                 ...model,
                                                 hierarchyLevel: value as DiseaseHierarchyLevel
                                             })}
                                             options={hierarchyLevelOptions}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label={t(DiseaseTranslKey.DESCRIPTION)}
                                           value={model.description}
                                           onChange={(value) => setModel({...model, description: value})}
                                           multiline={true}
                                           minRows={3}
                                           maxRows={7}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWithMore label={t(DiseaseTranslKey.PARENT_PRIMARY)}
                                                   value={model.parent?.name}
                                                   onClear={clearParent}
                                                   onMoreClick={showParentSearchDialog}
                                                   errorMessage={parentErrorMessage}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonBar>
                            <CancelButton onClick={() => onClose(false)}/>
                            <SaveButton variant={"primary"}
                                        onClick={save}
                                        disabled={!model || !validate()}
                                        inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                        </ButtonBar>
                    </DialogActions>
                </Dialog>

                <DiseaseSearchDialog open={parentSearchDialogOpen} onClose={onParentSearchDialogClosed}/>
            </>
    );
}
