import {Grid, Skeleton} from "@mui/material";

export default function RegistrationRequestBasicDetailPanelSkeleton() {
    return (
        <Grid container spacing={2} mt={-1}>
            <Grid item xs={12} sm={5} md={5}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={2} md={2}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
        </Grid>
    );
}