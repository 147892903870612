import Dialog from "../../../components/dialog/Dialog";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../../common/button/CommonButtons";
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {DiseaseDto, DiseaseHierarchyRelationDto} from "../../../client/disease/DiseaseApiClient";

enum DeleteDialogState {
    IDLE,
    DELETE_IN_PROGRESS,
}

export interface DiseaseParentRelationDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    relation?: DiseaseHierarchyRelationDto;
    parent?: DiseaseDto;
    diseaseId: string;
}

export default function DiseaseParentRelationDeleteDialog(props: DiseaseParentRelationDeleteDialogProps) {
    const {open, onClose, relation, parent, diseaseId} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.IDLE);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setDialogState(DeleteDialogState.IDLE);
        }
    }, [open]);

    function deleteRelation() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        diseaseClient.deleteHierarchyRelation(relation!.id, diseaseId)
            .then(() => {
                snackbar.success("Data deleted");
                onClose(true);
            })
            .catch((error) => {
                snackbar.error("", error);
                setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={"Delete relation to parent?"} />
            <DialogContent>
                <Typography variant={"body1"}>
                    {"Do you really want to delete a selected relation to parent "}
                    <strong>{parent?.name}</strong>
                    {"?"}
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteRelation}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}