import {styled, TextField as MuiTextField} from "@mui/material";

const StyledMuiTextField = styled(MuiTextField)(({theme}) => ({
    '& .MuiFilledInput-root:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.06)"
    },
    '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: "rgba(0, 0, 0, 0.09)"
    },
    '& .MuiFilledInput-root.Mui-focused:hover': {
        backgroundColor: "rgba(0, 0, 0, 0.09)"
    },
    '& .MuiFilledInput-root': {
        backgroundColor: "rgba(0, 0, 0, 0.025)"
    },
}));

export default StyledMuiTextField;