import {TranslationFunction} from "../../i18n/i18n";
import {TopicTranslKey} from "./TopicTranslKey";

export class TopicTypeFormatter {

    private constructor() {
    }

    static formatTopicType(topicType: string | null | undefined, t: TranslationFunction): string {
        if (!topicType) {
            return "";
        }

        const translKey = TopicTranslKey.TOPIC_TYPE_NAMESPACE + topicType;
        return t(translKey);
    }

    static formatTopicGroup(topicGroup: string | null | undefined, t: TranslationFunction): string {
        if (!topicGroup) {
            return "";
        }

        const translKey = TopicTranslKey.TOPIC_GROUP_NAMESPACE + topicGroup;
        return t(translKey);
    }
}
