import {InternalAxiosRequestConfig} from "axios";
import {OAuth2Client} from "./AuthContext";

export default class RefreshTokenRequestInterceptor {
    constructor(private readonly oauth2Client: OAuth2Client) {
    }

    interceptRequest(config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> {
        return this.oauth2Client.updateToken(2)
            .then(() => config);
    }
}