import dayjs from "dayjs";


export class DateTimeFormatter {

    static formatAsFullYear(date: Date | undefined | null) {
        if (date === undefined || date === null) {
            return '';
        }
        return date.getFullYear().toString();
    }

    static formatDate(date: Date | string | null | undefined): string | null {
        if (!date) {
            return null;
        }
        return dayjs(date).format("L");
    }

    static formatDateTime(date: Date | string | null | undefined) {
        if (!date) {
            return null;
        }
        return dayjs(date).format("L LTS");
    }

}