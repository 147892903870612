import {GridColDef, GridSortModel} from "@mui/x-data-grid-pro";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../client/disease/DiseaseApiClient";
import {formatHierarchyLevel} from "./DiseaseHierarchyLevelFormatter";
import DataGrid from "../../components/datagrid/DataGrid";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DiseaseTranslKey} from "./DiseaseTranslKey";


export interface DiseaseGridProps {
    diseases: DiseaseDto[];
    selectedId?: string;
    onSelectionChange?: (diseaseId?: string) => void;
    onRowDoubleClick?: (disease: DiseaseDto) => void;
}

export default function DiseaseGrid(props: Readonly<DiseaseGridProps>) {
    const {diseases, selectedId, onSelectionChange, onRowDoubleClick} = props;
    const {t} = useTranslation();

    const columnDefs: GridColDef[] = [
        { field: "name", headerName: t(DiseaseTranslKey.NAME), width: 450 },
        { field: "abbreviation", headerName: t(DiseaseTranslKey.ABBREVIATION), width: 120 },
        { field: "hierarchyLevel", headerName: t(DiseaseTranslKey.LEVEL), width: 100, valueFormatter: (params) => formatHierarchyLevel(params.value as DiseaseHierarchyLevel) },
        { field: "orphacode", headerName: t(DiseaseTranslKey.ORPHACODE), width: 120 },
    ];

    const defaultSortModel: GridSortModel = useMemo(() => [
        {field: "name", sort: "asc"},
    ], []);

    const [sortModel, setSortModel] = useState(defaultSortModel);

    return (
        <DataGrid
            rows={diseases}
            columns={columnDefs}
            autoHeight
            rowSelectionModel={selectedId}
            onRowSelectionModelChange={((rowSelectionModel) =>
                    onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowDoubleClick={(params) => onRowDoubleClick?.(params.row)} />
    );
}
