import {DiseaseSpecializationClient} from "../../disease/specialization/DiseaseSpecializationPanel";
import {DiseaseSpecializationDto} from "../../../client/disease/DiseaseSpecializationDto";
import {OrganizationApiClient} from "../../../client/organization/OrganizationApiClient";

export class OrganizationSpecializationClientAdapter implements DiseaseSpecializationClient {
    private readonly organizationId: string | undefined;
    private readonly organizationClient: OrganizationApiClient;

    constructor(organizationId: string | undefined, organizationClient: OrganizationApiClient) {
        this.organizationId = organizationId;
        this.organizationClient = organizationClient;
    }

    findSpecializations(): Promise<DiseaseSpecializationDto[]> {
        if (this.organizationId) {
            return this.organizationClient.findOrganizationSpecializations(this.organizationId);
        } else {
            return Promise.resolve([]);
        }
    }

    createSpecialization(diseaseId: string): Promise<string> {
        if (this.organizationId) {
            return this.organizationClient.createOrganizationSpecialization(this.organizationId, diseaseId);
        } else {
            return Promise.reject(new Error("Organization not initialized"));
        }
    }

    deleteSpecialization(specializationId: string): Promise<void> {
        if (this.organizationId) {
            return this.organizationClient.deleteOrganizationSpecialization(this.organizationId, specializationId);
        } else {
            return Promise.reject(new Error("Organization not initialized"));
        }

    }

}