import React from "react";
import PageHeader from "../../../components/page/PageHeader";
import {PageContent} from "../../../components/page/PageContent";
import RegistrationForm from "./RegistrationForm";
import {useTranslation} from "react-i18next";
import {RegistrationTranslKey} from "../RegistrationTranslKey";
import {Box, Paper} from "@mui/material";

export interface RegistrationPageProps {
}

export default function RegistrationFormPage(props: Readonly<RegistrationPageProps>) {
    const { t } = useTranslation();

    const rightElement = (
            <img src="/rita-logo.png" alt="RITA logo" style={{height: '50px', width: 'auto'}} />
    );

    return (
            <Box sx={{alignContent: 'center', justifyContent: 'center', display: 'flex'}}>
                <Box sx={{maxWidth: 'md', width: '100%'}}>
                    <Paper elevation={1} sx={{ p: 3, backgroundColor: 'background.paper' , border: 3, borderColor: 'primary.main'}}>
                        <PageHeader title={t(RegistrationTranslKey.REGISTRATION)} rightElement={rightElement} />
                        <PageContent>
                            <RegistrationForm />
                        </PageContent>
                    </Paper>
                </Box>
            </Box>
    );
}
