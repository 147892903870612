import {TopicDto} from "../../../client/topic/TopicApiClient";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SelectButton} from "../../../common/button/CommonButtons";
import TopicSearchPanel from "./TopicSearchPanel";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {TopicTranslKey} from "../TopicTranslKey";


export interface TopicSearchDialogProps {
    open: boolean;
    onClose: (selectedTopic?: TopicDto) => void;
}

export default function TopicSearchDialog(props: Readonly<TopicSearchDialogProps>) {
    const {open, onClose} = props;

    const [selected, setSelected] = useState<TopicDto>();
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"lg"}>
            <DialogTitle title={t(TopicTranslKey.SEARCH_TOPIC)} onClose={() => onClose(undefined)} />
            <DialogContent>
                <TopicSearchPanel onSelected={setSelected} />
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)} />
                    <SelectButton variant={"primary"}
                                  disabled={selected === undefined}
                                  onClick={() => onClose(selected)} />
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}