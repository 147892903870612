import {Typography} from "@mui/material";


export interface SectionHeaderProps {
    title: string;
}

export default function SectionHeader(props: SectionHeaderProps) {
    const { title } = props;

    return (
        <Typography variant={"h5"} color={"primary"}>{title}</Typography>
    );
}