import {EnqueueSnackbar, useSnackbar as useNotistackSnackbar, VariantType} from 'notistack'
import {useRef} from "react";

export interface Snackbar {
    success: (msg: string) => void;
    info: (msg: string) => void;
    warning: (msg: string) => void;
    error: (msg: string, err: any) => void;
}

export function useSnackbar(): Snackbar {
    const {enqueueSnackbar} = useNotistackSnackbar();
    const snackbarRef = useRef(new NotistackSnackbar(enqueueSnackbar));
    return snackbarRef.current;
}

class NotistackSnackbar implements Snackbar {
    constructor(private readonly enqueueSnackbar: EnqueueSnackbar) {
    }

    error(msg: string, err: any): void {
        this.toast(msg, 'error');
        console.error(`An error has occured:`);
        console.error(err);
    }

    info(msg: string): void {
        this.toast(msg, 'info');
    }

    success(msg: string): void {
        this.toast(msg, 'success');
    }

    warning(msg: string): void {
        this.toast(msg, 'warning');
    }

    private toast(msg: string, variant: VariantType = 'default') {
        this.enqueueSnackbar(msg, {variant});
    }
}