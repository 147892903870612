import {createContext, useContext} from "react";

export interface OAuth2Client {
    authenticated?: boolean;
    token?: string;
    refreshToken?: string;
    clientId?: string;
    updateToken: (minValidity?: number) => Promise<boolean>;
    login: () => void;
    logout: () => void;
}

export interface AuthContextProps {
    initialized: boolean;
    authClient?: OAuth2Client;
}

const AuthContext = createContext<AuthContextProps>({initialized: false, authClient: undefined});

export default AuthContext;

export function useAuth() {
    const context = useContext(AuthContext);

    if (!context.authClient) {
        throw new Error('Keycloak client has not been assigned to AuthProvider');
    }

    const { authClient, initialized } = context;
    return {
        initialized,
        authClient: authClient
    };
}