import {UserDto, UserRoleType} from "../../../client/user/UserApiClient";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SelectButton} from "../../../common/button/CommonButtons";
import UserSearchPanel from "./UserSearchPanel";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {UserTranslKey} from "../UserTranslKey";


export interface UserSearchDialogProps {
    open: boolean;
    onClose: (selectedUser?: UserDto) => void;
    fixedValidRoleTypes?: UserRoleType[];
}

export default function UserSearchDialog(props: Readonly<UserSearchDialogProps>) {
    const {open, onClose, fixedValidRoleTypes} = props;

    const [selected, setSelected] = useState<UserDto>();
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"lg"}>
            <DialogTitle title={t(UserTranslKey.SEARCH_USER)} onClose={() => onClose(undefined)} />
            <DialogContent>
                <UserSearchPanel onSelected={setSelected}
                                 fixedValidRoleTypes={fixedValidRoleTypes} />
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)} />
                    <SelectButton variant={"primary"}
                                  disabled={selected === undefined}
                                  onClick={() => onClose(selected)} />
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}