import {Divider, Grid, SvgIconProps, Typography} from "@mui/material";

export interface PageHeaderProps {
    title: string;
    Icon?: React.ComponentType<SvgIconProps>;
    rightElement?: React.ReactNode;
}

export default function PageHeader({title, Icon, rightElement}: PageHeaderProps) {
    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Grid container alignItems="center">
                        {Icon && (
                            <Grid item>
                                <Icon color="primary" fontSize="medium"/>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant="h4" padding={1} sx={{ color: (theme) => theme.palette.primary.main }}>
                                {title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {rightElement && (
                    <Grid item>
                        {rightElement}
                    </Grid>
                )}
            </Grid>
            <Divider />
        </>
    );
}
