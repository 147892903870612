import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {AddButton} from "../../../../common/button/CommonButtons";
import ButtonBar from "../../../../components/button/ButtonBar";
import {useClientLocator} from "../../../../client/ApiClientLocator";
import {useSnackbar} from "../../../../components/snackbar/Snackbar";
import ListSkeleton from "../../../../common/list/ListSkeleton";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../../common/CommonTranslKey";
import {ContactRegistrationTopicInterestDto} from "../../../../client/registration/RegistrationApiClient";
import {TopicDto} from "../../../../client/topic/TopicApiClient";
import TopicInterestListItem from "./TopicInterestListItem";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";
import TopicInterestCreateDialog from "./TopicInterestCreateDialog";
import TopicInterestDeleteDialog from "./TopicInterestDeleteDialog";

export interface TopicInterestModel {
    topicInterest: ContactRegistrationTopicInterestDto;
    topic: TopicDto;
}

export interface TopicInterestPanelProps {
    registrationRequestId: string;
    canUpdate: boolean;
    onUpdated?: () => void;
}

export default function TopicInterestPanel(props: Readonly<TopicInterestPanelProps>) {
    const {registrationRequestId, canUpdate, onUpdated} = props;

    const {registrationClient, topicClient} = useClientLocator();

    const [interests, setInterests] = useState<TopicInterestModel[]>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [interestToDelete, setInterestToDelete] = useState<TopicInterestModel>();
    const [refresh, setRefresh] = useState(Date.now());

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    useEffect(() => {
        registrationClient.findContactDataTopicInterests(registrationRequestId)
            .then((topicInterests) => {
                const promises: Promise<[ContactRegistrationTopicInterestDto, TopicDto]>[] = topicInterests.map(topicInterest =>
                    new Promise((resolve, reject) => {
                        topicClient.findById(topicInterest.topicId)
                            .then(topic => {
                                if (topic) {
                                    resolve([topicInterest, topic]);
                                } else {
                                    reject(`Topic with ID ${topicInterest.topicId} not found.`);
                                }
                            })
                            .catch((error) => reject(error));
                    }));
                return Promise.all(promises);
            })
            .then((values) => {
                const topicInterests: TopicInterestModel[] = values.map(([topicInterest, topic]) => ({topicInterest: topicInterest, topic: topic}));
                setInterests(topicInterests);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
    }, [registrationRequestId, refresh, registrationClient, topicClient, t, snackbar]);

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdInterestId?: string) {
        setCreateDialogOpen(false);
        if (createdInterestId) {
            setRefresh(Date.now());
            onUpdated?.();
        }
    }

    function showDeleteDialog(interest: TopicInterestModel) {
        setDeleteDialogOpen(true);
        setInterestToDelete(interest);
    }

    function onDeleteDialogClosed(deletedId: boolean) {
        setDeleteDialogOpen(false);
        if (deletedId) {
            setRefresh(Date.now());
            onUpdated?.();
        }
    }

    if (!interests) {
        return (
            <ListSkeleton rows={2} rowHeight={72} />
        );
    }

    let content: React.ReactNode;
    if (interests.length > 0) {
        content = (
            <List component={"nav"}>
                {interests.map(
                    (topicInterest) =>
                        <TopicInterestListItem topicInterestId={topicInterest.topicInterest.topicInterestId}
                                               topic={topicInterest.topic}
                                               key={topicInterest.topicInterest.topicInterestId}
                                               disabled={!canUpdate}
                                               onDeleteClick={() => showDeleteDialog(topicInterest)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <DataArray/>
                <Typography variant={"body2"}>{t(RegistrationTranslKey.NO_TOPICS)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!canUpdate} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>

            <TopicInterestCreateDialog open={createDialogOpen}
                                       registrationRequestId={registrationRequestId}
                                       onClose={onCreateDialogClosed} />
            <TopicInterestDeleteDialog open={deleteDialogOpen}
                                       onClose={(deleted: boolean) => onDeleteDialogClosed(deleted)}
                                       registrationRequestId={registrationRequestId}
                                       topicInterest={interestToDelete?.topicInterest}
                                       topic={interestToDelete?.topic} />
        </>
    );
}