import {Button as MuiButton, ButtonProps as MuiButtonProps, CircularProgress} from "@mui/material";
import {MouseEventHandler} from "react";


export type ButtonVariant = "primary" | "secondary" | "text";

const variantToMuiVariantMapping = new Map<ButtonVariant, MuiButtonProps["variant"]>([
    ["primary", "contained"],
    ["secondary", "outlined"],
    ["text", "text"],
]);

export interface ButtonProps {
    icon?: React.ReactNode;
    onClick?: MouseEventHandler;
    variant?: ButtonVariant;
    color?: MuiButtonProps["color"];
    disabled?: boolean;
    inProgress?: boolean;
    children: React.ReactNode;
}

export default function Button(props: ButtonProps) {
    const {icon, onClick, variant, color, disabled, inProgress, children} = props;
    const muiVariant = variant ? variantToMuiVariantMapping.get(variant) : "contained";

    return (
        <MuiButton variant={muiVariant}
                   onClick={onClick}
                   startIcon={icon}
                   color={color}
                   disabled={disabled || inProgress}
                   sx={{position: "relative"}}>
            {children}
            {inProgress &&
                <CircularProgress variant={"indeterminate"}
                                  size={24}
                                  sx={{position: "absolute", margin: "auto"}} />
            }
        </MuiButton>
    );
}