import './App.css';
import ApiClientLocatorContext, {ApiClientLocator} from "./client/ApiClientLocator";
import {DiseaseApiClient} from "./client/disease/DiseaseApiClient";
import {useRef} from "react";
import {EnvConfiguration} from "./EnvConfig";
import {ContactApiClient} from "./client/contact/ContactApiClient";
import {CountryApiClient} from "./client/catalog/CatalogApiClient";
import axios, {AxiosInstance} from "axios";
import {UserApiClient} from "./client/user/UserApiClient";
import {OrganizationApiClient} from "./client/organization/OrganizationApiClient";
import {AffiliationApiClient} from "./client/affiliation/AffiliationApiClient";
import {RegistrationApiClient} from "./client/registration/RegistrationApiClient";
import {TopicApiClient} from "./client/topic/TopicApiClient";
import {Outlet} from "react-router-dom";
import {TopicMembershipApiClient} from "./client/contact/TopicMembershipApiClient";


export default function PublicApp() {

    const envConfig = useRef(EnvConfiguration.getConfig());

    const apiClientLocator = useRef<ApiClientLocator>();
    if (!apiClientLocator.current) {
        apiClientLocator.current = initClientLocator(envConfig.current.registryApiUrl);
    }

    function initClientLocator(backendRestApiUrl: string): ApiClientLocator {
        const axiosInstance = initAxiosInstance(backendRestApiUrl);
        return {
            affiliationClient: new AffiliationApiClient(axiosInstance),
            contactClient: new ContactApiClient(axiosInstance),
            countryClient: new CountryApiClient(axiosInstance),
            diseaseClient: new DiseaseApiClient(axiosInstance),
            organizationClient: new OrganizationApiClient(axiosInstance),
            registrationClient: new RegistrationApiClient(axiosInstance),
            topicClient: new TopicApiClient(axiosInstance),
            topicMembershipClient: new TopicMembershipApiClient(axiosInstance),
            userClient: new UserApiClient(axiosInstance),
        };
    }

    function initAxiosInstance(backendRestApiUrl: string): AxiosInstance {
        const axiosInstance = axios.create({
            baseURL: backendRestApiUrl,
        });
        return axiosInstance;
    }

    return (
        <ApiClientLocatorContext.Provider value={apiClientLocator.current}>
            <Outlet/>
        </ApiClientLocatorContext.Provider>
    );
}
