
export enum CommonTranslKey {
    ABOUT = "common:about",
    ADD = "common:add",
    CANCEL = "common:cancel",
    CLEAR = "common:clear",
    DELETE = "common:delete",
    DETAILS = "common:details",
    EDIT = "common:edit",
    EMAIL_WRONG_FORMAT = "common:emailWrongFormat",
    CONTAINS_VALIDATION_ERRORS = "common:containsValidationErrors",
    DATA_DELETED = "common:dataDeleted",
    DATA_INVALIDATED = "common:dataInvalidated",
    DATA_SAVED = "common:dataSaved",
    INTERNATIONAL = "common:international",
    INVALIDATE = "common:invalidate",
    ITEM_MUST_BE_SELECTED = "common:itemMustBeSelected",
    LOGOUT = "common:logout",
    NO = "common:no",
    SAVE = "common:save",
    SEARCH = "common:search",
    SELECT = "common:select",
    SELECT_FROM_LIST = "common:selectFromList",
    SELECT_FROM_LIST_OR_TYPE_OWN = "common:selectFromListOrTypeOwn",
    SESSION_EXPIRED_PROMPT = "common:sessionExpiredPrompt",
    SUBMIT = "common:submit",
    TERMS_AND_CONDITIONS_AGREEMENT = "common:termsAndConditionsAgreement",
    UNEXPECTED_ERROR = "common:unexpectedError",
    YES = "common:yes",
    VALUE_MUST_NOT_BE_EMPTY_OR_BLANK = "common:valueMustNotBeEmptyOrBlank"
}
