import {Grid} from "@mui/material";
import {
    ContactRegistrationRequestFilterDto,
    ContactRegistrationRequestStateType
} from "../../../../client/registration/RegistrationApiClient";
import {CopyUtils} from "../../../../util/CopyUtils";
import TextField from "../../../../components/field/TextField";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";
import {useTranslation} from "react-i18next";
import MultiSelectField from "../../../../components/field/MultiSelectField";
import {ContactRegistrationRequestStateFormatter} from "../ContactRegistrationRequestStateFormatter";

export interface RegistrationRequestFilterFormProps {
    filter: ContactRegistrationRequestFilterDto;
    onChange: (filter: ContactRegistrationRequestFilterDto) => void;
}

export function RegistrationRequestFilterForm(props: RegistrationRequestFilterFormProps) {
    const {filter, onChange} = props;

    const {t} = useTranslation();

    function onFirstNameChange(firstName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.firstNameLike = normalizeString(firstName);
        onChange(updatedFilter);
    }

    function onLastNameChange(lastName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.lastNameLike = normalizeString(lastName);
        onChange(updatedFilter);
    }

    function onEmailChange(email: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.emailLike = normalizeString(email);
        onChange(updatedFilter);
    }

    function onStateChange(states: ContactRegistrationRequestStateType[]) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.states = states;
        onChange(updatedFilter);
    }

    function normalizeString(value: string): string | undefined {
        const normalizedValue = value.trim();
        return (normalizedValue.length > 0) ? normalizedValue : undefined;
    }

    const stateOptions = Object.keys(ContactRegistrationRequestStateType).map((stateType) => ({
        value: stateType as ContactRegistrationRequestStateType,
        label: ContactRegistrationRequestStateFormatter.formatStateType(stateType as ContactRegistrationRequestStateType, t)
    }));

    return (
        <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
                <TextField label={t(RegistrationTranslKey.FIRST_NAME)}
                           value={filter.firstNameLike ?? ""}
                           onChange={onFirstNameChange} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <TextField label={t(RegistrationTranslKey.LAST_NAME)}
                           value={filter.lastNameLike ?? ""}
                           onChange={onLastNameChange} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <TextField label={t(RegistrationTranslKey.EMAIL)}
                           value={filter.emailLike ?? ""}
                           onChange={onEmailChange} />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
                <MultiSelectField label={t(RegistrationTranslKey.STATE)}
                                  options={stateOptions}
                                  limitTags={1}
                                  value={filter.states ?? []}
                                  onChange={values => onStateChange(values as ContactRegistrationRequestStateType[])} />
            </Grid>
        </Grid>
    );
}

