import {DiseaseDetailDto, DiseaseDto, DiseaseHierarchyRelationDto} from "../../../client/disease/DiseaseApiClient";
import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {AddButton} from "../../../common/button/CommonButtons";
import ButtonBar from "../../../components/button/ButtonBar";
import DiseaseRelationListItem from "./DiseaseRelationListItem";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import DiseaseParentRelationDeleteDialog from "./DiseaseParentRelationDeleteDialog";
import DiseaseParentRelationCreateDialog from "./DiseaseParentRelationCreateDialog";
import ListSkeleton from "../../../common/list/ListSkeleton";

interface RelationModel {
    relation: DiseaseHierarchyRelationDto;
    parent: DiseaseDto;
}

export interface DiseaseRelationsPanelProps {
    disease?: DiseaseDetailDto;
    onUpdated?: () => void;
}

export default function DiseaseRelationsPanel(props: DiseaseRelationsPanelProps) {
    const {disease, onUpdated} = props;

    const {diseaseClient} = useClientLocator();

    const [relations, setRelations] = useState<RelationModel[]>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [relationToDelete, setRelationToDelete] = useState<DiseaseHierarchyRelationDto>();
    const [relationParentToDelete, setRelationParentToDelete] = useState<DiseaseDto>();

    const snackbar = useSnackbar();

    useEffect(() => {
        if (disease) {
            diseaseClient.findHierarchyRelations(disease.info.id)
                .then((relations) => {
                    const parentRelations = relations.filter(relation => relation.parent.toString() !== disease.info.id);
                    const promises: Promise<[DiseaseHierarchyRelationDto, DiseaseDto]>[] = parentRelations.map(relation =>
                            new Promise((resolve, reject) => {
                                diseaseClient.findById(relation.parent)
                                    .then(disease => {
                                        resolve([relation, disease!.info]);
                                    })
                                    .catch((error) => reject(error));
                            }));
                    return Promise.all(promises);
                })
                .then((values) => {
                    const relations: RelationModel[] = values.map(([relation, parent]) => ({relation: relation, parent: parent}));
                    setRelations(relations);
                })
                .catch((error) => snackbar.error("Unexpected error occured", error));
        } else {
            setRelations([]);
        }
    }, [disease, diseaseClient, snackbar]);

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdRelationId?: string) {
        setCreateDialogOpen(false);
        if (createdRelationId) {
            onUpdated?.();
        }
    }

    function showDeleteDialog(relation: DiseaseHierarchyRelationDto, parent: DiseaseDto) {
        setDeleteDialogOpen(true);
        setRelationToDelete(relation);
        setRelationParentToDelete(parent);
    }

    function onDeleteDialogClosed(deleted: boolean) {
        setDeleteDialogOpen(false);
        if (deleted) {
            onUpdated?.();
        }
    }

    if (!disease || !relations) {
        return (
            <ListSkeleton rows={2} rowHeight={72} />
        );
    }

    let content: React.ReactNode;
    if (relations.length > 0) {
        content = (
            <List component={"nav"}>
                {relations.map(
                    (relation, index) =>
                        <DiseaseRelationListItem relation={relation.relation}
                                                 parent={relation.parent}
                                                 key={relation.relation.id}
                                                 disabled={!disease.info.acl.canUpdate}
                                                 onDeleteClick={showDeleteDialog}/>
                )}
            </List>
        );
    } else {
        content = (
            <>
                <Stack direction={"row"}
                       spacing={1}
                       alignItems={"center"}
                       sx={{m: 2}} >
                    <DataArray/>
                    <Typography variant={"body2"}>No parents</Typography>
                </Stack>
            </>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!disease.info.acl.canUpdate} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>
            <DiseaseParentRelationCreateDialog open={createDialogOpen}
                                               onClose={onCreateDialogClosed}
                                               child={disease.info} />

            <DiseaseParentRelationDeleteDialog open={deleteDialogOpen}
                                               onClose={onDeleteDialogClosed}
                                               relation={relationToDelete}
                                               parent={relationParentToDelete}
                                               diseaseId={disease.info.id} />
        </>
    );
}