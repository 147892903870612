import {Grid} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import ButtonBar from "../../../components/button/ButtonBar";
import {SearchButton} from "../../../common/button/CommonButtons";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {ContactFilterDto, ContactSummaryDto} from "../../../client/contact/ContactApiClient";
import ContactGrid from "../ContactGrid";
import {ContactFilterForm} from "./ContactFilterForm";
import DataGridActionBar from "../../../components/datagrid/DataGridActionBar";
import {ContactEventType} from "../ContactEventType";
import {EventListenerUnsubscriber, useEventDispatcher} from "../../../components/EventDispatcher";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";

enum SearchPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}

export interface ContactSearchPanelProps {
    onSelected: (contact?: ContactSummaryDto) => void;
    onRowDoubleClick?: (contactId: string) => void;
    actions?: React.ReactNode;
}

export default function ContactSearchPanel(props: ContactSearchPanelProps) {
    const {onSelected, onRowDoubleClick, actions} = props;

    const {contactClient} = useClientLocator();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();
    const {t} = useTranslation();

    const [state, setState] = useState<SearchPanelState>(SearchPanelState.INITIALIZED);
    const [filter, setFilter] = useState<ContactFilterDto>({});
    const [contacts, setContacts] = useState<ContactSummaryDto[]>([]);

    const [selectedContact, setSelectedContact] = useState<ContactSummaryDto>();

    function onFilterChanged(updatedFilter: ContactFilterDto) {
        setFilter(updatedFilter);
    }

    const search = useCallback(() => {
        setState(SearchPanelState.SEARCH_IN_PROGRESS);
        contactClient.findByFilter(filter)
            .then((contacts) => {
                setContacts(contacts);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error))
            .finally(() => setState(SearchPanelState.IDLE))
    }, [filter, contactClient, snackbar, t]);

    useEffect(() => {
        const unsubscribers: EventListenerUnsubscriber[] = [];
        unsubscribers.push(eventDispatcher.addEventListener(ContactEventType.CONTACT_CREATED, () => search()));
        unsubscribers.push(eventDispatcher.addEventListener(ContactEventType.CONTACT_UPDATED, () => search()));
        unsubscribers.push(eventDispatcher.addEventListener(ContactEventType.CONTACT_DELETED, () => search()));

        return () => unsubscribers.forEach(unsubscribe => unsubscribe());
    }, [eventDispatcher, search, filter]);

    useEffect(() => {
        if (state === SearchPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    function onSelectionChange(contactId?: string) {
        let contact = contactId
            ? contacts.find((contact) => contact.id === contactId)
            : undefined;
        setSelectedContact(contact);
        onSelected(contact);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <ContactFilterForm filter={filter}
                                   onChange={onFilterChanged} />
            </Grid>
            <Grid item xs={12}>
                <ButtonBar>
                    <SearchButton onClick={() => search()}
                                  inProgress={state === SearchPanelState.SEARCH_IN_PROGRESS} />
                </ButtonBar>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {actions &&
                        <Grid item xs={12}>
                            <DataGridActionBar actions={actions} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {state !== SearchPanelState.INITIALIZED &&
                            <ContactGrid contacts={contacts}
                                         selectedId={selectedContact?.id}
                                         onSelectionChange={onSelectionChange}
                                         onRowDoubleClick={onRowDoubleClick}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}
