import {PagePaper} from "../../../components/page/PagePaper";
import PageHeader from "../../../components/page/PageHeader";
import {PageContent} from "../../../components/page/PageContent";
import RegistrationRequestSearchPanel from "./search/RegistrationRequestSearchPanel";
import {ContactRegistrationRequestDto} from "../../../client/registration/RegistrationApiClient";
import {useState} from "react";
import {DetailActionButton} from "../../../common/button/CommonActionButtons";
import {RegistrationTranslKey} from "../RegistrationTranslKey";
import {useTranslation} from "react-i18next";
import {AssignmentInd} from "@mui/icons-material";
import RegistrationRequestDetailDialog from "./RegistrationRequestDetailDialog";

export interface RegistrationRequestPageProps {
}

export default function RegistrationRequestPage(props: Readonly<RegistrationRequestPageProps>) {

    const [selectedRegistrationRequest, setSelectedRegistrationRequest] = useState<ContactRegistrationRequestDto>();

    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailRegistrationRequestId, setDetailRegistrationRequestId] = useState<string>();

    const {t} = useTranslation();

    function showDetailDialog(registrationRequestId: string) {
        setDetailRegistrationRequestId(registrationRequestId);
        setDetailDialogOpen(true);
    }

    function onDetailDialogClose() {
        setDetailDialogOpen(false);
    }

    const actions: React.ReactNode = (
        <>
            <DetailActionButton onClick={() => showDetailDialog(selectedRegistrationRequest!.id)}
                                disabled={!selectedRegistrationRequest} />
        </>
    );

    return (
        <>
            <PagePaper>
                <PageHeader title={t(RegistrationTranslKey.REGISTRATIONS)} Icon={AssignmentInd} />
                <PageContent>
                    <RegistrationRequestSearchPanel actions={actions}
                                                    onSelected={setSelectedRegistrationRequest}
                                                    onRowDoubleClick={showDetailDialog} />
                </PageContent>
            </PagePaper>

            <RegistrationRequestDetailDialog open={detailDialogOpen}
                                             onClose={() => onDetailDialogClose()}
                                             registrationRequestId={detailRegistrationRequestId}/>
        </>
    );
}