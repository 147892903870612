import {TranslationFunction} from "../../../i18n/i18n";
import {ContactTranslKey} from "../ContactTranslKey";
import {CpmsStatus} from "../../../client/contact/ContactApiClient";

export class CpmsStatusFormatter {
    public static translateCpmsStatus(status: string, t: TranslationFunction): string {
        const translKey = ContactTranslKey.CPMS_STATUS_NAMESPACE + status;
        return t(translKey);
    }

    public static composeStatusAndOtherStatus(status: string, otherStatus: string | undefined):  string {
        if (status === CpmsStatus.OTHER) {
            return otherStatus ?? "";
        } else {
            return status;
        }
    }

    public static decomposeStatusAndOtherStatus(status: string): [CpmsStatus, string | undefined] {
        const catalogStatuses = Object.keys(CpmsStatus)
                .filter((title) => title !== CpmsStatus.OTHER);
        if (catalogStatuses.includes(status)) {
            return [status as CpmsStatus, undefined];
        } else {
            return [CpmsStatus.OTHER, status];
        }
    }

    public static formatStatus(status: CpmsStatus | string | undefined, otherStatus: string | undefined | null, t: TranslationFunction): string {
        if (!status) {
            return "";
        }

        if (status === CpmsStatus.OTHER) {
            return otherStatus ?? "";
        }

        return this.translateCpmsStatus(status, t);
    }
}
