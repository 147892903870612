import {DiseaseTreeItemModel} from "./DiseaseTreeModel";
import {TreeItem} from "@mui/x-tree-view";
import {CircularProgress} from "@mui/material";

export interface InProgressTreeItemProps {
    parentItemModel: DiseaseTreeItemModel;
}

export default function InProgressTreeItem({parentItemModel}: InProgressTreeItemProps) {
    return (
        <TreeItem nodeId={`${parentItemModel.disease.id}-unfetched-children`}
                  label={<CircularProgress size={12}/>}
        />
);
}