import {DiseaseResourceUpdateFormModel} from "./DiseaseResourceUpdateFromModel";
import {CopyUtils} from "../../../util/CopyUtils";
import {CommonTranslKey} from "../../../common/CommonTranslKey";

interface ValidationResult {
    isValid: boolean;
    updateFormModel: DiseaseResourceUpdateFormModel;
}

export default function validateResourceUpdateFormModel(formModel: DiseaseResourceUpdateFormModel): ValidationResult {
    const validatedFormModel = CopyUtils.deepCopy(formModel);

    let isValid = true;

    if (formModel.title.value.trim().length < 1) {
        isValid = false;
        validatedFormModel.title.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    } else {
        validatedFormModel.title.errorMessageCode = undefined;
    }

    if (formModel.url.value.trim().length < 1) {
        isValid = false;
        validatedFormModel.url.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    } else {
        validatedFormModel.url.errorMessageCode = undefined;
    }

    if (formModel.location.value.trim().length < 1) {
        isValid = false;
        validatedFormModel.location.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    } else {
        validatedFormModel.location.errorMessageCode = undefined;
    }

    if (formModel.type.value.trim().length < 1) {
        isValid = false;
        validatedFormModel.type.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    } else {
        validatedFormModel.type.errorMessageCode = undefined;
    }

    return {
        isValid: isValid,
        updateFormModel: validatedFormModel
    }
}
