import {Breakpoint as MuiBreakpoint, Dialog as MuiDialog} from "@mui/material";

export interface DialogProps {
    open: boolean;
    onClose: () => void;
    maxWidth?: MuiBreakpoint | false;
    children?: React.ReactNode;
}

export default function Dialog(props: DialogProps) {
    const {open, onClose, maxWidth, children} = props;

    return (
        <MuiDialog open={open} onClose={onClose} maxWidth={maxWidth} fullWidth={true}>
            {children}
        </MuiDialog>
    );

}