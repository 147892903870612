import {TranslationFunction} from "../../i18n/i18n";
import {EuropeanReferenceNetwork} from "../../client/organization/OrganizationApiClient";
import {OrganizationTranslKey} from "./OrganizationTranslKey";

export class EuropeanReferenceNetworkFormatter {

    public static formatErn(ern: EuropeanReferenceNetwork | string | null | undefined, t: TranslationFunction): string {
        if (!ern) {
            return "";
        }

        const translKey = OrganizationTranslKey.ERN_NAMESPACE + ern;
        return t(translKey);
    }

    static composeAndFormatErn(title: string | undefined, otherTitle: string | undefined, t: TranslationFunction): string | undefined {
        if (title === EuropeanReferenceNetwork.OTHER) {
            return otherTitle;
        } else {
            return EuropeanReferenceNetworkFormatter.formatErn(title, t);
        }
    }

    static composeErn(title: string | undefined, otherTitle: string | undefined): string | undefined {
        if (title === EuropeanReferenceNetwork.OTHER) {
            return otherTitle;
        } else {
            return title;
        }
    }

    static decomposeErn(ern: string | undefined): [string | undefined, string | undefined] {
        if (ern) {
            const catalogErns = Object.keys(EuropeanReferenceNetwork)
                .filter((title) => title !== EuropeanReferenceNetwork.OTHER);
            if (catalogErns.includes(ern)) {
                return [ern, undefined];
            } else {
                return [EuropeanReferenceNetwork.OTHER, ern];
            }
        } else {
            return [undefined, undefined];
        }
    }
}