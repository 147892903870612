import {List, Skeleton} from "@mui/material";

export interface ListSkeletonProps {
    rows: number;
    rowHeight: number;
}

export default function ListSkeleton(props: ListSkeletonProps) {
    const {rows, rowHeight} = props;

    const skeletonItems: React.ReactNode[] = [];
    for (let i = 0; i < rows; i++) {
        skeletonItems.push(<Skeleton key={i} height={rowHeight} />);
    }

    return (
        <List component={"nav"}>
            {skeletonItems}
        </List>
    );
}