import {DiseaseApiClient} from "./disease/DiseaseApiClient";
import {createContext, useContext} from "react";
import {ContactApiClient} from "./contact/ContactApiClient";
import {CountryApiClient} from "./catalog/CatalogApiClient";
import {UserApiClient} from "./user/UserApiClient";
import {OrganizationApiClient} from "./organization/OrganizationApiClient";
import {AffiliationApiClient} from "./affiliation/AffiliationApiClient";
import {RegistrationApiClient} from "./registration/RegistrationApiClient";
import {TopicApiClient} from "./topic/TopicApiClient";
import {TopicMembershipApiClient} from "./contact/TopicMembershipApiClient";

export interface ApiClientLocator {
    affiliationClient: AffiliationApiClient;
    contactClient: ContactApiClient;
    countryClient: CountryApiClient;
    diseaseClient: DiseaseApiClient;
    organizationClient: OrganizationApiClient;
    registrationClient: RegistrationApiClient;
    topicClient: TopicApiClient;
    topicMembershipClient: TopicMembershipApiClient;
    userClient: UserApiClient;
}

export function useClientLocator() {
    const locator = useContext(ApiClientLocatorContext);
    if (locator) {
        return locator;
    } else {
        throw new Error("An API Client Locator context has not been provided");
    }
}

const ApiClientLocatorContext = createContext<ApiClientLocator | undefined>(undefined);
export default ApiClientLocatorContext;
