import {InternalAxiosRequestConfig} from "axios";
import {OAuth2Client} from "./AuthContext";

export default class BearerTokenRequestInterceptor {
    constructor(private readonly oauth2Client: OAuth2Client) {
    }

    interceptRequest(config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> {
        const token = this.oauth2Client.token;
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }
}