import {PagePaper} from "../../components/page/PagePaper";
import PageHeader from "../../components/page/PageHeader";
import {PageContent} from "../../components/page/PageContent";
import {Person} from "@mui/icons-material";
import UserSearchPanel from "./search/UserSearchPanel";
import {UserDto} from "../../client/user/UserApiClient";
import {useState} from "react";
import {DetailActionButton} from "../../common/button/CommonActionButtons";
import UserDetailDialog from "./UserDetailDialog";
import {useTranslation} from "react-i18next";
import {UserTranslKey} from "./UserTranslKey";

export interface UserPageProps {
}

export default function UserPage(props: UserPageProps) {

    const [selectedUser, setSelectedUser] = useState<UserDto>();
    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailUser, setDetailUser] = useState<UserDto>();

    const {t} = useTranslation();

    function showDetailDialog(user?: UserDto) {
        if (user) {
            setDetailUser(user);
            setDetailDialogOpen(true);
        }
    }

    function onDetailDialogClose() {
        setDetailDialogOpen(false);
    }

    const actions: React.ReactNode = (
        <>
            <DetailActionButton onClick={() => showDetailDialog(selectedUser)}
                                disabled={!selectedUser}/>
        </>
    );

    return (
        <>
            <PagePaper>
                <PageHeader title={t(UserTranslKey.USERS)} Icon={Person} />
                <PageContent>
                    <UserSearchPanel actions={actions}
                                     onSelected={setSelectedUser}
                                     onRowDoubleClick={showDetailDialog} />
                </PageContent>
            </PagePaper>

            {detailUser &&
                <UserDetailDialog open={detailDialogOpen}
                                  onClose={() => onDetailDialogClose()}
                                  userId={detailUser.id}/>
            }
        </>
    );
}