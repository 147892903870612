import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {Delete, Topic} from "@mui/icons-material";
import {TopicDto} from "../../../../client/topic/TopicApiClient";
import {useTranslation} from "react-i18next";
import {TopicTypeFormatter} from "../../../topic/TopicTypeFormatter";


export interface TopicInterestListItemProps {
    topicInterestId: string;
    topic: TopicDto;
    disabled?: boolean;
    onDeleteClick: (topicInterestId: string, topic: TopicDto) => void;
}

export default function TopicInterestListItem(props: Readonly<TopicInterestListItemProps>) {
    const {topicInterestId, topic, disabled, onDeleteClick} = props;

    const {t} = useTranslation();

    function formatSecondaryText(topic: TopicDto) {
        return TopicTypeFormatter.formatTopicType(topic.type, t);
    }

    const secondaryText = formatSecondaryText(topic);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <Topic />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={topic.name} secondary={secondaryText} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onDeleteClick(topicInterestId, topic)}>
                        <Delete />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}