import {Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import ButtonBar from "../../../../components/button/ButtonBar";
import {SearchButton} from "../../../../common/button/CommonButtons";
import {useClientLocator} from "../../../../client/ApiClientLocator";
import {useSnackbar} from "../../../../components/snackbar/Snackbar";
import RegistrationRequestGrid from "../RegistrationRequestGrid";
import {RegistrationRequestFilterForm} from "./RegistrationRequestFilterForm";
import {EventListenerUnsubscriber, useEventDispatcher} from "../../../../components/EventDispatcher";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../../common/CommonTranslKey";
import {
    ContactRegistrationRequestDto,
    ContactRegistrationRequestFilterDto
} from "../../../../client/registration/RegistrationApiClient";
import DataGridActionBar from "../../../../components/datagrid/DataGridActionBar";
import {RegistrationEventType} from "../../RegistrationEventType";

enum SearchPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}

export interface RegistrationRequestSearchPanelProps {
    onSelected: (registrationRequest?: ContactRegistrationRequestDto) => void;
    onRowDoubleClick?: (registrationRequestId: string) => void;
    actions?: React.ReactNode;
}

export default function RegistrationRequestSearchPanel(props: RegistrationRequestSearchPanelProps) {
    const {onSelected, onRowDoubleClick, actions} = props;

    const {registrationClient} = useClientLocator();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();
    const {t} = useTranslation();

    const [state, setState] = useState<SearchPanelState>(SearchPanelState.INITIALIZED);
    const [filter, setFilter] = useState<ContactRegistrationRequestFilterDto>({});
    const [registrationRequests, setRegistrationRequests] = useState<ContactRegistrationRequestDto[]>([]);

    const [selectedRegistrationRequest, setSelectedRegistrationRequest] = useState<ContactRegistrationRequestDto>();

    function onFilterChanged(updatedFilter: ContactRegistrationRequestFilterDto) {
        setFilter(updatedFilter);
    }

    const search = useCallback(() => {
        setState(SearchPanelState.SEARCH_IN_PROGRESS);
        registrationClient.findByFilter(filter)
            .then((registrationRequests) => {
                setRegistrationRequests(registrationRequests);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error))
            .finally(() => setState(SearchPanelState.IDLE))
    }, [filter, registrationClient, snackbar, t]);

    useEffect(() => {
        const unsubscribers: EventListenerUnsubscriber[] = [];
        unsubscribers.push(eventDispatcher.addEventListener(RegistrationEventType.REQUEST_DATA_UPDATED, () => search()));
        unsubscribers.push(eventDispatcher.addEventListener(RegistrationEventType.REQUEST_STATE_CHANGED, () => search()));

        return () => unsubscribers.forEach(unsubscribe => unsubscribe());
    }, [eventDispatcher, search, filter]);

    useEffect(() => {
        if (state === SearchPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    function onSelectionChange(registrationRequestId?: string) {
        let registrationRequest = registrationRequestId
            ? registrationRequests.find((registrationRequest) => registrationRequest.id === registrationRequestId)
            : undefined;
        setSelectedRegistrationRequest(registrationRequest);
        onSelected(registrationRequest);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RegistrationRequestFilterForm filter={filter}
                                   onChange={onFilterChanged} />
            </Grid>
            <Grid item xs={12}>
                <ButtonBar>
                    <SearchButton onClick={() => search()}
                                  inProgress={state === SearchPanelState.SEARCH_IN_PROGRESS} />
                </ButtonBar>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {actions &&
                        <Grid item xs={12}>
                            <DataGridActionBar actions={actions} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {state !== SearchPanelState.INITIALIZED &&
                            <RegistrationRequestGrid registrationRequests={registrationRequests}
                                         selectedId={selectedRegistrationRequest?.id}
                                         onSelectionChange={onSelectionChange}
                                         onRowDoubleClick={onRowDoubleClick}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}
