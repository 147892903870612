import React, {useEffect, useState} from "react";
import {ContactCpmsDto, ContactCpmsUpdateDto, CpmsPanel, CpmsStatus} from "../../../client/contact/ContactApiClient";
import {ContactCpmsUpdateModel} from "./ContactCpmsUpdateModel";
import {CpmsStatusFormatter} from "./CpmsStatusFormatter";
import {useTranslation} from "react-i18next";
import {useEventDispatcher} from "../../../components/EventDispatcher";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import {CpmsPanelFormatter} from "./CpmsPanelFormatter";
import {ContactEventType} from "../ContactEventType";
import TextField from "../../../components/field/TextField";
import {ContactTranslKey} from "../ContactTranslKey";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import SelectField from "../../../components/field/SelectField";
import MultiSelectField from "../../../components/field/MultiSelectField";
import validateContactCpmsUpdateModel, {ValidationResult} from "./ContactCpmsUpdateValidator";
import {Grid} from "@mui/material";

enum ContactCpmsUpdateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

const DEFAULT_MODEL: ContactCpmsUpdateModel = {
    referringPhysicianCount: {value: 0},
    panelLeadCount: {value: 0},
    panelMemberCount: {value: 0},
    status: {value: ""},
    panels: {value: []}
};

export interface ContactCpmsUpdateDialogProps {
    open: boolean;
    onClose: () => void;
    contactId: string;
    contactCpms?: ContactCpmsDto;
}

export default function ContactCpmsUpdateDialog(props: ContactCpmsUpdateDialogProps) {
    const {open, onClose, contactCpms, contactId} = props;

    const [dialogState, setDialogState] = useState(ContactCpmsUpdateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_MODEL);

    const {contactClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setModel(DEFAULT_MODEL);
            setDialogState(ContactCpmsUpdateDialogState.IDLE);
        } else if (contactCpms) {
            setModel(convertContactCpmsToUpdateModel(contactCpms));
        }
    }, [open, contactCpms]);

    function convertContactCpmsToUpdateModel(contactCpms: ContactCpmsDto): ContactCpmsUpdateModel {
        return {
            referringPhysicianCount: {value: contactCpms.referringPhysicianCount},
            panelLeadCount: {value: contactCpms.panelLeadCount},
            panelMemberCount: {value: contactCpms.panelMemberCount},
            status: {value: CpmsStatusFormatter.composeStatusAndOtherStatus(contactCpms.status, contactCpms.otherStatus)},
            panels: {value: contactCpms.panels}
        };
    }

    function updateCpms() {
        setDialogState(ContactCpmsUpdateDialogState.SAVE_IN_PROGRESS);

        if (validate()) {
            const contactDataUpdate = normalize(model);

            contactClient.updateContactCpms(contactId, contactDataUpdate)
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose();
                        eventDispatcher.dispatchEvent(new Event(ContactEventType.CONTACT_CPMS_UPDATED));
                    })
                    .catch((error) => {
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                    });
        }
    }

    function validate(): ValidationResult {
        const result = validateContactCpmsUpdateModel(model);
        setModel(result.formModel);

        return result;
    }

    function normalize(model: ContactCpmsUpdateModel): ContactCpmsUpdateDto {
        const [cpmsStatus, otherCpmsStatus] = CpmsStatusFormatter.decomposeStatusAndOtherStatus(model.status.value);

        return {
            referringPhysicianCount: model.referringPhysicianCount.value,
            panelLeadCount: model.panelLeadCount.value,
            panelMemberCount: model.panelMemberCount.value,
            status: cpmsStatus,
            otherStatus: otherCpmsStatus? otherCpmsStatus.trim() : undefined,
            panels: model.panels.value
        };
    }

    const panelOptions = Object.keys(CpmsPanel)
            .map((panel) => ({
                value: panel,
                label: CpmsPanelFormatter.formatPanel(panel, t)
            }));

    const cpmsStatusOptions = Object.keys(CpmsStatus)
            .filter((status) => status !== CpmsStatus.OTHER)
            .map((status) => ({
                value: status,
                label: CpmsStatusFormatter.translateCpmsStatus(status, t)
            }));

    function isNumber(str: string): boolean {
        const num = Number(str);
        return !isNaN(num) && isFinite(num);
    }

    function translateFromErrorCode(errorCode: string | undefined): string | undefined {
        return errorCode ? t(errorCode) : undefined;
    }

    return (
            <Dialog open={open} onClose={() => onClose()} maxWidth={"md"}>
                <DialogTitle title={t(ContactTranslKey.UPDATE_CONTACT_CPMS)}/>
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(ContactTranslKey.PANEL_LEAD_COUNT)}
                                       required={true}
                                       value={model.panelLeadCount.value}
                                       onChange={(value) => isNumber(value) && setModel({
                                           ...model,
                                           panelLeadCount: {...model.panelLeadCount, value: +value}
                                       })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(ContactTranslKey.PANEL_MEMBER_COUNT)}
                                       required={true}
                                       value={model.panelMemberCount.value}
                                       onChange={(value) => isNumber(value) && setModel({
                                           ...model,
                                           panelMemberCount: {...model.panelMemberCount, value: +value}
                                       })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(ContactTranslKey.REFERRED_PHYSICIAN_COUNT)}
                                       required={true}
                                       value={model.referringPhysicianCount.value}
                                       onChange={(value) => isNumber(value) && setModel({
                                           ...model,
                                           referringPhysicianCount: {
                                               ...model.referringPhysicianCount,
                                               value: +value
                                           }
                                       })}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SelectField label={t(ContactTranslKey.CPMS_STATUS)}
                                         options={cpmsStatusOptions}
                                         acceptFreeText={true}
                                         required={true}
                                         value={model.status.value}
                                         onChange={(value) => {
                                             setModel({
                                                 ...model,
                                                 status: {
                                                     ...model.status,
                                                     value: value as CpmsStatus | string
                                                 }
                                             })
                                         }}
                                         errorMessage={translateFromErrorCode(model.status.errorMessageCode)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MultiSelectField label={t(ContactTranslKey.PANELS)}
                                              options={panelOptions}
                                              value={model.panels.value}
                                              onChange={(value) => setModel({
                                                  ...model,
                                                  panels: {...model.panels, value: value as CpmsPanel[]}
                                              })}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose()}/>
                        <SaveButton variant={"primary"}
                                    onClick={updateCpms}
                                    disabled={!model || !validateContactCpmsUpdateModel(model).isValid}
                                    inProgress={dialogState === ContactCpmsUpdateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>
    );
}

