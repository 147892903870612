import {TranslationFunction} from "../../i18n/i18n";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {HealthcareProviderAffiliation} from "../../client/organization/OrganizationApiClient";

export class HealthcareProviderAffiliationFormatter {
    public static formatAffiliation(hcpAffiliation: HealthcareProviderAffiliation | string | null | undefined, t: TranslationFunction): string {
        if (!hcpAffiliation) {
            return "";
        }

        const translKey = OrganizationTranslKey.HEALTHCARE_PROVIDER_AFFILIATION_NAMESPACE + hcpAffiliation;
        return t(translKey);
    }
}