import {GridColDef, GridSortModel} from "@mui/x-data-grid-pro";
import {TopicDto} from "../../client/topic/TopicApiClient";
import DataGrid from "../../components/datagrid/DataGrid";
import {TopicTranslKey} from "./TopicTranslKey";
import {useTranslation} from "react-i18next";
import {TopicTypeFormatter} from "./TopicTypeFormatter";
import {useMemo, useState} from "react";

export interface TopicGridProps {
    topics: TopicDto[];
    selectedId?: string;
    onSelectionChange?: (topicId?: string) => void;
    onRowDoubleClick?: (topicId: string) => void;
}

export default function TopicGrid(props: Readonly<TopicGridProps>) {
    const {topics, selectedId, onSelectionChange, onRowDoubleClick} = props;

    const {t} = useTranslation();

    const columnDefs: GridColDef<TopicDto>[] = useMemo(() => [
        { field: "name", headerName: t(TopicTranslKey.NAME), width: 220 },
        { field: "type", headerName: t(TopicTranslKey.TYPE), width: 150, valueFormatter: params => TopicTypeFormatter.formatTopicType(params.value, t) },
        { field: "typeGroup", headerName: t(TopicTranslKey.GROUP), width: 150, valueFormatter: params => TopicTypeFormatter.formatTopicGroup(params.value, t) },
        { field: "description", headerName: t(TopicTranslKey.DESCRIPTION), width: 500 },
    ], [t]);

    const defaultSortModel: GridSortModel = useMemo(() => [
        {field: "name", sort: "asc"},
    ], []);

    const [sortModel, setSortModel] = useState(defaultSortModel);

    return (
        <DataGrid rows={topics}
                  columns={columnDefs}
                  autoHeight
                  rowSelectionModel={selectedId}
                  onRowSelectionModelChange={((rowSelectionModel) =>
                      onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
                  sortModel={sortModel}
                  onSortModelChange={setSortModel}
                  onRowDoubleClick={(params) => onRowDoubleClick?.(params.row.id)} />
    );
}
