import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import {useTranslation} from "react-i18next";
import SelectField from "../../../components/field/SelectField";
import {
    TopicMembershipDto,
    TopicMembershipRole,
    TopicMembershipTitle
} from "../../../client/contact/TopicMembershipApiClient";
import {TopicMembershipRoleFormatter} from "./TopicMembershipRoleFormatter";
import {ContactTranslKey} from "../ContactTranslKey";
import {TopicMembershipTitleFormatter} from "./TopicMembershipTitleFormatter";
import {CommonTranslKey} from "../../../common/CommonTranslKey";

enum DialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

export interface TopicMembershipUpdateDialogProps {
    open: boolean;
    onClose: (updatedTopicMembershipId: string | undefined) => void;
    contactId?: string;
    topicMembership?: TopicMembershipDto;
}

export default function TopicMembershipUpdateDialog(props: Readonly<TopicMembershipUpdateDialogProps>) {
    const {open, onClose, contactId, topicMembership } = props;

    const [dialogState, setDialogState] = useState(DialogState.IDLE);
    const [role, setRole] = useState<TopicMembershipRole>();
    const [title, setTitle] = useState<string>();

    const {topicMembershipClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open) {
            setRole(undefined);
            setDialogState(DialogState.IDLE);
        } else if (topicMembership) {
            setRole(topicMembership.role);
            setTitle(composeTitleAndOtherTitle(topicMembership.title, topicMembership.otherTitle));
        }
    }, [open, contactId, topicMembership]);

    function updateTopicMembership() {
        if (contactId && topicMembership) {
            setDialogState(DialogState.SAVE_IN_PROGRESS);

            const [newTitle, otherTitle] = decomposeTitleAndOtherTitle(title);

            topicMembershipClient.updateMembership(contactId, topicMembership.id, {role: role, title: newTitle, otherTitle: otherTitle})
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(topicMembership.id);
                    })
                    .catch((error) => {
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        setDialogState(DialogState.IDLE);
                    });
        }
    }

    function composeTitleAndOtherTitle(title: string | undefined, otherTitle: string | undefined): string | undefined {
        if (title === TopicMembershipTitle.OTHER) {
            return otherTitle;
        } else {
            return title;
        }
    }

    function decomposeTitleAndOtherTitle(title: string | undefined): [string | undefined, string | undefined] {
        if (title) {
            const catalogTitles = Object.keys(TopicMembershipTitle)
                .filter((title) => title !== TopicMembershipTitle.OTHER);
            if (catalogTitles.includes(title)) {
                return [title, undefined];
            } else {
                return [TopicMembershipTitle.OTHER, title];
            }
        } else {
            return [undefined, undefined];
        }
    }

    const roleOptions = Object.keys(TopicMembershipRole).map((role) => ({
        value: role,
        label: TopicMembershipRoleFormatter.formatRole(role, t)
    }));

    const titleOptions = Object.keys(TopicMembershipTitle)
        .filter((title) => title !== TopicMembershipTitle.OTHER)
        .map((title) => ({
            value: title,
            label: TopicMembershipTitleFormatter.formatTitle(title, null, t)
        }));

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
            <DialogTitle title={t(ContactTranslKey.UPDATE_TOPIC_MEMBERSHIP)} />
            <DialogContent>
                <Grid container mt={0} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <SelectField label={t(ContactTranslKey.MEMBERSHIP_ROLE)}
                                     options={roleOptions}
                                     value={role}
                                     onChange={(value) => setRole(value as TopicMembershipRole)} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField label={t(ContactTranslKey.MEMBERSHIP_TITLE)}
                                     options={titleOptions}
                                     acceptFreeText={true}
                                     value={title}
                                     onChange={(value) => setTitle(value as string)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)}
                                  disabled={dialogState === DialogState.SAVE_IN_PROGRESS}/>
                    <SaveButton variant={"primary"}
                                onClick={updateTopicMembership}
                                disabled={Boolean(!topicMembership)}
                                inProgress={dialogState === DialogState.SAVE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
