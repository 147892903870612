import {Grid} from "@mui/material";
import TextField from "../../components/field/TextField";
import ButtonBar from "../../components/button/ButtonBar";
import {EditButton} from "../../common/button/CommonButtons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TopicDto} from "../../client/topic/TopicApiClient";
import {TopicTranslKey} from "./TopicTranslKey";
import {TopicTypeFormatter} from "./TopicTypeFormatter";
import TopicUpdateDialog from "./TopicUpdateDialog";

export interface TopicDetailPanelProps {
    topic: TopicDto;
    onUpdate?: () => void;
    hideActions?: boolean;
}

export default function TopicBasicDetailPanel(props: Readonly<TopicDetailPanelProps>) {
    const {topic, hideActions, onUpdate} = props;
    const {t} = useTranslation();

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

    function showUpdateDialog() {
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClosed(updatedTopicId?: string) {
        setUpdateDialogOpen(false);
        if (updatedTopicId) {
            onUpdate?.();
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5}>
                    <TextField label={t(TopicTranslKey.NAME)}
                               value={topic.name}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <TextField label={t(TopicTranslKey.TYPE)}
                               value={TopicTypeFormatter.formatTopicType(topic.type, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <TextField label={t(TopicTranslKey.GROUP)}
                               value={TopicTypeFormatter.formatTopicGroup(topic.typeGroup, t)}
                               readOnly={true} />
                </Grid>

                <Grid item xs={12}>
                    <TextField label={t(TopicTranslKey.DESCRIPTION)}
                               value={topic.description}
                               multiline={true}
                               minRows={3}
                               maxRows={8}
                               readOnly={true} />
                </Grid>
                {!hideActions &&
                    <Grid item xs={12}>
                        <ButtonBar>
                            <EditButton disabled={!topic.acl.canUpdate} onClick={showUpdateDialog} />
                        </ButtonBar>
                    </Grid>
                }
            </Grid>

            <TopicUpdateDialog open={updateDialogOpen}
                               onClose={onUpdateDialogClosed}
                               topic={topic} />
        </>
    );
}