import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import TabPanel from "../../components/tab/TabPanel";
import Tab from "../../components/tab/Tab";
import Tabs from "../../components/tab/Tabs";
import Dialog from "../../components/dialog/Dialog";
import DialogContent from "../../components/dialog/DialogContent";
import DialogTitle from "../../components/dialog/DialogTitle";
import {useTranslation} from "react-i18next";
import {UserTranslKey} from "./UserTranslKey";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {UserEventType} from "./UserEventType";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {UserDto} from "../../client/user/UserApiClient";
import UserBasicDetailsPanel from "./UserBasicDetailsPanel";
import UserBasicDetailsPanelSkeleton from "./UserBasicDetailsPanelSkeleton";
import UserRolePanel from "./role/UserRolePanel";

enum TabKey {
    BASIC = "basic",
    ROLES = "roles",
}

export interface UserDetailDialogProps {
    open: boolean;
    onClose: () => void;
    userId: string | undefined;
}

export default function UserDetailDialog(props: Readonly<UserDetailDialogProps>) {
    const {open, onClose, userId} = props;

    const {userClient} = useClientLocator();
    const eventDispacher = useEventDispatcher();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    const [user, setUser] = useState<UserDto>();
    const [selectedTab, setSelectedTab] = useState(TabKey.BASIC);
    const [refresh, setRefresh] = useState(Date.now());

    useEffect(() => {
        if (open && userId) {
            userClient.findById(userId)
                .then((user) => {
                    setUser(user);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        }
        if (!open) {
            setUser(undefined);
            setSelectedTab(TabKey.BASIC);
        }
    }, [open, userId, refresh, userClient, snackbar, t]);

    function onUpdated() {
        setRefresh(Date.now());
        eventDispacher.dispatchEvent(new Event(UserEventType.USER_UPDATED));
    }

    let basicDetailsContent: JSX.Element;
    if (user) {
        basicDetailsContent = (
            <UserBasicDetailsPanel user={user} />
        );
    } else {
        basicDetailsContent = (
            <UserBasicDetailsPanelSkeleton />
        );
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose()} maxWidth={"lg"}>
                <DialogTitle title={t(UserTranslKey.USER)} onClose={() => onClose()} />
                <DialogContent>
                    <Tabs value={selectedTab}
                          onChange={(_, tab) => setSelectedTab(tab)}
                          variant={"scrollable"}>
                        <Tab label={t(CommonTranslKey.DETAILS)} value={TabKey.BASIC} />
                        <Tab label={t(UserTranslKey.ROLES)} value={TabKey.ROLES} disabled={user === undefined} />
                    </Tabs>
                    <TabPanel value={TabKey.BASIC} selectedValue={selectedTab}>
                        <Box sx={{mt: 1}}>
                            {basicDetailsContent}
                        </Box>
                    </TabPanel>
                    <TabPanel value={TabKey.ROLES} selectedValue={selectedTab}>
                        <UserRolePanel user={user} onUpdated={() => onUpdated()} />
                    </TabPanel>
                </DialogContent>
            </Dialog>
        </>
    );
}