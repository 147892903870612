import {GridColDef, GridColumnVisibilityModel, GridSortModel} from "@mui/x-data-grid-pro";
import {ContactSummaryDto} from "../../client/contact/ContactApiClient";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useTranslation} from "react-i18next";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../client/disease/DiseaseApiClient";
import {DiseaseSpecializationDto} from "../../client/disease/DiseaseSpecializationDto";
import {Check} from "@mui/icons-material";
import {ContactTranslKey} from "./ContactTranslKey";
import DataGrid from "../../components/datagrid/DataGrid";
import {ContactAffiliationRoleFormatter} from "../affiliation/ContactAffiliationRoleFormatter";
import {OrganizationTranslKey} from "../organization/OrganizationTranslKey";
import {AffiliationTranslKey} from "../affiliation/AffiliationTranslKey";
import {CountryFormatter} from "../country/CountryFormatter";

const SPECIALIZATION_COLUMN_PREFIX = "specialization#";

function hasSpecializationToDisease(specializations: DiseaseSpecializationDto[] | undefined, diseaseId: string): boolean {
    if (!specializations) {
        return false;
    }
    return specializations.filter(spec => spec.diseaseId === diseaseId).length > 0;
}

function renderSpecializationToDiseaseCell(isSpecialized: boolean): ReactNode | null {
    return isSpecialized ? <Check/> : null;
}
export interface ContactGridProps {
    contacts: ContactSummaryDto[];
    selectedId?: string;
    onSelectionChange?: (contactId?: string) => void;
    onRowDoubleClick?: (contactId: string) => void;
}

export default function ContactGrid(props: Readonly<ContactGridProps>) {
    const {contacts, selectedId, onSelectionChange, onRowDoubleClick} = props;

    const {diseaseClient} = useClientLocator();
    const {t} = useTranslation();

    const [diseaseStreams, setDiseaseStreams] = useState<DiseaseDto[]>([]);
    const [diseaseSubstreams, setDiseaseSubstreams] = useState<DiseaseDto[]>([]);

    useEffect(() => {
        diseaseClient.findByFilter({level: DiseaseHierarchyLevel.STREAM})
                .then((streams) => {
                    setDiseaseStreams(streams);
                });
        diseaseClient.findByFilter({level: DiseaseHierarchyLevel.SUB_STREAM})
                .then((substreams) => {
                    setDiseaseSubstreams(substreams);
                });
    }, [diseaseClient]);

    const columnDefs: GridColDef<ContactSummaryDto>[] = useMemo(() => {
        let defs: GridColDef<ContactSummaryDto>[] = [
            {field: "firstName", headerName: t(ContactTranslKey.FIRST_NAME), width: 150},
            {field: "lastName", headerName: t(ContactTranslKey.LAST_NAME), width: 150},
            {field: "email", headerName: t(ContactTranslKey.EMAIL), width: 250},
            {
                field: "country",
                headerName: t(OrganizationTranslKey.COUNTRY),
                width: 80,
                valueGetter: ({value}) => value?.code,
                valueFormatter: params => CountryFormatter.formatCountry(params.value, t)
            },
            {
                field: "primaryAffiliation.organization.name",
                headerName: t(AffiliationTranslKey.PRIMARY_ORGANIZATION_NAME),
                width: 200,
                valueGetter: params => params.row.primaryAffiliation?.organization.name
            },
            {
                field: "primaryAffiliation.role",
                headerName: t(AffiliationTranslKey.PRIMARY_ORGANIZATION_ROLE),
                width: 200,
                valueGetter: params => params.row.primaryAffiliation?.role,
                valueFormatter: params => ContactAffiliationRoleFormatter.formatRole(params.value, t)
            },
            {
                field: "primaryAffiliation.expertise",
                headerName: t(AffiliationTranslKey.PRIMARY_ORGANIZATION_EXPERTISE),
                width: 200,
                valueGetter: params => params.row.primaryAffiliation?.expertise,
            },
            {field: "cpmsStatus", headerName: t(ContactTranslKey.CPMS_STATUS), width: 150},
        ];

        diseaseStreams.toSorted((a, b) => a.name.localeCompare(b.name))
                .forEach((stream) => {
                    defs.push({
                        field: `${SPECIALIZATION_COLUMN_PREFIX}${stream.id}`,
                        headerName: stream.abbreviation ?? stream.name,
                        width: 250,
                        resizable: true,
                        valueGetter: params => hasSpecializationToDisease(params.row.specializations, stream.id),
                        renderCell: params => renderSpecializationToDiseaseCell(params.value),
                    });
                });

        diseaseSubstreams.toSorted((a, b) => a.name.localeCompare(b.name))
                .forEach((substream) => {
                    defs.push({
                        field: `${SPECIALIZATION_COLUMN_PREFIX}${substream.id}`,
                        headerName: substream.abbreviation ?? substream.name,
                        width: 250,
                        resizable: true,
                        valueGetter: params => hasSpecializationToDisease(params.row.specializations, substream.id),
                        renderCell: params => renderSpecializationToDiseaseCell(params.value),
                    });
                });
        return defs;
    }, [t, diseaseStreams, diseaseSubstreams]);

    const defaultColumnVisibilityModel: GridColumnVisibilityModel = useMemo(() => ({
        firstName: true,
        lastName: true,
        email: true,
        "primaryAffiliation.organization.name": true,
        "primaryAffiliation.role": true,
        "primaryAffiliation.expertise": true,
        cpmsStatus:false,
        country: true,
    }), []);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(defaultColumnVisibilityModel);

    useEffect(() => {
        if (diseaseSubstreams.length > 0 && columnVisibilityModel[`${SPECIALIZATION_COLUMN_PREFIX}${diseaseSubstreams[0].id}`] === undefined) {
            const visibilityModel: GridColumnVisibilityModel = {...columnVisibilityModel};
            diseaseSubstreams.forEach((substream) => visibilityModel[`${SPECIALIZATION_COLUMN_PREFIX}${substream.id}`] = false);
            setColumnVisibilityModel(visibilityModel);
        }
    }, [diseaseSubstreams, columnVisibilityModel]);

    const defaultSortModel: GridSortModel = useMemo(() => [
        {field: "lastName", sort: "asc"},
    ], []);

    const [sortModel, setSortModel] = useState(defaultSortModel);

    return (
        <DataGrid
            rows={contacts}
            columns={columnDefs}
            autoHeight
            rowSelectionModel={selectedId}
            onRowSelectionModelChange={((rowSelectionModel) =>
                    onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            onRowDoubleClick={(params) => onRowDoubleClick?.(params.row.id)}
        />
    );
}
