import {useEffect, useState} from "react";
import {Grid, IconButton} from "@mui/material";
import {DiseaseDto, DiseaseHierarchyRelationCreateDto} from "../../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import TextField from "../../../components/field/TextField";
import {Clear, MoreHoriz} from "@mui/icons-material";
import DiseaseSearchDialog from "../search/DiseaseSearchDialog";
import SelectField from "../../../components/field/SelectField";
import {compareHierarchyLevel} from "../DiseaseHierarchyLevelComparator";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

enum RelationType {
    PRIMARY = "PRIMARY",
    SECONDARY = "SECONDARY"
}

export interface DiseaseParentRelationCreateDialogProps {
    open: boolean;
    onClose: (createdRelationId?: string) => void;
    child?: DiseaseDto;
}

export default function DiseaseParentRelationCreateDialog(props: DiseaseParentRelationCreateDialogProps) {
    const {open, onClose, child} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [parent, setParent] = useState<DiseaseDto>();
    const [parentErrorMessage, setParentErrorMessage] = useState<string>();
    const [relationType, setRelationType] = useState(RelationType.PRIMARY);

    const [parentSearchDialogOpen, setParentSearchDialogOpen] = useState(false);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setParent(undefined);
            setRelationType(RelationType.PRIMARY);
            setDialogState(CreateDialogState.IDLE);
        }
    }, [open, child]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const relationCreate: DiseaseHierarchyRelationCreateDto = {
                parentId: parent!.id,
                isPrimary: relationType === RelationType.PRIMARY
            };
            diseaseClient.createHierarchyRelation(child!.id, relationCreate)
                .then((relationId) => {
                    snackbar.success("Data saved");
                    onClose(relationId);
                })
                .catch((error) => {
                    snackbar.error("", error);
                    setDialogState(CreateDialogState.IDLE);
                });
        }
    }

    function validate(): boolean {
        let isValid = (parent !== undefined && child !== undefined);

        if (parent && child && compareHierarchyLevel(child.hierarchyLevel, parent.hierarchyLevel) >= 0) {
            if (!parentErrorMessage) {
                setParentErrorMessage("Parent level must be higher than the child's level");
            }
            isValid = false;
        } else {
            if (parentErrorMessage) {
                setParentErrorMessage(undefined);
            }
        }
        return isValid;
    }

    function clearParent() {
        setParent(undefined);
    }

    function showParentSearchDialog() {
        setParentSearchDialogOpen(true);
    }

    function onParentSearchDialogClosed(parent?: DiseaseDto) {
        setParentSearchDialogOpen(false);
        if (parent) {
            setParent(parent);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={"Create new parent relation"} />
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12}>
                            <TextField label={"Parent"}
                                       value={parent?.name ?? ""}
                                       readOnly={true}
                                       required={true}
                                       errorMessage={parentErrorMessage}
                                       endAdornment={(
                                           <>
                                                {parent && <IconButton onClick={clearParent}><Clear/></IconButton>}
                                                <IconButton onClick={showParentSearchDialog}><MoreHoriz/></IconButton>
                                           </>
                                       )} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={"Child"}
                                       value={child?.name ?? ""}
                                       readOnly={true}
                                       required={true} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <SelectField label={"Relation Type"}
                                         value={relationType}
                                         onChange={(value) => setRelationType(value as RelationType)}
                                         options={[
                                             {value: RelationType.PRIMARY, label: "Primary"},
                                             {value: RelationType.SECONDARY, label: "Secondary"},
                                         ]}
                                         required={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!validate()}
                                    inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <DiseaseSearchDialog open={parentSearchDialogOpen} onClose={onParentSearchDialogClosed}/>
        </>
    );
}