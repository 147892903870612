import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import {useCallback, useEffect, useState} from "react";
import {AddButton} from "../../../common/button/CommonButtons";
import ButtonBar from "../../../components/button/ButtonBar";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {ContactDto} from "../../../client/contact/ContactApiClient";
import ContactAffiliationListItem from "./ContactAffiliationListItem";
import ListSkeleton from "../../../common/list/ListSkeleton";
import {AffiliationDto, AffiliationFilterDto} from "../../../client/affiliation/AffiliationApiClient";
import {OrganizationDto} from "../../../client/organization/OrganizationApiClient";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {AffiliationTranslKey} from "../../affiliation/AffiliationTranslKey";
import AffiliationCreateDialog from "../../affiliation/AffiliationCreateDialog";
import AffiliationInvalidateDialog from "../../affiliation/AffiliationInvalidateDialog";
import AffiliationUpdateDialog from "../../affiliation/AffiliationUpdateDialog";

interface AffiliationModel {
    affiliation: AffiliationDto;
    organization: OrganizationDto;
}

export interface ContactAffiliationPanelProps {
    contact?: ContactDto;
    onUpdated?: () => void;
}

export default function ContactAffiliationPanel(props: Readonly<ContactAffiliationPanelProps>) {
    const {contact, onUpdated} = props;

    const {affiliationClient, organizationClient} = useClientLocator();

    const [affiliations, setAffiliations] = useState<AffiliationModel[]>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [invalidateDialogOpen, setInvalidateDialogOpen] = useState(false);
    const [affiliationToInvalidate, setAffiliationToInvalidate] = useState<AffiliationModel>();
    const [affiliationToUpdate, setAffiliationToUpdate] = useState<AffiliationModel>();

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    const search = useCallback(() => {
        if (contact) {
            const filter: AffiliationFilterDto = {
                contactId: contact.id,
                validAt: new Date().toISOString()
            };
            affiliationClient.findByFilter(filter)
                    .then((affiliations) => {
                        const promises: Promise<[AffiliationDto, OrganizationDto]>[] = affiliations.map(affiliation =>
                                new Promise((resolve, reject) => {
                                    organizationClient.findById(affiliation.organizationId)
                                            .then(organization => {
                                                if (organization) {
                                                    resolve([affiliation, organization]);
                                                } else {
                                                    reject(new Error(`Organization with ID ${affiliation.organizationId} not found.`));
                                                }
                                            })
                                            .catch((error) => reject(error));
                                }));
                        return Promise.all(promises);
                    })
                    .then((values) => {
                        const affiliations: AffiliationModel[] = values.map(([affiliation, organization]) => ({
                            affiliation: affiliation,
                            organization: organization
                        }));
                        setAffiliations(affiliations);
                    })
                    .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        } else {
            setAffiliations([]);
        }
    }, [contact, affiliationClient, organizationClient, t, snackbar]);

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdRelationId?: string) {
        setCreateDialogOpen(false);
        if (createdRelationId) {
            onUpdated?.();
        }
    }

    function onUpdateDialogClosed() {
        setUpdateDialogOpen(false);
        onUpdated?.();
    }

    function showUpdateDialog(affiliation: AffiliationDto, organization: OrganizationDto) {
        setUpdateDialogOpen(true);
        setAffiliationToUpdate({affiliation, organization});
    }

    function showInvalidateDialog(affiliation: AffiliationDto, organization: OrganizationDto) {
        setInvalidateDialogOpen(true);
        setAffiliationToInvalidate({affiliation, organization});
    }

    function onInvalidateDialogClosed(deletedId: boolean) {
        setInvalidateDialogOpen(false);
        if (deletedId) {
            onUpdated?.();
        }
    }

    useEffect(() => {
        search();
    }, [search]);

    if (!affiliations) {
        return (
                <ListSkeleton rows={1} rowHeight={72}/>
        );
    }

    let content: React.ReactNode;
    if (affiliations.length > 0) {
        content = (
                <List component={"nav"}>
                    {affiliations.map(
                            (affiliation) =>
                                    <ContactAffiliationListItem affiliation={affiliation.affiliation}
                                                                organization={affiliation.organization}
                                                                key={affiliation.affiliation.id}
                                                                onUpdateClick={(affiliation, organization) => showUpdateDialog(affiliation, organization)}
                                                                onInvalidateClick={(affiliation, organization) => showInvalidateDialog(affiliation, organization)}/>
                    )}
                </List>
        );
    } else {
        content = (
                <Stack direction={"row"}
                       spacing={1}
                       alignItems={"center"}
                       sx={{m: 2}}>
                    <DataArray/>
                    <Typography variant={"body2"}>{t(AffiliationTranslKey.NO_AFFILIATIONS)}</Typography>
                </Stack>
        );
    }
    return (
            <>
                <Stack direction={"column"}>
                    {content}
                    <ButtonBar>
                        <AddButton disabled={!contact?.acl?.canUpdate} onClick={showCreateDialog}/>
                    </ButtonBar>
                </Stack>

                <AffiliationCreateDialog open={createDialogOpen}
                                         onClose={onCreateDialogClosed}
                                         allowPrimary={!affiliations.some(affiliation => affiliation.affiliation.isPrimary)}
                                         contact={contact}/>

                {contact && affiliationToUpdate &&
                    <AffiliationUpdateDialog open={updateDialogOpen}
                                             onClose={onUpdateDialogClosed}
                                             affiliation={affiliationToUpdate.affiliation}
                                             contact={contact}
                                             organization={affiliationToUpdate.organization}/>
                }

                <AffiliationInvalidateDialog open={invalidateDialogOpen}
                                             onClose={(deleted: boolean) => onInvalidateDialogClosed(deleted)}
                                             affiliation={affiliationToInvalidate?.affiliation}
                                             contact={contact}
                                             organization={affiliationToInvalidate?.organization}/>
            </>
    );
}
