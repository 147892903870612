import {Box} from "@mui/material";
import DiseaseBasicDetailPanel from "./DiseaseBasicDetailPanel";
import {useEffect, useState} from "react";
import {DiseaseDetailDto} from "../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import DiseaseBasicDetailPanelSkeleton from "./DiseaseBasicDetailPanelSkeleton";
import TabPanel from "../../components/tab/TabPanel";
import Tab from "../../components/tab/Tab";
import Tabs from "../../components/tab/Tabs";
import DiseaseAlternativeNamesPanel from "./altname/DiseaseAlternativeNamesPanel";
import DiseaseResourcesPanel from "./resource/DiseaseResourcesPanel";
import Dialog from "../../components/dialog/Dialog";
import DialogContent from "../../components/dialog/DialogContent";
import DialogTitle from "../../components/dialog/DialogTitle";
import DiseaseRelationsPanel from "./relation/DiseaseRelationsPanel";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../common/CommonTranslKey";

enum TabKey {
    BASIC = "basic",
    ALT_NAMES = "altNames",
    RESOURCES = "resources",
    RELATIONS = "relations",
}

export interface DiseaseDetailDialogProps {
    open: boolean;
    onClose: (updated: boolean) => void;
    diseaseId: string | undefined;
}

export default function DiseaseDetailDialog(props: DiseaseDetailDialogProps) {
    const {open, onClose, diseaseId} = props;

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    const [disease, setDisease] = useState<DiseaseDetailDto>();
    const [selectedTab, setSelectedTab] = useState(TabKey.BASIC);
    const [refresh, setRefresh] = useState(Date.now());
    const [updated, setUpdated] = useState(false);

    useEffect(() => {
        if (open && diseaseId) {
            diseaseClient.findById(diseaseId)
                .then((disease) => {
                    setDisease(disease);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        }
        if (!open) {
            setDisease(undefined);
            setUpdated(false);
            setSelectedTab(TabKey.BASIC);
        }
    }, [open, diseaseId, diseaseClient, snackbar, refresh, t]);

    function refreshData() {
        setRefresh(Date.now());
        setUpdated(true);
    }

    let content: React.JSX.Element;
    if (disease) {
        content = (
            <DiseaseBasicDetailPanel disease={disease.info} onUpdate={refreshData} />
        );
    } else {
        content = (
            <DiseaseBasicDetailPanelSkeleton />
        );
    }

    return (
        <Dialog open={open} onClose={() => onClose(updated)} maxWidth={"lg"}>
            <DialogTitle title={"Disease"} onClose={() => onClose(updated)} />
            <DialogContent>
                <Tabs value={selectedTab}
                      onChange={(e, tab) => setSelectedTab(tab)}
                      variant={"scrollable"}>
                    <Tab label={"Detail"} value={TabKey.BASIC} />
                    <Tab label={"Alternative Names"} value={TabKey.ALT_NAMES} disabled={disease === undefined} />
                    <Tab label={"Resources"} value={TabKey.RESOURCES} disabled={disease === undefined} />
                    <Tab label={"Relations"} value={TabKey.RELATIONS} disabled={disease === undefined} />
                </Tabs>
                <TabPanel value={TabKey.BASIC} selectedValue={selectedTab}>
                    <Box sx={{mt: 1}}>
                        {content}
                    </Box>
                </TabPanel>
                <TabPanel value={TabKey.ALT_NAMES} selectedValue={selectedTab}>
                    <DiseaseAlternativeNamesPanel disease={disease} onUpdated={refreshData} />
                </TabPanel>
                <TabPanel value={TabKey.RESOURCES} selectedValue={selectedTab}>
                    <DiseaseResourcesPanel disease={disease} onUpdated={refreshData} />
                </TabPanel>
                <TabPanel value={TabKey.RELATIONS} selectedValue={selectedTab}>
                    <DiseaseRelationsPanel disease={disease} onUpdated={refreshData} />
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
}
