import {useEffect, useState} from "react";
import {Grid, IconButton, Stack} from "@mui/material";
import {useSnackbar} from "../../../../components/snackbar/Snackbar";
import Dialog from "../../../../components/dialog/Dialog";
import DialogTitle from "../../../../components/dialog/DialogTitle";
import DialogContent from "../../../../components/dialog/DialogContent";
import DialogActions from "../../../../components/dialog/DialogActions";
import ButtonBar from "../../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../../common/button/CommonButtons";
import TextField from "../../../../components/field/TextField";
import {AccountCircle, Clear, MoreHoriz} from "@mui/icons-material";
import {CommonTranslKey} from "../../../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {UserDto, UserRoleType} from "../../../../client/user/UserApiClient";
import {useClientLocator} from "../../../../client/ApiClientLocator";
import {ContactRegistrationRequestDto} from "../../../../client/registration/RegistrationApiClient";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";
import {UserFormatter} from "../../../user/UserFormatter";
import UserSearchDialog from "../../../user/search/UserSearchDialog";
import {useUser} from "../../../user/UserContext";
import Button from "../../../../components/button/Button";
import {RegistrationEventType} from "../../RegistrationEventType";
import {useEventDispatcher} from "../../../../components/EventDispatcher";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

const REVIEWER_ROLE_TYPES = [
    UserRoleType.SYSTEM_ADMINISTRATOR,
    UserRoleType.REGISTRY_ADMINISTRATOR
];

export interface RegistrationReviwerAssignmentDialogProps {
    open: boolean;
    onClose: (assignedToReviewer: boolean) => void;
    registrationRequest?: ContactRegistrationRequestDto;
}

export default function RegistrationReviwerAssignmentDialog(props: Readonly<RegistrationReviwerAssignmentDialogProps>) {
    const {open, onClose, registrationRequest} = props;

    const user = useUser();
    const {registrationClient} = useClientLocator();

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [reviewer, setReviewer] = useState<UserDto>();
    const [reviewerErrorMessage, setReviewerErrorMessage] = useState<string>();

    const [reviewerSearchDialogOpen, setReviewerSearchDialogOpen] = useState(false);

    const snackbar = useSnackbar();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setReviewer(undefined);
            setDialogState(CreateDialogState.IDLE);
            setReviewerErrorMessage(undefined);
        } else if (registrationRequest?.assignedReviewer) {
            setReviewer(registrationRequest.assignedReviewer);
        }
    }, [open, registrationRequest]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate(reviewer)) {
            registrationClient.assignToReviewer(registrationRequest!.id, reviewer!.id)
                .then(() => {
                    snackbar.success(t(CommonTranslKey.DATA_SAVED));
                    eventDispatcher.dispatchEvent(new Event(RegistrationEventType.REQUEST_DATA_UPDATED));
                    setReviewerErrorMessage(undefined);
                    onClose(true);
                })
                .catch((error) => {
                    snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                    setReviewerErrorMessage(undefined);
                    setDialogState(CreateDialogState.IDLE);
                });
        }
    }

    function validate(reviewer: UserDto | undefined): boolean {
        setReviewerErrorMessage(undefined);
        if (reviewer === undefined) {
            return false;
        }
        if (reviewer?.id === registrationRequest?.assignedReviewer?.id) {
            setReviewerErrorMessage(t(RegistrationTranslKey.CANNOT_REASSIGN_TO_SAME_REVIEWER));
            return false;
        }
        return true;
    }

    function isPreValidated(): boolean {
        return (reviewer !== undefined);
    }

    function clearReviewer() {
        setReviewer(undefined);
        validate(undefined);
    }

    function showReviewerSearchDialog() {
        setReviewerSearchDialogOpen(true);
    }

    function onReviewerSearchDialogClosed(reviewer?: UserDto) {
        setReviewerSearchDialogOpen(false);
        if (reviewer) {
            setReviewerErrorMessage(undefined);
            setReviewer(reviewer);
            validate(reviewer);
        }
    }

    function assignToMe() {
        setReviewer(user);
        validate(user);
    }

    const reviewerIsMe = reviewer?.id === user.id;

    return (
        <>
            <Dialog open={open} onClose={() => onClose(false)} maxWidth={"md"}>
                <DialogTitle title={t(RegistrationTranslKey.ASSIGN_REVIEWER)}/>
                <DialogContent>
                    <Grid container mt={0} spacing={0.5} justifyItems={"flex-end"}>
                        <Grid item xs={12}>
                            <TextField label={t(RegistrationTranslKey.REVIEWER)}
                                       value={UserFormatter.formatUser(reviewer)}
                                       readOnly={true}
                                       required={true}
                                       errorMessage={reviewerErrorMessage}
                                       endAdornment={(
                                           <>
                                               {reviewer && <IconButton title={t(CommonTranslKey.CLEAR)} onClick={clearReviewer}><Clear/></IconButton>}
                                               <IconButton title={t(CommonTranslKey.SEARCH)} onClick={showReviewerSearchDialog}><MoreHoriz/></IconButton>
                                           </>
                                       )}/>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack direction="row" justifyContent={"flex-end"}>
                                <Button variant={"text"} onClick={assignToMe} disabled={reviewerIsMe}>
                                    <AccountCircle/>&nbsp;{t(RegistrationTranslKey.ASSIGN_TO_ME)}
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(false)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!isPreValidated()}
                                    inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <UserSearchDialog open={reviewerSearchDialogOpen}
                              onClose={onReviewerSearchDialogClosed}
                              fixedValidRoleTypes={REVIEWER_ROLE_TYPES} />
        </>
    );
}
