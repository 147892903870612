import {IconButton} from "@mui/material";
import {Clear, MoreHoriz} from "@mui/icons-material";
import TextField, {TextFieldProps} from "./TextField";

export interface TextFieldWithMoreProps extends Omit<TextFieldProps, "endAdornment"> {
    onClear: () => void;
    onMoreClick: () => void;
}

export default function TextFieldWithMore(props: Readonly<TextFieldWithMoreProps>) {
    const {onClear, onMoreClick, readOnly, ...restProps} = props;

    let endAdornment: React.ReactNode;
    if (readOnly) {
        endAdornment = null;
    } else {
        endAdornment = (
            <>
                {props.value && <IconButton onClick={onClear}><Clear/></IconButton>}
                <IconButton onClick={onMoreClick}><MoreHoriz/></IconButton>
            </>
        );
    }

    return (
        <TextField {...restProps}
                   readOnly={true}
                   endAdornment={endAdornment} />
    );
}