import {DiseaseResourceDto, DiseaseResourceLocation} from "../../../client/disease/DiseaseApiClient";
import {useTranslation} from "react-i18next";
import {Link as LinkIcon} from "@mui/icons-material";
import {DiseaseResourceTranslKey} from "./DiseaseResourceTranslKey";
import {Avatar, Badge} from "@mui/material";

export interface DiseaseResourceBadgedAvatarProps {
    resource: DiseaseResourceDto;
}

export default function DiseaseResourceBadgedAvatar(props: Readonly<DiseaseResourceBadgedAvatarProps>) {
    const {resource} = props;

    const {t} = useTranslation();

    const badgeText = resource.location === DiseaseResourceLocation.EXTERNAL ? DiseaseResourceTranslKey.EXTERNAL_BADGE : DiseaseResourceTranslKey.INTERNAL_BADGE;
    const badgeColor = resource.location === DiseaseResourceLocation.EXTERNAL ? "primary" : "info";
    const icon = <LinkIcon/>;

    return (
        <Badge badgeContent={t(badgeText)}
               color={badgeColor}
               overlap={"circular"}
               anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
            <Avatar>
                {icon}
            </Avatar>
        </Badge>
);
}
