import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {CopyUtils} from "../../../util/CopyUtils";
import {ContactFilterDto, CpmsPanel} from "../../../client/contact/ContactApiClient";
import {useContext, useEffect, useState} from "react";
import CountryContext from "../../../catalog/CountryContext";
import MultiSelectField, {SelectOption} from "../../../components/field/MultiSelectField";
import SelectField from "../../../components/field/SelectField";
import {CpmsPanelFormatter} from "../cpms/CpmsPanelFormatter";
import {useTranslation} from "react-i18next";
import {DiseaseTranslKey} from "../../disease/DiseaseTranslKey";
import {ContactTranslKey} from "../ContactTranslKey";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {formatHierarchyLevel} from "../../disease/DiseaseHierarchyLevelFormatter";
import {TopicTranslKey} from "../../topic/TopicTranslKey";

export interface ContactFilterFormProps {
    filter: ContactFilterDto;
    onChange: (filter: ContactFilterDto) => void;
}

export function ContactFilterForm(props: ContactFilterFormProps) {
    const {filter, onChange} = props;

    const {t} = useTranslation();
    const {diseaseClient} = useClientLocator();
    const countries = useContext(CountryContext);

    const [diseaseStreams, setDiseaseStreams] = useState<DiseaseDto[]>([]);
    const [diseaseSubstreams, setDiseaseSubstreams] = useState<DiseaseDto[]>([]);

    useEffect(() => {
        const streamPromise = diseaseClient.findByFilter({level: DiseaseHierarchyLevel.STREAM});
        const substreamPromise = diseaseClient.findByFilter({level: DiseaseHierarchyLevel.SUB_STREAM});
        Promise.all([streamPromise, substreamPromise])
                .then(([streams, substreams]) => {
                    streams.sort((a, b) => a.name.localeCompare(b.name));
                    substreams.sort((a, b) => a.name.localeCompare(b.name));
                    setDiseaseStreams(streams);
                    setDiseaseSubstreams(substreams);
                });
    }, [diseaseClient]);

    function onFirstNameChange(firstName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.firstNameLike = normalizeString(firstName);
        onChange(updatedFilter);
    }

    function onLastNameChange(lastName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.lastNameLike = normalizeString(lastName);
        onChange(updatedFilter);
    }

    function onEmailChange(email: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.emailLike = normalizeString(email);
        onChange(updatedFilter);
    }

    function onTopicChange(topic: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.topicNameLike = normalizeString(topic);
        onChange(updatedFilter);
    }

    function onCountryChange(countryIds: unknown[]) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.countries = countryIds.map(id => Number(id));
        onChange(updatedFilter);
    }

    function onPanelChange(panel: CpmsPanel) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.cpmsPanel = panel;
        onChange(updatedFilter);
    }

    function onPrimaryOrganizationNameChange(primaryOrganizationName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.primaryOrganizationNameLike = normalizeString(primaryOrganizationName);
        onChange(updatedFilter);
    }

    function onSecondaryOrganizationNameChange(secondaryOrganizationName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.secondaryOrganizationNameLike = normalizeString(secondaryOrganizationName);
        onChange(updatedFilter);
    }

    function onDiseasesChange(diseases: string[]) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.diseases = diseases;
        onChange(updatedFilter);
    }
    function normalizeString(value: string): string | undefined {
        const normalizedValue = value.trim();
        return (normalizedValue.length > 0) ? normalizedValue : undefined;
    }

    const diseaseStreamsAndSubstreams = [...diseaseStreams, ...diseaseSubstreams];

    const diseaseOptions: SelectOption[] = diseaseStreamsAndSubstreams.map(disease => ({
        value: disease.id,
        label: disease.abbreviation ?? disease.name,
        group: formatHierarchyLevel(disease.hierarchyLevel)
    }));

    const countryOptions: SelectOption[] = countries.map((country) => ({
        value: country.id,
        label: `${country.name} [${country.code}]`
    }));

    const panelOptions = Object.keys(CpmsPanel)
            .map((panel) => ({
                value: panel,
                label: CpmsPanelFormatter.formatPanel(panel, t)
            }));

    return (
        <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField label={t(ContactTranslKey.FIRST_NAME)}
                           value={filter.firstNameLike ?? ""}
                           onChange={onFirstNameChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField label={t(ContactTranslKey.LAST_NAME)}
                           value={filter.lastNameLike ?? ""}
                           onChange={onLastNameChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField label={t(ContactTranslKey.EMAIL)}
                           value={filter.emailLike ?? ""}
                           onChange={onEmailChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiSelectField label={t(ContactTranslKey.COUNTRY)}
                                  value={filter.countries}
                                  onChange={onCountryChange}
                                  limitTags={1}
                                  options={countryOptions} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={t(ContactTranslKey.PRIMARY_ORGANIZATION_NAME)}
                           value={filter.primaryOrganizationNameLike ?? ""}
                           onChange={onPrimaryOrganizationNameChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={t(ContactTranslKey.SECONDARY_ORGANIZATION_NAME)}
                           value={filter.secondaryOrganizationNameLike ?? ""}
                           onChange={onSecondaryOrganizationNameChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={t(TopicTranslKey.TOPIC)}
                           value={filter.topicNameLike ?? ""}
                           onChange={onTopicChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <SelectField label={t(ContactTranslKey.CPMS_PANEL)}
                                  value={filter.cpmsPanel}
                                  onChange={(value) => onPanelChange(value as CpmsPanel)}
                                  limitTags={1}
                                  options={panelOptions} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <MultiSelectField label={t(DiseaseTranslKey.DISEASE)}
                                  value={filter.diseases}
                                  onChange={diseases => onDiseasesChange(diseases as string[])}
                                  limitTags={-1}
                                  options={diseaseOptions}
                />
            </Grid>
        </Grid>
    );
}

