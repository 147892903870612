import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {Delete, Folder, Vaccines} from "@mui/icons-material";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../../client/disease/DiseaseApiClient";
import {Theme} from "@mui/material/styles/createTheme";
import {SystemStyleObject} from "@mui/system/styleFunctionSx/styleFunctionSx";
import {formatHierarchyLevel} from "../DiseaseHierarchyLevelFormatter";
import {DiseaseSpecializationDto} from "../../../client/disease/DiseaseSpecializationDto";


export interface DiseaseSpecializationListItemProps<T extends DiseaseSpecializationDto> {
    specialization: T;
    disease: DiseaseDto;
    disabled?: boolean;
    onDeleteClick: (specialization: T, disease: DiseaseDto) => void;
}

export default function DiseaseSpecializationListItem<T extends DiseaseSpecializationDto>(props: Readonly<DiseaseSpecializationListItemProps<T>>) {
    const {specialization, disease, disabled, onDeleteClick} = props;

    function formatSecondaryText(disease: DiseaseDto) {
        return formatHierarchyLevel(disease.hierarchyLevel);
    }

    function buildAvatarStyle(disease: DiseaseDto, theme: Theme): SystemStyleObject<Theme> {
        return {};
    }

    function getIconByLevel(level: DiseaseHierarchyLevel) {
        if (level === DiseaseHierarchyLevel.DISEASE) {
            return <Vaccines />;
        } else {
            return <Folder />;
        }
    }

    const secondaryText = formatSecondaryText(disease);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar sx={(theme) => buildAvatarStyle(disease, theme)}>
                    {getIconByLevel(disease.hierarchyLevel)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={disease.name} secondary={secondaryText} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onDeleteClick(specialization, disease)}>
                        <Delete />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}