import {Grid} from "@mui/material";
import TextField from "../../components/field/TextField";
import React from "react";
import {useTranslation} from "react-i18next";
import {UserDto} from "../../client/user/UserApiClient";
import {UserTranslKey} from "./UserTranslKey";

export interface UserBasicDetailsPanelProps {
    user: UserDto;
}

export default function UserBasicDetailsPanel(props: Readonly<UserBasicDetailsPanelProps>) {
    const {user} = props;
    const {t} = useTranslation();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField label={t(UserTranslKey.LOGIN)}
                               value={user.login}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField label={t(UserTranslKey.EMAIL)}
                               value={user.email}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(UserTranslKey.FIRST_NAME)}
                               value={user.firstName}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(UserTranslKey.LAST_NAME)}
                               value={user.lastName}
                               readOnly={true} />
                </Grid>
            </Grid>
        </>
    );
}