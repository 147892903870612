import {Axios} from "axios";
import {ValidityIntervalDto} from "../time/ValidityIntervalDto";

export enum ContactAffiliationRole {
    ORGANIZATION_ADMINISTRATOR = "ORGANIZATION_ADMINISTRATOR",
    ORGANIZATION_MEMBER = "ORGANIZATION_MEMBER",
}

export enum ContactAffiliationTitle {
    ADMINISTRATIVE_CONTACT = "ADMINISTRATIVE_CONTACT",
    COORDINATOR = "COORDINATOR",
    COORDINATOR_SUBSTITUTE = "COORDINATOR_SUBSTITUTE",
    MAIN_CONTACT = "MAIN_CONTACT",
    HEALTH_PROFESSIONAL_CONSULTANT = "HEALTH_PROFESSIONAL_CONSULTANT",
    HEALTH_PROFESSIONAL_FELLOW = "HEALTH_PROFESSIONAL_FELLOW",
    HEALTH_PROFESSIONAL_NURSE = "HEALTH_PROFESSIONAL_NURSE",
    HEALTH_PROFESSIONAL_RESEARCHER = "HEALTH_PROFESSIONAL_RESEARCHER",
    LEGAL_REPRESENTATIVE = "LEGAL_REPRESENTATIVE",
    MEMBER = "MEMBER",
    PRESIDENT = "PRESIDENT",
    COUNCIL_MEMBER = "COUNCIL_MEMBER",
    BOARD_MEMBER = "BOARD_MEMBER",
    PROGRAMME_MANAGER = "PROGRAMME_MANAGER",
    PROJECT_MANAGER = "PROJECT_MANAGER",
    STREAM_LEAD = "STREAM_LEAD",
    STREAM_LEAD_SUBSTITUTE = "STREAM_LEAD_SUBSTITUTE",
    IT_HELPDESK = "IT_HELPDESK",
    OTHER = "OTHER",
}

export interface AffiliationDto {
    id: string;
    isPrimary: boolean;
    contactId: string;
    organizationId: string;

    contactAffiliationRole: ContactAffiliationRole;
    contactAffiliationTitle: ContactAffiliationTitle;
    otherContactAffiliationTitle?: string;
    expertise: string;

    validityInterval?: ValidityIntervalDto;

    acl: AffiliationAclDto;
}

export interface AffiliationAclDto {
    canUpdate: boolean;
    canInvalidate: boolean;
    canDelete: boolean;
}

export interface AffiliationFilterDto {
    contactId?: string;
    organizationId?: string;

    contactRole?: string;
    contactTitle?: string;
    isPrimary?: boolean;

    validAt?: string;
}

export interface AffiliationCreateDto {
    contactId: string;
    organizationId: string;
    isPrimary: boolean;

    contactAffiliationRole: ContactAffiliationRole;
    contactAffiliationTitle: ContactAffiliationTitle;
    otherContactAffiliationTitle?: string;
    expertise?: string;
}

export interface AffiliationUpdateDto {
    contactAffiliationTitle: ContactAffiliationTitle;
    otherContactAffiliationTitle?: string;
    expertise?: string;
}

export class AffiliationApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(affiliationId: string): Promise<AffiliationDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<AffiliationDto | undefined>(`/affiliations/${affiliationId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findByFilter(filter: AffiliationFilterDto): Promise<AffiliationDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<AffiliationDto[]>("/affiliations:search", filter)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createAffiliation(affiliationCreate: AffiliationCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/affiliations`, affiliationCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateAffiliation(affiliationId: string, affiliationUpdate: AffiliationUpdateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.put<string>(`/affiliations/${affiliationId}`, affiliationUpdate)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }

    invalidateAffiliation(affiliationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post<void>(`/affiliations/${affiliationId}:invalidate`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }
}
