export enum UserTranslKey {
    ASSIGN_NEW_USER_ROLE = "user:assignNewUserRole",
    EMAIL = "user:email",
    FIRST_NAME = "user:firstName",
    FROM = "user:from",
    INVALIDATE_USER_ROLE = "user:invalidateUserRole",
    INVALIDATE_USER_ROLE_PROMPT = "user:invalidateUserRolePrompt",
    LAST_NAME = "user:lastName",
    LOGIN = "user:login",
    NO_ROLES = "user:noRoles",
    ROLES = "user:roles",
    SEARCH_USER = "user:searchUser",
    USER_ROLE_TYPE_NAMESPACE = "user:catalog:userRoleType:",
    USER = "user:user",
    USERS = "user:users",
    USER_ROLE = "user:userRole",
}