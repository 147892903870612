import {Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import ButtonBar from "../../../components/button/ButtonBar";
import {SearchButton} from "../../../common/button/CommonButtons";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {TopicDto, TopicFilterDto} from "../../../client/topic/TopicApiClient";
import TopicGrid from "../TopicGrid";
import {TopicFilterForm} from "./TopicFilterForm";
import DataGridActionBar from "../../../components/datagrid/DataGridActionBar";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {EventListenerUnsubscriber, useEventDispatcher} from "../../../components/EventDispatcher";
import {TopicEventType} from "../TopicEventType";

enum SearchPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}

export interface TopicSearchPanelProps {
    onSelected: (topic?: TopicDto) => void;
    actions?: React.ReactNode;
    onRowDoubleClick?: (topic?: TopicDto) => void;
}

export default function TopicSearchPanel(props: Readonly<TopicSearchPanelProps>) {
    const {onSelected, actions} = props;

    const {topicClient} = useClientLocator();
    const {t} = useTranslation();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();

    const [state, setState] = useState<SearchPanelState>(SearchPanelState.INITIALIZED);
    const [filter, setFilter] = useState<TopicFilterDto>({});
    const [topics, setTopics] = useState<TopicDto[]>();

    const [selectedTopic, setSelectedTopic] = useState<TopicDto>();

    function onFilterChanged(updatedFilter: TopicFilterDto) {
        setFilter(updatedFilter);
    }

    const search = useCallback(() => {
        setState(SearchPanelState.SEARCH_IN_PROGRESS);
        topicClient.findByFilter(filter)
            .then((topics) => {
                setTopics(topics);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error))
            .finally(() => setState(SearchPanelState.IDLE))
    }, [filter, topicClient, snackbar, t]);

    useEffect(() => {
        if (state === SearchPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    useEffect(() => {
        const unsubscribers: EventListenerUnsubscriber[] = [];
        unsubscribers.push(eventDispatcher.addEventListener(TopicEventType.TOPIC_UPDATED, () => search()));
        return () => unsubscribers.forEach(unsubscribe => unsubscribe());
    }, [search, filter, eventDispatcher]);

    function onSelectionChange(topicId?: string) {
        let topic = topicId
            ? topics?.find((topic) => topic.id === topicId)
            : undefined;
        setSelectedTopic(topic);
        onSelected(topic);
    }

    function onRowDoubleClick(topicId: string) {
        let topic = topicId
            ? topics?.find((topic) => topic.id === topicId)
            : undefined;
        props.onRowDoubleClick?.(topic);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TopicFilterForm filter={filter}
                                 onChange={onFilterChanged} />
            </Grid>
            <Grid item xs={12}>
                <ButtonBar>
                    <SearchButton onClick={() => search()}
                                  inProgress={state === SearchPanelState.SEARCH_IN_PROGRESS} />
                </ButtonBar>
            </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {actions &&
                            <Grid item xs={12}>
                                <DataGridActionBar actions={actions} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {state !== SearchPanelState.INITIALIZED && topics &&
                                <TopicGrid topics={topics}
                                           selectedId={selectedTopic?.id}
                                           onSelectionChange={onSelectionChange}
                                           onRowDoubleClick={onRowDoubleClick}/>
                            }
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    );

}
