import React from "react";
import {closeSnackbar, SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps} from "notistack";
import {Close} from "@mui/icons-material";

export default function SnackbarProvider(props: SnackbarProviderProps) {
    return (
        <NotistackSnackbarProvider anchorOrigin={{vertical: "top", horizontal: "right"}}
                                   maxSnack={3}
                                   action={(snackbarKey) => <Close onClick={() => closeSnackbar(snackbarKey)} />}>
            {props.children}
        </NotistackSnackbarProvider>
    )
}
