import {Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText} from "@mui/material";

export interface CheckboxProps {
    label: string;
    checked?: boolean;
    onChange?: (value: boolean) => void;

    required?: boolean;
    errorMessage?: string;
    readOnly?: boolean;
}

export default function Checkbox(props: Readonly<CheckboxProps>) {
    const {label, checked, onChange, required, errorMessage, readOnly} = props;
    return (
        <FormControl error={errorMessage !== undefined}>
            <FormControlLabel label={label}
                              required={required}
                              disabled={readOnly}
                              control={
                                  <MuiCheckbox checked={checked}
                                               onChange={(event, value) => onChange?.(value)} />
                              }/>
            {errorMessage &&
                <FormHelperText>{errorMessage}</FormHelperText>
            }
        </FormControl>
    );
}