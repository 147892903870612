import {Box} from "@mui/material";
import React, {useEffect, useState} from "react";
import Dialog from "../../components/dialog/Dialog";
import DialogContent from "../../components/dialog/DialogContent";
import DialogTitle from "../../components/dialog/DialogTitle";
import {useTranslation} from "react-i18next";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {TopicDto} from "../../client/topic/TopicApiClient";
import TopicBasicDetailPanel from "./TopicBasicDetailPanel";
import {TopicTranslKey} from "./TopicTranslKey";
import {TopicEventType} from "./TopicEventType";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import {useClientLocator} from "../../client/ApiClientLocator";

export interface TopicDetailDialogProps {
    open: boolean;
    onClose: () => void;
    topicId?: string;
}

export default function TopicDetailDialog(props: Readonly<TopicDetailDialogProps>) {
    const {open, onClose, topicId} = props;

    const {topicClient} = useClientLocator();
    const eventDispacher = useEventDispatcher();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    const [topic, setTopic] = useState<TopicDto>();
    const [refresh, setRefresh] = useState(Date.now());

    useEffect(() => {
        if (open && topicId) {
            topicClient.findById(topicId)
                .then((topic) => {
                    setTopic(topic);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        }
        if (!open) {
            setTopic(undefined);
        }
    }, [open, topicId, topicClient, snackbar, t, refresh]);


    function onUpdated() {
        setRefresh(Date.now());
        eventDispacher.dispatchEvent(new Event(TopicEventType.TOPIC_UPDATED));
    }

    let content = null;
    if (topic) {
        content = <TopicBasicDetailPanel topic={topic} onUpdate={onUpdated} />;
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose()} maxWidth={"lg"}>
                <DialogTitle title={t(TopicTranslKey.TOPIC)} onClose={() => onClose()} />
                <DialogContent>
                    <Box sx={{mt: 1}}>
                        {content}
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}