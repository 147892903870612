import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import {useCallback, useEffect, useState} from "react";
import {AddButton} from "../../../common/button/CommonButtons";
import ButtonBar from "../../../components/button/ButtonBar";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {ContactDto} from "../../../client/contact/ContactApiClient";
import ListSkeleton from "../../../common/list/ListSkeleton";
import {AffiliationDto, AffiliationFilterDto} from "../../../client/affiliation/AffiliationApiClient";
import {OrganizationDto} from "../../../client/organization/OrganizationApiClient";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {AffiliationTranslKey} from "../../affiliation/AffiliationTranslKey";
import AffiliationCreateDialog from "../../affiliation/AffiliationCreateDialog";
import AffiliationInvalidateDialog from "../../affiliation/AffiliationInvalidateDialog";
import OrganizationAffiliationListItem from "./OrganizationAffiliationListItem";
import AffiliationUpdateDialog from "../../affiliation/AffiliationUpdateDialog";

interface AffiliationModel {
    affiliation: AffiliationDto;
    contact: ContactDto;
}

export interface OrganizationAffiliationPanelProps {
    organization?: OrganizationDto;
    onUpdated?: () => void;
}

export default function OrganizationAffiliationPanel(props: Readonly<OrganizationAffiliationPanelProps>) {
    const {organization, onUpdated} = props;

    const {affiliationClient, contactClient} = useClientLocator();

    const [affiliations, setAffiliations] = useState<AffiliationModel[]>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [invalidateDialogOpen, setInvalidateDialogOpen] = useState(false);
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [affiliationToInvalidate, setAffiliationToInvalidate] = useState<AffiliationModel>();
    const [affiliationToUpdate, setAffiliationToUpdate] = useState<AffiliationModel>();


    const {t} = useTranslation();
    const snackbar = useSnackbar();

    const search = useCallback(() => {
        if (organization) {
            const filter: AffiliationFilterDto = {
                organizationId: organization.id,
                validAt: new Date().toISOString()
            };
            affiliationClient.findByFilter(filter)
                .then((affiliations) => {
                    const promises: Promise<[AffiliationDto, ContactDto]>[] = affiliations.map(affiliation =>
                        new Promise((resolve, reject) => {
                            contactClient.findById(affiliation.contactId)
                                .then(contact => {
                                    if (contact) {
                                        resolve([affiliation, contact]);
                                    } else {
                                        reject(new Error(`Contact with ID ${affiliation.contactId} not found.`));
                                    }
                                })
                                .catch((error) => reject(error));
                        }));
                    return Promise.all(promises);
                })
                .then((values) => {
                    const affiliations: AffiliationModel[] = values.map(([affiliation, contact]) => ({affiliation: affiliation, contact: contact}));
                    setAffiliations(affiliations);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        } else {
            setAffiliations([]);
        }
    }, [organization, affiliationClient, contactClient, t, snackbar]);

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdRelationId?: string) {
        setCreateDialogOpen(false);
        if (createdRelationId) {
            onUpdated?.();
        }
    }

    function showInvalidateDialog(affiliation: AffiliationDto, contact: ContactDto) {
        setInvalidateDialogOpen(true);
        setAffiliationToInvalidate({affiliation, contact});
    }

    function showUpdateDialog(affiliation: AffiliationDto, contact: ContactDto) {
        setUpdateDialogOpen(true);
        setAffiliationToUpdate({affiliation, contact});
    }

    function onInvalidateDialogClosed(deletedId: boolean) {
        setInvalidateDialogOpen(false);
        if (deletedId) {
            onUpdated?.();
        }
    }

    function onUpdateDialogClosed() {
        setUpdateDialogOpen(false);
        onUpdated?.();
    }

    useEffect(() => {
        search();
    }, [search]);

    if (!affiliations) {
        return (
            <ListSkeleton rows={1} rowHeight={72} />
        );
    }

    let content: React.ReactNode;
    if (affiliations.length > 0) {
        content = (
            <List component={"nav"}>
                {affiliations.map(
                    (affiliation) =>
                        <OrganizationAffiliationListItem affiliation={affiliation.affiliation}
                                                         contact={affiliation.contact}
                                                         key={affiliation.affiliation.id}
                                                         onUpdateClick={(affiliation, contact) => showUpdateDialog(affiliation, contact)}
                                                         onInvalidateClick={(affiliation, contact) => showInvalidateDialog(affiliation, contact)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <DataArray/>
                <Typography variant={"body2"}>{t(AffiliationTranslKey.NO_AFFILIATIONS)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!organization?.acl?.canUpdate} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>

            <AffiliationCreateDialog open={createDialogOpen}
                                     onClose={onCreateDialogClosed}
                                     allowPrimary={true}
                                     organization={organization} />

            {organization && affiliationToUpdate &&
                <AffiliationUpdateDialog open={updateDialogOpen}
                                         onClose={onUpdateDialogClosed}
                                         affiliation={affiliationToUpdate.affiliation}
                                         contact={affiliationToUpdate.contact}
                                         organization={organization}/>
            }

            <AffiliationInvalidateDialog open={invalidateDialogOpen}
                                         onClose={(deleted: boolean) => onInvalidateDialogClosed(deleted)}
                                         affiliation={affiliationToInvalidate?.affiliation}
                                         contact={affiliationToInvalidate?.contact}
                                         organization={organization} />
        </>
    );
}
