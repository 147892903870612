import {DialogActions as MuiDialogActions} from "@mui/material";

export interface DialogActionsProps {
    children?: React.ReactNode;
}

export default function DialogActions(props: DialogActionsProps) {
    const {children} = props;

    return (
        <MuiDialogActions sx={{mr: 2, ml: 2, mb: 2}}>
            {children}
        </MuiDialogActions>
    );

}