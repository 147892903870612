import Dialog from "../../components/dialog/Dialog";
import DialogContent from "../../components/dialog/DialogContent";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../common/button/CommonButtons";
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import {DiseaseDto} from "../../client/disease/DiseaseApiClient";
import {formatHierarchyLevel} from "./DiseaseHierarchyLevelFormatter";

enum DeleteDialogState {
    IDLE,
    DELETE_IN_PROGRESS,
}

export interface DiseaseDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    disease?: DiseaseDto;
}

export default function DiseaseDeleteDialog(props: DiseaseDeleteDialogProps) {
    const {open, onClose, disease} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.IDLE);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setDialogState(DeleteDialogState.IDLE);
        }
    }, [open]);

    function deleteResource() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        diseaseClient.deleteDisease(disease!.id)
            .then(() => {
                snackbar.success("Data deleted");
                onClose(true);
            })
            .catch((error) => {
                snackbar.error("", error);
                setDialogState(DeleteDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={"Delete disease?"} />
            <DialogContent>
                <Typography variant={"body1"}>
                    {"Do you really want to delete an item "}
                    <strong>{disease?.name}</strong>
                    {" ("}
                    {disease && formatHierarchyLevel(disease.hierarchyLevel)}
                    {")?"}
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteResource}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}