import {DiseaseDetailDto, DiseaseResourceDto} from "../../../client/disease/DiseaseApiClient";
import {List, Stack, Typography} from "@mui/material";
import DiseaseResourceListItem from "./DiseaseResourceListItem";
import {DataArray} from "@mui/icons-material";
import {useState} from "react";
import DiseaseResourceDeleteDialog from "./DiseaseResourceDeleteDialog";
import DiseaseResourceUpdateDialog from "./DiseaseResourceUpdateDialog";
import {AddButton} from "../../../common/button/CommonButtons";
import ButtonBar from "../../../components/button/ButtonBar";

export interface DiseaseResourcesPanelProps {
    disease?: DiseaseDetailDto;
    onUpdated?: () => void;
}

export default function DiseaseResourcesPanel(props: DiseaseResourcesPanelProps) {
    const {disease, onUpdated} = props;

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [resourceToUpdate, setResourceToUpdate] = useState<DiseaseResourceDto>();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [resourceToDelete, setResourceToDelete] = useState<DiseaseResourceDto>();

    function showCreateDialog() {
        setUpdateDialogOpen(true);
        setResourceToUpdate(undefined);
    }

    function showUpdateDialog(resource: DiseaseResourceDto) {
        setUpdateDialogOpen(true);
        setResourceToUpdate(resource);
    }

    function onCreateDialogClosed(createdResourceId?: string) {
        setUpdateDialogOpen(false);
        if (createdResourceId) {
            onUpdated?.();
        }
    }

    function showDeleteDialog(resource: DiseaseResourceDto) {
        setDeleteDialogOpen(true);
        setResourceToDelete(resource);
    }

    function onDeleteDialogClosed(deleted: boolean) {
        setDeleteDialogOpen(false);
        if (deleted) {
            onUpdated?.();
        }
    }

    if (!disease) {
        return null;
    }

    let content: React.ReactNode;
    if (disease.resources && disease.resources.length > 0) {
        content = (
            <List component={"nav"}>
                {disease.resources.map(
                    (resource, index) =>
                        <DiseaseResourceListItem resource={resource}
                                                 key={resource.id}
                                                 disabled={!disease.info.acl.canUpdate}
                                                 onUpdateClick={showUpdateDialog}
                                                 onDeleteClick={showDeleteDialog}/>
                )}
            </List>
        );
    } else {
        content = (
            <>
                <Stack direction={"row"}
                       spacing={1}
                       alignItems={"center"}
                       sx={{m: 2}} >
                    <DataArray/>
                    <Typography variant={"body2"}>No additional resources</Typography>
                </Stack>
            </>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!disease.info.acl.canUpdate} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>
            <DiseaseResourceUpdateDialog open={updateDialogOpen}
                                         onClose={onCreateDialogClosed}
                                         resource={resourceToUpdate}
                                         diseaseId={disease.info.id} />

            <DiseaseResourceDeleteDialog open={deleteDialogOpen}
                                         onClose={onDeleteDialogClosed}
                                         resource={resourceToDelete}
                                         diseaseId={disease.info.id} />
        </>
    );
}