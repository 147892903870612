import { DiseaseDto } from "../../client/disease/DiseaseApiClient";

export function getDiseaseChainPathToRoot(disease: DiseaseDto, diseaseLookup: Map<string, DiseaseDto>): string[] {
    if (disease.primaryParentId) {
        const parent = diseaseLookup.get(disease.primaryParentId);
        if (parent) {
            return [...getDiseaseChainPathToRoot(parent!, diseaseLookup), disease.id];
        }
    }

    return [disease.id];
}
