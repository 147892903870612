import {Axios} from "axios";

export interface DiseaseDto {
    id: string;
    name: string;
    abbreviation?: string;
    hierarchyLevel: DiseaseHierarchyLevel;
    description?: string;
    orphacode?: string;
    primaryParentId?: string;
    acl: DiseaseAclDto;
}

export enum DiseaseHierarchyLevel {
    DISEASE = "DISEASE",
    DISEASE_GROUP = "DISEASE_GROUP",
    SUB_STREAM = "SUB_STREAM",
    STREAM = "STREAM",
}

export interface DiseaseAclDto {
    canDelete: boolean;
    canUpdate: boolean;
}

export interface DiseaseFilterDto {
    name?: string;
    nameOrAbbreviationLike?: string;
    nameLike?: string;
    orphacode?: string;
    orphacodeLike?: string;
    parentId?: string;
    isRoot?: boolean;
    level?: DiseaseHierarchyLevel;
}

export interface DiseaseCreateDto {
    name: string;
    abbreviation?: string;
    hierarchyLevel: DiseaseHierarchyLevel;
    description?: string;
    orphacode?: string;
    parentId?: string;
}

export interface DiseaseUpdateDto {
    name: string;
    abbreviation?: string;
    description?: string;
    orphacode?: string;
}

export interface DiseaseDetailDto {
    info: DiseaseDto;
    alternativeNames?: DiseaseAlternativeNameDto[];
    resources?: DiseaseResourceDto[];
}

export interface DiseaseAlternativeNameDto {
    id: string;
    alternativeName: string;
}

export interface DiseaseAlternativeNameUpdateDto {
    alternativeName: string;
}

export enum DiseaseResourceLocation {
    INTERNAL = "INTERNAL",
    EXTERNAL = "EXTERNAL"
}

export interface DiseaseResourceDto {
    id: string;
    title?: string;
    url?: string;
    location?: DiseaseResourceLocation;
    type?: string;
}

export interface DiseaseResourceUpdateDto {
    title?: string;
    url?: string;
    location?: DiseaseResourceLocation;
    type?: string;
}

export interface DiseaseHierarchyRelationDto {
    id: string;
    parent: string;
    child: string;
    isPrimary: boolean;
}

export interface DiseaseHierarchyRelationCreateDto {
    parentId: string;
    isPrimary: boolean;
}


export class DiseaseApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(diseaseId: string): Promise<DiseaseDetailDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get(`/diseases/${diseaseId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findByFilter(filter: DiseaseFilterDto): Promise<DiseaseDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<DiseaseDto[]>("/diseases:search", filter)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createDisease(create: DiseaseCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/diseases`, create)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateDisease(diseaseId: string, update: DiseaseUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/diseases/${diseaseId}`, update)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }

    deleteDisease(diseaseId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/diseases/${diseaseId}`)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }

    createAlternativeName(diseaseId: string, name: string): Promise<string> {
        const create: DiseaseAlternativeNameUpdateDto = {
            alternativeName: name
        };
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/diseases/${diseaseId}/alternativeNames`, create)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateAlternativeName(alternativeNameId: string, diseaseId: string, name: string): Promise<void> {
        const update: DiseaseAlternativeNameUpdateDto = {
            alternativeName: name
        };
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/diseases/${diseaseId}/alternativeNames/${alternativeNameId}`, update)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }

    deleteAlternativeName(alternativeNameId: string, diseaseId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/diseases/${diseaseId}/alternativeNames/${alternativeNameId}`)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }

    createResource(diseaseId: string, resource: DiseaseResourceUpdateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/diseases/${diseaseId}/resources`, resource)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateResource(resourceId: string, diseaseId: string, resourceUpdate: DiseaseResourceUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/diseases/${diseaseId}/resources/${resourceId}`, resourceUpdate)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }

    deleteResource(resourceId: string, diseaseId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/diseases/${diseaseId}/resources/${resourceId}`)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }

    findHierarchyRelations(diseaseId: string, isPrimary?: boolean): Promise<DiseaseHierarchyRelationDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get(`/diseases/${diseaseId}/relations`, {params: {isPrimary: isPrimary}})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createHierarchyRelation(diseaseId: string, relationCreate: DiseaseHierarchyRelationCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/diseases/${diseaseId}/relations`, relationCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteHierarchyRelation(relationId: string, diseaseId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/diseases/${diseaseId}/relations/${relationId}`)
                .then((response) => resolve())
                .catch((error) => reject(error));
        });
    }
}
