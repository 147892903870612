import {Axios} from "axios";
import {CatalogDto} from "../catalog/CatalogApiClient";
import {DiseaseSpecializationDto} from "../disease/DiseaseSpecializationDto";
import {ContactAffiliationRole} from "../affiliation/AffiliationApiClient";

export enum Unit {
    PAEDIATRIC = "PAEDIATRIC",
    ADULT = "ADULT"
}

export enum ContactTitle {
    DR = "Dr.",
    PROF = "Prof.",
    MBA = "MBA",
    PHD = "PhD"
}

export interface ContactSummaryDto {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    country?: CatalogDto;
    cpmsStatus: CpmsStatus;
    primaryAffiliation: ContactAffiliationSummaryDto;
    specializations: DiseaseSpecializationDto[];

    acl: ContactAclDto;
}

export interface ContactAffiliationSummaryDto {
    id: string;
    organization: OrganizationSummaryDto;
    role: ContactAffiliationRole;
    expertise: string;
}

export interface OrganizationSummaryDto {
    id: string;
    name: string;
    abbreviation: string;
}

export interface ContactDto {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    phone: string;
    note: string;
    survey: boolean;
    cpg: boolean;
    cpgJia: boolean;
    standIn: ContactBriefDto | null;
    webinars: string[];
    country?: CatalogDto;
    units: Unit[];
    hasCpms: boolean;
    acl: ContactAclDto;
}

export interface ContactBriefDto {
    id: string;
    firstName: string;
    lastName: string;
    title: string;
}

export interface ContactAclDto {
    canUpdate: boolean;
    canDelete: boolean;
}

export interface ContactFilterDto {
    firstNameLike?: string;
    lastNameLike?: string;
    emailLike?: string;
    countries?: number[];
    units?: Unit[];

    diseases?: string[];
    primaryOrganizationNameLike?: string;
    secondaryOrganizationNameLike?: string;
    cpmsPanel?: CpmsPanel;
    topicNameLike?: string;
}

export interface ContactCreateDto {
    firstName: string;
    lastName: string;
    email: string;
    title?: string;
    phone?: string;
    note?: string;
    survey?: boolean;
    cpg?: boolean;
    cpgJia?: boolean;
    standInId?: string;
    webinars: string[];
    countryId?: number | null;
    units: Unit[];
}

export interface ContactUpdateDto extends ContactCreateDto {
}

export interface ContactSpecializationDto extends DiseaseSpecializationDto {
}

export interface ContactSpecializationCreateDto {
    diseaseId: string;
}

export enum CpmsStatus {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    REJECTED = "REJECTED",
    INSTRUCTIONS_SENT = "INSTRUCTIONS_SENT",
    TO_BE_CONTACTED = "TO_BE_CONTACTED",
    OTHER = "OTHER"
}

export enum CpmsPanel {
    PID = "PID",
    AID = "AID",
    AI = "AI",
    PR = "PR"
}

export interface ContactCpmsDto {
    referringPhysicianCount: number;
    panelLeadCount: number;
    panelMemberCount: number;
    status: CpmsStatus;
    otherStatus: string | undefined;
    panels: CpmsPanel[];
}

export interface ContactCpmsUpdateDto {
    referringPhysicianCount: number;
    panelLeadCount: number;
    panelMemberCount: number;
    status: string;
    otherStatus: string | undefined;
    panels: CpmsPanel[];
}



export class ContactApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(contactId: string): Promise<ContactDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<ContactDto | undefined>(`/contacts/${contactId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findByFilter(filter: ContactFilterDto): Promise<ContactSummaryDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<ContactSummaryDto[]>("/contacts:search", filter)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createContact(contactCreate: ContactCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>("/contacts", contactCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateContact(contactId: string, contactUpdate: ContactUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/contacts/${contactId}`, contactUpdate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteContact(contactId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/contacts/${contactId}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    findContactSpecializations(contactId: string): Promise<ContactSpecializationDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get<ContactSpecializationDto[]>(`/contacts/${contactId}/specializations`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createContactSpecialization(contactId: string, diseaseId: string): Promise<string> {
        const specializationCreate: ContactSpecializationCreateDto = {
            diseaseId: diseaseId
        };
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/contacts/${contactId}/specializations`, specializationCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteContactSpecialization(contactId: string, specializationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/contacts/${contactId}/specializations/${specializationId}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    findContactCpms(contactId: string): Promise<ContactCpmsDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<ContactCpmsDto | undefined>(`/contacts/${contactId}/cpms`)
                    .then((response) => resolve(response.data))
                    .catch((error) => {
                        if (error?.response?.status === 404) {
                            resolve(undefined);
                        }
                        reject(error);
                    });
        });
    }

    updateContactCpms(contactId: string, contactCpmsUpdate: ContactCpmsUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/contacts/${contactId}/cpms`, contactCpmsUpdate)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }
}
