import {TopicMembershipDto, TopicMembershipTitle} from "../../../client/contact/TopicMembershipApiClient";
import {TranslationFunction} from "../../../i18n/i18n";
import {ContactTranslKey} from "../ContactTranslKey";

export class TopicMembershipTitleFormatter {
    public static formatTitle(title: TopicMembershipTitle | string | null | undefined, otherTitle: string | null | undefined, t: TranslationFunction): string {
        if (!title) {
            return "";
        }

        if (title === TopicMembershipTitle.OTHER) {
            return otherTitle ?? "";
        }

        const translKey = ContactTranslKey.TOPIC_MEMBERSHIP_TITLE_NAMESPACE + title;
        return t(translKey);
    }

    public static formatTitleOfMembership(membership: TopicMembershipDto, t: TranslationFunction): string {
        return TopicMembershipTitleFormatter.formatTitle(membership.title, membership.otherTitle, t);
    }
}