import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {CopyUtils} from "../../../util/CopyUtils";
import {OrganizationFilterDto, OrganizationType} from "../../../client/organization/OrganizationApiClient";
import {useContext, useEffect, useState} from "react";
import CountryContext from "../../../catalog/CountryContext";
import MultiSelectField, {SelectOption} from "../../../components/field/MultiSelectField";
import {OrganizationTranslKey} from "../OrganizationTranslKey";
import {useTranslation} from "react-i18next";
import SelectField from "../../../components/field/SelectField";
import {OrganizationTypeFormatter} from "../OrganizationTypeFormatter";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../../client/ApiClientLocator";
import DatePicker from "../../../components/field/DatePicker";

export interface OrganizationFilterFormProps {
    filter: OrganizationFilterDto;
    onChange: (filter: OrganizationFilterDto) => void;
}

export function OrganizationFilterForm(props: Readonly<OrganizationFilterFormProps>) {
    const {filter, onChange} = props;

    const {diseaseClient} = useClientLocator();
    const {t} = useTranslation();

    const countries = useContext(CountryContext);
    const [diseaseStreamsAndSubstreams, setDiseaseStreamsAndSubstreams] = useState<DiseaseDto[]>([]);

    useEffect(() => {
        const streamPromise = diseaseClient.findByFilter({level: DiseaseHierarchyLevel.STREAM});
        const substreamPromise = diseaseClient.findByFilter({level: DiseaseHierarchyLevel.SUB_STREAM});
        Promise.all([streamPromise, substreamPromise])
            .then(([streams, substreams]) => {
                streams.sort((a, b) => a.name.localeCompare(b.name));
                substreams.sort((a, b) => a.name.localeCompare(b.name))
                setDiseaseStreamsAndSubstreams(streams.concat(substreams));
            });
    }, [diseaseClient]);

    function onNameOrAbbreviationChange(name: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.nameOrAbbreviationLike = normalizeString(name);
        onChange(updatedFilter);
    }

    function onOrgTypeChange(type: unknown) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.organizationType = type as OrganizationType;
        onChange(updatedFilter);
    }

    function onCountriesChange(countryIds: unknown[]) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.countries = countryIds.map(id => Number(id));
        onChange(updatedFilter);
    }

    function onDiseasesChange(diseaseIds: unknown[]) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.diseases = diseaseIds.map(id => id as string);
        onChange(updatedFilter);
    }

    function onAffiliatedSinceChange(affiliatedSince: string | undefined) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.hcpAffiliationValidFromGreaterOrEq = affiliatedSince;
        onChange(updatedFilter);
    }

    function normalizeString(value: string): string | undefined {
        const normalizedValue = value.trim();
        return (normalizedValue.length > 0) ? normalizedValue : undefined;
    }

    const orgTypeOptions = Object.keys(OrganizationType).map((type) => ({
        value: type as OrganizationType,
        label: OrganizationTypeFormatter.formatOrganizationType(type as OrganizationType, t)
    }));

    const diseaseOptions = diseaseStreamsAndSubstreams.map((disease) => ({
        value: disease.id,
        label: disease.abbreviation ?? disease.name
    }));

    const countryOptions: SelectOption[] = countries.map((country) => ({
        value: country.id,
        label: `${country.name} [${country.code}]`
    }));

    return (
        <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <TextField label={t(OrganizationTranslKey.NAME_OR_ABBREVIATION)}
                           value={filter.nameOrAbbreviationLike ?? ""}
                           onChange={onNameOrAbbreviationChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <SelectField label={t(OrganizationTranslKey.ORGANIZATION_TYPE)}
                                  value={filter.organizationType}
                                  onChange={onOrgTypeChange}
                                  limitTags={1}
                                  options={orgTypeOptions} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiSelectField label={t(OrganizationTranslKey.COUNTRY)}
                                  value={filter.countries}
                                  onChange={onCountriesChange}
                                  limitTags={1}
                                  options={countryOptions} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <MultiSelectField label={t(OrganizationTranslKey.DISEASE)}
                                  value={filter.diseases}
                                  onChange={onDiseasesChange}
                                  limitTags={1}
                                  options={diseaseOptions} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <DatePicker label={t(OrganizationTranslKey.AFFILIATED_SINCE)}
                            value={filter.hcpAffiliationValidFromGreaterOrEq}
                            onChange={onAffiliatedSinceChange} />
            </Grid>
        </Grid>
    );
}

