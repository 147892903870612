import {GridColDef, GridSortModel} from "@mui/x-data-grid-pro";
import {UserDto} from "../../client/user/UserApiClient";
import DataGrid from "../../components/datagrid/DataGrid";
import {UserRoleFormatter} from "./role/UserRoleFormatter";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserTranslKey} from "./UserTranslKey";


export interface UserGridProps {
    users: UserDto[];
    selectedId?: string;
    onSelectionChange?: (userId?: string) => void;
    onRowDoubleClick?: (user: UserDto) => void;
}

export default function UserGrid(props: UserGridProps) {
    const {users, selectedId, onSelectionChange, onRowDoubleClick} = props;

    const {t} = useTranslation();

    const columnDefs: GridColDef<UserDto>[] = [
        { field: "login", headerName: t(UserTranslKey.LOGIN), width: 150 },
        { field: "firstName", headerName: t(UserTranslKey.FIRST_NAME), width: 150 },
        { field: "lastName", headerName: t(UserTranslKey.LAST_NAME), width: 150 },
        { field: "email", headerName: t(UserTranslKey.EMAIL), width: 250 },
        { field: "activeRoles", headerName: t(UserTranslKey.ROLES), width: 400, valueFormatter: params => UserRoleFormatter.formatUserRoles(params.value, t) },
    ];

    const defaultSortModel: GridSortModel = useMemo(() => [
        {field: "login", sort: "asc"},
    ], []);

    const [sortModel, setSortModel] = useState(defaultSortModel);

    return (
        <DataGrid
            rows={users}
            columns={columnDefs}
            autoHeight
            rowSelectionModel={selectedId}
            onRowSelectionModelChange={((rowSelectionModel) =>
                    onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowDoubleClick={(params) => onRowDoubleClick?.(params.row)} />
    );
}
