import {Grid, List, Stack, Typography} from "@mui/material";
import ButtonBar from "../../../../components/button/ButtonBar";
import {AddButton} from "../../../../common/button/CommonButtons";
import {useTranslation} from "react-i18next";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";
import TopicListItem from "./TopicListItem";
import {TopicDto} from "../../../../client/topic/TopicApiClient";
import TopicSearchDialog from "../../../topic/search/TopicSearchDialog";
import React, {useState} from "react";
import RegistrationFormHint from "../RegistrationFormHint";

export interface TopicPanelProps {
    topics: TopicDto[];
    onChange?: (topics: TopicDto[]) => void;
}

export default function TopicPanel(props: Readonly<TopicPanelProps>) {
    const {topics, onChange} = props;

    const {t} = useTranslation();

    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    function showSearchDialog() {
        setSearchDialogOpen(true);
    }

    function onSearchDialogClosed(selectedTopic?: TopicDto) {
        setSearchDialogOpen(false);
        if (selectedTopic) {
            onChange?.([...topics, selectedTopic]);
        }
    }

    function removeTopic(topic: TopicDto) {
        const updatedTopics = topics.filter(t => t.id !== topic.id);
        onChange?.(updatedTopics);
    }

    let content: React.ReactNode;
    if (topics.length > 0) {
        content = (
            <List component={"nav"}>
                {topics.map(
                    (topic) =>
                        <TopicListItem topic={topic}
                                       key={topic.id}
                                       onRemoveClick={(topic) => removeTopic(topic)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <Typography variant={"body2"} fontStyle={"italic"}>{t(RegistrationTranslKey.NO_TOPICS)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                <Grid item mt={2} mb={1}>
                    <RegistrationFormHint message={t(RegistrationTranslKey.SELECT_TOPIC_INTERESTS_HINT)}/>
                </Grid>
                {content}
                <ButtonBar>
                    <AddButton onClick={() => showSearchDialog()}/>
                </ButtonBar>
            </Stack>

            <TopicSearchDialog open={searchDialogOpen}
                               onClose={onSearchDialogClosed}/>
        </>
    );
}
