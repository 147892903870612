import {Box} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import TabPanel from "../../components/tab/TabPanel";
import Tab from "../../components/tab/Tab";
import Tabs from "../../components/tab/Tabs";
import Dialog from "../../components/dialog/Dialog";
import DialogContent from "../../components/dialog/DialogContent";
import DialogTitle from "../../components/dialog/DialogTitle";
import ContactBasicDetailPanelSkeleton from "./ContactBasicDetailPanelSkeleton";
import ContactBasicDetailPanel from "./ContactBasicDetailPanel";
import {ContactDto} from "../../client/contact/ContactApiClient";
import DiseaseSpecializationPanel, {
    DiseaseSpecializationClient
} from "../disease/specialization/DiseaseSpecializationPanel";
import ContactAffiliationPanel from "./affiliation/ContactAffiliationPanel";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {ContactSpecializationClientAdapter} from "./specialization/ContactSpecializationClientAdapter";
import {ContactEventType} from "./ContactEventType";
import {useEventDispatcher} from "../../components/EventDispatcher";
import TopicMembershipPanel from "./membership/TopicMembershipPanel";
import {ContactTranslKey} from "./ContactTranslKey";
import ContactCpmsPanel from "./cpms/ContactCpmsPanel";

enum TabKey {
    BASIC = "basic",
    AFFILIATIONS = "affiliations",
    SPECIALIZATIONS = "specializations",
    TOPICS = "topics",
    CPMS = "cpms"
}

export interface ContactDetailDialogProps {
    open: boolean;
    onClose: (updated: boolean) => void;
    contactId: string | undefined;
}

export default function ContactDetailDialog(props: Readonly<ContactDetailDialogProps>) {
    const {open, onClose, contactId} = props;

    const {contactClient} = useClientLocator();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();
    const snackbar = useSnackbar();

    const [contact, setContact] = useState<ContactDto>();
    const [selectedTab, setSelectedTab] = useState(TabKey.BASIC);
    const [refresh, setRefresh] = useState(Date.now());
    const [updated, setUpdated] = useState(false);

    const specializationClient: DiseaseSpecializationClient = useMemo(
        () => new ContactSpecializationClientAdapter(contact?.id, contactClient),
        [contactClient, contact]);

    useEffect(() => {
        if (open && contactId) {
            contactClient.findById(contactId)
                .then((contact) => {
                    setContact(contact);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        }
        if (!open) {
            setContact(undefined);
            setUpdated(false);
            setSelectedTab(TabKey.BASIC);
        }
    }, [open, contactId, contactClient, snackbar, t, refresh]);

    function onUpdated() {
        setRefresh(Date.now());
        setUpdated(true);
        eventDispatcher.dispatchEvent(new Event(ContactEventType.CONTACT_UPDATED));
    }

    let content: JSX.Element;
    if (contact) {
        content = (
            <ContactBasicDetailPanel contact={contact} onUpdate={onUpdated} />
        );
    } else {
        content = (
            <ContactBasicDetailPanelSkeleton />
        );
    }

    return (
        <Dialog open={open} onClose={() => onClose(updated)} maxWidth={"lg"}>
            <DialogTitle title={"Contact"} onClose={() => onClose(updated)} />
            <DialogContent>
                <Tabs value={selectedTab}
                      onChange={(e, tab) => setSelectedTab(tab)}
                      variant={"scrollable"}>
                    <Tab label={t(CommonTranslKey.DETAILS)} value={TabKey.BASIC} />
                    <Tab label={t(ContactTranslKey.ORGANIZATIONS)} value={TabKey.AFFILIATIONS} disabled={contact === undefined} />
                    <Tab label={t(ContactTranslKey.DISEASES)} value={TabKey.SPECIALIZATIONS} disabled={contact === undefined} />
                    <Tab label={t(ContactTranslKey.TOPICS)} value={TabKey.TOPICS} disabled={contact === undefined} />
                    <Tab label={t(ContactTranslKey.CPMS)} value={TabKey.CPMS} disabled={contact === undefined || !contact.hasCpms} />
                </Tabs>
                <TabPanel value={TabKey.BASIC} selectedValue={selectedTab}>
                    <Box sx={{mt: 1}}>
                        {content}
                    </Box>
                </TabPanel>
                <TabPanel value={TabKey.AFFILIATIONS} selectedValue={selectedTab}>
                    <ContactAffiliationPanel contact={contact} onUpdated={onUpdated} />
                </TabPanel>
                <TabPanel value={TabKey.SPECIALIZATIONS} selectedValue={selectedTab}>
                    <DiseaseSpecializationPanel canUpdate={contact?.acl.canUpdate ?? false}
                                                onUpdated={onUpdated}
                                                specializationClient={specializationClient}/>
                </TabPanel>
                <TabPanel value={TabKey.TOPICS} selectedValue={selectedTab}>
                    <TopicMembershipPanel contactId={contact?.id}
                                          canUpdate={contact?.acl.canUpdate ?? false}
                                          onUpdated={onUpdated}/>
                </TabPanel>
                <TabPanel value={TabKey.CPMS} selectedValue={selectedTab}>
                    <ContactCpmsPanel contactId={contactId}
                                      canUpdate={contact?.acl.canUpdate}/>
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
}
