import {Axios} from "axios";
import {CatalogDto} from "../catalog/CatalogApiClient";
import {Unit} from "../contact/ContactApiClient";
import {DiseaseSpecializationDto} from "../disease/DiseaseSpecializationDto";
import {ValidityIntervalDto} from "../time/ValidityIntervalDto";

export enum OrganizationType {
    HEALTHCARE_PROVIDER = "HEALTHCARE_PROVIDER",
    PATIENT_ORGANIZATION = "PATIENT_ORGANIZATION",
    SCIENTIFIC_SOCIETY = "SCIENTIFIC_SOCIETY",
    PHARMACEUTICAL_COMPANY = "PHARMACEUTICAL_COMPANY",
    RARE_DISEASE_REGISTRY = "RARE_DISEASE_REGISTRY",
    EUROPEAN_REFERENCE_NETWORK = "EUROPEAN_REFERENCE_NETWORK",
}

export enum HealthcareProviderAffiliation {
    FULL_MEMBER = "FULL_MEMBER",
    AFFILIATED_PARTNER = "AFFILIATED_PARTNER",
    GUEST = "GUEST",
    COORDINATION_HUB = "COORDINATION_HUB",
    COLLABORATIVE_PARTNER = "COLLABORATIVE_PARTNER",
    NO_AFFILIATION = "NO_AFFILIATION",
}

export enum EuropeanReferenceNetwork {
    ERN_BOND = "ERN_BOND",
    ERN_CRANIO = "ERN_CRANIO",
    ENDO_ERN = "ENDO_ERN",
    ERN_EPI_CARE = "ERN_EPI_CARE",
    ERK_NET = "ERK_NET",
    ERN_RND = "ERN_RND",
    ERNICA = "ERNICA",
    ERN_LUNG = "ERN_LUNG",
    ERN_SKIN = "ERN_SKIN",
    ERN_EURACAN = "ERN_EURACAN",
    ERN_EURO_BLOOD_NET = "ERN_EURO_BLOOD_NET",
    ERN_E_UROGEN = "ERN_E_UROGEN",
    ERN_EURO_NMD = "ERN_EURO_NMD",
    ERN_EYE = "ERN_EYE",
    ERN_GENTURIS = "ERN_GENTURIS",
    ERN_GUARD_HEART = "ERN_GUARD_HEART",
    ERN_ITHACA = "ERN_ITHACA",
    METAB_ERN = "METAB_ERN",
    ERN_PAED_CAN = "ERN_PAED_CAN",
    ERN_RARE_LIVER = "ERN_RARE_LIVER",
    ERN_RE_CONNET = "ERN_RE_CONNET",
    ERN_RITA = "ERN_RITA",
    ERN_TRANSPLANT_CHILD = "ERN_TRANSPLANT_CHILD",
    VASCERN = "VASCERN",
    OTHER = "OTHER",
}

export interface OrganizationDto {
    id: string;
    name: string;
    abbreviation: string;
    description?: string;
    units: Unit[];

    address?: string;
    city?: string;
    country?: CatalogDto;

    gps?: string;
    website?: string;

    typeDetail?: OrganizationTypeDetailDto;

    validityInterval?: ValidityIntervalDto;
    specializations?: OrganizationSpecializationDto[];

    acl: OrganizationAclDto;
}

export interface OrganizationTypeDetailDto {
    organizationType: OrganizationType;
    hcpAffiliation?: HealthcareProviderAffiliation;
    hcpAffiliationValidity?: ValidityIntervalDto;
    isMeritaMember?: boolean;
    isRipagMember?: boolean;
    ern?: string;
    otherErn?: string;
}

export interface OrganizationAclDto {
    canUpdate: boolean;
    canDelete: boolean;
}

export interface OrganizationFilterDto {
    nameLike?: string;
    nameOrAbbreviationLike?: string;
    organizationType?: OrganizationType;
    units?: Unit[];

    addressLike?: string;
    cityLike?: string;
    countries?: number[];

    diseases?: string[];

    validAt?: string;

    hcpAffiliation?: HealthcareProviderAffiliation;
    hcpAffiliationValidFromGreaterOrEq?: string;
}

export interface OrganizationCreateDto {
    name: string;
    abbreviation?: string;
    description?: string;
    units: Unit[];
    address?: string;
    city?: string;
    countryId?: number | null;

    gps?: string;
    website?: string;

    typeDetail: OrganizationTypeDetailDto;
}

export interface OrganizationCpmsStatisticsDto {
    statuses: { [key: string]: number };

    referringPhysician: number;
    panelLead: number;
    panelMember: number;
}

export interface OrganizationUpdateDto extends OrganizationCreateDto {
}

export interface OrganizationSpecializationDto extends DiseaseSpecializationDto {
}

export interface OrganizationSpecializationCreateDto {
    diseaseId: string;
}


export class OrganizationApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(organizationId: string): Promise<OrganizationDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<OrganizationDto | undefined>(`/organizations/${organizationId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findByFilter(filter: OrganizationFilterDto): Promise<OrganizationDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<OrganizationDto[]>("/organizations:search", filter)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createOrganization(organizationCreate: OrganizationCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>("/organizations", organizationCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateOrganization(organizationId: string, organizationUpdate: OrganizationUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/organizations/${organizationId}`, organizationUpdate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteOrganization(organizationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete(`/organizations/${organizationId}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    invalidateOrganization(organizationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post(`/organizations/${organizationId}:invalidate`)
                    .then(() => resolve())
                    .catch((error) => reject(error));
        });
    }

    findOrganizationSpecializations(contactId: string): Promise<OrganizationSpecializationDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get<OrganizationSpecializationDto[]>(`/organizations/${contactId}/specializations`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createOrganizationSpecialization(contactId: string, diseaseId: string): Promise<string> {
        const specializationCreate: OrganizationSpecializationCreateDto = {
            diseaseId: diseaseId
        };
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/organizations/${contactId}/specializations`, specializationCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteOrganizationSpecialization(contactId: string, specializationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/organizations/${contactId}/specializations/${specializationId}`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    computeOrganizationCpmsStatistics(organizationId: string): Promise<OrganizationCpmsStatisticsDto> {
        return new Promise((resolve, reject) => {
            this.axios.post<OrganizationCpmsStatisticsDto>(`/organizations/${organizationId}:compute-cpms-statistics`)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }
}
