import {useClientLocator} from "../../client/ApiClientLocator";
import {ContactSummaryDto} from "../../client/contact/ContactApiClient";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import {Typography} from "@mui/material";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../common/button/CommonButtons";
import {useState} from "react";
import {ContactFormatter} from "./ContactFormatter";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {ContactEventType} from "./ContactEventType";
import {Trans, useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {ContactTranslKey} from "./ContactTranslKey";

enum DeleteDialogState {
    IDLE,
    DELETE_IN_PROGRESS,
}

export interface ContactDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    contact: ContactSummaryDto;
}

export default function ContactDeleteDialog(props: Readonly<ContactDeleteDialogProps>) {
    const {open, onClose, contact} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.IDLE);

    const {contactClient} = useClientLocator();
    const {t} = useTranslation();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();

    function deleteContact() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        contactClient.deleteContact(contact.id)
            .then(() => {
                snackbar.success(t(CommonTranslKey.DATA_DELETED));
                setDialogState(DeleteDialogState.IDLE);
                onClose(true);
                eventDispatcher.dispatchEvent(new Event(ContactEventType.CONTACT_DELETED));
            })
            .catch((error) => {
                snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                setDialogState(DeleteDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(ContactTranslKey.DELETE_CONTACT)}/>
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={ContactTranslKey.DELETE_CONTACT_PROMPT}
                           values={{contactName: ContactFormatter.formatContactName(contact)}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteContact}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
