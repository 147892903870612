import {Axios} from "axios";
import {UserDto} from "../user/UserApiClient";
import {DiseaseSpecializationDto} from "../disease/DiseaseSpecializationDto";
import {ContactAffiliationRole, ContactAffiliationTitle} from "../affiliation/AffiliationApiClient";

export enum ContactRegistrationRequestStateType {
    RECEIVED = "RECEIVED",
    ASSIGNED_TO_REVIEWER = "ASSIGNED_TO_REVIEWER",
    APPROVED = "APPROVED",
    REJECTED = "REJECTED"
}

export interface ContactRegistrationRequestAclDto {
    canCreate: boolean;
    canUpdate: boolean;
    canAssign: boolean;
    canApprove: boolean;
    canReject: boolean;
}

export interface ContactRegistrationRequestStateDto {
    id: number;
    stateType: string;
    triggeredAt: string;
    triggeredBy: string;
}

export interface ContactRegistrationDataDto {
    title?: string;
    firstName: string;
    lastName: string;
    email: string;

    primaryOrganizationId: string;
    primaryOrganizationRole: ContactAffiliationRole;
    primaryOrganizationTitle: ContactAffiliationTitle;
    otherPrimaryOrganizationTitle?: string;
    expertise?: string;
}

export interface ContactRegistrationDataUpdateDto {
    firstName: string;
    lastName: string;
    title?: string;
    primaryOrganizationRole: ContactAffiliationRole;
    primaryOrganizationTitle: ContactAffiliationTitle;
    otherPrimaryOrganizationTitle?: string;
    expertise?: string;
}

export interface ContactRegistrationRequestFilterDto {
    firstNameLike?: string;
    lastNameLike?: string;
    emailLike?: string;
    states?: ContactRegistrationRequestStateType[];
}

export interface ContactRegistrationRequestDto {
    id: string;
    assignedReviewer?: UserDto;
    createdContactId?: string;
    currentState: ContactRegistrationRequestStateDto;
    contactRegistrationData: ContactRegistrationDataDto;
    acl: ContactRegistrationRequestAclDto;
}

export interface ContactRegistrationRequestCreateDto {
    firstName: string;
    lastName: string;
    title?: string;
    email: string;

    primaryOrganizationId: string;
    primaryOrganizationRole: ContactAffiliationRole;
    primaryOrganizationTitle: ContactAffiliationTitle;
    otherPrimaryOrganizationTitle?: string;
    expertise?: string;

    diseaseIds: string[];
    topicIds: string[];
}

export interface ContactRegistrationSpecializationDto extends DiseaseSpecializationDto {
}

export interface ContactRegistrationSpecializationCreateDto {
    diseaseId: string;
}

export interface ContactRegistrationTopicInterestDto {
    topicInterestId: string;
    topicId: string;
}

export interface ContactRegistrationTopicInterestCreateDto {
    topicId: string;
}


export class RegistrationApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(registrationId: string): Promise<ContactRegistrationRequestDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<ContactRegistrationRequestDto | undefined>(`/registrations/${registrationId}`)
                    .then((response) => resolve(response.data))
                    .catch((error) => {
                        if (error?.response?.status === 404) {
                            resolve(undefined);
                        }
                        reject(error);
                    });
        });
    }

    findByFilter(filter: ContactRegistrationRequestFilterDto): Promise<ContactRegistrationRequestDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<ContactRegistrationRequestDto[]>("/registrations:search", filter)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }

    createRegistration(requestCreate: ContactRegistrationRequestCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>("/registrations", requestCreate)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }

    completeContactData(registrationId: string, contactData: ContactRegistrationDataUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post<void>(`/registrations/${registrationId}:completeContactData`, contactData)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }

    findContactDataSpecializations(registrationId: string): Promise<ContactRegistrationSpecializationDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get<ContactRegistrationSpecializationDto[]>(`/registrations/${registrationId}/specializations`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createContactDataSpecialization(registrationId: string, diseaseId: string): Promise<string> {
        const specializationCreate: ContactRegistrationSpecializationCreateDto = {
            diseaseId: diseaseId
        };
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/registrations/${registrationId}/specializations`, specializationCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteContactDataSpecialization(registrationId: string, specializationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/registrations/${registrationId}/specializations/${specializationId}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    findContactDataTopicInterests(registrationId: string): Promise<ContactRegistrationTopicInterestDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get<ContactRegistrationTopicInterestDto[]>(`/registrations/${registrationId}/topic-interests`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    createContactDataTopicInterest(registrationId: string, topicId: string): Promise<string> {
        const topicCreate: ContactRegistrationTopicInterestCreateDto = {
            topicId: topicId
        };
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/registrations/${registrationId}/topic-interests`, topicCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    deleteContactDataTopicInterest(registrationId: string, topicInterestId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.delete<void>(`/registrations/${registrationId}/topic-interests/${topicInterestId}`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    approve(registrationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post<void>(`/registrations/${registrationId}:approve`)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
        });
    }

    reject(registrationId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post<void>(`/registrations/${registrationId}:reject`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    assignToReviewer(registrationId: string, reviewerUserId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const config = { headers: {'Content-Type': 'application/json'} };
            this.axios.post<void>(`/registrations/${registrationId}:assign`, reviewerUserId, config)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}
