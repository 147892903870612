export enum DiseaseTranslKey {
    ABBREVIATION = "disease:abbreviation",
    CREATE_NEW_DISEASE = "disease:createNewDisease",
    DESCRIPTION = "disease:description",
    DISEASE = "disease:disease",
    DISEASES = "disease:diseases",
    LEVEL = "disease:level",
    NAME = "disease:name",
    NAME_OR_ABBREVIATION = "disease:nameOrAbbreviation",
    ORPHACODE = "disease:orphacode",
    PARENT_LEVEL_MUST_BE_HIGHER = "disease:parentLevelMustBeHigher",
    PARENT_MUST_BE_PROVIDED = "disease:parentMustBeProvided",
    PARENT_PRIMARY = "disease:parentPrimary",
    SEARCH_DISEASE = "disease:searchDisease",
}
