import {TranslationFunction} from "../../../i18n/i18n";
import {ContactRegistrationRequestStateDto} from "../../../client/registration/RegistrationApiClient";
import {RegistrationTranslKey} from "../RegistrationTranslKey";

export class ContactRegistrationRequestStateFormatter {

    private constructor() {
    }

    static formatState(state: ContactRegistrationRequestStateDto | null | undefined, t: TranslationFunction): string {
        return ContactRegistrationRequestStateFormatter.formatStateType(state?.stateType, t);
    }

    static formatStateType(stateType: string | null | undefined, t: TranslationFunction): string {
        if (!stateType) {
            return "";
        }

        const translKey = RegistrationTranslKey.CONTACT_REGISTRATION_REQUEST_STATE_TYPE_NAMESPACE + stateType;
        return t(translKey);
    }

}
