import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {RemoveCircle, Topic} from "@mui/icons-material";
import {TopicDto} from "../../../../client/topic/TopicApiClient";
import {TopicTypeFormatter} from "../../../topic/TopicTypeFormatter";
import {useTranslation} from "react-i18next";


export interface TopicListItemProps {
    topic: TopicDto;
    disabled?: boolean;
    onRemoveClick: (topic: TopicDto) => void;
}

export default function TopicListItem(props: Readonly<TopicListItemProps>) {
    const {topic, disabled, onRemoveClick} = props;

    const {t} = useTranslation();

    function formatSecondaryText(topic: TopicDto) {
        return TopicTypeFormatter.formatTopicType(topic.type, t);
    }

    const secondaryText = formatSecondaryText(topic);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <Topic />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={topic.name} secondary={secondaryText} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onRemoveClick(topic)}>
                        <RemoveCircle />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}