import {Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import ButtonBar from "../../../components/button/ButtonBar";
import {SearchButton} from "../../../common/button/CommonButtons";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {UserDto, UserFilterDto, UserRoleType} from "../../../client/user/UserApiClient";
import UserGrid from "../UserGrid";
import {UserFilterForm} from "./UserFilterForm";
import DataGridActionBar from "../../../components/datagrid/DataGridActionBar";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";

enum SearchPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}
export enum UserEvent {
    USER_DELETED = 'user_deleted'
}

export interface UserSearchPanelProps {
    onSelected: (user?: UserDto) => void;
    actions?: React.ReactNode;
    onRowDoubleClick?: (user: UserDto) => void;
    fixedValidRoleTypes?: UserRoleType[];
}

export default function UserSearchPanel(props: UserSearchPanelProps) {
    const {onSelected, actions, onRowDoubleClick} = props;

    const {userClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    const [state, setState] = useState<SearchPanelState>(SearchPanelState.INITIALIZED);
    const [filter, setFilter] = useState<UserFilterDto>({
        validRoleTypes: props.fixedValidRoleTypes
    });
    const [users, setUsers] = useState<UserDto[]>([]);

    const [selectedUser, setSelectedUser] = useState<UserDto>();

    function onFilterChanged(updatedFilter: UserFilterDto) {
        setFilter(updatedFilter);
    }

    const search = useCallback(() => {
        setState(SearchPanelState.SEARCH_IN_PROGRESS);
        userClient.findByFilter(filter)
            .then((users) => {
                setUsers(users);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error))
            .finally(() => setState(SearchPanelState.IDLE))
    }, [userClient, filter, snackbar, t]);

    useEffect(() => {
        window.addEventListener(UserEvent.USER_DELETED, search);

        return () => {
            window.removeEventListener(UserEvent.USER_DELETED, search);
        };
    }, [search]);

    useEffect(() => {
        if (state === SearchPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    function onSelectionChange(userId?: string) {
        let user = userId
            ? users.find((user) => user.id === userId)
            : undefined;
        setSelectedUser(user);
        onSelected(user);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <UserFilterForm filter={filter}
                                onChange={onFilterChanged} />
            </Grid>
            <Grid item xs={12}>
                <ButtonBar>
                    <SearchButton onClick={search} inProgress={state === SearchPanelState.SEARCH_IN_PROGRESS} />
                </ButtonBar>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    {actions &&
                        <Grid item xs={12}>
                            <DataGridActionBar actions={actions} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {state !== SearchPanelState.INITIALIZED &&
                            <UserGrid users={users}
                                      selectedId={selectedUser?.id}
                                      onSelectionChange={onSelectionChange}
                                      onRowDoubleClick={onRowDoubleClick}/>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}
