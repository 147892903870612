import {Stack} from "@mui/material";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";


interface ButtonBarProps {
    sx?: SxProps<Theme>;
    children: React.ReactNode;
}

export default function ButtonBar({sx, children}: ButtonBarProps) {
    return (
        <Stack direction={"row"} spacing={1} justifyContent={"flex-end"} sx={sx}>
            {children}
        </Stack>
    );
}