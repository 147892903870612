import Dialog from "../../../components/dialog/Dialog";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../../common/button/CommonButtons";
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {DiseaseResourceDto} from "../../../client/disease/DiseaseApiClient";

enum DeleteDialogState {
    PROMPT,
    DELETE_IN_PROGRESS,
}

export interface DiseaseResourceDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    resource?: DiseaseResourceDto;
    diseaseId: string;
}

export default function DiseaseResourceDeleteDialog(props: DiseaseResourceDeleteDialogProps) {
    const {open, onClose, resource, diseaseId} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.PROMPT);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setDialogState(DeleteDialogState.PROMPT);
        }
    }, [open]);

    function deleteResource() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        diseaseClient.deleteResource(resource!.id, diseaseId)
            .then(() => {
                snackbar.success("Data deleted");
                onClose(true);
            })
            .catch((error) => {
                snackbar.error("", error);
                setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={"Delete resource?"} />
            <DialogContent>
                <Typography variant={"body1"}>
                    {"Do you really want to delete a selected resource?"}
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteResource}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}