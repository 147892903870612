import {DialogContent as MuiDialogContent} from "@mui/material";

export interface DialogContentProps {
    children?: React.ReactNode;
}

export default function DialogContent(props: DialogContentProps) {
    const {children} = props;

    return (
        <MuiDialogContent>
            {children}
        </MuiDialogContent>
    );

}