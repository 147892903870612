import {Typography} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "../../../../components/snackbar/Snackbar";
import Dialog from "../../../../components/dialog/Dialog";
import DialogTitle from "../../../../components/dialog/DialogTitle";
import DialogContent from "../../../../components/dialog/DialogContent";
import DialogActions from "../../../../components/dialog/DialogActions";
import ButtonBar from "../../../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../../../common/button/CommonButtons";
import {CommonTranslKey} from "../../../../common/CommonTranslKey";
import {Trans, useTranslation} from "react-i18next";
import {ContactRegistrationTopicInterestDto} from "../../../../client/registration/RegistrationApiClient";
import {useClientLocator} from "../../../../client/ApiClientLocator";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";
import {TopicDto} from "../../../../client/topic/TopicApiClient";

enum DeleteDialogState {
    IDLE,
    DELETE_IN_PROGRESS,
}

export interface TopicInterestDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    registrationRequestId: string;
    topicInterest?: ContactRegistrationTopicInterestDto;
    topic?: TopicDto;
}

export default function TopicInterestDeleteDialog(props: Readonly<TopicInterestDeleteDialogProps>) {
    const {open, onClose, registrationRequestId, topicInterest, topic} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.IDLE);

    const {registrationClient} = useClientLocator();

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    function deleteContactSpecialization() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        registrationClient.deleteContactDataTopicInterest(registrationRequestId, topicInterest!.topicInterestId)
            .then(() => {
                snackbar.success(t(CommonTranslKey.DATA_DELETED));
                setDialogState(DeleteDialogState.IDLE);
                onClose(true);
            })
            .catch((error) => {
                snackbar.error("", error);
                setDialogState(DeleteDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(RegistrationTranslKey.DELETE_TOPIC_INTEREST)}/>
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={RegistrationTranslKey.DELETE_TOPIC_INTEREST_PROMPT} values={{topicName: topic?.name}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteContactSpecialization}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}