import Dialog from "../../../components/dialog/Dialog";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {DiseaseAlternativeNameDto} from "../../../client/disease/DiseaseApiClient";

enum UpdateDialogState {
    FILLING,
    SAVE_IN_PROGRESS,
}

export interface DiseaseAlternativeNameUpdateDialogProps {
    open: boolean;
    onClose: (createdAltNameId?: string) => void;
    diseaseId: string;
    alternativeName?: DiseaseAlternativeNameDto;
}

export default function DiseaseAlternativeNameUpdateDialog(props: DiseaseAlternativeNameUpdateDialogProps) {
    const {open, onClose, diseaseId, alternativeName} = props;

    const [dialogState, setDialogState] = useState(UpdateDialogState.FILLING);
    const [name, setName] = useState("");

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setName("");
            setDialogState(UpdateDialogState.FILLING);
        } else {
            if (alternativeName) {
                setName(alternativeName.alternativeName);
            }
        }
    }, [open, alternativeName]);

    function save() {
        setDialogState(UpdateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const normalizedName = name.trim();
            setName(normalizedName);
            if (alternativeName) {
                diseaseClient.updateAlternativeName(alternativeName.id, diseaseId, normalizedName)
                    .then(() => {
                        snackbar.success("Data saved");
                        onClose(alternativeName.id);
                    })
                    .catch((error) => {
                        snackbar.error("", error);
                        setDialogState(UpdateDialogState.FILLING);
                    });
            } else {
                diseaseClient.createAlternativeName(diseaseId, normalizedName)
                    .then((altNameId) => {
                        snackbar.success("Data saved");
                        onClose(altNameId);
                    })
                    .catch((error) => {
                        snackbar.error("", error);
                        setDialogState(UpdateDialogState.FILLING);
                    });
            }
        }
    }

    function validate(): boolean {
        return (name.trim().length > 0);
    }

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"sm"}>
            <DialogTitle title={"Alternative Name"} />
            <DialogContent>
                <Grid container mt={1}>
                    <Grid item xs={12}>
                        <TextField label={"Alternative Name"}
                                   value={name}
                                   onChange={setName}
                                   required={true} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)}/>
                    <SaveButton variant={"primary"}
                                onClick={save}
                                disabled={!validate()}
                                inProgress={dialogState === UpdateDialogState.SAVE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}