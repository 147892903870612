import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import {Delete, Edit, ShortText} from "@mui/icons-material";
import {DiseaseAlternativeNameDto} from "../../../client/disease/DiseaseApiClient";


export interface DiseaseAlternativeNameListItemProps {
    alternativeName: DiseaseAlternativeNameDto;
    disabled?: boolean;
    onUpdateClick: (alternativeName: DiseaseAlternativeNameDto) => void;
    onDeleteClick: (alternativeName: DiseaseAlternativeNameDto) => void;
}

export default function DiseaseAlternativeNameListItem(props: DiseaseAlternativeNameListItemProps) {
    const {alternativeName, disabled, onUpdateClick, onDeleteClick} = props;

    return (
        <ListItem secondaryAction={
            <Stack direction={"row"} spacing={0}>
                <IconButton disabled={disabled} onClick={() => onUpdateClick(alternativeName)}>
                    <Edit />
                </IconButton>
                <IconButton disabled={disabled} onClick={() => onDeleteClick(alternativeName)}>
                    <Delete />
                </IconButton>
            </Stack>
        }>
            <ListItemAvatar>
                <Avatar>
                    <ShortText/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={alternativeName.alternativeName} />
        </ListItem>
    );
}