import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {Edit, Topic} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {TopicMembershipDto} from "../../../client/contact/TopicMembershipApiClient";
import {TopicMembershipRoleFormatter} from "./TopicMembershipRoleFormatter";
import {TopicMembershipTitleFormatter} from "./TopicMembershipTitleFormatter";


export interface TopicMembershipListItemProps {
    topicMembership: TopicMembershipDto;
    disabled?: boolean;
    onUpdateClick: (topicMembership: TopicMembershipDto) => void;
}

export default function TopicMembershipListItem(props: Readonly<TopicMembershipListItemProps>) {
    const {topicMembership, disabled, onUpdateClick} = props;

    const {t} = useTranslation();

    function buildSecondaryContent(topicMembership: TopicMembershipDto) {
        const role = TopicMembershipRoleFormatter.formatRole(topicMembership.role, t);
        const title = TopicMembershipTitleFormatter.formatTitleOfMembership(topicMembership, t);

        return (
            <>
                <span>{role}</span>
                <span> | </span>
                <span><em>{title}</em></span>
            </>
        );
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <Topic />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={topicMembership.topic.name} secondary={buildSecondaryContent(topicMembership)} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onUpdateClick(topicMembership)}>
                        <Edit />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}