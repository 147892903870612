import Dialog from "../../../../components/dialog/Dialog";
import DialogContent from "../../../../components/dialog/DialogContent";
import DialogTitle from "../../../../components/dialog/DialogTitle";
import DialogActions from "../../../../components/dialog/DialogActions";
import ButtonBar from "../../../../components/button/ButtonBar";
import {CancelButton} from "../../../../common/button/CommonButtons";
import React, {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useClientLocator} from "../../../../client/ApiClientLocator";
import {useSnackbar} from "../../../../components/snackbar/Snackbar";
import {
    ContactRegistrationDataDto,
    ContactRegistrationRequestDto
} from "../../../../client/registration/RegistrationApiClient";
import {CommonTranslKey} from "../../../../common/CommonTranslKey";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";
import {Trans, useTranslation} from "react-i18next";
import {ContactFormatter} from "../../../contact/ContactFormatter";
import {Verified} from "@mui/icons-material";
import Button from "../../../../components/button/Button";
import {useEventDispatcher} from "../../../../components/EventDispatcher";
import {RegistrationEventType} from "../../RegistrationEventType";

enum ApproveDialogState {
    IDLE,
    IN_PROGRESS,
}

export interface RegistrationRequestApproveDialogProps {
    open: boolean;
    onClose: (approved: boolean) => void;
    registrationRequest?: ContactRegistrationRequestDto;
}

export default function RegistrationRequestApproveDialog(props: Readonly<RegistrationRequestApproveDialogProps>) {
    const {open, onClose, registrationRequest} = props;

    const [dialogState, setDialogState] = useState(ApproveDialogState.IDLE);

    const {registrationClient} = useClientLocator();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setDialogState(ApproveDialogState.IDLE);
        }
    }, [open]);

    function approveRegistration() {
        setDialogState(ApproveDialogState.IN_PROGRESS);
        registrationClient.approve(registrationRequest!.id)
            .then(() => {
                snackbar.success(t(RegistrationTranslKey.REGISTRATION_APPROVED));
                eventDispatcher.dispatchEvent(new Event(RegistrationEventType.REQUEST_STATE_CHANGED));
                onClose(true);
            })
            .catch((error) => {
                snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                setDialogState(ApproveDialogState.IDLE);
            });
    }

    function formatContactName(contactData: ContactRegistrationDataDto | undefined) {
        return contactData
            ? ContactFormatter.formatFirstNameAndLastName(contactData.firstName, contactData.lastName)
            : "";
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(RegistrationTranslKey.APPROVE_REGISTRATION)} />
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={RegistrationTranslKey.APPROVE_REGISTRATION_PROMPT} values={{contactName: formatContactName(registrationRequest?.contactRegistrationData)}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <Button icon={<Verified />}
                            variant={"primary"}
                            color={"success"}
                            inProgress={dialogState === ApproveDialogState.IN_PROGRESS}
                            onClick={approveRegistration}>
                        {t(RegistrationTranslKey.APPROVE)}
                    </Button>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}