import {
    ContactBriefDto,
    ContactDto,
    ContactSummaryDto
} from "../../client/contact/ContactApiClient";

export class ContactFormatter {
    private constructor() {
    }

    static formatContactName(contact: ContactDto | ContactSummaryDto | ContactBriefDto | undefined | null): string {
        if (contact) {
            return ContactFormatter.formatFirstNameAndLastName(contact.firstName, contact.lastName);
        } else {
            return ""
        }
    }
    static formatFirstNameAndLastName(firstName: string | undefined | null, lastName: string | undefined | null): string {
        let name = "";
        if (firstName && firstName !== "") {
            name = firstName;
        }
        if (lastName  && lastName !== "") {
            if (name !== "") {
                name += " ";
            }
            name += lastName;
        }
        return name;
    }

    static formatContactBrief(standIn: ContactBriefDto | undefined | null): string {
        if (standIn) {
            return ContactFormatter.formatFirstNameAndLastName(standIn.firstName, standIn.lastName);
        } else {
            return "";
        }
    }

    static formatWebinars(webinars: string[] | undefined): string | null {
        if (!webinars || webinars.length === 0) {
            return null;
        }
        return webinars.sort().join(", ");
    }
}
