import {IconButton, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {DiseaseResourceDto} from "../../../client/disease/DiseaseApiClient";
import DiseaseResourceBadgedAvatar from "./DiseaseResourceBadgedAvatar";


export interface DiseaseResourceListItemProps {
    resource: DiseaseResourceDto;
    disabled?: boolean;
    onUpdateClick: (resource: DiseaseResourceDto) => void;
    onDeleteClick: (resource: DiseaseResourceDto) => void;
}

export default function DiseaseResourceListItem(props: Readonly<DiseaseResourceListItemProps>) {
    const {resource, disabled, onUpdateClick, onDeleteClick} = props;
    function buildSecondaryContent(resource: DiseaseResourceDto) {
        return (
                <>
                    <span>{<a href={resource.url} target="_blank" rel="noopener noreferrer">{resource.url}</a>}</span>
                    <span> | </span>
                    <span><em>{resource.type}</em></span>
                </>
        );
    }

    return (
        <ListItem secondaryAction={
            <Stack direction={"row"} spacing={0}>
                <IconButton disabled={disabled} onClick={() => onUpdateClick(resource)}>
                    <Edit />
                </IconButton>
                <IconButton disabled={disabled} onClick={() => onDeleteClick(resource)}>
                    <Delete />
                </IconButton>
            </Stack>
        }>
            <ListItemAvatar>
                <DiseaseResourceBadgedAvatar resource={resource}/>
            </ListItemAvatar>
            <ListItemText
                    primary={resource.title}
                    secondary={buildSecondaryContent(resource)}
            />
        </ListItem>
    );
}
