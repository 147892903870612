import {Typography} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, InvalidateButton} from "../../../common/button/CommonButtons";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {Trans, useTranslation} from "react-i18next";
import {UserDto, UserRoleDto} from "../../../client/user/UserApiClient";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {UserTranslKey} from "../UserTranslKey";
import {UserFormatter} from "../UserFormatter";
import {UserRoleFormatter} from "./UserRoleFormatter";

enum InvalidateDialogState {
    IDLE,
    INVALIDATE_IN_PROGRESS,
}

export interface UserRoleInvalidateDialogProps {
    open: boolean;
    onClose: (invalidated: boolean) => void;
    userRole?: UserRoleDto;
    user?: UserDto;
}

export default function UserRoleInvalidateDialog(props: Readonly<UserRoleInvalidateDialogProps>) {
    const {open, onClose, userRole, user} = props;

    const [dialogState, setDialogState] = useState(InvalidateDialogState.IDLE);

    const {userClient} = useClientLocator();
    const {t} = useTranslation();
    const snackbar = useSnackbar();

    function invalidateUserRole() {
        setDialogState(InvalidateDialogState.INVALIDATE_IN_PROGRESS);
        userClient.invalidateUserRole(user!.id, userRole!.id)
            .then(() => {
                snackbar.success(t(CommonTranslKey.DATA_INVALIDATED));
                setDialogState(InvalidateDialogState.IDLE);
                onClose(true);
            })
            .catch((error) => {
                snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                setDialogState(InvalidateDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(UserTranslKey.INVALIDATE_USER_ROLE)}/>
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={UserTranslKey.INVALIDATE_USER_ROLE_PROMPT} values={{userRoleType: UserRoleFormatter.formatUserRole(userRole, t), userName: UserFormatter.formatUser(user)}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <InvalidateButton variant={"primary"}
                                      onClick={invalidateUserRole}
                                      inProgress={dialogState === InvalidateDialogState.INVALIDATE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}