import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {UserTranslKey} from "../UserTranslKey";
import {UserDto, UserRoleType} from "../../../client/user/UserApiClient";
import {useClientLocator} from "../../../client/ApiClientLocator";
import SelectField from "../../../components/field/SelectField";
import {UserRoleFormatter} from "./UserRoleFormatter";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

export interface UserRoleCreateDialogProps {
    open: boolean;
    onClose: (createdUserRoleId?: string) => void;
    user?: UserDto;
}

export default function UserRoleCreateDialog(props: Readonly<UserRoleCreateDialogProps>) {
    const {open, onClose, user} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [userRoleType, setUserRoleType] = useState<UserRoleType>();

    const {userClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open) {
            setUserRoleType(undefined);
            setDialogState(CreateDialogState.IDLE);
        }
    }, [open]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            userClient.createUserRole(user!.id, { userRoleType: userRoleType! })
                .then((userRoleId) => {
                    snackbar.success(t(CommonTranslKey.DATA_SAVED));
                    onClose(userRoleId);
                })
                .catch((error) => {
                    snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                    setDialogState(CreateDialogState.IDLE);
                });
        }
    }

    function validate(): boolean {
        return (userRoleType !== undefined);
    }

    const validUserRoleTypes = user?.activeRoles?.map(role => role.userRoleType) ?? [];

    const userRoleTypeOptions = Object.keys(UserRoleType)
        .filter(key => key !== UserRoleType.REGISTERED_USER)
        .filter(key => !validUserRoleTypes.includes(UserRoleType[key as keyof typeof UserRoleType]))
        .map((key) => ({
            value: UserRoleType[key as keyof typeof UserRoleType],
            label: UserRoleFormatter.formatUserRoleType(UserRoleType[key as keyof typeof UserRoleType], t),
        }));

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={t(UserTranslKey.ASSIGN_NEW_USER_ROLE)}/>
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12} sm={8} md={6}>
                            <SelectField label={t(UserTranslKey.USER_ROLE)}
                                         value={userRoleType ?? ""}
                                         options={userRoleTypeOptions}
                                         onChange={(value) => setUserRoleType(value as UserRoleType)}
                                         required={true} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!validate()}
                                    inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>
        </>
    );
}
