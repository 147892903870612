import {DiseaseDto} from "../../../client/disease/DiseaseApiClient";
import {CopyUtils} from "../../../util/CopyUtils";
import {DiseaseTreeItemModel, DiseaseTreeModel} from "./DiseaseTreeModel";

export class DiseaseTreeModelBuilder {
    static buildRootModel(rootDiseases: DiseaseDto[] | undefined): DiseaseTreeModel {
        const rootItems: DiseaseTreeItemModel[] = [];

        if (rootDiseases) {
            rootItems.push(...rootDiseases.map((disease => this.buildItemModel(disease, undefined))));
        }

        return {
            rootItems: rootItems
        };
    }

    static buildItemModel(disease: DiseaseDto, children?: DiseaseTreeItemModel[]): DiseaseTreeItemModel {
        return {
            disease: disease,
            children: children
        };
    }

    static findItemById(itemId: string, items: DiseaseTreeItemModel[]): DiseaseTreeItemModel | undefined {
        for (const item of items) {
            if (item.disease.id === itemId) {
                return item;
            }
            if (item.children) {
                const child = DiseaseTreeModelBuilder.findItemById(itemId, item.children);
                if (child) {
                    return child;
                }
            }
        }
        return undefined;
    }

    static replaceDiseasesChildren(model: DiseaseTreeModel, parentToChildrenDiseasesMap: Map<string, DiseaseDto[]>): DiseaseTreeModel {
        const updatedModel = CopyUtils.shallowCopy(model);
        parentToChildrenDiseasesMap.forEach((diseases, parentId) => {
            const itemModel = DiseaseTreeModelBuilder.findItemById(parentId, updatedModel.rootItems);
            if (itemModel) {
                itemModel.children = diseases.map(disease => DiseaseTreeModelBuilder.buildItemModel(disease, undefined));
            }
        });
        return updatedModel;
    }
}