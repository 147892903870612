import {Axios} from "axios";
import {TopicDto} from "../topic/TopicApiClient";
import {ValidityIntervalDto} from "../time/ValidityIntervalDto";


export enum TopicMembershipRole {
    TOPIC_MEMBER = "TOPIC_MEMBER",
    TOPIC_EDITOR = "TOPIC_EDITOR",
    TOPIC_ADMINISTRATOR = "TOPIC_ADMINISTRATOR",
}

export enum TopicMembershipTitle {
    NETWORK_COORDINATOR = "NETWORK_COORDINATOR",
    REGISTRY_COORDINATOR = "REGISTRY_COORDINATOR",
    WG_MEMBER = "WG_MEMBER",
    WG_CHAIR = "WG_CHAIR",
    WG_CO_CHAIR = "WG_CO_CHAIR",
    NETWORK_SENIOR_PROJECT_MANAGER = "NETWORK_SENIOR_PROJECT_MANAGER",
    NETWORK_PROJECT_MANAGER = "NETWORK_PROJECT_MANAGER",
    EX_OFICIO_PID = "EX_OFICIO_PID",
    EX_OFICIO_AID = "EX_OFICIO_AID",
    EX_OFICIO_AI = "EX_OFICIO_AI",
    EX_OFICIO_PR = "EX_OFICIO_PR",
    PATIENT_REPRESENTATIVE_PID = "PATIENT_REPRESENTATIVE_PID",
    PATIENT_REPRESENTATIVE_AID = "PATIENT_REPRESENTATIVE_AID",
    PATIENT_REPRESENTATIVE_AI = "PATIENT_REPRESENTATIVE_AI",
    PATIENT_REPRESENTATIVE_PR = "PATIENT_REPRESENTATIVE_PR",
    STREAM_LEAD_PID = "STREAM_LEAD_PID",
    STREAM_LEAD_AID = "STREAM_LEAD_AID",
    STREAM_LEAD_AI = "STREAM_LEAD_AI",
    STREAM_LEAD_PR = "STREAM_LEAD_PR",
    STREAM_LEAD_SUBSTITUTE_PID = "STREAM_LEAD_SUBSTITUTE_PID",
    STREAM_LEAD_SUBSTITUTE_AID = "STREAM_LEAD_SUBSTITUTE_AID",
    STREAM_LEAD_SUBSTITUTE_AI = "STREAM_LEAD_SUBSTITUTE_AI",
    STREAM_LEAD_SUBSTITUTE_PR = "STREAM_LEAD_SUBSTITUTE_PR",
    OTHER = "OTHER",
}

export interface TopicMembershipAclDto {
    canUpdate: boolean;
}

export interface TopicMembershipDto {
    id: string;
    topic: TopicDto;
    role?: TopicMembershipRole;
    title?: TopicMembershipTitle;
    otherTitle?: string;
    validityInterval?: ValidityIntervalDto;
    acl: TopicMembershipAclDto;
}

export interface TopicMembershipUpdateDto {
    role?: string;
    title?: string;
    otherTitle?: string;
}

export class TopicMembershipApiClient {

    constructor(private readonly axios: Axios) {
    }

    findAll(contactId: string): Promise<TopicMembershipDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.get<TopicMembershipDto[]>(`/contacts/${contactId}/topic-memberships`)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    findById(contactId: string, topicMembershipId: string): Promise<TopicMembershipDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<TopicMembershipDto | undefined>(`/contacts/${contactId}/topic-memberships/${topicMembershipId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    updateMembership(contactId: string, topicMembershipId: string, update: TopicMembershipUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/contacts/${contactId}/topic-memberships/${topicMembershipId}`, update)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}