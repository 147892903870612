import {TreeView} from "@mui/x-tree-view";
import {ArrowDropDown, ArrowRight} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {DiseaseTreeItemModel, DiseaseTreeModel} from "./DiseaseTreeModel";
import {DiseaseTreeModelBuilder} from "./DiseaseTreeModelBuilder";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {DiseaseTreeSkeleton} from "./DiseaseTreeSkeleton";
import DiseaseTreeItem from "./DiseaseTreeItem";
import {DiseaseDto, DiseaseFilterDto, DiseaseHierarchyLevel} from "../../../client/disease/DiseaseApiClient";
import {useSnackbar} from "../../../components/snackbar/Snackbar";

export interface DiseaseTreeProps {
    onItemSelected?: (itemModel: DiseaseTreeItemModel | null) => void;
    onContextMenu?: (event: React.MouseEvent, itemModel: DiseaseTreeItemModel) => void;
    onDoubleClick?: (event: React.MouseEvent, itemModel: DiseaseTreeItemModel) => void;
    treeRefresh?: number;
}

export default function DiseaseTree(props: DiseaseTreeProps) {
    const {onItemSelected, onContextMenu, onDoubleClick, treeRefresh} = props;

    const {diseaseClient} = useClientLocator();
    const [model, setModel] = useState<DiseaseTreeModel>();
    const [expanded, setExpanded] = useState<string[]>([]);
    const [selected, setSelected] = useState<string | null>(null);
    const snackbar = useSnackbar();

    useEffect(() => {
        setExpanded([]);
        diseaseClient.findByFilter({level: DiseaseHierarchyLevel.STREAM})
            .then((rootDiseases) => {
                setModel(DiseaseTreeModelBuilder.buildRootModel(rootDiseases));
            })
            .catch((err) => snackbar.error("Unexpected error occurred while loading data", err));
    }, [diseaseClient, snackbar, treeRefresh]);

    function onNodeToggle(event: React.SyntheticEvent, nodeIds: string[]) {
        setExpanded(nodeIds);

        const unfetchedChildrenDiseaseIds = nodeIds
                .filter(nodeId => !isChildrenFetched(nodeId));
        if (unfetchedChildrenDiseaseIds.length > 0) {
            const promises = unfetchedChildrenDiseaseIds.map(diseaseId => findDiseaseChildren(diseaseId));
            Promise.all(promises)
                .then((results) => {
                    const parentToChildrenMap = createParentToChildrenMap(unfetchedChildrenDiseaseIds, results);
                    const updatedModel = DiseaseTreeModelBuilder.replaceDiseasesChildren(model!, parentToChildrenMap);
                    setModel(updatedModel);
                })
                .catch((error) => {
                    snackbar.error("Unexpected error occurred while loading data", error);
                    setExpanded(expanded.filter(nodeId => !unfetchedChildrenDiseaseIds.includes(nodeId)));
                });
        }
    }

    function isChildrenFetched(nodeId: string) {
        const item = DiseaseTreeModelBuilder.findItemById(nodeId, model!.rootItems);
        return item?.children;
    }

    function findDiseaseChildren(parentId: string): Promise<DiseaseDto[]> {
        const filter: DiseaseFilterDto = {
            parentId: parentId
        };
        return diseaseClient.findByFilter(filter);
    }

    function createParentToChildrenMap(unfetchedChildrenDiseaseIds: string[], results: DiseaseDto[][]) {
        const parentToChildrenMap = new Map<string, DiseaseDto[]>();
        unfetchedChildrenDiseaseIds.forEach((parentId, index) => {
            const childDiseases = results[index];
            if (childDiseases) {
                childDiseases.sort((a, b) => (a.name ?? "").localeCompare(b.name ?? ""));
                parentToChildrenMap.set(parentId, childDiseases);
            }
        });
        return parentToChildrenMap;
    }

    function onNodeSelect(event: React.SyntheticEvent, nodeId: string | null) {
        setSelected(nodeId);
        if (nodeId) {
            onItemSelected?.(DiseaseTreeModelBuilder.findItemById(nodeId, model!.rootItems) ?? null);
        } else {
            onItemSelected?.(null);
        }
    }

    if (!model) {
        return (
            <DiseaseTreeSkeleton />
        );
    }

    const children = model.rootItems.map(rootItem => <DiseaseTreeItem key={rootItem.disease.id} itemModel={rootItem} onContextMenu={onContextMenu} onDoubleClick={onDoubleClick}/>);

    return (
        <TreeView defaultCollapseIcon={<ArrowDropDown />}
                  defaultExpandIcon={<ArrowRight />}
                  onNodeToggle={onNodeToggle}
                  expanded={expanded}
                  multiSelect={false}
                  onNodeSelect={onNodeSelect}
                  selected={selected} >
            {children}
        </TreeView>
    );
}