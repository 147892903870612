import {CopyUtils} from "../../../util/CopyUtils";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {ContactCpmsUpdateModel} from "./ContactCpmsUpdateModel";

export interface ValidationResult {
    isValid: boolean;
    formModel: ContactCpmsUpdateModel;
}

export default function validateContactCpmsUpdateModel(model: ContactCpmsUpdateModel): ValidationResult {
    const validatedModel = CopyUtils.deepCopy(model);
    let isValid = true;

    if (!model.status.value) {
        isValid = false;
        validatedModel.status.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    }

    return {
        isValid: isValid,
        formModel: validatedModel
    };
}
