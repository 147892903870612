import {UserDto} from "../../client/user/UserApiClient";
import {createContext, useContext} from "react";

const UserContext = createContext<UserDto | undefined>(undefined);

export default UserContext;

export function useUser(): UserDto {
    const user = useContext(UserContext);
    if (user) {
        return user;
    } else {
        throw new Error("A user context has not been provided");
    }
}