import Dialog from "../../../components/dialog/Dialog";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {
    DiseaseResourceDto,
    DiseaseResourceLocation,
    DiseaseResourceUpdateDto,
} from "../../../client/disease/DiseaseApiClient";
import SelectField from "../../../components/field/SelectField";
import {DiseaseResourceUpdateFormModel} from "./DiseaseResourceUpdateFromModel";
import validateResourceUpdateFormModel from "./DiseaseResourceUpdateFormValidator";
import FreetextSelectField from "../../../components/field/FreetextSelectField";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {DiseaseResourceTranslKey} from "./DiseaseResourceTranslKey";
import {useTranslation} from "react-i18next";
import {UrlFormatter} from "../../../common/formatter/UrlFormatter";

enum UpdateDialogState {
    FILLING,
    SAVE_IN_PROGRESS,
}

const DEFAULT_FORM_MODEL: DiseaseResourceUpdateFormModel = {
    title: {value: ""},
    url: {value: ""},
    location: {value: DiseaseResourceLocation.EXTERNAL},
    type: {value: ""},
};

export interface DiseaseResourceUpdateDialogProps {
    open: boolean;
    onClose: (createdAltNameId?: string) => void;
    diseaseId: string;
    resource?: DiseaseResourceDto;
}

export default function DiseaseResourceUpdateDialog(props: Readonly<DiseaseResourceUpdateDialogProps>) {
    const {open, onClose, diseaseId, resource} = props;
    const {t} = useTranslation();

    const [dialogState, setDialogState] = useState(UpdateDialogState.FILLING);
    const [formModel, setFormModel] = useState<DiseaseResourceUpdateFormModel>(DEFAULT_FORM_MODEL);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setFormModel(DEFAULT_FORM_MODEL);
            setDialogState(UpdateDialogState.FILLING);
        } else if (resource) {
            setFormModel({
                title: {value: resource.title ?? ""},
                url: {value: resource.url ?? ""},
                location: {value: resource.location ?? DiseaseResourceLocation.EXTERNAL},
                type: {value: resource.type ?? ""},
            });
        }
    }, [open, resource]);

    function save() {
        if (validate()) {
            setDialogState(UpdateDialogState.SAVE_IN_PROGRESS);
            const update = buildNormalizedUpdate(formModel);

            if (resource) {
                diseaseClient.updateResource(resource.id, diseaseId, update)
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(resource.id);
                    })
                    .catch((error) => {
                        snackbar.error("", error);
                        setDialogState(UpdateDialogState.FILLING);
                    });
            } else {
                diseaseClient.createResource(diseaseId, update)
                    .then((altNameId) => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(altNameId);
                    })
                    .catch((error) => {
                        snackbar.error("", error);
                        setDialogState(UpdateDialogState.FILLING);
                    });
            }
        }
    }

    function validate(): boolean {
        const result = validateResourceUpdateFormModel(formModel);
        if (!result.isValid) {
            setFormModel(result.updateFormModel);
        }

        return result.isValid;
    }

    function buildNormalizedUpdate(formModel: DiseaseResourceUpdateFormModel): DiseaseResourceUpdateDto {
        const title = formModel.title.value.trim();
        const url = formModel.url.value.trim();
        return {
            title: title,
            url: url,
            location: formModel.location.value,
            type: formModel.type.value,
        };
    }

    function translateFromErrorCode(errorCode: string | undefined): string | undefined {
        return errorCode ? t(errorCode) : undefined;
    }

    const resourceLocationOptions = [
        {value: DiseaseResourceLocation.EXTERNAL, label: "External"},
        {value: DiseaseResourceLocation.INTERNAL, label: "Internal"},
    ];

   const resourceTypeOptions = [
        "General information",
        "Reference centers",
        "Patient organizations",
        "Scientific societies",
        "Rare disease registries",
        "Guidelines"
    ];

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"sm"}>
            <DialogTitle title={t(DiseaseResourceTranslKey.DISEASE_RESOURCE_HEADING)}/>
            <DialogContent>
                <Grid container mt={0} spacing={2}>
                    <Grid item xs={12}>
                        <TextField label={t(DiseaseResourceTranslKey.DISEASE_RESOURCE_TITLE)}
                                   value={formModel.title.value}
                                   onChange={(value) => setFormModel({
                                       ...formModel,
                                       title: {...formModel.title, value: value}
                                   })}
                                   errorMessage={translateFromErrorCode(formModel.title.errorMessageCode)}
                                   required={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField label={t(DiseaseResourceTranslKey.DISEASE_RESOURCE_URL)}
                                   value={formModel.url.value}
                                   onChange={(value) => setFormModel({
                                       ...formModel,
                                       url: {...formModel.url, value: value}
                                   })}
                                   onBlur={(value) => setFormModel({
                                       ...formModel,
                                       url: {...formModel.url, value: value ?? UrlFormatter.normalizeUrl(value)}
                                   })}
                                   errorMessage={translateFromErrorCode(formModel.url.errorMessageCode)}
                                   required={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectField label={t(DiseaseResourceTranslKey.DISEASE_RESOURCE_LOCATION)}
                                     value={formModel.location.value}
                                     onChange={(value) => setFormModel({
                                         ...formModel,
                                         location: {...formModel.location, value: value as DiseaseResourceLocation}
                                     })}
                                     options={resourceLocationOptions}
                                     errorMessage={translateFromErrorCode(formModel.location.errorMessageCode)}
                                     required={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FreetextSelectField label={t(DiseaseResourceTranslKey.DISEASE_RESOURCE_TYPE)}
                                     value={formModel.type.value}
                                     onChange={(value) => setFormModel({
                                         ...formModel,
                                         type: {...formModel.type, value: value as string}
                                     })}
                                     options={resourceTypeOptions}
                                     errorMessage={translateFromErrorCode(formModel.type.errorMessageCode)}
                                     required={true}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)}/>
                    <SaveButton variant={"primary"}
                                onClick={save}
                                inProgress={dialogState === UpdateDialogState.SAVE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
