import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {Folder, RemoveCircle, Vaccines} from "@mui/icons-material";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../../../client/disease/DiseaseApiClient";
import {formatHierarchyLevel} from "../../../disease/DiseaseHierarchyLevelFormatter";


export interface DiseaseListItemProps {
    disease: DiseaseDto;
    disabled?: boolean;
    onRemoveClick: (disease: DiseaseDto) => void;
}

export default function DiseaseListItem(props: Readonly<DiseaseListItemProps>) {
    const {disease, disabled, onRemoveClick} = props;

    function formatSecondaryText(disease: DiseaseDto) {
        return formatHierarchyLevel(disease.hierarchyLevel);
    }

    function getIconByLevel(level: DiseaseHierarchyLevel) {
        if (level === DiseaseHierarchyLevel.DISEASE) {
            return <Vaccines />;
        } else {
            return <Folder />;
        }
    }

    const secondaryText = formatSecondaryText(disease);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    {getIconByLevel(disease.hierarchyLevel)}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={disease.name} secondary={secondaryText} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onRemoveClick(disease)}>
                        <RemoveCircle />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}