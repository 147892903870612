
declare global {
    interface Window {
        _envConfig: EnvConfig;
    }
}

export interface OAuth2ClientConfig {
    keycloakUrl: string;
    realm: string;
    clientId: string;
    scope: string;
}

export interface EnvConfig {
    registryApiUrl: string;
    frontendBaseUrl: string;
    oauth2: OAuth2ClientConfig;
}

export class EnvConfiguration {
    private constructor() {
    }

    static getConfig(): EnvConfig {
        return window._envConfig;
    }
}