import CountryContext from "./CountryContext"
import {useEffect, useState} from "react";
import {CountryDto} from "../client/catalog/CatalogApiClient";
import {useClientLocator} from "../client/ApiClientLocator";

export interface CatalogProviderProps {
    children: React.ReactNode;
}

export default function CatalogProvider({children}: CatalogProviderProps) {
    const [countries, setCountries] = useState<CountryDto[]>([]);

    const clientLocator = useClientLocator();

    useEffect(() => {
        const promises =  [
            clientLocator.countryClient.findAll()
        ];

        Promise.all(promises)
            .then((results) => {
                setCountries(results[0]);
            })
            .catch();
    }, [clientLocator]);

    return (
        <CountryContext.Provider value={countries}>
            {children}
        </CountryContext.Provider>
    );
}