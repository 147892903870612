import {GridColDef, GridSortModel} from "@mui/x-data-grid-pro";
import {ContactRegistrationRequestDto} from "../../../client/registration/RegistrationApiClient";
import DataGrid from "../../../components/datagrid/DataGrid";
import {useMemo, useState} from "react";
import {UserFormatter} from "../../user/UserFormatter";
import {ContactRegistrationRequestStateFormatter} from "./ContactRegistrationRequestStateFormatter";
import {useTranslation} from "react-i18next";
import {RegistrationTranslKey} from "../RegistrationTranslKey";


export interface RegistrationRequestGridProps {
    registrationRequests: ContactRegistrationRequestDto[];
    selectedId?: string;
    onSelectionChange?: (userId?: string) => void;
    onRowDoubleClick?: (userId: string) => void;
}

export default function RegistrationRequestGrid(props: Readonly<RegistrationRequestGridProps>) {
    const {registrationRequests, selectedId, onSelectionChange, onRowDoubleClick} = props;

    const {t} = useTranslation();

    const columnDefs: GridColDef<ContactRegistrationRequestDto>[] = [
        { field: "contactRegistrationData.firstName", headerName: t(RegistrationTranslKey.FIRST_NAME), width: 150, valueGetter: params => params.row.contactRegistrationData?.firstName },
        { field: "contactRegistrationData.lastName", headerName: t(RegistrationTranslKey.LAST_NAME), width: 150, valueGetter: params => params.row.contactRegistrationData?.lastName },
        { field: "contactRegistrationData.email", headerName: t(RegistrationTranslKey.EMAIL), width: 250, valueGetter: params => params.row.contactRegistrationData?.email },
        { field: "currentState", headerName: t(RegistrationTranslKey.STATE), width: 200, valueFormatter: params => ContactRegistrationRequestStateFormatter.formatState(params.value, t) },
        { field: "assignedReviewer", headerName: t(RegistrationTranslKey.REVIEWER), width: 200, valueFormatter: params => UserFormatter.formatUser(params.value) },
    ];

    const defaultSortModel: GridSortModel = useMemo(() => [
        {field: "contactRegistrationData.lastName", sort: "asc"},
        {field: "contactRegistrationData.firstName", sort: "asc"},
        {field: "contactRegistrationData.email", sort: "asc"},
    ], []);

    const [sortModel, setSortModel] = useState(defaultSortModel);

    return (
        <DataGrid
            rows={registrationRequests}
            columns={columnDefs}
            autoHeight
            rowSelectionModel={selectedId}
            onRowSelectionModelChange={((rowSelectionModel) =>
                    onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowDoubleClick={(params) => onRowDoubleClick?.(params.row.id)} />
    );
}
