import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {CopyUtils} from "../../../util/CopyUtils";
import {TopicFilterDto, TopicType} from "../../../client/topic/TopicApiClient";
import {TopicTranslKey} from "../TopicTranslKey";
import {useTranslation} from "react-i18next";
import MultiSelectField from "../../../components/field/MultiSelectField";
import {TopicTypeFormatter} from "../TopicTypeFormatter";

export interface TopicFilterFormProps {
    filter: TopicFilterDto;
    onChange: (filter: TopicFilterDto) => void;
}

export function TopicFilterForm(props: Readonly<TopicFilterFormProps>) {
    const {filter, onChange} = props;

    const {t} = useTranslation();

    function onNameChange(name: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.nameLike = normalizeString(name);
        onChange(updatedFilter);
    }

    function onTypeChange(types: unknown[]) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.types = types as string[];
        onChange(updatedFilter);
    }

    function normalizeString(value: string): string | undefined {
        const normalizedValue = value.trim();
        return (normalizedValue.length > 0) ? normalizedValue : undefined;
    }

    const typeOptions = Object.keys(TopicType).map((topic) => ({
        value: topic,
        label: TopicTypeFormatter.formatTopicType(topic, t)
    }));

    return (
        <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={5} md={4}>
                <TextField label={t(TopicTranslKey.NAME)}
                           value={filter.nameLike ?? ""}
                           onChange={onNameChange} />
            </Grid>
            <Grid item xs={12} sm={7} md={5}>
                <MultiSelectField label={t(TopicTranslKey.TYPE)}
                                  limitTags={1}
                                  options={typeOptions}
                                  value={filter.types ?? []}
                                  onChange={onTypeChange} />
            </Grid>
        </Grid>
    );
}

