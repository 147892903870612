import './App.css';
import ApiClientLocatorContext, {ApiClientLocator} from "./client/ApiClientLocator";
import {DiseaseApiClient} from "./client/disease/DiseaseApiClient";
import {useRef} from "react";
import {EnvConfiguration, OAuth2ClientConfig} from "./EnvConfig";
import {ContactApiClient} from "./client/contact/ContactApiClient";
import {CountryApiClient} from "./client/catalog/CatalogApiClient";
import axios, {AxiosInstance} from "axios";
import {UserApiClient} from "./client/user/UserApiClient";
import {OrganizationApiClient} from "./client/organization/OrganizationApiClient";
import {AffiliationApiClient} from "./client/affiliation/AffiliationApiClient";
import BearerTokenRequestInterceptor from "./auth/BearerTokenRequestInterceptor";
import Content from "./content/Content";
import {RegistrationApiClient} from "./client/registration/RegistrationApiClient";
import {TopicApiClient} from "./client/topic/TopicApiClient";
import {TopicMembershipApiClient} from "./client/contact/TopicMembershipApiClient";
import Keycloak from "keycloak-js";
import KeycloakAuthProvider from "./auth/keycloak/KeycloakAuthProvider";
import RefreshTokenRequestInterceptor from "./auth/RefreshTokenRequestInterceptor";

function buildClientLocator(backendRestApiUrl: string, keycloak: Keycloak): ApiClientLocator {
    const axiosInstance = buildAxiosInstance(backendRestApiUrl, keycloak);
    return {
        affiliationClient: new AffiliationApiClient(axiosInstance),
        contactClient: new ContactApiClient(axiosInstance),
        countryClient: new CountryApiClient(axiosInstance),
        diseaseClient: new DiseaseApiClient(axiosInstance),
        organizationClient: new OrganizationApiClient(axiosInstance),
        registrationClient: new RegistrationApiClient(axiosInstance),
        topicClient: new TopicApiClient(axiosInstance),
        topicMembershipClient: new TopicMembershipApiClient(axiosInstance),
        userClient: new UserApiClient(axiosInstance),
    };
}

function buildAxiosInstance(backendRestApiUrl: string, keycloak: Keycloak): AxiosInstance {
    const axiosInstance = axios.create({
        baseURL: backendRestApiUrl,
    });

    const bearerTokenInterceptor = new BearerTokenRequestInterceptor(keycloak);
    axiosInstance.interceptors.request.use(
        (config) => bearerTokenInterceptor.interceptRequest(config),
        (error) => Promise.reject(error));

    const refreshTokenInterceptor = new RefreshTokenRequestInterceptor(keycloak);
    axiosInstance.interceptors.request.use(
        (config) => refreshTokenInterceptor.interceptRequest(config),
        (error) => Promise.reject(error)
        );

    return axiosInstance;
}

function buildKeycloakClient(clientConfig: OAuth2ClientConfig): Keycloak {
    return new Keycloak({
        url: clientConfig.keycloakUrl,
        realm: clientConfig.realm,
        clientId: clientConfig.clientId,
    });
}

export default function App() {
    const envConfig = useRef(EnvConfiguration.getConfig());

    const keycloak = useRef<Keycloak>(buildKeycloakClient(envConfig.current.oauth2));

    const apiClientLocator = useRef<ApiClientLocator>();
    if (!apiClientLocator.current) {
        apiClientLocator.current = buildClientLocator(envConfig.current.registryApiUrl, keycloak.current);
    }

    return (
        <KeycloakAuthProvider keycloak={keycloak.current} initOptions={{ scope: envConfig.current.oauth2.scope }}>
            <ApiClientLocatorContext.Provider value={apiClientLocator.current}>
                <Content />
            </ApiClientLocatorContext.Provider>
        </KeycloakAuthProvider>
    );
}
