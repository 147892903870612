import {OrganizationType, OrganizationTypeDetailDto} from "../../client/organization/OrganizationApiClient";
import {HealthcareProviderAffiliationFormatter} from "./HealthcareProviderAffiliationFormatter";

export class RitaAffiliationFormatter {
    private constructor() {
    }

    static formatRitaAffiliation(typeDetail: OrganizationTypeDetailDto | undefined, t: (key: string) => string): string {
        if (!typeDetail) {
            return "";
        }
        if (typeDetail.organizationType === OrganizationType.HEALTHCARE_PROVIDER) {
            return HealthcareProviderAffiliationFormatter.formatAffiliation(typeDetail.hcpAffiliation, t);
        } else if (typeDetail.organizationType === OrganizationType.PATIENT_ORGANIZATION) {
            return typeDetail.isRipagMember ? "RIPAG" : "";
        } else if (typeDetail.organizationType === OrganizationType.RARE_DISEASE_REGISTRY) {
            return typeDetail.isMeritaMember ? "MERITA" : "";
        } else {
            return "";
        }
    }
}