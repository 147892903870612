import {PagePaper} from "../../components/page/PagePaper";
import PageHeader from "../../components/page/PageHeader";
import {PageContent} from "../../components/page/PageContent";
import TopicSearchPanel from "./search/TopicSearchPanel";
import {TopicDto} from "../../client/topic/TopicApiClient";
import {useState} from "react";
import {DetailActionButton} from "../../common/button/CommonActionButtons";
import {useTranslation} from "react-i18next";
import {TopicTranslKey} from "./TopicTranslKey";
import {Topic} from "@mui/icons-material";
import TopicDetailDialog from "./TopicDetailDialog";

export interface TopicPageProps {
}

export default function TopicPage(props: Readonly<TopicPageProps>) {
    const [selectedTopic, setSelectedTopic] = useState<TopicDto>();
    const {t} = useTranslation();

    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailTopic, setDetailTopic] = useState<TopicDto>();

    function showDetailDialog(topic?: TopicDto) {
        if (topic) {
            setDetailTopic(topic);
            setDetailDialogOpen(true);
        }
    }

    function onDetailDialogClose() {
        setDetailDialogOpen(false);
    }

    const actions: React.ReactNode = (
        <>
            <DetailActionButton onClick={() => showDetailDialog(selectedTopic)}
                                disabled={!selectedTopic}/>
        </>
    );

    return (
        <>
            <PagePaper>
                <PageHeader title={t(TopicTranslKey.TOPICS)} Icon={Topic} />
                <PageContent>
                    <TopicSearchPanel actions={actions}
                                      onSelected={setSelectedTopic}
                                      onRowDoubleClick={showDetailDialog} />
                </PageContent>
            </PagePaper>

            {detailTopic &&
                <TopicDetailDialog open={detailDialogOpen}
                                   onClose={() => onDetailDialogClose()}
                                   topicId={detailTopic.id}/>
            }
        </>
    );
}