import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import SelectField from "../../components/field/SelectField";
import {
    AffiliationDto, AffiliationUpdateDto,
    ContactAffiliationTitle
} from "../../client/affiliation/AffiliationApiClient";
import {useTranslation} from "react-i18next";
import {AffiliationTranslKey} from "./AffiliationTranslKey";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {ContactFormatter} from "../contact/ContactFormatter";
import {AffiliationEventType} from "./AffiliationEventType";
import Checkbox from "../../components/field/Checkbox";
import {ContactAffiliationTitleFormatter} from "./ContactAffiliationTitleFormatter";
import TextField from "../../components/field/TextField";
import {ContactAffiliationRoleFormatter} from "./ContactAffiliationRoleFormatter";
import {OrganizationDto} from "../../client/organization/OrganizationApiClient";
import {ContactDto} from "../../client/contact/ContactApiClient";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}


interface AffiliationUpdateModel {
    contactAffiliationTitle: ContactAffiliationTitle | string;
    expertise?: string;
}

const DEFAULT_AFFILIATION_UPDATE_MODEL: AffiliationUpdateModel = {
    contactAffiliationTitle: ContactAffiliationTitle.MEMBER,
    expertise: undefined
};

export interface AffiliationUpdateDialogProps {
    open: boolean;
    affiliation: AffiliationDto,
    organization: OrganizationDto,
    contact: ContactDto,
    onClose: () => void;
}

export default function AffiliationUpdateDialog(props: Readonly<AffiliationUpdateDialogProps>) {
    const {open, affiliation, contact, organization, onClose} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_AFFILIATION_UPDATE_MODEL);

    const {affiliationClient} = useClientLocator();
    const {t} = useTranslation();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setModel(DEFAULT_AFFILIATION_UPDATE_MODEL);
            setDialogState(CreateDialogState.IDLE);
        } else {
            setModel({
                ...DEFAULT_AFFILIATION_UPDATE_MODEL,
                expertise: affiliation.expertise,
                contactAffiliationTitle: ContactAffiliationTitleFormatter.composeTitleAndOtherTitle(affiliation.contactAffiliationTitle, affiliation.otherContactAffiliationTitle)
            });
        }
    }, [open, affiliation]);

    function update() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const update = convertToUpdate(model);
            affiliationClient.updateAffiliation(affiliation.id, update)
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose();
                        eventDispatcher.dispatchEvent(new Event(AffiliationEventType.AFFILIATION_UPDATED));
                    })
                    .catch((error) => {
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        setDialogState(CreateDialogState.IDLE);
                    });
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (model.contactAffiliationTitle === undefined) {
            isValid = false;
        }

        return isValid;
    }

    function convertToUpdate(model: AffiliationUpdateModel): AffiliationUpdateDto {
        const [title, otherTitle] = ContactAffiliationTitleFormatter.decomposeTitleAndOtherTitle(model.contactAffiliationTitle);

        return {
            contactAffiliationTitle: title,
            otherContactAffiliationTitle: otherTitle,
            expertise: model.expertise
        };
    }

    const titleOptions = Object.keys(ContactAffiliationTitle)
            .filter((title) => title !== ContactAffiliationTitle.OTHER)
            .map((title) => ({
                value: title,
                label: ContactAffiliationTitleFormatter.translateTitle(title, t)
            }));

    return (
            <>
                <Dialog open={open} onClose={() => onClose()} maxWidth={"md"}>
                    <DialogTitle title={t(AffiliationTranslKey.UPDATE_AFFILIATION)}/>
                    <DialogContent>
                        <Grid container mt={0} spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField label={t(AffiliationTranslKey.ORGANIZATION)}
                                           value={organization.name}
                                           readOnly/>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField label={t(AffiliationTranslKey.CONTACT)}
                                           value={ContactFormatter.formatContactName(contact)}
                                           readOnly/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <TextField label={t(AffiliationTranslKey.CONTACT_AFFILIATION_ROLE)}
                                           value={ContactAffiliationRoleFormatter.formatRole(affiliation.contactAffiliationRole, t)}
                                           readOnly/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Checkbox label={t(AffiliationTranslKey.PRIMARY)}
                                          checked={affiliation.isPrimary}
                                          readOnly/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SelectField label={t(AffiliationTranslKey.CONTACT_AFFILIATION_TITLE)}
                                             options={titleOptions}
                                             acceptFreeText={true}
                                             value={model.contactAffiliationTitle}
                                             onChange={(value) => setModel({
                                                 ...model,
                                                 contactAffiliationTitle: value as ContactAffiliationTitle | string
                                             })}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label={t(AffiliationTranslKey.EXPERTISE)}
                                           value={model.expertise}
                                           onChange={(value) => setModel({
                                               ...model,
                                               expertise: value
                                           })}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonBar>
                            <CancelButton onClick={() => onClose()}/>
                            <SaveButton variant={"primary"}
                                        onClick={update}
                                        disabled={!model || !validate()}
                                        inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                        </ButtonBar>
                    </DialogActions>
                </Dialog>
            </>
    );
}
