import {IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Stack} from "@mui/material";
import {Delete, Edit} from "@mui/icons-material";
import {AffiliationDto} from "../../../client/affiliation/AffiliationApiClient";
import {OrganizationDto} from "../../../client/organization/OrganizationApiClient";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import AffiliationBadgedAvatar from "../../affiliation/AffiliationBadgedAvatar";
import {ContactAffiliationRoleFormatter} from "../../affiliation/ContactAffiliationRoleFormatter";
import {ContactAffiliationTitleFormatter} from "../../affiliation/ContactAffiliationTitleFormatter";


export interface ContactAffiliationListItemProps {
    affiliation: AffiliationDto;
    organization: OrganizationDto;
    onInvalidateClick: (affiliation: AffiliationDto, organization: OrganizationDto) => void;
    onUpdateClick: (affiliation: AffiliationDto, organization: OrganizationDto) => void;
}

export default function ContactAffiliationListItem(props: Readonly<ContactAffiliationListItemProps>) {
    const {affiliation, organization, onInvalidateClick, onUpdateClick} = props;

    const {t} = useTranslation();

    function buildSecondaryContent(affiliation: AffiliationDto) {
        return (
            <>
                <span>{ContactAffiliationRoleFormatter.formatRole(affiliation.contactAffiliationRole, t)}</span>
                <span> | </span>
                <span><em>{ContactAffiliationTitleFormatter.formatTitle(affiliation.contactAffiliationTitle,affiliation.otherContactAffiliationTitle, t)}</em></span>
                {affiliation.expertise &&
                    <>
                        <span> | </span>
                        <span><em>{affiliation.expertise}</em></span>
                    </>
                }
            </>
        );
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <AffiliationBadgedAvatar affiliation={affiliation} iconType={"organization"} />
            </ListItemAvatar>
            <ListItemText primary={organization.name} secondary={buildSecondaryContent(affiliation)} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton title={t(CommonTranslKey.EDIT)} disabled={!affiliation.acl.canUpdate}
                                onClick={() => onUpdateClick(affiliation, organization)}>
                        <Edit/>
                    </IconButton>
                    <IconButton title={t(CommonTranslKey.INVALIDATE)} disabled={!affiliation.acl.canInvalidate}
                                onClick={() => onInvalidateClick(affiliation, organization)}>
                        <Delete/>
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}
