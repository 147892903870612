import {useContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import TextField from "../../components/field/TextField";
import SelectField, {SelectOption} from "../../components/field/SelectField";
import {
    EuropeanReferenceNetwork,
    HealthcareProviderAffiliation,
    OrganizationCreateDto,
    OrganizationDto,
    OrganizationType
} from "../../client/organization/OrganizationApiClient";
import CountryContext from "../../catalog/CountryContext";
import {formatUnit} from "../contact/UnitFormatter";
import MultiSelectField from "../../components/field/MultiSelectField";
import {Unit} from "../../client/contact/ContactApiClient";
import {useTranslation} from "react-i18next";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {OrganizationTypeFormatter} from "./OrganizationTypeFormatter";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {OrganizationEventType} from "./OrganizationEventType";
import {HealthcareProviderAffiliationFormatter} from "./HealthcareProviderAffiliationFormatter";
import {EuropeanReferenceNetworkFormatter} from "./EuropeanReferenceNetworkFormatter";
import Checkbox from "../../components/field/Checkbox";
import DatePicker from "../../components/field/DatePicker";
import {CountryFormatter} from "../country/CountryFormatter";

enum UpdateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

const DEFAULT_ORGANIZATION_MODEL: OrganizationCreateDto = {
    name: "",
    abbreviation: "",
    description: "",
    countryId: undefined,
    city: "",
    address: "",
    units: [],
    typeDetail: {
        organizationType: OrganizationType.HEALTHCARE_PROVIDER,
    }
};

export interface OrganizationUpdateDialogProps {
    open: boolean;
    onClose: (updatedOrganizationId: string | undefined) => void;
    organization?: OrganizationDto;
}

export default function OrganizationUpdateDialog(props: OrganizationUpdateDialogProps) {
    const {open, onClose, organization} = props;

    const [dialogState, setDialogState] = useState(UpdateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_ORGANIZATION_MODEL);

    const countries = useContext(CountryContext);

    const {t} = useTranslation();
    const {organizationClient} = useClientLocator();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setModel(DEFAULT_ORGANIZATION_MODEL);
            setDialogState(UpdateDialogState.IDLE);
        } else if (organization) {
            setModel({
                ...organization,
                countryId: organization.country ? organization.country.id : CountryFormatter.COUNTRY_INTERNATIONAL_ID,
                typeDetail: {
                    ...organization.typeDetail,
                    organizationType: organization.typeDetail?.organizationType ?? OrganizationType.HEALTHCARE_PROVIDER,
                }
            });
        }
    }, [open, organization]);

    function save() {
        setDialogState(UpdateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const create = normalize(model);

            if (organization) {
                organizationClient.updateOrganization(organization.id, create)
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(organization.id);
                        eventDispatcher.dispatchEvent(new Event(OrganizationEventType.ORGANIZATION_CREATED));
                    })
                    .catch((error) => {
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        setDialogState(UpdateDialogState.IDLE);
                    });
            } else {
                organizationClient.createOrganization(create)
                    .then((organizationId) => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(organizationId);
                        eventDispatcher.dispatchEvent(new Event(OrganizationEventType.ORGANIZATION_CREATED));
                    })
                    .catch((error) => {
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        setDialogState(UpdateDialogState.IDLE);
                    });
            }
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (model.name.trim().length === 0) {
            isValid = false;
        }
        if (model.typeDetail.organizationType === undefined) {
            isValid = false;
        }
        if (model.countryId === undefined) {
            isValid = false;
        }

        return isValid;
    }

    function normalize(model: OrganizationCreateDto): OrganizationCreateDto {
        return {
            ...model,
            name: model.name.trim(),
            city: model.address?.trim(),
            address: model.address?.trim(),
            countryId: model.countryId === CountryFormatter.COUNTRY_INTERNATIONAL_ID ? null : model.countryId,
        };
    }

    function onHcpAffiliatedSinceChange(since: string | undefined) {
        const hcpAffiliationValidity = model.typeDetail.hcpAffiliationValidity ?? {};
        hcpAffiliationValidity.validFrom = since;
        setModel({...model, typeDetail: {...model.typeDetail, hcpAffiliationValidity: hcpAffiliationValidity}});
    }

    function onHcpAffiliatedUntilChange(until: string | undefined) {
        const hcpAffiliationValidity = model.typeDetail.hcpAffiliationValidity ?? {};
        hcpAffiliationValidity.validTo = until;
        setModel({...model, typeDetail: {...model.typeDetail, hcpAffiliationValidity: hcpAffiliationValidity}});
    }

    function onErnChange(ern: unknown) {
        if (ern) {
            const [ernValue, otherErnValue] = EuropeanReferenceNetworkFormatter.decomposeErn(ern as string);
            setModel({...model, typeDetail: {...model.typeDetail, ern: ernValue, otherErn: otherErnValue}});
        } else {
            setModel({...model, typeDetail: {...model.typeDetail, ern: undefined, otherErn: undefined}});
        }
    }

    function onCountryChange(countryId: number) {
        if (countryId === CountryFormatter.COUNTRY_INTERNATIONAL_ID) {
            setModel({...model, countryId: countryId, address: undefined, city: undefined})
        } else {
            setModel({...model, countryId: countryId})
        }
    }

    const orgTypeOptions = Object.keys(OrganizationType).map((type) => ({
        value: type as OrganizationType,
        label: OrganizationTypeFormatter.formatOrganizationType(type as OrganizationType, t)
    }));

    const hcpAffiliationOptions = Object.keys(HealthcareProviderAffiliation).map((affiliation) => ({
        value: affiliation as HealthcareProviderAffiliation,
        label: HealthcareProviderAffiliationFormatter.formatAffiliation(affiliation as HealthcareProviderAffiliation, t)
    }));

    const ernOptions = Object.keys(EuropeanReferenceNetwork)
        .filter((ern) => ern !== EuropeanReferenceNetwork.OTHER)
        .map((ern) => ({
            value: ern as EuropeanReferenceNetwork,
            label: EuropeanReferenceNetworkFormatter.formatErn(ern as EuropeanReferenceNetwork, t)
        }));

    const countryOptions: SelectOption[] = [
        {value: CountryFormatter.COUNTRY_INTERNATIONAL_ID, label: CountryFormatter.COUNTRY_INTERNATIONAL_LABEL},
        ...countries.map((country) => ({
            value: country.id,
            label: `${country.name} [${country.code}]`
        }))
    ];

    const unitOptions = Object.keys(Unit).map((unit) => ({
        value: unit as Unit,
        label: formatUnit(unit as Unit)
    }));

    const dialogTitle = organization ? t(OrganizationTranslKey.UPDATE_ORGANIZATION) : t(OrganizationTranslKey.CREATE_NEW_ORGANIZATION);

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={dialogTitle} />
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12} sm={7} md={8}>
                            <TextField label={t(OrganizationTranslKey.NAME)}
                                       required={true}
                                       value={model.name}
                                       onChange={(value) => setModel({...model, name: value})} />
                        </Grid>
                        <Grid item xs={12} sm={5} md={4}>
                            <TextField label={t(OrganizationTranslKey.ABBREVIATION)}
                                       value={model.abbreviation}
                                       onChange={(value) => setModel({...model, abbreviation: value})} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SelectField label={t(OrganizationTranslKey.ORGANIZATION_TYPE)}
                                         value={model.typeDetail.organizationType}
                                         onChange={(value) => setModel({...model, typeDetail: {...model.typeDetail, organizationType: value as OrganizationType}})}
                                         required={true}
                                         options={orgTypeOptions} />
                        </Grid>

                        {model.typeDetail.organizationType === OrganizationType.HEALTHCARE_PROVIDER &&
                            <>
                                <Grid item xs={12} sm={6} md={8}>
                                    <SelectField label={t(OrganizationTranslKey.AFFILIATION)}
                                                 value={model.typeDetail.hcpAffiliation}
                                                 onChange={(value) => setModel({...model, typeDetail: {...model.typeDetail, hcpAffiliation: value as HealthcareProviderAffiliation}})}
                                                 options={hcpAffiliationOptions} />
                                </Grid>
                                <Grid item md={4} display={{xs: "none", sm: "none", md: "block"}}></Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DatePicker label={t(OrganizationTranslKey.AFFILIATED_SINCE)}
                                                value={model.typeDetail.hcpAffiliationValidity?.validFrom}
                                                onChange={onHcpAffiliatedSinceChange} />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <DatePicker label={t(OrganizationTranslKey.AFFILIATED_UNTIL)}
                                                value={model.typeDetail.hcpAffiliationValidity?.validTo}
                                                onChange={onHcpAffiliatedUntilChange} />
                                </Grid>
                            </>
                        }
                        {model.typeDetail.organizationType === OrganizationType.PATIENT_ORGANIZATION &&
                            <>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Checkbox label={t(OrganizationTranslKey.RIPAG_MEMBER)}
                                              checked={model.typeDetail.isRipagMember ?? false}
                                              onChange={value => setModel({...model, typeDetail: {...model.typeDetail, isRipagMember: value}})} />
                                </Grid>
                            </>
                        }
                        {model.typeDetail.organizationType === OrganizationType.RARE_DISEASE_REGISTRY &&
                            <>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Checkbox label={t(OrganizationTranslKey.MERITA_MEMBER)}
                                              checked={model.typeDetail.isMeritaMember ?? false}
                                              onChange={value => setModel({...model, typeDetail: {...model.typeDetail, isMeritaMember: value}})} />
                                </Grid>
                            </>
                        }
                        {model.typeDetail.organizationType === OrganizationType.EUROPEAN_REFERENCE_NETWORK &&
                            <>
                                <Grid item xs={12} sm={6} md={8}>
                                    <SelectField label={t(OrganizationTranslKey.ERN)}
                                                 value={EuropeanReferenceNetworkFormatter.composeErn(model.typeDetail.ern, model.typeDetail.otherErn)}
                                                 options={ernOptions}
                                                 onChange={onErnChange}
                                                 acceptFreeText={true} />
                                </Grid>
                            </>
                        }

                        <Grid item xs={12} sm={12} md={12}>
                            <TextField label={t(OrganizationTranslKey.WEBSITE)}
                                       value={model.website}
                                       onChange={(value) => setModel({...model, website: value})} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextField label={t(OrganizationTranslKey.DESCRIPTION)}
                                       value={model.description}
                                       onChange={(value) => setModel({...model, description: value})}
                                       multiline={true}
                                       minRows={3}
                                       maxRows={7} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <SelectField label={t(OrganizationTranslKey.COUNTRY)}
                                         value={model.countryId}
                                         onChange={(value) => onCountryChange(value as number)}
                                         required={true}
                                         options={countryOptions} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(OrganizationTranslKey.CITY)}
                                       readOnly={model.countryId === CountryFormatter.COUNTRY_INTERNATIONAL_ID}
                                       value={model.city}
                                       onChange={(value) => setModel({...model, city: value})} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <TextField label={t(OrganizationTranslKey.ADDRESS)}
                                       readOnly={model.countryId === CountryFormatter.COUNTRY_INTERNATIONAL_ID}
                                       value={model.address}
                                       onChange={(value) => setModel({...model, address: value})} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <MultiSelectField label={t(OrganizationTranslKey.UNITS)}
                                              value={model.units}
                                              onChange={(value) => setModel({...model, units: value as Unit[]})}
                                              options={unitOptions} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <TextField label={t(OrganizationTranslKey.GPS)}
                                       value={model.gps}
                                       onChange={(value) => setModel({...model, gps: value})} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!model || !validate()}
                                    inProgress={dialogState === UpdateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>
        </>
    );
}
