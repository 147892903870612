
export interface TabPanelProps {
    value: string;
    selectedValue?: string;
    children: React.ReactNode;
}

export default function TabPanel(props: TabPanelProps) {
    const {value, selectedValue, children} = props;

    if (value === selectedValue) {
        return (
            <>
                {children}
            </>
        );
    } else {
        return null;
    }
}