import {DataGridPro as MuiDataGrid, DataGridProProps as MuiDataGridProps} from "@mui/x-data-grid-pro";
import {styled} from "@mui/material";

const StyledDataGrid = styled(MuiDataGrid)(({theme}) => ({
    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: "bold",
        color: theme.palette.primary.dark,
    }
}));

export default function DataGrid(props: MuiDataGridProps) {
    return (
        <StyledDataGrid {...props} density={"compact"} />
    );
}
