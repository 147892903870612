import {DiseaseDto} from "../../../client/disease/DiseaseApiClient";
import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {AddButton} from "../../../common/button/CommonButtons";
import ButtonBar from "../../../components/button/ButtonBar";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import DiseaseSpecializationListItem from "./DiseaseSpecializationListItem";
import ListSkeleton from "../../../common/list/ListSkeleton";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import DiseaseSpecializationCreateDialog, {
    DiseaseSpecializationCreateClient
} from "./DiseaseSpecializationCreateDialog";
import {DiseaseSpecializationTranslKey} from "./DiseaseSpecializationTranslKey";
import DiseaseSpecializationDeleteDialog, {
    DiseaseSpecializationDeleteClient
} from "./DiseaseSpecializationDeleteDialog";
import {DiseaseSpecializationDto} from "../../../client/disease/DiseaseSpecializationDto";

interface SpecializationModel {
    specialization: DiseaseSpecializationDto;
    disease: DiseaseDto;
}

export interface DiseaseSpecializationClient extends DiseaseSpecializationCreateClient, DiseaseSpecializationDeleteClient {
    findSpecializations(): Promise<DiseaseSpecializationDto[]>;
}

export interface DiseaseSpecializationPanelProps {
    canUpdate: boolean;
    onUpdated?: () => void;
    specializationClient: DiseaseSpecializationClient;
}

export default function DiseaseSpecializationPanel(props: Readonly<DiseaseSpecializationPanelProps>) {
    const {canUpdate, onUpdated, specializationClient} = props;

    const {diseaseClient} = useClientLocator();

    const [specializations, setSpecializations] = useState<SpecializationModel[]>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [specializationToDelete, setSpecializationToDelete] = useState<SpecializationModel>();

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    useEffect(() => {
        specializationClient.findSpecializations()
            .then((specializations) => {
                const promises: Promise<[DiseaseSpecializationDto, DiseaseDto]>[] = specializations.map(specialization =>
                    new Promise((resolve, reject) => {
                        diseaseClient.findById(specialization.diseaseId)
                            .then(disease => {
                                if (disease) {
                                    resolve([specialization, disease.info]);
                                } else {
                                    reject(`Disease with ID ${specialization.diseaseId} not found.`);
                                }
                            })
                            .catch((error) => reject(error));
                    }));
                return Promise.all(promises);
            })
            .then((values) => {
                const specializations: SpecializationModel[] = values.map(([specialization, disease]) => ({specialization: specialization, disease: disease}));
                setSpecializations(specializations);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
    }, [specializationClient, diseaseClient, t, snackbar]);

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdSpecializationId?: string) {
        setCreateDialogOpen(false);
        if (createdSpecializationId) {
            onUpdated?.();
        }
    }

    function showDeleteDialog(specialization: DiseaseSpecializationDto, disease: DiseaseDto) {
        setDeleteDialogOpen(true);
        setSpecializationToDelete({specialization, disease});
    }

    function onDeleteDialogClosed(deletedId: boolean) {
        setDeleteDialogOpen(false);
        if (deletedId) {
            onUpdated?.();
        }
    }

    if (!specializations) {
        return (
            <ListSkeleton rows={2} rowHeight={72} />
        );
    }

    let content: React.ReactNode;
    if (specializations.length > 0) {
        content = (
            <List component={"nav"}>
                {specializations.map(
                    (specialization) =>
                        <DiseaseSpecializationListItem specialization={specialization.specialization}
                                                       disease={specialization.disease}
                                                       key={specialization.specialization.id}
                                                       disabled={!canUpdate}
                                                       onDeleteClick={(specialization, disease) => showDeleteDialog(specialization, disease)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <DataArray/>
                <Typography variant={"body2"}>{t(DiseaseSpecializationTranslKey.NO_SPECIALIZATIONS)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!canUpdate} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>

            <DiseaseSpecializationCreateDialog open={createDialogOpen}
                                               onClose={onCreateDialogClosed}
                                               specializationClient={specializationClient} />
            <DiseaseSpecializationDeleteDialog open={deleteDialogOpen}
                                               onClose={(deleted: boolean) => onDeleteDialogClosed(deleted)}
                                               diseaseSpecialization={specializationToDelete?.specialization}
                                               disease={specializationToDelete?.disease}
                                               specializationClient={specializationClient}/>
        </>
    );
}