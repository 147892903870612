import {TranslationFunction} from "../../i18n/i18n";
import {AffiliationTranslKey} from "./AffiliationTranslKey";
import {ContactAffiliationRole} from "../../client/affiliation/AffiliationApiClient";

export class ContactAffiliationRoleFormatter {
    public static formatRole(role: ContactAffiliationRole | string | null | undefined, t: TranslationFunction): string {
        if (!role) {
            return "";
        }

        const translKey = AffiliationTranslKey.CONTACT_AFFILIATION_ROLE_NAMESPACE + role;
        return t(translKey);
    }
}
