import {Skeleton} from "@mui/material";

export function DiseaseTreeSkeleton() {
    return (
        <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
        </>
    );
}