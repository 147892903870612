export enum RegistrationTranslKey {
    ADD_NEW_TOPIC_INTEREST = "registration:addNewTopicInterest",
    APPROVE = "registration:approve",
    APPROVE_REGISTRATION = "registration:approveRegistration",
    APPROVE_REGISTRATION_PROMPT = "registration:approveRegistrationPrompt",
    ASSIGN = "registration:assign",
    ASSIGN_REVIEWER = "registration:assignReviewer",
    ASSIGN_TO_ME = "registration:assignToMe",
    CANNOT_REASSIGN_TO_SAME_REVIEWER = "registration:cannotReassignToSameReviewer",
    CONTACT_REGISTRATION_REQUEST_STATE_TYPE_NAMESPACE = "registration:catalog:contactRegistrationRequestStateType:",
    CHOOSE_PRIMARY_ORGANIZATION_HINT_PART_1 = "registration:choosePrimaryOrganizationHintPart1",
    CHOOSE_PRIMARY_ORGANIZATION_HINT_PART_2 = "registration:choosePrimaryOrganizationHintPart2",
    DELETE_TOPIC_INTEREST = "registration:deleteTopicInterest",
    DELETE_TOPIC_INTEREST_PROMPT = "registration:deleteTopicInterestPrompt",
    DISEASES = "registration:diseases",
    EMAIL = "registration:email",
    EXPERTISE = "registration:expertise",
    FILL_IN_EXPERTISE_HINT = "registration:fillInExpertiseHint",
    FIRST_NAME = "registration:firstName",
    LAST_NAME = "registration:lastName",
    NO_TOPICS = "registration:noTopics",
    ORGANIZATION = "registration:organization",
    PRIMARY_ORGANIZATION_TITLE =  "registration:primaryOrganizationTitle",
    PRIMARY_ORGANIZATION_ROLE = "registration:primaryOrganizationRole",
    REASSIGN = "registration:reassign",
    REGISTRATION = "registration:registration",
    REGISTRATIONS = "registration:registrations",
    REGISTRATION_APPROVED = "registration:registrationApproved",
    REGISTRATION_FORM_INTRO_TEXT_1 = "registration:registrationFormIntroText1",
    REGISTRATION_FORM_INTRO_TEXT_2 = "registration:registrationFormIntroText2",
    REGISTRATION_FORM_INTRO_TEXT_3 = "registration:registrationFormIntroText3",
    REGISTRATION_FORM_INTRO_TEXT_4 = "registration:registrationFormIntroText4",
    REGISTRATION_REJECTED = "registration:registrationRejected",
    REGISTRATION_SUCCESSFULLY_SENT = "registration:registrationSuccessfullySent",
    REJECT = "registration:reject",
    REJECT_REGISTRATION = "registration:rejectRegistration",
    REJECT_REGISTRATION_PROMPT = "registration:rejectRegistrationPrompt",
    REVIEWER = "registration:reviewer",
    SELECT_TOPIC_INTERESTS_HINT = "registration:selectTopicInterestsHint",
    SELECT_DISEASE_SPECIALIZATIONS_HINT = "registration:selectDiseaseSpecializationsHint",
    STATE = "registration:state",
    TITLE = "registration:title",
    TOPIC = "registration:topic",
    TOPICS = "registration:topics",
    TOPIC_INTEREST_ALREADY_EXISTS = "registration:topicInterestAlreadyExists",
    UPDATE_REGISTRATION = "registration:updateRegistration",

}
