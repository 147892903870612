import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import ListSkeleton from "../../../common/list/ListSkeleton";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {TopicMembershipDto} from "../../../client/contact/TopicMembershipApiClient";
import TopicMembershipListItem from "./TopicMembershipListItem";
import {ContactTranslKey} from "../ContactTranslKey";
import TopicMembershipUpdateDialog from "./TopicMembershipUpdateDialog";

export interface TopicMembershipPanelProps {
    contactId?: string;
    canUpdate: boolean;
    onUpdated?: () => void;
}

export default function TopicMembershipPanel(props: Readonly<TopicMembershipPanelProps>) {
    const {contactId, canUpdate, onUpdated} = props;

    const {topicMembershipClient} = useClientLocator();

    const [memberships, setMemberships] = useState<TopicMembershipDto[]>();

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [topicMembershipToUpdate, setTopicMembershipToUpdate] = useState<TopicMembershipDto>();

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (contactId) {
            topicMembershipClient.findAll(contactId)
                .then((memberships) => {
                    setMemberships(memberships);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        } else {
            setMemberships(undefined);
        }
    }, [contactId, topicMembershipClient, t, snackbar]);

    function showUpdateDialog(topicMembership: TopicMembershipDto) {
        setTopicMembershipToUpdate(topicMembership);
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClose(updatedTopicMembershipId?: string) {
        setUpdateDialogOpen(false);
        if (updatedTopicMembershipId) {
            onUpdated?.();
        }
    }

    if (!memberships) {
        return (
            <ListSkeleton rows={2} rowHeight={72} />
        );
    }

    let content: React.ReactNode;
    if (memberships.length > 0) {
        content = (
            <List component={"nav"}>
                {memberships.map(
                    (membership) =>
                        <TopicMembershipListItem topicMembership={membership}
                                                 key={membership.id}
                                                 disabled={!canUpdate}
                                                 onUpdateClick={(topicMembership) => showUpdateDialog(topicMembership)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <DataArray/>
                <Typography variant={"body2"}>{t(ContactTranslKey.NO_TOPICS)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            {content}

            <TopicMembershipUpdateDialog open={updateDialogOpen}
                                         onClose={onUpdateDialogClose}
                                         contactId={contactId}
                                         topicMembership={topicMembershipToUpdate} />
        </>
    );
}