import Keycloak, {KeycloakInitOptions} from "keycloak-js";
import {useEffect, useState} from "react";
import AuthContext from "../AuthContext";


const DEFAULT_KEYCLOAK_OPTIONS: KeycloakInitOptions = {
    onLoad: "login-required",
    checkLoginIframe: true,
    pkceMethod: "S256",
};

enum AuthState {
    UNINITIALIZED = "UNINITIALIZED",
    INITIALIZING = "INITIALIZING",
    INITIALIZED = "INITIALIZED",
    FAILED = "FAILED",
}

export interface KeycloakAuthProviderProps {
    keycloak: Keycloak;
    initOptions?: KeycloakInitOptions;
    children?: React.ReactNode;
}

export default function KeycloakAuthProvider(props: KeycloakAuthProviderProps) {
    const {keycloak, initOptions, children} = props;
    const [state, setState] = useState(AuthState.UNINITIALIZED);

    useEffect(() => {
        const initialize = () => {
            if ((keycloak as any).authenticationInProgress) {
                return;
            } else {
                (keycloak as any).authenticationInProgress = true;
                keycloak.init({...DEFAULT_KEYCLOAK_OPTIONS, ...initOptions})
                    .then(() => {
                        setState(AuthState.INITIALIZED);
                        (keycloak as any).authenticationInProgress = false;
                    })
                    .catch((err) => {
                        setState(AuthState.FAILED);
                        (keycloak as any).authenticationInProgress = undefined;
                        throw new Error(err);
                    });
            }
        };
        if (state === AuthState.UNINITIALIZED) {
            setState(AuthState.INITIALIZING);
            initialize();
        }
    }, [keycloak, initOptions, state]);

    return (
        <AuthContext.Provider value={{initialized: state === AuthState.INITIALIZED, authClient: keycloak}}>
            {children}
        </AuthContext.Provider>
    );
}