import {DiseaseHierarchyLevel} from "../../client/disease/DiseaseApiClient";

const HIERARCHY_LEVEL_LABEL_DICT = new Map<DiseaseHierarchyLevel, string>([
    [DiseaseHierarchyLevel.STREAM, "Stream"],
    [DiseaseHierarchyLevel.SUB_STREAM, "Substream"],
    [DiseaseHierarchyLevel.DISEASE_GROUP, "Group"],
    [DiseaseHierarchyLevel.DISEASE, "Disease"],
]);

export function formatHierarchyLevel(level?: DiseaseHierarchyLevel): string {
    if(level){
        const label = HIERARCHY_LEVEL_LABEL_DICT.get(level);
        return label? label: "";
    }

    return "";
}
