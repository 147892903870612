import {Grid} from "@mui/material";
import TextField from "../../components/field/TextField";
import ButtonBar from "../../components/button/ButtonBar";
import {EditButton} from "../../common/button/CommonButtons";
import {useState} from "react";
import {OrganizationDto, OrganizationType} from "../../client/organization/OrganizationApiClient";
import {OrganizationTypeFormatter} from "./OrganizationTypeFormatter";
import {useTranslation} from "react-i18next";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {BooleanFormatter} from "../../common/formatter/BooleanFormatter";
import {DateTimeFormatter} from "../../common/formatter/DateTimeFormatter";
import {formatUnits} from "../contact/UnitFormatter";
import {HealthcareProviderAffiliationFormatter} from "./HealthcareProviderAffiliationFormatter";
import {EuropeanReferenceNetworkFormatter} from "./EuropeanReferenceNetworkFormatter";
import OrganizationUpdateDialog from "./OrganizationUpdateDialog";
import {CountryFormatter} from "../country/CountryFormatter";

export interface OrganizationDetailPanelProps {
    organization: OrganizationDto;
    onUpdate?: () => void;
    hideActions?: boolean;
}

export default function OrganizationBasicDetailPanel(props: Readonly<OrganizationDetailPanelProps>) {
    const {organization, onUpdate, hideActions} = props;

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

    const {t} = useTranslation();

    function showUpdateDialog() {
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClosed(updated: boolean) {
        setUpdateDialogOpen(false);
        if (updated) {
            onUpdate?.();
        }
    }

    function formatType(organizationType: OrganizationType | undefined): string {
        return organizationType ? OrganizationTypeFormatter.formatOrganizationType(organizationType, t) : "";
    }

    const typeDetail = organization.typeDetail;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={7} md={8}>
                    <TextField label={t(OrganizationTranslKey.NAME)}
                               value={organization.name}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                    <TextField label={t(OrganizationTranslKey.ABBREVIATION)}
                               value={organization.abbreviation}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t(OrganizationTranslKey.ORGANIZATION_TYPE)}
                               value={formatType(organization.typeDetail?.organizationType)}
                               readOnly={true} />
                </Grid>
                {typeDetail?.organizationType === OrganizationType.HEALTHCARE_PROVIDER &&
                    <>
                        <Grid item xs={12} sm={6} md={8}>
                            <TextField label={t(OrganizationTranslKey.AFFILIATION)}
                                       value={HealthcareProviderAffiliationFormatter.formatAffiliation(typeDetail?.hcpAffiliation, t)}
                                       readOnly={true} />
                        </Grid>
                        <Grid item md={4} display={{xs: "none", sm: "none", md: "block"}}></Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(OrganizationTranslKey.AFFILIATED_SINCE)}
                                       value={DateTimeFormatter.formatDate(typeDetail?.hcpAffiliationValidity?.validFrom)}
                                       readOnly={true} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(OrganizationTranslKey.AFFILIATED_UNTIL)}
                                       value={DateTimeFormatter.formatDate(typeDetail?.hcpAffiliationValidity?.validTo)}
                                       readOnly={true} />
                        </Grid>
                    </>
                }
                {typeDetail?.organizationType === OrganizationType.PATIENT_ORGANIZATION &&
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(OrganizationTranslKey.RIPAG_MEMBER)}
                                       value={BooleanFormatter.formatAsYesNo(typeDetail?.isRipagMember, t)}
                                       readOnly={true} />
                        </Grid>
                    </>
                }
                {typeDetail?.organizationType === OrganizationType.RARE_DISEASE_REGISTRY &&
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField label={t(OrganizationTranslKey.MERITA_MEMBER)}
                                       value={BooleanFormatter.formatAsYesNo(typeDetail?.isMeritaMember, t)}
                                       readOnly={true} />
                        </Grid>
                    </>
                }
                {typeDetail?.organizationType === OrganizationType.EUROPEAN_REFERENCE_NETWORK &&
                    <>
                        <Grid item xs={12} sm={6} md={8}>
                            <TextField label={t(OrganizationTranslKey.ERN)}
                                       value={EuropeanReferenceNetworkFormatter.composeAndFormatErn(typeDetail?.ern, typeDetail?.otherErn, t)}
                                       readOnly={true} />
                        </Grid>
                    </>
                }
                <Grid item xs={12} sm={12} md={12}>
                    <TextField label={t(OrganizationTranslKey.WEBSITE)}
                               value={organization.website}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField label={t(OrganizationTranslKey.DESCRIPTION)}
                               value={organization.description}
                               readOnly={true}
                               multiline={true}
                               minRows={3}
                               maxRows={7} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t(OrganizationTranslKey.COUNTRY)}
                               value={CountryFormatter.formatCountryName(organization.country, t)}
                               readOnly={true} />
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t(OrganizationTranslKey.CITY)}
                               value={organization.city}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <TextField label={t(OrganizationTranslKey.ADDRESS)}
                               value={organization.address}
                               readOnly={true} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(OrganizationTranslKey.UNITS)}
                               value={formatUnits(organization.units)}
                               readOnly={true} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(OrganizationTranslKey.GPS)}
                               value={organization.gps}
                               readOnly={true} />
                </Grid>

                {!hideActions &&
                    <Grid item xs={12}>
                        <ButtonBar>
                            <EditButton disabled={!organization.acl.canUpdate} onClick={showUpdateDialog} />
                        </ButtonBar>
                    </Grid>
                }
            </Grid>

            <OrganizationUpdateDialog open={updateDialogOpen}
                                      onClose={(updatedId) => onUpdateDialogClosed(updatedId !== undefined)}
                                      organization={organization} />
        </>
    );
}
