import {Grid, Skeleton} from "@mui/material";

export default function OrganizationBasicDetailPanelSkeleton() {
    return (
        <Grid container spacing={2} mt={-1}>
            <Grid item xs={12} sm={7} md={8}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={5} md={4}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>


            <Grid item xs={12} sm={12} md={12}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Skeleton variant={"rounded"} height={100} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
        </Grid>
    );
}