import {UserDto} from "../../client/user/UserApiClient";


export class UserFormatter {

    private constructor() {
    }

    static formatUser(user: UserDto | null | undefined): string {
        if (!user) {
            return "";
        }

        let formattedUser = "";
        if (user.firstName && user.firstName.length > 0) {
            formattedUser = user.firstName;
        }
        if (user.lastName && user.lastName.length > 0) {
            formattedUser += (formattedUser.length > 0 ? " " : "") + user.lastName;
        }
        if (formattedUser.length === 0) {
            formattedUser = user.login;
        }

        return formattedUser;
    }

}