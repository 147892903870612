import {DiseaseHierarchyLevel} from "../../client/disease/DiseaseApiClient";

const HIERARCHY_LEVEL_VALUE_DICT = new Map<DiseaseHierarchyLevel, number>([
    [DiseaseHierarchyLevel.DISEASE, 1],
    [DiseaseHierarchyLevel.DISEASE_GROUP, 2],
    [DiseaseHierarchyLevel.SUB_STREAM, 3],
    [DiseaseHierarchyLevel.STREAM, 4],
]);

export function compareHierarchyLevel(l1: DiseaseHierarchyLevel, l2: DiseaseHierarchyLevel): number {
    const v1 = HIERARCHY_LEVEL_VALUE_DICT.get(l1) ?? 0;
    const v2 = HIERARCHY_LEVEL_VALUE_DICT.get(l2) ?? 0;
    return v1 - v2;
}