import {AppBar, Divider, Grid, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import logo from "./rita-logo-white.png";
import {
    AccountCircle,
    Apartment,
    AssignmentInd,
    Contacts,
    Info,
    Logout,
    Person,
    Topic,
    Vaccines
} from "@mui/icons-material";
import {CONTACTS, DISEASES, ORGANIZATIONS, REGISTRATION_REQUESTS, TOPICS, USERS} from "./AppRouter";
import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {OrganizationTranslKey} from "./content/organization/OrganizationTranslKey";
import {RegistrationTranslKey} from "./content/registration/RegistrationTranslKey";
import {ContactTranslKey} from "./content/contact/ContactTranslKey";
import {DiseaseTranslKey} from "./content/disease/DiseaseTranslKey";
import {UserTranslKey} from "./content/user/UserTranslKey";
import {TopicTranslKey} from "./content/topic/TopicTranslKey";
import {UserFormatter} from "./content/user/UserFormatter";
import UserContext from "./content/user/UserContext";
import AppBarIconLink from "./components/icon/AppBarIconLink";
import AppBarIconButton from "./components/icon/AppBarIconButton";
import {CommonTranslKey} from "./common/CommonTranslKey";
import {useAuth} from "./auth/AuthContext";

export default function AppMenu() {
    const {t} = useTranslation();

    const user = useContext(UserContext);
    const { authClient } = useAuth();

    const [userProfileElement, setUserProfileElement] = useState<Element | null>();
    const userMenuOpen = Boolean(userProfileElement);

    function logout() {
        authClient?.logout();
    }

    return (
        <AppBar position={"static"}>
            <Grid container spacing={1} alignItems={"center"}>
                <Grid item xs={"auto"} margin={1}>
                    <img src={logo} alt="RITA" height={"35px"} />
                </Grid>
                <Grid item xs>
                    <Grid container spacing={1}>
                        <Grid item>
                            <AppBarIconLink tooltip={t(ContactTranslKey.CONTACTS)}
                                            to={CONTACTS}
                                            icon={Contacts} />
                        </Grid>
                        <Grid item>
                            <AppBarIconLink tooltip={t(OrganizationTranslKey.ORGANIZATIONS)}
                                            to={ORGANIZATIONS}
                                            icon={Apartment} />
                        </Grid>
                        <Grid item>
                            <AppBarIconLink tooltip={t(RegistrationTranslKey.REGISTRATIONS)}
                                            to={REGISTRATION_REQUESTS}
                                            icon={AssignmentInd} />
                        </Grid>
                        <Grid item>
                            <AppBarIconLink tooltip={t(UserTranslKey.USERS)}
                                            to={USERS}
                                            icon={Person} />
                        </Grid>
                        <Grid item>
                            <AppBarIconLink tooltip={t(TopicTranslKey.TOPICS)}
                                            to={TOPICS}
                                            icon={Topic} />
                        </Grid>
                        <Grid item>
                            <AppBarIconLink tooltip={t(DiseaseTranslKey.DISEASES)}
                                            to={DISEASES}
                                            icon={Vaccines} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={"auto"}>
                    <Grid container spacing={0} alignItems={"center"}>
                        <Grid item xs={"auto"} sx={{ display : {xs: "none", sm: "none", md: "flex"}}}>
                            <Typography variant={"h6"}>{UserFormatter.formatUser(user)}</Typography>
                        </Grid>
                        <Grid item xs={"auto"}>
                            <AppBarIconButton tooltip={""}
                                              onClick={(event) => setUserProfileElement(event.currentTarget)}>
                                <AccountCircle />
                            </AppBarIconButton>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Menu open={userMenuOpen}
                  onClose={() => setUserProfileElement(undefined)}
                  anchorEl={userProfileElement}>
                <MenuItem onClick={() => {}} disabled={true}>
                    <ListItemIcon><Info/></ListItemIcon>
                    <ListItemText>{t(CommonTranslKey.ABOUT)}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout}>
                    <ListItemIcon><Logout/></ListItemIcon>
                    <ListItemText>{t(CommonTranslKey.LOGOUT)}</ListItemText>
                </MenuItem>
            </Menu>
        </AppBar>
    );
}