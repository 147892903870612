import {CatalogDto} from "../../client/catalog/CatalogApiClient";
import {TFunction} from "i18next";
import {CommonTranslKey} from "../../common/CommonTranslKey";

export class CountryFormatter {

    public static COUNTRY_INTERNATIONAL_ID: number = -1;
    public static COUNTRY_INTERNATIONAL_LABEL: string = "International";
    public static formatCountry(country: CatalogDto | undefined, t: TFunction): string | null {
        return country ? country.code : t(CommonTranslKey.INTERNATIONAL);
    }

    public static formatCountryName(country: CatalogDto | undefined, t: TFunction): string | null {
        return country ? country.name : t(CommonTranslKey.INTERNATIONAL);
    }
}
