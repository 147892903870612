import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import TextField from "../../../components/field/TextField";
import {RegistrationEventType} from "../RegistrationEventType";
import {useEventDispatcher} from "../../../components/EventDispatcher";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {
    ContactRegistrationDataDto,
    ContactRegistrationDataUpdateDto,
    ContactRegistrationRequestDto
} from "../../../client/registration/RegistrationApiClient";
import {RegistrationTranslKey} from "../RegistrationTranslKey";
import SelectField from "../../../components/field/SelectField";
import {ContactAffiliationRole, ContactAffiliationTitle} from "../../../client/affiliation/AffiliationApiClient";
import {ContactAffiliationRoleFormatter} from "../../affiliation/ContactAffiliationRoleFormatter";
import {ContactAffiliationTitleFormatter} from "../../affiliation/ContactAffiliationTitleFormatter";
import {RegistrationContactDataUpdateModel} from "./RegistrationContactDataUpdateModel";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

const DEFAULT_MODEL: RegistrationContactDataUpdateModel = {
    title: {value: ""},
    firstName: {value: ""},
    lastName: {value: ""},
    expertise: {value: ""},
    primaryOrganizationRole: {value: ContactAffiliationRole.ORGANIZATION_MEMBER},
    primaryOrganizationTitle: {value: ContactAffiliationTitle.MEMBER}
};

export interface RegistrationContactDataUpdateDialogProps {
    open: boolean;
    onClose: (updatedRegistrationId: string | undefined) => void;
    registrationRequest?: ContactRegistrationRequestDto;
}

export default function RegistrationContactDataUpdateDialog(props: RegistrationContactDataUpdateDialogProps) {
    const {open, onClose, registrationRequest} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_MODEL);

    const {registrationClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setModel(DEFAULT_MODEL);
            setDialogState(CreateDialogState.IDLE);
        } else if (registrationRequest) {
            setModel(convertContactRegistrationDataToUpdateModel(registrationRequest.contactRegistrationData));
        }
    }, [open, registrationRequest]);

    function convertContactRegistrationDataToUpdateModel(contactRegistrationData: ContactRegistrationDataDto): RegistrationContactDataUpdateModel {
        return {
            title: {value: contactRegistrationData.title},
            firstName: {value: contactRegistrationData.firstName},
            lastName: {value: contactRegistrationData.lastName},
            expertise: {value: contactRegistrationData.expertise},
            primaryOrganizationRole: {value: contactRegistrationData.primaryOrganizationRole},
            primaryOrganizationTitle: {value: ContactAffiliationTitleFormatter.composeTitleAndOtherTitle(contactRegistrationData.primaryOrganizationTitle, contactRegistrationData.otherPrimaryOrganizationTitle)},
        };
    }

    function completeContactData() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);

        if (validate()) {
            const contactDataUpdate = normalize(model);
            if (registrationRequest) {
                registrationClient.completeContactData(registrationRequest!.id, contactDataUpdate)
                        .then(() => {
                            snackbar.success(t(CommonTranslKey.DATA_SAVED));
                            onClose(registrationRequest!.id);
                            eventDispatcher.dispatchEvent(new Event(RegistrationEventType.REQUEST_DATA_UPDATED));
                        })
                        .catch((error) => {
                            snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        });
            }
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (model.firstName.value.trim().length === 0) {
            isValid = false;
        }
        if (model.lastName.value.trim().length === 0) {
            isValid = false;
        }

        return isValid;
    }

    function normalize(model: RegistrationContactDataUpdateModel): ContactRegistrationDataUpdateDto {
        const [primaryOrganizationTitle, otherPrimaryOrganizationTitle] = ContactAffiliationTitleFormatter.decomposeTitleAndOtherTitle(model.primaryOrganizationTitle.value);

        return {
            firstName: model.firstName.value.trim(),
            lastName: model.lastName.value.trim(),
            title: model.title.value ? model.title.value.trim() : undefined,

            primaryOrganizationTitle: primaryOrganizationTitle,
            otherPrimaryOrganizationTitle: otherPrimaryOrganizationTitle,

            primaryOrganizationRole: model.primaryOrganizationRole.value,
            expertise: model.expertise.value ? model.expertise.value.trim() : undefined,
        };
    }

    const roleOptions = Object.keys(ContactAffiliationRole)
            .map((role) => ({
        value: role,
        label: ContactAffiliationRoleFormatter.formatRole(role, t)
    }));

    const titleOptions = Object.keys(ContactAffiliationTitle)
            .filter((title) => title !== ContactAffiliationTitle.OTHER)
            .map((title) => ({
                value: title,
                label: ContactAffiliationTitleFormatter.translateTitle(title, t)
            }));

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
            <DialogTitle title={t(RegistrationTranslKey.UPDATE_REGISTRATION)} />
            <DialogContent>
                <Grid container mt={0} spacing={2}>
                    <Grid item xs={12} sm={5}>
                        <TextField label={t(RegistrationTranslKey.FIRST_NAME)}
                                   required={true}
                                   value={model.firstName.value}
                                   onChange={(value) => setModel({
                                       ...model,
                                       firstName: {...model.firstName, value: value}
                                   })} />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <TextField label={t(RegistrationTranslKey.LAST_NAME)}
                                   value={model.lastName.value}
                                   required={true}
                                   onChange={(value) => setModel({
                                       ...model,
                                       lastName: {...model.lastName, value: value}
                                   })} />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField label={t(RegistrationTranslKey.TITLE)}
                                   value={model.title.value}
                                   onChange={(value) => setModel({
                                       ...model,
                                       title: {...model.title, value: value}
                                   })} />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SelectField label={t(RegistrationTranslKey.PRIMARY_ORGANIZATION_ROLE)}
                                     options={roleOptions}
                                     value={model.primaryOrganizationRole.value}
                                     onChange={(value) => setModel({
                                         ...model,
                                         primaryOrganizationRole: {...model.primaryOrganizationRole, value: value as ContactAffiliationRole}
                                     })} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField label={t(RegistrationTranslKey.PRIMARY_ORGANIZATION_TITLE)}
                                     options={titleOptions}
                                     acceptFreeText={true}
                                     value={model.primaryOrganizationTitle.value}
                                     onChange={(value) => setModel({
                                         ...model,
                                         primaryOrganizationTitle: {...model.primaryOrganizationTitle, value: value as ContactAffiliationTitle | string}
                                     })} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField label={t(RegistrationTranslKey.EXPERTISE)}
                                   value={model.expertise.value}
                                   onChange={(value) => setModel({
                                       ...model,
                                       expertise: {...model.expertise, value: value}
                                   })} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)}/>
                    <SaveButton variant={"primary"}
                                onClick={completeContactData}
                                disabled={!model || !validate()}
                                inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
