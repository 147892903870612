import {
    Avatar,
    Grid,
    Skeleton
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {ContactCpmsDto} from "../../../client/contact/ContactApiClient";
import TextField from "../../../components/field/TextField";
import SectionHeader from "../../../components/section/SectionHeader";
import {OrganizationTranslKey} from "../../organization/OrganizationTranslKey";
import {useTranslation} from "react-i18next";
import {ContactTranslKey} from "../ContactTranslKey";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {CpmsStatusFormatter} from "./CpmsStatusFormatter";
import {EventListenerUnsubscriber, useEventDispatcher} from "../../../components/EventDispatcher";
import {ContactEventType} from "../ContactEventType";
import ButtonBar from "../../../components/button/ButtonBar";
import {EditButton} from "../../../common/button/CommonButtons";
import ContactCpmsUpdateDialog from "./ContactCpmsUpdateDialog";

enum ContactCpmsPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}

export interface ContactCpmsPanelProps {
    contactId? : string;
    canUpdate?: boolean;
}

export default function ContactCpmsPanel(props: ContactCpmsPanelProps) {
    const {contactId, canUpdate} = props;
    const {t} = useTranslation();
    const snackbar = useSnackbar();
    const {contactClient} = useClientLocator();
    const eventDispatcher = useEventDispatcher();

    const [cpms, setCpms] = useState<ContactCpmsDto>();
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [state, setState] = useState<ContactCpmsPanelState>(ContactCpmsPanelState.INITIALIZED);

    const search = useCallback(() => {
        setState(ContactCpmsPanelState.SEARCH_IN_PROGRESS);

        if (contactId) {
            contactClient.findContactCpms(contactId)
                    .then((cpms) => {
                        setCpms(cpms);
                    })
                    .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error))
                    .finally(() => setState(ContactCpmsPanelState.IDLE))
        } else {
            setCpms(undefined);
            setState(ContactCpmsPanelState.IDLE)
        }
    }, [contactId, snackbar, t, contactClient]);

    useEffect(() => {
        const unsubscribers: EventListenerUnsubscriber[] = [];
        unsubscribers.push(eventDispatcher.addEventListener(ContactEventType.CONTACT_CPMS_UPDATED, () => search()));

        return () => unsubscribers.forEach(unsubscribe => unsubscribe());
    }, [eventDispatcher, search]);

    useEffect(() => {
        if (state === ContactCpmsPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    function showUpdateDialog() {
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClosed() {
        setUpdateDialogOpen(false);
    }

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SectionHeader title={t(OrganizationTranslKey.CPMS_ROLES)}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                {state !== ContactCpmsPanelState.SEARCH_IN_PROGRESS ? <TextField
                                        label={t(ContactTranslKey.PANEL_LEAD_COUNT)}
                                        value={cpms?.panelLeadCount}
                                        readOnly={true}
                                /> : <Skeleton variant="rounded"/>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                {state !== ContactCpmsPanelState.SEARCH_IN_PROGRESS ? <TextField
                                        label={t(ContactTranslKey.PANEL_MEMBER_COUNT)}
                                        value={cpms?.panelMemberCount}
                                        readOnly={true}
                                /> : <Skeleton variant="rounded"/>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                {state !== ContactCpmsPanelState.SEARCH_IN_PROGRESS ? <TextField
                                        label={t(ContactTranslKey.REFERRED_PHYSICIAN_COUNT)}
                                        value={cpms?.referringPhysicianCount}
                                        readOnly={true}
                                /> : <Skeleton variant="rounded"/>}
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                {state !== ContactCpmsPanelState.SEARCH_IN_PROGRESS ? <TextField
                                        label={t(ContactTranslKey.CPMS_STATUS)}
                                        value={CpmsStatusFormatter.formatStatus(cpms?.status, cpms?.otherStatus, t)}
                                        readOnly={true}
                                /> : <Skeleton variant="rounded"/>}
                            </Grid>
                        </Grid>
                    </Grid>

                    {cpms?.panels && cpms.panels.length > 0 &&
                        <Grid item xs={12}>
                            <SectionHeader title={t(ContactTranslKey.PANELS)}/>
                            <Grid container alignItems="center">
                                {cpms?.panels.map((panel, index) => (
                                        <Grid item key={index} m={1}>
                                            <Avatar>{panel}</Avatar>
                                        </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <ButtonBar>
                            <EditButton disabled={!canUpdate} onClick={showUpdateDialog} />
                        </ButtonBar>
                    </Grid>
                </Grid>

                {contactId &&
                    <ContactCpmsUpdateDialog open={updateDialogOpen}
                                             onClose={onUpdateDialogClosed}
                                             contactId={contactId}
                                             contactCpms={cpms}/>
                }
            </>
    );
}
