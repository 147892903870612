import {DiseaseTreeItemModel} from "./DiseaseTreeModel";
import {TreeItem} from "@mui/x-tree-view";
import InProgressTreeItem from "./InProgressTreeItem";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../../client/disease/DiseaseApiClient";
import {Grid} from "@mui/material";
import {Folder, Vaccines} from "@mui/icons-material";


export interface DiseaseTreeItemProps {
    itemModel: DiseaseTreeItemModel;
    onContextMenu?: (event: React.MouseEvent, itemModel: DiseaseTreeItemModel) => void;
    onDoubleClick?: (event: React.MouseEvent, itemModel: DiseaseTreeItemModel) => void;
}

export default function DiseaseTreeItem(props: DiseaseTreeItemProps) {
    const {itemModel, onContextMenu, onDoubleClick} = props;

    function isNotLeaf(disease: DiseaseDto): boolean {
        return disease.hierarchyLevel !== DiseaseHierarchyLevel.DISEASE;
    }

    let childItems = null;
    if (itemModel.children) {
        childItems = itemModel.children.map((child) => <DiseaseTreeItem key={child.disease.id} itemModel={child} onContextMenu={onContextMenu} onDoubleClick={onDoubleClick}/>);
    } else if (isNotLeaf(itemModel.disease)) {
        childItems = [<InProgressTreeItem key={`${itemModel.disease.id}-children-in-progress`} parentItemModel={itemModel} />];
    }

    return (
        <>
            <TreeItem nodeId={itemModel.disease.id}
                      label={<DiseaseTreeItemLabel name={itemModel.disease.name} level={itemModel.disease.hierarchyLevel} />}
                      onContextMenu={(event) => {
                          if (onContextMenu) {
                              event.preventDefault();
                              event.stopPropagation();
                              onContextMenu(event, itemModel);
                          }
                      }}
                      onDoubleClick={(event) => {
                          if (onDoubleClick) {
                              event.stopPropagation();
                              onDoubleClick(event, itemModel);
                          }
                      }} >
                {childItems}
            </TreeItem>
        </>
    );
}

interface DiseaseTreeItemLabelProps {
    name: string;
    level: DiseaseHierarchyLevel;
}

function DiseaseTreeItemLabel({name, level}: DiseaseTreeItemLabelProps) {

    function getItemIconByLevel(level: DiseaseHierarchyLevel) {
        if (level === DiseaseHierarchyLevel.DISEASE) {
            return <Vaccines fontSize={"small"} />;
        } else {
            return <Folder fontSize={"small"} />;
        }
    }

    const icon = getItemIconByLevel(level);

    return (
        <Grid container alignItems={"center"} spacing={0.5}>
            <Grid item xs={"auto"} sx={{height: "24px"}}>{icon}</Grid>
            <Grid item xs sx={{height: "28px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>{name}</Grid>
        </Grid>
    );

}