import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import TextField from "../../components/field/TextField";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {useTranslation} from "react-i18next";
import {TopicDto, TopicType} from "../../client/topic/TopicApiClient";
import {TopicTranslKey} from "./TopicTranslKey";
import SelectField from "../../components/field/SelectField";
import {TopicTypeFormatter} from "./TopicTypeFormatter";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {TopicEventType} from "./TopicEventType";

enum DialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

export interface TopicUpdateDialogProps {
    open: boolean;
    onClose: (updatedTopicId: string | undefined) => void;
    topic?: TopicDto;
}

export default function TopicUpdateDialog(props: Readonly<TopicUpdateDialogProps>) {
    const {open, onClose, topic} = props;

    const [dialogState, setDialogState] = useState(DialogState.IDLE);
    const [type, setType] = useState<string>();

    const {topicClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setType(undefined);
            setDialogState(DialogState.IDLE);
        } else if (topic) {
            setType(topic.type);
        }
    }, [open, topic]);

    function updateTopicType() {
        setDialogState(DialogState.SAVE_IN_PROGRESS);

        if (validate()) {
            if (topic) {
                topicClient.updateTopicType(topic.id, {type: type!})
                        .then(() => {
                            snackbar.success(t(CommonTranslKey.DATA_SAVED));
                            onClose(topic.id);
                            eventDispatcher.dispatchEvent(new Event(TopicEventType.TOPIC_UPDATED));
                        })
                        .catch((error) => {
                            snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        });
            }
        }
    }

    function validate(): boolean {
        return type !== undefined;
    }

    const typeOptions = Object.keys(TopicType).map((topic) => ({
        value: topic,
        label: TopicTypeFormatter.formatTopicType(topic, t)
    }));

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
            <DialogTitle title={t(TopicTranslKey.UPDATE_TOPIC)} />
            <DialogContent>
                <Grid container mt={0} spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField label={t(TopicTranslKey.NAME)}
                                   readOnly={true}
                                   value={topic?.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SelectField label={t(TopicTranslKey.TYPE)}
                                     options={typeOptions}
                                     value={type}
                                     required={true}
                                     onChange={(value) => setType(value as string)} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)}/>
                    <SaveButton variant={"primary"}
                                onClick={updateTopicType}
                                disabled={!validate()}
                                inProgress={dialogState === DialogState.SAVE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
