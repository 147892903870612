import {Axios} from "axios";

export enum UserRoleType {
    REGISTERED_USER = "REGISTERED_USER",
    DISEASE_ADMINISTRATOR = "DISEASE_ADMINISTRATOR",
    TOPIC_ADMINISTRATOR = "TOPIC_ADMINISTRATOR",
    REGISTRY_ADMINISTRATOR = "REGISTRY_ADMINISTRATOR",
    SYSTEM_ADMINISTRATOR = "SYSTEM_ADMINISTRATOR",
}

export interface UserDto {
    id: string;
    login: string;

    contactId?: string;
    firstName?: string;
    lastName?: string;
    email?: string;

    activeRoles?: UserRoleDto[];
    acl: UserAclDto;
}

export interface UserAclDto {
    canInvalidate: boolean;
    canCreateUserRoles: boolean;
}

export interface UserFilterDto {
    loginLike?: string;
    firstNameLike?: string;
    lastNameLike?: string;
    emailLike?: string;
    validRoleTypes?: UserRoleType[];
}

export interface UserRoleDto {
    id: string;
    userRoleType: UserRoleType;
    validFrom?: string;
    validTo?: string;
    acl: UserRoleAclDto;
}

export interface UserRoleAclDto {
    canInvalidate: boolean;
}

export interface UserRoleCreateDto {
    userRoleType: UserRoleType;
}


export class UserApiClient {

    constructor(private readonly axios: Axios) {
    }

    findMe(): Promise<UserDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<UserDto | undefined>("/users/me")
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findById(userId: string): Promise<UserDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<UserDto | undefined>(`/users/${userId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findByFilter(filter: UserFilterDto): Promise<UserDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<UserDto[]>("/users:search", filter)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    invalidateUser(userId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post<void>(`/users/${userId}:invalidate`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

    createUserRole(userId: string, userRoleCreate: UserRoleCreateDto): Promise<string> {
        return new Promise((resolve, reject) => {
            this.axios.post<string>(`/users/${userId}/roles`, userRoleCreate)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    invalidateUserRole(userId: string, userRoleId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.post<void>(`/users/${userId}/roles/${userRoleId}:invalidate`)
                .then(() => resolve())
                .catch((error) => reject(error));
        });
    }

}
