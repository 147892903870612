import {FilledInputProps, InputProps, OutlinedInputProps, TextFieldProps as MuiTextFieldProps} from "@mui/material";
import StyledMuiTextField from "./StyledMuiTextField";

const TEXT_FIELD_MAX_LENGTH = 255;
const TEXT_AREA_MAX_LENGTH = 4000;

export interface TextFieldProps {
    label: string;
    value?: unknown;
    onChange?: (text: string) => void;
    onBlur?: (text: string) => void;

    placeholder?: string;
    required?: boolean;
    errorMessage?: string;
    readOnly?: boolean;

    size?: MuiTextFieldProps["size"];

    multiline?: boolean;
    rows?: number;
    minRows?: number;
    maxRows?: number;

    maxLength?: number;

    startAdornment?: React.ReactNode;
    endAdornment?: React.ReactNode;
}

export default function TextField(props: TextFieldProps) {
    const {label, value, onChange, onBlur, placeholder, required, errorMessage, readOnly} = props;
    const {size} = props;
    const {multiline, rows, minRows, maxRows, maxLength} = props;
    const {startAdornment, endAdornment} = props;

    let variant: MuiTextFieldProps["variant"] = "outlined";
    let inputProps: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps> | any = {};
    let inputLabelProps = {};
    if (readOnly) {
        variant = "filled";
        inputProps = {
            readOnly: true,
            disableUnderline: true,
            startAdornment: startAdornment,
            endAdornment: endAdornment,

        };
        inputLabelProps = {
            shrink: true,
        };
    } else {
        inputProps = {
            maxLength: maxLength ?? (multiline ? TEXT_AREA_MAX_LENGTH : TEXT_FIELD_MAX_LENGTH),
        }
    }

    return (
        <StyledMuiTextField variant={variant}
                      label={label}
                      placeholder={placeholder}
                      type={"text"}
                      value={value ?? ""}
                      onChange={(event) => onChange?.(event.target.value)}
                      onBlur={(event) => onBlur?.(event.target.value)}
                      title={value ? value.toString() : undefined}
                      required={required}
                      error={errorMessage !== undefined}
                      helperText={errorMessage}
                      size={size ?? "small"}
                      fullWidth={true}
                      multiline={multiline}
                      rows={rows}
                      minRows={minRows}
                      maxRows={maxRows}
                      InputProps={inputProps}
                      InputLabelProps={inputLabelProps}
        />
    );
}
