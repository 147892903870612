import {Stack} from "@mui/material";

export interface DataGridActionBarProps {
    actions: React.ReactNode;
}

export default function DataGridActionBar(props: DataGridActionBarProps) {
    const {actions} = props;

    return (
        <Stack direction={"row"} alignItems={"flex-start"} spacing={1}>
            {actions}
        </Stack>
    );
}

