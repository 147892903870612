import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import './i18n/i18n';
import AppRoutes from "./AppRouter";
import ritaTheme from "./theme/RitaTheme";
import SnackbarProvider from "./components/snackbar/SnackbarProvider";
import {ThemeProvider} from "@mui/material";
import {LicenseInfo} from "@mui/x-license-pro";
import {LocalizationProvider} from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import "dayjs/locale/en";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

LicenseInfo.setLicenseKey("8880cde512fa62f93d1e2df90b224172Tz04MzUzOCxFPTE3Mzg1NzI1MzUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

dayjs.extend(localizedFormat);
dayjs.locale("en");

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={ritaTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"en"}>
                <SnackbarProvider>
                    <BrowserRouter>
                        <AppRoutes />
                    </BrowserRouter>
                </SnackbarProvider>
            </LocalizationProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
