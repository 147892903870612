import {TranslationFunction} from "../../i18n/i18n";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {OrganizationType} from "../../client/organization/OrganizationApiClient";

export class OrganizationTypeFormatter {
    public static formatOrganizationType(organizationType: OrganizationType | string | null | undefined, t: TranslationFunction): string {
        if (!organizationType) {
            return "";
        }

        const translKey = OrganizationTranslKey.ORGANIZATION_TYPE_NAMESPACE + organizationType;
        return t(translKey);
    }
}