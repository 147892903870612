import React, {ReactNode} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';

interface RegistrationFormHintProps {
    message: ReactNode;
}

const RegistrationFormHint: React.FC<RegistrationFormHintProps> = ({ message }) => {
    return (
            <Box sx={{ backgroundColor: 'background.default', display: 'flex', alignItems: 'center', gap: 2 }}>
                <InfoIcon color="primary" />
                <Typography variant="body1">{message}</Typography>
            </Box>
    );
};

export default RegistrationFormHint;
