import {AffiliationTranslKey} from "./AffiliationTranslKey";
import {Avatar, Badge} from "@mui/material";
import {Apartment, Contacts} from "@mui/icons-material";
import {AffiliationDto} from "../../client/affiliation/AffiliationApiClient";
import {useTranslation} from "react-i18next";

export interface AffiliationBadgedAvatarProps {
    affiliation: AffiliationDto;
    iconType: "contact" | "organization";
}

export default function AffiliationBadgedAvatar(props: Readonly<AffiliationBadgedAvatarProps>) {
    const {affiliation, iconType} = props;

    const {t} = useTranslation();

    const badgeText = affiliation.isPrimary ? AffiliationTranslKey.PRIMARY_BADGE : AffiliationTranslKey.SECONDARY_BADGE;
    const badgeColor = affiliation.isPrimary ? "primary" : "info";
    const icon = iconType === "contact" ? <Contacts /> : <Apartment />;

    return (
        <Badge badgeContent={t(badgeText)}
               color={badgeColor}
               overlap={"circular"}
               anchorOrigin={{horizontal: "right", vertical: "bottom"}}>
            <Avatar>
                {icon}
            </Avatar>
        </Badge>
    );
}