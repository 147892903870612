import {Paper} from "@mui/material";


export interface PagePaperProps {
    children: React.ReactNode;
}

export function PagePaper({children}: PagePaperProps) {
    return (
        <Paper elevation={2}
               sx={{margin: "5px"}}>
            {children}
        </Paper>
    );
}