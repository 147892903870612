import {PagePaper} from "../../components/page/PagePaper";
import PageHeader from "../../components/page/PageHeader";
import {PageContent} from "../../components/page/PageContent";
import {Contacts} from "@mui/icons-material";
import ContactSearchPanel from "./search/ContactSearchPanel";
import {ContactSummaryDto} from "../../client/contact/ContactApiClient";
import {useState} from "react";
import {AddActionButton, DeleteActionButton, DetailActionButton} from "../../common/button/CommonActionButtons";
import ContactDetailDialog from "./ContactDetailDialog";
import ContactUpdateDialog from "./ContactUpdateDialog";
import ContactDeleteDialog from "./ContactDeleteDialog";

const CONTACT_DELETION_ALLOWED : boolean = false;

export interface ContactPageProps {
}

export default function ContactPage(props: ContactPageProps) {

    const [selectedContact, setSelectedContact] = useState<ContactSummaryDto>();

    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailContactId, setDetailContactId] = useState<string>();
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [contactToDelete, setContactToDelete] = useState<ContactSummaryDto>();
    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    function showDetailDialog(contactId: string) {
        setDetailDialogOpen(true);
        setDetailContactId(contactId);
    }

    function onDetailDialogClosed() {
        setDetailDialogOpen(false);
    }

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdId: string | undefined) {
        setCreateDialogOpen(false);
        if (createdId) {
            showDetailDialog(createdId);
        }
    }

    function showDeleteDialog(contact: ContactSummaryDto | undefined) {
        setContactToDelete(contact);
        setDeleteDialogOpen(true);
    }

    function onDeleteDialogClose(deleted: boolean) {
        setDeleteDialogOpen(false);
        if (deleted) {
            setSelectedContact(undefined);
        }
    }

    const actions: React.ReactNode = (
        <>
            <DetailActionButton onClick={() => showDetailDialog(selectedContact!.id)}
                                disabled={!selectedContact} />
            {CONTACT_DELETION_ALLOWED &&
                <DeleteActionButton onClick={() => showDeleteDialog(selectedContact)}
                                disabled={!selectedContact || !selectedContact.acl.canDelete}/>
            }
            <AddActionButton onClick={() => showCreateDialog()}/>
        </>
    );

    return (
        <>
            <PagePaper>
                <PageHeader title={"Contacts"} Icon={Contacts} />
                <PageContent>
                    <ContactSearchPanel actions={actions}
                                        onSelected={setSelectedContact}
                                        onRowDoubleClick={showDetailDialog} />
                </PageContent>
            </PagePaper>

            <ContactDetailDialog open={detailDialogOpen}
                                 onClose={() => onDetailDialogClosed()}
                                 contactId={detailContactId} />
            <ContactUpdateDialog open={createDialogOpen}
                                 onClose={(createdId) => onCreateDialogClosed(createdId)}
                                 contact={undefined} />
            <ContactDeleteDialog open={deleteDialogOpen}
                                 onClose={(deleted) => onDeleteDialogClose(deleted)}
                                 contact={contactToDelete!}/>
        </>
    );
}
