import {DialogTitle as MuiDialogTitle, Grid, IconButton, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";

export interface DialogTitleProps {
    title: string;
    onClose?: () => void;
}

export default function DialogTitle(props: DialogTitleProps) {
    const {title, onClose} = props;

    return (
        <MuiDialogTitle component={"div"}>
            <Grid container>
                <Grid item xs>
                    <Typography variant={"h4"} color={"primary"}>{title}</Typography>
                </Grid>
                {onClose &&
                    <Grid item xs={"auto"}>
                        <IconButton onClick={onClose}><Close/></IconButton>
                    </Grid>
                }
            </Grid>
        </MuiDialogTitle>
    );

}