import {PagePaper} from "../../components/page/PagePaper";
import PageHeader from "../../components/page/PageHeader";
import {PageContent} from "../../components/page/PageContent";
import OrganizationSearchPanel from "./search/OrganizationSearchPanel";
import {OrganizationDto} from "../../client/organization/OrganizationApiClient";
import {useState} from "react";
import {AddActionButton, DeleteActionButton, DetailActionButton} from "../../common/button/CommonActionButtons";
import {useTranslation} from "react-i18next";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {Apartment} from "@mui/icons-material";
import OrganizationDetailDialog from "./OrganizationDetailDialog";
import OrganizationUpdateDialog from "./OrganizationUpdateDialog";
import OrganizationDeleteDialog from "./OrganizationDeleteDialog";

export interface OrganizationPageProps {
}

export default function OrganizationPage(props: Readonly<OrganizationPageProps>) {
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationDto>();
    const {t} = useTranslation();

    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailOrganizationId, setDetailOrganizationId] = useState<string>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [organizationToDelete, setOrganizationToDelete] = useState<OrganizationDto>();

    function showDetailDialog(organizationId: string) {
        setDetailOrganizationId(organizationId);
        setDetailDialogOpen(true);
    }

    function onDetailDialogClose() {
        setDetailDialogOpen(false)
    }

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClose(createdOrganizationId?: string) {
        setCreateDialogOpen(false);
        if (createdOrganizationId) {
            showDetailDialog(createdOrganizationId);
        }
    }

    function showDeleteDialog(organization: OrganizationDto | undefined) {
        setOrganizationToDelete(organization);
        setDeleteDialogOpen(true);
    }

    function onDeleteDialogClose(deleted: boolean) {
        setDeleteDialogOpen(false);
        if (deleted) {
            setSelectedOrganization(undefined);
        }
    }

    const actions: React.ReactNode = (
        <>
            <DetailActionButton onClick={() => showDetailDialog(selectedOrganization!.id)}
                                disabled={!selectedOrganization}/>
            <DeleteActionButton onClick={() => showDeleteDialog(selectedOrganization)}
                                disabled={!selectedOrganization?.acl.canDelete} />
            <AddActionButton onClick={() => showCreateDialog()} />
        </>
    );

    return (
        <>
            <PagePaper>
                <PageHeader title={t(OrganizationTranslKey.ORGANIZATIONS)} Icon={Apartment} />
                <PageContent>
                    <OrganizationSearchPanel actions={actions}
                                             onSelected={setSelectedOrganization}
                                             onRowDoubleClick={showDetailDialog} />
                </PageContent>
            </PagePaper>

            <OrganizationDetailDialog open={detailDialogOpen}
                                      onClose={() => onDetailDialogClose()}
                                      organizationId={detailOrganizationId}/>

            <OrganizationUpdateDialog open={createDialogOpen}
                                      onClose={(createdOrganizationId) => onCreateDialogClose(createdOrganizationId)}
                                      organization={undefined} />
            <OrganizationDeleteDialog open={deleteDialogOpen}
                                      onClose={(deleted) => onDeleteDialogClose(deleted)}
                                      organization={organizationToDelete!} />
        </>
    );
}