import {PagePaper} from "../../components/page/PagePaper";
import PageHeader from "../../components/page/PageHeader";
import {PageContent} from "../../components/page/PageContent";
import DiseaseTree from "./tree/DiseaseTree";
import {Add, Delete, Description, Search, Vaccines} from "@mui/icons-material";
import {Panel, PanelGroup, PanelResizeHandle} from "react-resizable-panels";
import {Divider, ListItemIcon, ListItemText, Menu, MenuItem, Stack, useMediaQuery, useTheme} from "@mui/material";
import DiseaseBasicDetailPanel from "./DiseaseBasicDetailPanel";
import {useState} from "react";
import {DiseaseDto} from "../../client/disease/DiseaseApiClient";
import {DiseaseTreeItemModel} from "./tree/DiseaseTreeModel";
import DiseaseDetailDialog from "./DiseaseDetailDialog";
import ButtonBar from "../../components/button/ButtonBar";
import {DetailButton} from "../../common/button/CommonButtons";
import DiseaseCreateDialog from "./DiseaseCreateDialog";
import ActionButton from "../../components/button/ActionButton";
import DiseaseDeleteDialog from "./DiseaseDeleteDialog";
import {AddActionButton, DeleteActionButton, DetailActionButton} from "../../common/button/CommonActionButtons";


export interface DiseasePageProps {
}

export default function DiseasePage(props: DiseasePageProps) {
    const theme = useTheme();
    const useMinimalLayout = useMediaQuery(theme.breakpoints.down("sm"));

    const [selectedDisease, setSelectedDisease] = useState<DiseaseDto | null>(null);
    const [treeRefresh, setTreeRefresh] = useState<number>();

    const [detailDialogOpen, setDetailDialogOpen] = useState(false);
    const [detailDisease, setDetailDisease] = useState<DiseaseDto>();

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [parentDisease, setParentDisease] = useState<DiseaseDto>();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [diseaseToDelete, setDiseaseToDelete] = useState<DiseaseDto>();

    const [contextMenuOpen, setContextMenuOpen] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({x: 0, y: 0});
    const [contextMenuItem, setContextMenuItem] = useState<DiseaseTreeItemModel>();

    function onItemSelected(itemModel: DiseaseTreeItemModel | null) {
        if (itemModel) {
            setSelectedDisease(itemModel.disease);
        } else {
            setSelectedDisease(null);
        }
    }

    function showDetailDialog(disease: DiseaseDto) {
        setDetailDisease(disease);
        setDetailDialogOpen(true);
    }

    function onDetailDialogClosed(updated: boolean) {
        setDetailDialogOpen(false);
        if (updated) {
            refreshTree();
            setSelectedDisease(null);
        }
    }

    function showCreateDialog(parentDisease?: DiseaseDto) {
        setParentDisease(parentDisease);
        setCreateDialogOpen(true);
    }

    function onCreateDialogClose(created: boolean) {
        setCreateDialogOpen(false);
        if (created) {
            refreshTree();
        }
    }

    function showDeleteDialog(disease: DiseaseDto) {
        setDiseaseToDelete(disease);
        setDeleteDialogOpen(true);
    }

    function onDeleteDialogClose(deleted: boolean) {
        setDeleteDialogOpen(false);
        if (deleted) {
            refreshTree();
            setSelectedDisease(null);
        }
    }

    function showContextMenu(event: React.MouseEvent, itemModel: DiseaseTreeItemModel) {
        setContextMenuOpen(true);
        setContextMenuItem(itemModel);
        setContextMenuPosition({x: event.clientX, y: event.clientY});
    }

    function onDetailMenuClicked() {
        setContextMenuOpen(false);
        showDetailDialog(contextMenuItem!.disease);
    }

    function onDeleteMenuClicked() {
        setContextMenuOpen(false);
        showDeleteDialog(contextMenuItem!.disease);
    }

    function onAddChildMenuClicked() {
        setContextMenuOpen(false);
        showCreateDialog(contextMenuItem?.disease);
    }

    function refreshTree() {
        setTreeRefresh(Date.now());
    }


    const tree = (
        <Stack>
            <ButtonBar>
                <ActionButton tooltip={"Search"}
                              icon={<Search/>}
                              disabled={true}
                              onClick={() => {}} />
                {useMinimalLayout &&
                    <DetailActionButton disabled={!selectedDisease}
                                        onClick={() => showDetailDialog(selectedDisease!)} />
                }
                <AddActionButton onClick={() => showCreateDialog(selectedDisease ?? undefined)} />
                <DeleteActionButton disabled={!(selectedDisease?.acl.canDelete)}
                                    onClick={() => showDeleteDialog(selectedDisease!)} />
            </ButtonBar>
            <DiseaseTree onItemSelected={onItemSelected}
                         onContextMenu={showContextMenu}
                         onDoubleClick={(event, item) => showDetailDialog(item.disease)}
                         treeRefresh={treeRefresh}/>
        </Stack>
    );

    let content: React.JSX.Element;
    if (useMinimalLayout) {
        content = (
            <Stack direction={"column"}>
                {tree}
            </Stack>
        );
    } else {
        content = (
            <PanelGroup direction={"horizontal"} autoSaveId={"disease-tree-page-panels"}>
                <Panel defaultSize={25}
                       minSize={15}
                       order={1}>
                    {tree}
                </Panel>
                <PanelResizeHandle>
                    <Divider orientation={"vertical"} sx={{ml: 1, mr: 1}}/>
                </PanelResizeHandle>
                <Panel defaultSize={75}
                       minSize={30}
                       order={2}>
                    {selectedDisease &&
                        <>
                            <DiseaseBasicDetailPanel disease={selectedDisease}
                                                     hideActions={true}/>
                            <ButtonBar sx={{mt: 2}}>
                                <DetailButton onClick={() => showDetailDialog(selectedDisease)} />
                            </ButtonBar>
                        </>
                    }
                </Panel>
            </PanelGroup>
        );
    }

    return (
        <>
            <PagePaper>
                <PageHeader title={"Diseases"} Icon={Vaccines}/>
                <PageContent>
                    {content}
                </PageContent>
            </PagePaper>

            <DiseaseDetailDialog open={detailDialogOpen}
                                 onClose={(updated) => onDetailDialogClosed(updated)}
                                 diseaseId={detailDisease?.id} />
            <DiseaseCreateDialog open={createDialogOpen}
                                 onClose={(created) => onCreateDialogClose(created)}
                                 initialParent={parentDisease} />

            <DiseaseDeleteDialog open={deleteDialogOpen}
                                 onClose={(deleted) => onDeleteDialogClose(deleted)}
                                 disease={diseaseToDelete} />

            <Menu open={contextMenuOpen}
                  onClose={() => setContextMenuOpen(false)}
                  anchorReference={"anchorPosition"}
                  anchorPosition={{top: contextMenuPosition.y, left: contextMenuPosition.x}}>
                <MenuItem onClick={onDetailMenuClicked}>
                    <ListItemIcon><Description/></ListItemIcon>
                    <ListItemText>{"Detail"}</ListItemText>
                </MenuItem>
                <MenuItem onClick={onDeleteMenuClicked} disabled={!contextMenuItem?.disease.acl.canDelete}>
                    <ListItemIcon><Delete/></ListItemIcon>
                    <ListItemText>{"Delete"}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={onAddChildMenuClicked} disabled={!contextMenuItem?.disease.acl.canUpdate}>
                    <ListItemIcon><Add/></ListItemIcon>
                    <ListItemText>{"Add Child"}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
}