import {Grid, List, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import DiseaseListItem from "./DiseaseListItem";
import {DiseaseDto} from "../../../../client/disease/DiseaseApiClient";
import ButtonBar from "../../../../components/button/ButtonBar";
import {AddButton} from "../../../../common/button/CommonButtons";
import DiseaseSearchDialog from "../../../disease/search/DiseaseSearchDialog";
import {useTranslation} from "react-i18next";
import {DiseaseSpecializationTranslKey} from "../../../disease/specialization/DiseaseSpecializationTranslKey";
import RegistrationFormHint from "../RegistrationFormHint";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";

export interface RegistrationSpecializationPanelProps {
    diseases: DiseaseDto[];
    onChange?: (diseases: DiseaseDto[]) => void;
}

export default function DiseaseSpecializationPanel(props: Readonly<RegistrationSpecializationPanelProps>) {
    const {diseases, onChange} = props;

    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    const {t} = useTranslation();

    function showSearchDialog() {
        setSearchDialogOpen(true);
    }

    function onSearchDialogClosed(selectedDisease?: DiseaseDto) {
        setSearchDialogOpen(false);
        if (selectedDisease) {
            onChange?.([...diseases, selectedDisease]);
        }
    }

    function removeDisease(disease: DiseaseDto) {
        const updatedDiseases = diseases.filter(d => d.id !== disease.id);
        onChange?.(updatedDiseases);
    }

    let content: React.ReactNode;
    if (diseases.length > 0) {
        content = (
            <List component={"nav"}>
                {diseases.map(
                    (disease) =>
                        <DiseaseListItem disease={disease}
                                         key={disease.id}
                                         onRemoveClick={(disease) => removeDisease(disease)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <Typography variant={"body2"} fontStyle={"italic"}>{t(DiseaseSpecializationTranslKey.NO_SPECIALIZATIONS)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                <Grid item mt={2} mb={1}>
                    <RegistrationFormHint message={t(RegistrationTranslKey.SELECT_DISEASE_SPECIALIZATIONS_HINT)}/>
                </Grid>
                {content}
                <ButtonBar>
                    <AddButton onClick={showSearchDialog} />
                </ButtonBar>
            </Stack>

            <DiseaseSearchDialog open={searchDialogOpen}
                                 onClose={onSearchDialogClosed}/>
        </>
    );
}
