import {Autocomplete, Checkbox} from "@mui/material";
import StyledMuiTextField from "./StyledMuiTextField";
import {CheckBox, CheckBoxOutlineBlank} from "@mui/icons-material";

export interface SelectOption {
    value: unknown;
    label: string;
    group?: string;
}

export interface MultiSelectFieldProps {
    label: string;
    value?: unknown[];
    onChange?: (value: unknown[]) => void;

    options: SelectOption[];

    required?: boolean;
    errorMessage?: string;
    readOnly?: boolean;

    limitTags?: number;
}

export default function MultiSelectField(props: MultiSelectFieldProps) {
    const {label, value, onChange, options} = props;
    const {required, errorMessage, readOnly, limitTags} = props;

    const optionValues = options.map(option => option.value);
    const optionToLabelMap = new Map(options.map(option => [option.value, option.label]));
    const optionToGroupMap = new Map(options.map(option => [option.value, option.group]));

    return (
        <Autocomplete multiple={true}
                      value={value ?? []}
                      onChange={(event, value) => onChange?.(value)}
                      options={optionValues}
                      getOptionLabel={(option => optionToLabelMap.get(option) ?? option as string)}
                      groupBy={(option) => optionToGroupMap.get(option) ?? ""}
                      readOnly={readOnly}
                      limitTags={limitTags}
                      size={"small"}
                      renderOption={(props, option, { selected }) => (
                          <li {...props}>
                              <Checkbox
                                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                                  checkedIcon={<CheckBox color="primary" fontSize="small" />}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                              />
                              {optionToLabelMap.get(option) ?? ""}
                          </li>
                      )}
                      renderInput={(params) =>
                          <StyledMuiTextField {...params}
                                              label={label}
                                              required={required}
                                              error={errorMessage !== undefined}
                                              helperText={errorMessage} />
                      } />
    );
}
