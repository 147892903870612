import {DataGridProProps, GridColDef, GridSortModel} from "@mui/x-data-grid-pro";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../client/disease/DiseaseApiClient";
import {formatHierarchyLevel} from "./DiseaseHierarchyLevelFormatter";
import DataGrid from "../../components/datagrid/DataGrid";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DiseaseTranslKey} from "./DiseaseTranslKey";
import {getDiseaseChainPathToRoot} from "./DiseaseChainPathFormatter";


export interface DiseaseTreeGridProps {
    diseases: DiseaseDto[];
    selectedId?: string;
    onSelectionChange?: (diseaseId?: string) => void;
    onRowDoubleClick?: (disease: DiseaseDto) => void;
}

export default function DiseaseTreeGrid(props: Readonly<DiseaseTreeGridProps>) {
    const {diseases, selectedId, onSelectionChange, onRowDoubleClick} = props;

    const {t} = useTranslation();
    const [sortModel, setSortModel] = useState([] as GridSortModel);

    const diseaseLookup = useMemo(() => {
        return new Map<string, DiseaseDto>(diseases.map(d => [d.id, d]));
    }, [diseases]);

    const columnDefs: GridColDef<DiseaseDto>[] = [
        { field: "abbreviation", headerName: t(DiseaseTranslKey.ABBREVIATION), width: 120 },
        { field: "hierarchyLevel", headerName: t(DiseaseTranslKey.LEVEL), width: 100, valueFormatter: (params) => formatHierarchyLevel(params.value as DiseaseHierarchyLevel) },
        { field: "orphacode", headerName: t(DiseaseTranslKey.ORPHACODE), width: 120 },
    ];

    const groupingColDef: DataGridProProps<DiseaseDto>['groupingColDef'] = {
        headerName: t(DiseaseTranslKey.DISEASE),
        valueGetter: (params) => {
            return params.row.name;
        },
        width: 700,
    };

    return (
        <DataGrid
            treeData
            getTreeDataPath={(row) => getDiseaseChainPathToRoot(row, diseaseLookup)}
            groupingColDef={groupingColDef}
            rows={diseases}
            columns={columnDefs}
            autoHeight
            rowSelectionModel={selectedId}
            onRowSelectionModelChange={((rowSelectionModel) =>
                    onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            onRowDoubleClick={(params) => onRowDoubleClick?.(params.row)} />
    );
}

