import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import SelectField from "../../components/field/SelectField";
import {
    AffiliationCreateDto,
    ContactAffiliationRole,
    ContactAffiliationTitle
} from "../../client/affiliation/AffiliationApiClient";
import {ContactDto, ContactSummaryDto} from "../../client/contact/ContactApiClient";
import {useTranslation} from "react-i18next";
import {AffiliationTranslKey} from "./AffiliationTranslKey";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {OrganizationDto} from "../../client/organization/OrganizationApiClient";
import TextFieldWithMore from "../../components/field/TextFieldWithMore";
import {ContactFormatter} from "../contact/ContactFormatter";
import {AffiliationEventType} from "./AffiliationEventType";
import Checkbox from "../../components/field/Checkbox";
import ContactSearchDialog from "../contact/search/ContactSearchDialog";
import OrganizationSearchDialog from "../organization/search/OrganizationSearchDialog";
import {ContactAffiliationTitleFormatter} from "./ContactAffiliationTitleFormatter";
import {ContactAffiliationRoleFormatter} from "./ContactAffiliationRoleFormatter";
import TextField from "../../components/field/TextField";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}



interface AffiliationCreateModel {
    contact?: ContactSummaryDto | ContactDto;
    organization?: OrganizationDto;
    isPrimary: boolean;
    contactAffiliationRole: ContactAffiliationRole;
    contactAffiliationTitle: ContactAffiliationTitle | string;
    expertise?: string;
}

const DEFAULT_AFFILIATION_CREATE_MODEL: AffiliationCreateModel = {
    isPrimary: true,
    contactAffiliationTitle: ContactAffiliationTitle.MEMBER,
    contactAffiliationRole: ContactAffiliationRole.ORGANIZATION_MEMBER
};

export interface AffiliationCreateDialogProps {
    open: boolean;
    contact?: ContactDto;
    organization?: OrganizationDto;
    onClose: (createdAffiliationId: string | undefined) => void;
    allowPrimary?: boolean
}

export default function AffiliationCreateDialog(props: Readonly<AffiliationCreateDialogProps>) {
    const {open, contact, organization, onClose, allowPrimary} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_AFFILIATION_CREATE_MODEL);

    const [organizationSearchDialogOpen, setOrganizationSearchDialogOpen] = useState(false);
    const [organizationIsReadOnly, setOrganizationIsReadOnly] = useState(true);

    const [contactSearchDialogOpen, setContactSearchDialogOpen] = useState(false);
    const [contactIsReadOnly, setContactIsReadOnly] = useState(true);

    const {affiliationClient} = useClientLocator();
    const {t} = useTranslation();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        if (!open) {
            setModel(DEFAULT_AFFILIATION_CREATE_MODEL);
            setDialogState(CreateDialogState.IDLE);
            setContactIsReadOnly(true);
            setOrganizationIsReadOnly(true);
        } else {
            setModel({
                ...DEFAULT_AFFILIATION_CREATE_MODEL,
                contact: contact,
                organization: organization,
                isPrimary: !!allowPrimary
            });
            setContactIsReadOnly(contact !== undefined);
            setOrganizationIsReadOnly(organization !== undefined);
        }
    }, [open, contact, organization, allowPrimary]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const create = convertToCreate(model);
            affiliationClient.createAffiliation(create)
                .then((affiliationId) => {
                    snackbar.success(t(CommonTranslKey.DATA_SAVED));
                    onClose(affiliationId);
                    eventDispatcher.dispatchEvent(new Event(AffiliationEventType.AFFILIATION_CREATED));
                })
                .catch((error) => {
                    snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                    setDialogState(CreateDialogState.IDLE);
                });
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (model.contact === undefined) {
            isValid = false;
        }
        if (model.organization === undefined) {
            isValid = false;
        }
        if (model.contactAffiliationRole === undefined) {
            isValid = false;
        }
        if (model.contactAffiliationTitle === undefined) {
            isValid = false;
        }

        return isValid;
    }

    function convertToCreate(model: AffiliationCreateModel): AffiliationCreateDto {
        const [title, otherTitle] = ContactAffiliationTitleFormatter.decomposeTitleAndOtherTitle(model.contactAffiliationTitle);

        return {
            contactId: model.contact!.id,
            organizationId: model.organization!.id,
            isPrimary: model.isPrimary,
            contactAffiliationRole: model.contactAffiliationRole!,
            contactAffiliationTitle: title,
            otherContactAffiliationTitle: otherTitle,
            expertise: model.expertise
        };
    }

    function clearContact() {
        setModel({...model, contact: undefined});
    }

    function showContactSearchDialog() {
        setContactSearchDialogOpen(true);
    }

    function onContactSearchDialogClose(contact?: ContactSummaryDto) {
        setContactSearchDialogOpen(false);
        if (contact) {
            setModel({...model, contact: contact});
        }
    }

    function clearOrganization() {
        setModel({...model, organization: undefined});
    }

    function showOrganizationSearchDialog() {
        setOrganizationSearchDialogOpen(true);
    }

    function onOrganizationSearchDialogClose(organization?: OrganizationDto) {
        setOrganizationSearchDialogOpen(false);
        if (organization) {
            setModel({...model, organization: organization});
        }
    }

    const titleOptions = Object.keys(ContactAffiliationTitle)
            .filter((title) => title !== ContactAffiliationTitle.OTHER)
            .map((title) => ({
                value: title,
                label: ContactAffiliationTitleFormatter.translateTitle(title, t)
            }));

    const roleOptions = Object.keys(ContactAffiliationRole).map((role) => ({
        value: role as ContactAffiliationRole,
        label: ContactAffiliationRoleFormatter.formatRole(role as ContactAffiliationRole, t)
    }));

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={t(AffiliationTranslKey.ADD_NEW_AFFILIATION)} />
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextFieldWithMore label={t(AffiliationTranslKey.ORGANIZATION)}
                                               value={model.organization?.name}
                                               onClear={() => clearOrganization()}
                                               onMoreClick={() => showOrganizationSearchDialog()}
                                               readOnly={organizationIsReadOnly}
                                               required={true} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <TextFieldWithMore label={t(AffiliationTranslKey.CONTACT)}
                                               value={ContactFormatter.formatContactName(model.contact)}
                                               onClear={() => clearContact()}
                                               onMoreClick={() => showContactSearchDialog()}
                                               readOnly={contactIsReadOnly}
                                               required={true} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <SelectField label={t(AffiliationTranslKey.CONTACT_AFFILIATION_TITLE)}
                                         options={titleOptions}
                                         acceptFreeText={true}
                                         value={model.contactAffiliationTitle}
                                         onChange={(value) => setModel({
                                             ...model,
                                             contactAffiliationTitle: value as ContactAffiliationTitle | string
                                         })}/>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <SelectField label={t(AffiliationTranslKey.CONTACT_AFFILIATION_ROLE)}
                                         value={model.contactAffiliationRole}
                                         onChange={(value) => setModel({...model, contactAffiliationRole: value as ContactAffiliationRole})}
                                         required={true}
                                         options={roleOptions} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label={t(AffiliationTranslKey.EXPERTISE)}
                                       value={model.expertise}
                                       onChange={(value) => setModel({
                                           ...model,
                                           expertise: value
                                       })}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Checkbox label={t(AffiliationTranslKey.PRIMARY)}
                                      checked={model.isPrimary}
                                      readOnly={!allowPrimary}
                                      onChange={(value) => setModel({...model, isPrimary: value})} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!model || !validate()}
                                    inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <OrganizationSearchDialog open={organizationSearchDialogOpen} onClose={onOrganizationSearchDialogClose} />

            <ContactSearchDialog open={contactSearchDialogOpen} onClose={onContactSearchDialogClose} />
        </>
    );
}
