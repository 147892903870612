import {Box} from "@mui/material";


export interface PageContentProps {
    children: React.ReactNode;
}

export function PageContent({children}: PageContentProps) {
    return (
        <Box padding={1}>
            {children}
        </Box>
    );
}