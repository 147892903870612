import {TranslationFunction} from "../../../i18n/i18n";
import {CpmsPanel} from "../../../client/contact/ContactApiClient";
import {ContactTranslKey} from "../ContactTranslKey";

export class CpmsPanelFormatter {
    public static formatPanel(panel: CpmsPanel | string | null | undefined, t: TranslationFunction): string {
        if (!panel) {
            return "";
        }

        const translKey = ContactTranslKey.CPMS_PANEL_NAMESPACE + panel;
        return t(translKey);
    }
}
