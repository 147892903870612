import {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {DiseaseDto, DiseaseUpdateDto} from "../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import TextField from "../../components/field/TextField";
import {useTranslation} from "react-i18next";
import {DiseaseTranslKey} from "./DiseaseTranslKey";
import {CommonTranslKey} from "../../common/CommonTranslKey";

enum UpdateDialogState {
    FILLING,
    SAVE_IN_PROGRESS,
}

const DEFAULT_DISEASE_UPDATE: DiseaseUpdateDto = {
    name: "",
    abbreviation: "",
    orphacode: "",
    description: "",
};

export interface DiseaseUpdateDialogProps {
    open: boolean;
    onClose: (updated: boolean) => void;
    disease?: DiseaseDto;
}

export default function DiseaseUpdateDialog(props: DiseaseUpdateDialogProps) {
    const {open, onClose, disease} = props;

    const [dialogState, setDialogState] = useState(UpdateDialogState.FILLING);
    const [diseaseUpdate, setDiseaseUpdate] = useState(DEFAULT_DISEASE_UPDATE);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open) {
            setDiseaseUpdate(DEFAULT_DISEASE_UPDATE);
            setDialogState(UpdateDialogState.FILLING);
        } else if (disease) {
            setDiseaseUpdate({
                name: disease.name,
                abbreviation: disease.abbreviation ?? "",
                description: disease.description ?? "",
                orphacode: disease.orphacode ?? "",
            });
        }
    }, [open, disease]);

    function save() {
        setDialogState(UpdateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            const update = normalize(diseaseUpdate);
            diseaseClient.updateDisease(disease!.id, update)
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(true);
                    })
                    .catch((error) => {
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                        setDialogState(UpdateDialogState.FILLING);
                    });
        }
    }

    function validate(): boolean {
        return (diseaseUpdate.name.trim().length > 0);
    }

    function normalize(update: DiseaseUpdateDto): DiseaseUpdateDto {
        const name = update.name.trim();
        const abbreviation = update.abbreviation?.trim();
        const orphacode = update.orphacode?.trim();
        const description = update.description;
        return {
            name: name,
            abbreviation: (abbreviation && abbreviation.length > 0) ? abbreviation : undefined,
            orphacode: (orphacode && orphacode.length > 0) ? orphacode : undefined,
            description: (description && description.length > 0) ? description : undefined,
        };
    }

    return (
            <Dialog open={open} onClose={() => onClose(false)} maxWidth={"md"}>
                <DialogTitle title={"Update Disease"}/>
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextField label={t(DiseaseTranslKey.NAME)}
                                       required={true}
                                       value={diseaseUpdate.name}
                                       onChange={(value) => setDiseaseUpdate({...diseaseUpdate, name: value})}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField label={t(DiseaseTranslKey.ABBREVIATION)}
                                       value={diseaseUpdate.abbreviation}
                                       onChange={(value) => setDiseaseUpdate({...diseaseUpdate, abbreviation: value})}/>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <TextField label={t(DiseaseTranslKey.ORPHACODE)}
                                       value={diseaseUpdate.orphacode}
                                       onChange={(value) => setDiseaseUpdate({...diseaseUpdate, orphacode: value})}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={t(DiseaseTranslKey.DESCRIPTION)}
                                       value={diseaseUpdate.description}
                                       onChange={(value) => setDiseaseUpdate({...diseaseUpdate, description: value})}
                                       multiline={true}
                                       minRows={3}
                                       maxRows={7}/>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(false)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!disease || !validate()}
                                    inProgress={dialogState === UpdateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>
    );
}
