import Button, {ButtonProps, ButtonVariant} from "../../components/button/Button";
import {MouseEventHandler} from "react";
import {Add, Cancel, Delete, Description, Done, Edit, Save, Search, Send} from "@mui/icons-material";
import {CommonTranslKey} from "../CommonTranslKey";
import {useTranslation} from "react-i18next";

export interface CommonButtonProps {
    variant?: ButtonVariant;
    onClick?: MouseEventHandler;
    disabled?: boolean;
    inProgress?: boolean;
}

export function AddButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.ADD)} icon={<Add/>} {...props} />;
}

export function CancelButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.CANCEL)} icon={<Cancel/>} {...props} />;
}

export function DeleteButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.DELETE)} icon={<Delete/>} color={"error"} {...props} />;
}

export function DetailButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.DETAILS)} icon={<Description/>} {...props} />;
}

export function EditButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.EDIT)} icon={<Edit/>} {...props} />;
}

export function InvalidateButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.INVALIDATE)} icon={<Delete/>} color={"error"} {...props} />;
}

export function SaveButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.SAVE)} icon={<Save/>} {...props} />;
}

export function SearchButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.SEARCH)} icon={<Search/>} {...props} />;
}

export function SelectButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.SELECT)} icon={<Done/>} {...props} />;
}

export function SubmitButton(props: CommonButtonProps) {
    const {t} = useTranslation();
    return <CommonButtonImpl title={t(CommonTranslKey.SUBMIT)} icon={<Send/>} {...props} />;
}


interface CommonButtonImplProps extends CommonButtonProps {
    title: string;
    icon?: React.ReactNode;
    color?: ButtonProps["color"];
}

function CommonButtonImpl(props: CommonButtonImplProps) {
    const {variant, onClick, icon, title, color, disabled, inProgress} = props;
    return (
        <Button icon={icon}
                variant={variant ?? "secondary"}
                color={color}
                onClick={onClick}
                disabled={disabled}
                inProgress={inProgress}>
            {title}
        </Button>
    );
}