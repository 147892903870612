import {GridColDef, GridColumnVisibilityModel, GridSortModel} from "@mui/x-data-grid-pro";
import {OrganizationDto} from "../../client/organization/OrganizationApiClient";
import DataGrid from "../../components/datagrid/DataGrid";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {useTranslation} from "react-i18next";
import {OrganizationTypeFormatter} from "./OrganizationTypeFormatter";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {DateTimeFormatter} from "../../common/formatter/DateTimeFormatter";
import {RitaAffiliationFormatter} from "./RitaAffiliationFormatter";
import {DiseaseDto, DiseaseHierarchyLevel} from "../../client/disease/DiseaseApiClient";
import {useClientLocator} from "../../client/ApiClientLocator";
import {DiseaseSpecializationDto} from "../../client/disease/DiseaseSpecializationDto";
import { Check } from "@mui/icons-material";
import {CountryFormatter} from "../country/CountryFormatter";

const SPECIALIZATION_COLUMN_PREFIX = "specialization#";

function hasSpecializationToDisease(specializations: DiseaseSpecializationDto[] | undefined, diseaseId: string): boolean {
    if (!specializations) {
        return false;
    }
    return specializations.filter(spec => spec.diseaseId === diseaseId).length > 0;
}

function renderSpecializationToDiseaseCell(isSpecialized: boolean): ReactNode | null {
    return isSpecialized ? <Check/> : null;
}

export interface OrganizationGridProps {
    organizations: OrganizationDto[];
    selectedId?: string;
    onSelectionChange?: (organizationId?: string) => void;
    onRowDoubleClick?: (organizationId: string) => void;
}

export default function OrganizationGrid(props: Readonly<OrganizationGridProps>) {
    const {organizations, selectedId, onSelectionChange, onRowDoubleClick} = props;

    const {diseaseClient} = useClientLocator();
    const {t} = useTranslation();

    const [diseaseStreams, setDiseaseStreams] = useState<DiseaseDto[]>([]);
    const [diseaseSubstreams, setDiseaseSubstreams] = useState<DiseaseDto[]>([]);

    useEffect(() => {
        diseaseClient.findByFilter({level: DiseaseHierarchyLevel.STREAM})
            .then((streams) => {
                setDiseaseStreams(streams);
            });
        diseaseClient.findByFilter({level: DiseaseHierarchyLevel.SUB_STREAM})
            .then((substreams) => {
                setDiseaseSubstreams(substreams);
            });
    }, [diseaseClient]);

    const columnDefs: GridColDef<OrganizationDto>[] = useMemo(() => {
        let defs: GridColDef<OrganizationDto>[] = [
            { field: "name", headerName: t(OrganizationTranslKey.NAME), width: 220 },
            { field: "typeDetail.organizationType", headerName: t(OrganizationTranslKey.ORGANIZATION_TYPE), width: 150, valueGetter: params => params.row.typeDetail?.organizationType, valueFormatter: params => OrganizationTypeFormatter.formatOrganizationType(params.value, t) },
            { field: "typeDetail#ritaAffiliation", headerName: t(OrganizationTranslKey.RITA_AFFILIATION), width: 150, valueGetter: params => params.row.typeDetail, valueFormatter: params => RitaAffiliationFormatter.formatRitaAffiliation(params.value, t) },
            { field: "typeDetail.hcpAffiliationValidity.validFrom", headerName: t(OrganizationTranslKey.AFFILIATED_SINCE), width: 150, valueGetter: params => params.row.typeDetail?.hcpAffiliationValidity?.validFrom, valueFormatter: params => DateTimeFormatter.formatDate(params.value) },
            { field: "city", headerName: t(OrganizationTranslKey.CITY), width: 150 },
            { field: "country", headerName: t(OrganizationTranslKey.COUNTRY), width: 120, valueGetter: ({value}) => value?.code, valueFormatter: params => CountryFormatter.formatCountry(params.value, t)}
        ];

        diseaseStreams.toSorted((a, b) => a.name.localeCompare(b.name))
            .forEach((stream) => {
                defs.push({
                    field: `${SPECIALIZATION_COLUMN_PREFIX}${stream.id}`,
                    headerName: stream.abbreviation ?? stream.name,
                    width: 250,
                    valueGetter: params => hasSpecializationToDisease(params.row.specializations, stream.id),
                    renderCell: params => renderSpecializationToDiseaseCell(params.value),
                });
            });

        diseaseSubstreams.toSorted((a, b) => a.name.localeCompare(b.name))
            .forEach((substream) => {
                defs.push({
                    field: `${SPECIALIZATION_COLUMN_PREFIX}${substream.id}`,
                    headerName: substream.abbreviation ?? substream.name,
                    width: 250,
                    valueGetter: params => hasSpecializationToDisease(params.row.specializations, substream.id),
                    renderCell: params => renderSpecializationToDiseaseCell(params.value),
                });
            });
        return defs;
    }, [t, diseaseStreams, diseaseSubstreams]);

    const defaultColumnVisibilityModel: GridColumnVisibilityModel = useMemo(() => ({
        name: true,
        "typeDetail.organizationType": true,
        "typeDetail#ritaAffiliation": false,
        "typeDetail.hcpAffiliationValidity.validFrom": false,
        city: false,
        country: true,
    }), []);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(defaultColumnVisibilityModel);

    useEffect(() => {
        if (diseaseSubstreams.length > 0 && columnVisibilityModel[`${SPECIALIZATION_COLUMN_PREFIX}${diseaseSubstreams[0].id}`] === undefined) {
            const visibilityModel: GridColumnVisibilityModel = {...columnVisibilityModel};
            diseaseSubstreams.forEach((substream) => visibilityModel[`${SPECIALIZATION_COLUMN_PREFIX}${substream.id}`] = false);
            setColumnVisibilityModel(visibilityModel);
        }
    }, [diseaseSubstreams, columnVisibilityModel]);

    const defaultSortModel: GridSortModel = useMemo(() => [
        {field: "name", sort: "asc"},
    ], []);

    const [sortModel, setSortModel] = useState(defaultSortModel);

    return (
        <DataGrid
            rows={organizations}
            columns={columnDefs}
            autoHeight
            rowSelectionModel={selectedId}
            onRowSelectionModelChange={((rowSelectionModel) =>
                    onSelectionChange?.(rowSelectionModel.length > 0 ? rowSelectionModel?.[0] as string : undefined))}
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={setColumnVisibilityModel}
            onRowDoubleClick={(params) => onRowDoubleClick?.(params.row.id)}
        />
    );
}
