import {Grid} from "@mui/material";
import TextField from "../../components/field/TextField";
import ButtonBar from "../../components/button/ButtonBar";
import {EditButton} from "../../common/button/CommonButtons";
import {ContactDto} from "../../client/contact/ContactApiClient";
import {formatUnits} from "./UnitFormatter";
import ContactUpdateDialog from "./ContactUpdateDialog";
import {useState} from "react";
import {ContactTranslKey} from "./ContactTranslKey";
import {useTranslation} from "react-i18next";
import {BooleanFormatter} from "../../common/formatter/BooleanFormatter";
import {ContactFormatter} from "./ContactFormatter";
import {CountryFormatter} from "../country/CountryFormatter";

export interface ContactDetailPanelProps {
    contact: ContactDto;
    onUpdate?: () => void;
    hideActions?: boolean;
}

export default function ContactBasicDetailPanel(props: ContactDetailPanelProps) {
    const {contact, hideActions, onUpdate} = props;
    const {t} = useTranslation();

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

    function showUpdateDialog() {
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClosed(updated: boolean) {
        setUpdateDialogOpen(false);
        if (updated) {
            onUpdate?.();
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={5}>
                    <TextField label={t(ContactTranslKey.FIRST_NAME)}
                               value={contact.firstName}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                    <TextField label={t(ContactTranslKey.LAST_NAME)}
                               value={contact.lastName}
                               readOnly={true}/>
                </Grid>
                <Grid item xs={12} sm={6} lg={2}>
                    <TextField label={t(ContactTranslKey.TITLE)}
                               value={contact.title}
                               readOnly={true}/>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(ContactTranslKey.EMAIL)}
                               value={contact.email}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(ContactTranslKey.PHONE)}
                               value={contact.phone}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label={t(ContactTranslKey.COUNTRY)}
                               value={CountryFormatter.formatCountryName(contact.country, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label={t(ContactTranslKey.STAND_IN)}
                               value={ContactFormatter.formatContactBrief(contact.standIn)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t(ContactTranslKey.SURVEY)}
                               value={BooleanFormatter.formatAsYesNo(contact.survey, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t(ContactTranslKey.CPG)}
                               value={BooleanFormatter.formatAsYesNo(contact.cpg, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField label={t(ContactTranslKey.CPG_JIA)}
                               value={BooleanFormatter.formatAsYesNo(contact.cpgJia, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label={t(ContactTranslKey.UNITS)}
                               value={formatUnits(contact.units)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label={t(ContactTranslKey.WEBINARS)}
                               value={ContactFormatter.formatWebinars(contact.webinars)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12}>
                    <TextField label={t(ContactTranslKey.NOTE)}
                               value={contact.note}
                               readOnly={true}
                               multiline={true}
                               minRows={3}
                               maxRows={7}/>
                </Grid>
                {!hideActions &&
                    <Grid item xs={12}>
                        <ButtonBar>
                            <EditButton disabled={!contact.acl.canUpdate} onClick={showUpdateDialog} />
                        </ButtonBar>
                    </Grid>
                }
            </Grid>

            <ContactUpdateDialog open={updateDialogOpen}
                                 onClose={(updatedId) => onUpdateDialogClosed(updatedId !== undefined)}
                                 contact={contact} />
        </>
    );
}
