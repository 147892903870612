export enum ContactTranslKey {
    CONTACTS = "contact:contacts",
    COUNTRY = "contact:country",
    CPG = "contact:cpg",
    CPG_JIA = "contact:cpgJia",
    CPMS = "contact:cpms",
    CPMS_PANEL = "contact:cpmsPanel",
    CPMS_PANEL_NAMESPACE = "contact:catalog:cpmsPanel:",
    CPMS_STATUS_NAMESPACE = "contact:catalog:cpmsStatus:",
    CREATE_NEW_CONTACT = "contact:createNewContact",
    DELETE_CONTACT = "contact:deleteContact",
    DELETE_CONTACT_PROMPT = "contact:deleteContactPrompt",
    DISEASES = "contact:diseases",
    EMAIL = "contact:email",
    EMAIL_ALREADY_EXISTS = "contact:emailAlreadyExists",
    EMAIl_WRONG_FORMAT = "contact:emailWrongFormat",
    FIRST_NAME = "contact:firstName",
    LAST_NAME = "contact:lastName",
    NO_TOPICS = "contact:noTopics",
    MEMBERSHIP_ROLE = "contact:membershipRole",
    MEMBERSHIP_TITLE = "contact:membershipTitle",
    NOTE = "contact:note",
    ORGANIZATIONS = "contact:organizations",
    PANEL_LEAD_COUNT = "contact:panelLeadCount",
    PANEL_MEMBER_COUNT = "contact:panelMemberCount",
    PANELS = "contact:panels",
    PHONE = "contact:phone",
    PRIMARY_ORGANIZATION_NAME = "contact:primaryOrganizationName",
    SECONDARY_ORGANIZATION_NAME = "contact:secondaryOrganizationName",
    REFERRED_PHYSICIAN_COUNT = "contact:referredPhysicianCount",
    SEARCH_CONTACT = "contact:searchContact",
    CPMS_STATUS = "contact:cpmsStatus",
    STAND_IN = "contact:standIn",
    SURVEY = "contact:survey",
    TITLE = "contact:title",
    TOPIC_MEMBERSHIP_ROLE_NAMESPACE = "contact:catalog:topicMembershipRole:",
    TOPIC_MEMBERSHIP_TITLE_NAMESPACE = "contact:catalog:topicMembershipTitle:",
    TOPICS = "contact:topics",
    UNITS = "contact:units",
    UPDATE_CONTACT = "contact:updateContact",
    UPDATE_CONTACT_CPMS = "contact:updateContactCpms",
    UPDATE_TOPIC_MEMBERSHIP = "contact:updateTopicMembership",
    WEBINARS = "contact:webinars",
}
