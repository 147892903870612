import {createTheme} from "@mui/material";
import {purple} from "@mui/material/colors";

const ritaTheme = createTheme({
    palette: {
        primary: {
            main: purple[800]
        }
    }
});

export default ritaTheme;