import {Grid} from "@mui/material";
import {useCallback, useEffect, useState} from "react";
import ButtonBar from "../../../components/button/ButtonBar";
import {SearchButton} from "../../../common/button/CommonButtons";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {OrganizationDto, OrganizationFilterDto} from "../../../client/organization/OrganizationApiClient";
import OrganizationGrid from "../OrganizationGrid";
import {OrganizationFilterForm} from "./OrganizationFilterForm";
import DataGridActionBar from "../../../components/datagrid/DataGridActionBar";
import {EventListenerUnsubscriber, useEventDispatcher} from "../../../components/EventDispatcher";
import {OrganizationEventType} from "../OrganizationEventType";
import {useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../../common/CommonTranslKey";

enum SearchPanelState {
    INITIALIZED,
    IDLE,
    SEARCH_IN_PROGRESS
}

export interface OrganizationSearchPanelProps {
    onSelected: (organization?: OrganizationDto) => void;
    actions?: React.ReactNode;
    onRowDoubleClick?: (organizationId: string) => void;
}

export default function OrganizationSearchPanel(props: Readonly<OrganizationSearchPanelProps>) {
    const {onSelected, actions, onRowDoubleClick} = props;

    const {organizationClient} = useClientLocator();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();
    const {t} = useTranslation();

    const [state, setState] = useState<SearchPanelState>(SearchPanelState.INITIALIZED);
    const [filter, setFilter] = useState<OrganizationFilterDto>({});
    const [organizations, setOrganizations] = useState<OrganizationDto[]>();

    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationDto>();

    function onFilterChanged(updatedFilter: OrganizationFilterDto) {
        setFilter(updatedFilter);
    }

    const search = useCallback(() => {
        setState(SearchPanelState.SEARCH_IN_PROGRESS);
        organizationClient.findByFilter({...filter, validAt: new Date().toISOString()})
            .then((organizations) => {
                setOrganizations(organizations);
            })
            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error))
            .finally(() => setState(SearchPanelState.IDLE))
    }, [filter, organizationClient, snackbar, t]);

    useEffect(() => {
        const unsubscribers: EventListenerUnsubscriber[] = [];
        unsubscribers.push(eventDispatcher.addEventListener(OrganizationEventType.ORGANIZATION_CREATED, () => search()));
        unsubscribers.push(eventDispatcher.addEventListener(OrganizationEventType.ORGANIZATION_UPDATED, () => search()));
        unsubscribers.push(eventDispatcher.addEventListener(OrganizationEventType.ORGANIZATION_DELETED, () => search()));

        return () => unsubscribers.forEach(unsubscribe => unsubscribe());
    }, [search, filter, eventDispatcher]);

    useEffect(() => {
        if (state === SearchPanelState.INITIALIZED) {
            search();
        }
    }, [search, state]);

    function onSelectionChange(organizationId?: string) {
        let organization = organizationId
            ? organizations?.find((organization) => organization.id === organizationId)
            : undefined;
        setSelectedOrganization(organization);
        onSelected(organization);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <OrganizationFilterForm filter={filter}
                                        onChange={onFilterChanged} />
            </Grid>
            <Grid item xs={12}>
                <ButtonBar>
                    <SearchButton onClick={() => search()}
                                  inProgress={state === SearchPanelState.SEARCH_IN_PROGRESS} />
                </ButtonBar>
            </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {actions &&
                            <Grid item xs={12}>
                                <DataGridActionBar actions={actions} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {state !== SearchPanelState.INITIALIZED && organizations &&
                                <OrganizationGrid organizations={organizations}
                                                  selectedId={selectedOrganization?.id}
                                                  onSelectionChange={onSelectionChange}
                                                  onRowDoubleClick={onRowDoubleClick}/>
                            }
                        </Grid>
                    </Grid>
                </Grid>
        </Grid>
    );

}
