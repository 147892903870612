import {Navigate, Route, Routes} from "react-router-dom";
import DiseasePage from "./content/disease/DiseasePage";
import ContactPage from "./content/contact/ContactPage";
import App from "./App";
import UserPage from "./content/user/UserPage";
import OrganizationPage from "./content/organization/OrganizationPage";
import RegistrationFormPage from "./content/registration/form/RegistrationFormPage";
import RegistrationRequestPage from "./content/registration/request/RegistrationRequestPage";
import TopicPage from "./content/topic/TopicPage";
import PublicApp from "./PublicApp";

const CONTACTS_PATH = "contacts";
export const CONTACTS = `/${CONTACTS_PATH}`;

const DISEASES_PATH = "diseases";
export const DISEASES  = `/${DISEASES_PATH}`;

const ORGANIZATIONS_PATH = "organizations";
export const ORGANIZATIONS = `/${ORGANIZATIONS_PATH}`;

const REGISTRATION_FORM_PATH = "registration-form";
export const REGISTRATION_FORM  = `/${REGISTRATION_FORM_PATH}`;

const REGISTRATION_REQUESTS_PATH = "registration-requests";
export const REGISTRATION_REQUESTS  = `/${REGISTRATION_REQUESTS_PATH}`;

const USERS_PATH = "users";
export const USERS  = `/${USERS_PATH}`;

const TOPICS_PATH = "topics";
export const TOPICS  = `/${TOPICS_PATH}`;

export default function AppRoutes() {
    return (
        <Routes>
            <Route path={"/"} element={<App/>}>
                <Route path={CONTACTS_PATH} element={<ContactPage/>}/>
                <Route path={DISEASES_PATH} element={<DiseasePage/>}/>
                <Route path={ORGANIZATIONS_PATH} element={<OrganizationPage/>}/>
                <Route path={REGISTRATION_REQUESTS_PATH} element={<RegistrationRequestPage/>}/>
                <Route path={USERS_PATH} element={<UserPage/>}/>
                <Route path={TOPICS_PATH} element={<TopicPage/>}/>
                <Route path={"*"} element={<Navigate to={CONTACTS}/>} />
                <Route index element={<Navigate to={CONTACTS}/>} />
            </Route>
            <Route path={"/public"} element={<PublicApp/>}>
                <Route path={REGISTRATION_FORM_PATH} element={<RegistrationFormPage/>}/>
            </Route>
        </Routes>
    );
}
