import {Typography} from "@mui/material";
import {useState} from "react";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import {DiseaseDto} from "../../../client/disease/DiseaseApiClient";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../../common/button/CommonButtons";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {Trans, useTranslation} from "react-i18next";
import {DiseaseSpecializationTranslKey} from "./DiseaseSpecializationTranslKey";
import {DiseaseSpecializationDto} from "../../../client/disease/DiseaseSpecializationDto";

enum DeleteDialogState {
    IDLE,
    DELETE_IN_PROGRESS,
}

export interface DiseaseSpecializationDeleteClient {
    deleteSpecialization(diseaseId: string): Promise<void>;
}

export interface DiseaseSpecializationDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    diseaseSpecialization?: DiseaseSpecializationDto;
    disease?: DiseaseDto;
    specializationClient: DiseaseSpecializationDeleteClient;
}

export default function DiseaseSpecializationDeleteDialog(props: Readonly<DiseaseSpecializationDeleteDialogProps>) {
    const {open, onClose, diseaseSpecialization, disease, specializationClient} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.IDLE);

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    function deleteContactSpecialization() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        specializationClient.deleteSpecialization(diseaseSpecialization!.id)
            .then(() => {
                snackbar.success(t(CommonTranslKey.DATA_DELETED));
                setDialogState(DeleteDialogState.IDLE);
                onClose(true);
            })
            .catch((error) => {
                snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                setDialogState(DeleteDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(DiseaseSpecializationTranslKey.DELETE_DISEASE_SPECIALIZATION)}/>
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={DiseaseSpecializationTranslKey.DELETE_DISEASE_SPECIALIZATION_PROMPT} values={{diseaseName: disease?.name}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteContactSpecialization}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}