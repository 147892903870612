import {DiseaseSpecializationClient} from "../../disease/specialization/DiseaseSpecializationPanel";
import {ContactApiClient} from "../../../client/contact/ContactApiClient";
import {DiseaseSpecializationDto} from "../../../client/disease/DiseaseSpecializationDto";

export class ContactSpecializationClientAdapter implements DiseaseSpecializationClient {
    private readonly contactId: string | undefined;
    private readonly contactClient: ContactApiClient;

    constructor(contactId: string | undefined, contactClient: ContactApiClient) {
        this.contactId = contactId;
        this.contactClient = contactClient;
    }

    findSpecializations(): Promise<DiseaseSpecializationDto[]> {
        if (this.contactId) {
            return this.contactClient.findContactSpecializations(this.contactId);
        } else {
            return Promise.resolve([]);
        }
    }

    createSpecialization(diseaseId: string): Promise<string> {
        if (this.contactId) {
            return this.contactClient.createContactSpecialization(this.contactId, diseaseId);
        } else {
            return Promise.reject(new Error("Contact not initialized"));
        }
    }

    deleteSpecialization(specializationId: string): Promise<void> {
        if (this.contactId) {
            return this.contactClient.deleteContactSpecialization(this.contactId, specializationId);
        } else {
            return Promise.reject(new Error("Contact not initialized"));
        }

    }

}