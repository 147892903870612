import {Autocomplete, Checkbox, Icon} from "@mui/material";
import StyledMuiTextField from "./StyledMuiTextField";
import {Check} from "@mui/icons-material";
import {SyntheticEvent} from "react";

export interface SelectOption {
    value: unknown;
    label: string;
}

export interface SelectFieldProps {
    label: string;
    value?: unknown;
    onChange?: (value: unknown) => void;

    options: SelectOption[];

    required?: boolean;
    errorMessage?: string;
    helperText?: string;
    readOnly?: boolean;
    acceptFreeText?: boolean;
    limitTags?: number;
}

export default function SelectField(props: SelectFieldProps) {
    const {label, value, onChange, options, helperText} = props;
    const {required, errorMessage, readOnly, limitTags} = props;

    const optionValues = options.map(option => option.value);
    const optionToLabelMap = new Map(options.map(option => [option.value, option.label]));
    const acceptFreeText = props.acceptFreeText;

    function handleOnChange(event: SyntheticEvent, value: unknown) {
        if (acceptFreeText && value) {
            onChange?.(getOptionValueIfValueMatchesItsLabel(value, options));
        } else {
            onChange?.(value);
        }
    }

    function getOptionValueIfValueMatchesItsLabel(valueOrLabel: unknown, options: SelectOption[]): unknown {
        // fixes an issue in MUI Autocomplete when both freeSolo and autoSelect are true (in this case an Autocomplete
        // calls onChange with the label instead of the value on blur)
        const option = options.find(option => option.label === valueOrLabel);
        if (option) {
            return option.value;
        } else {
            return valueOrLabel;
        }
    }

    return (
        <Autocomplete multiple={false}
                      value={value ?? null}
                      freeSolo={props.acceptFreeText}
                      autoSelect={props.acceptFreeText}
                      onChange={handleOnChange}
                      options={optionValues}
                      getOptionLabel={(option => optionToLabelMap.get(option) ?? (acceptFreeText ? option as string : ""))}
                      readOnly={readOnly}
                      disableClearable={(required || readOnly)}
                      limitTags={limitTags}
                      size={"small"}
                      renderOption={(props, option, {selected}) => (
                          <li {...props}>
                              <Checkbox
                                  icon={<Icon fontSize="small"/>}
                                  checkedIcon={<Check color="primary" fontSize="small"/>}
                                  style={{marginRight: 8}}
                                  checked={selected}
                              />
                              {optionToLabelMap.get(option) ?? (acceptFreeText ? option as string : "")}
                          </li>
                      )}
                      renderInput={(params) =>
                          <StyledMuiTextField {...params}
                                              label={label}
                                              required={required}
                                              error={errorMessage !== undefined}
                                              helperText={errorMessage ? errorMessage : helperText}/>
                      }/>
    );
}
