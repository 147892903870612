import {Box, Checkbox, Grid, Link, Stack, Typography} from "@mui/material";
import TextField from "../../../components/field/TextField";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {RegistrationTranslKey} from "../RegistrationTranslKey";
import {ContactRegistrationRequestCreateDto} from "../../../client/registration/RegistrationApiClient";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import {ContactTranslKey} from "../../contact/ContactTranslKey";
import validateRegistrationFormModel, {ValidationResult} from "./RegistrationFormValidator";
import {RegistrationFormModel} from "./RegistrationFormModel";
import ButtonBar from "../../../components/button/ButtonBar";
import {SubmitButton} from "../../../common/button/CommonButtons";
import SelectField, {SelectOption} from "../../../components/field/SelectField";
import DiseaseSpecializationPanel from "./specialization/DiseaseSpecializationPanel";
import {DiseaseDto} from "../../../client/disease/DiseaseApiClient";
import {TopicDto} from "../../../client/topic/TopicApiClient";
import TopicPanel from "./topic/TopicPanel";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {CheckCircleOutline} from "@mui/icons-material";
import {ContactAffiliationRole, ContactAffiliationTitle} from "../../../client/affiliation/AffiliationApiClient";
import {ContactAffiliationTitleFormatter} from "../../affiliation/ContactAffiliationTitleFormatter";
import {ContactTitle} from "../../../client/contact/ContactApiClient";
import RegistrationFormHint from "./RegistrationFormHint";

const RITA_CONTACT_URL = "https://ern-rita.org/contact";

enum RegistrationFormState {
    IDLE,
    SUBMITTING,
    SUCCESSFULLY_SUBMITTED
}

const DEFAULT_REGISTRATION_FORM_MODEL: RegistrationFormModel = {
    title: {value: ""},
    firstName: {value: ""},
    lastName: {value: ""},
    email: {value: ""},
    expertise: {value: ""},
    primaryOrganizationId: {value: undefined},
    primaryOrganizationRole: {value: ContactAffiliationRole.ORGANIZATION_MEMBER},
    primaryOrganizationTitle: {value: ContactAffiliationTitle.MEMBER},
};

export default function RegistrationForm() {
    const {t} = useTranslation();
    const {registrationClient, organizationClient} = useClientLocator();
    const snackbar = useSnackbar();

    const [formState, setFormState] = useState<RegistrationFormState>(RegistrationFormState.IDLE);
    const [registrationModel, setRegistrationModel] = useState(DEFAULT_REGISTRATION_FORM_MODEL);
    const [diseases, setDiseases] = useState<DiseaseDto[]>([]);
    const [topics, setTopics] = useState<TopicDto[]>([]);
    const [termsAgreed, setTermsAgreed] = useState(false);

    const [organizationOptions, setOrganizationOptions] = useState<SelectOption[]>([]);

    useEffect(() => {
        organizationClient.findByFilter({})
                .then((organizations) => {
                    setOrganizationOptions(organizations.map((organization) => {
                        return {value: organization.id, label: organization.name};
                    }));
                });
    }, [organizationClient]);

    useEffect(() => {
        return () => {
            setTermsAgreed(false);
        }
    }, []);

    function save() {
        const result = validate();
        if (result.isValid) {
            setFormState(RegistrationFormState.SUBMITTING);
            const registrationCreate = convertToRegistrationCreate(registrationModel, diseases, topics);
            registrationClient.createRegistration(registrationCreate)
                    .then(() => {
                        snackbar.success(t(RegistrationTranslKey.REGISTRATION_SUCCESSFULLY_SENT));
                        setRegistrationModel(DEFAULT_REGISTRATION_FORM_MODEL);
                        setFormState(RegistrationFormState.SUCCESSFULLY_SUBMITTED);
                    })
                    .catch((error) => {
                        processRegistrationError(error, result.formModel);
                        setFormState(RegistrationFormState.IDLE);
                    });
        }
    }

    function validate(): ValidationResult {
        const result = validateRegistrationFormModel(registrationModel);
        setRegistrationModel(result.formModel);
        return result;
    }

    function convertToRegistrationCreate(model: RegistrationFormModel, diseases: DiseaseDto[], topics: TopicDto[]): ContactRegistrationRequestCreateDto {
        const [primaryOrganizationTitle, otherPrimaryOrganizationTitle] = ContactAffiliationTitleFormatter.decomposeTitleAndOtherTitle(model.primaryOrganizationTitle.value);

        return {
            firstName: model.firstName.value.trim(),
            lastName: model.lastName.value.trim(),
            title: model.title.value ? model.title.value.trim() : undefined,
            email: model.email.value.trim(),

            primaryOrganizationId: model.primaryOrganizationId.value!,
            expertise: model.expertise.value ? model.expertise.value.trim() : undefined,
            primaryOrganizationTitle: primaryOrganizationTitle,
            otherPrimaryOrganizationTitle: otherPrimaryOrganizationTitle? otherPrimaryOrganizationTitle.trim() : undefined,
            primaryOrganizationRole: model.primaryOrganizationRole.value,

            diseaseIds: diseases.map(disease => disease.id),
            topicIds: topics.map(topic => topic.id)
        };
    }

    function translateFromErrorCode(errorCode: string | undefined): string | undefined {
        return errorCode ? t(errorCode) : undefined;
    }

    function processRegistrationError(error: any, formModel: RegistrationFormModel) {
        if (error.response?.data?.code === "CONFLICT") {
            setRegistrationModel({
                ...formModel, email: {...formModel.email, errorMessageCode: ContactTranslKey.EMAIL_ALREADY_EXISTS}
            });
        } else if (error.response?.data?.code === "EMAIL_WRONG_FORMAT") {
            setRegistrationModel({
                ...formModel, email: {...formModel.email, errorMessageCode: CommonTranslKey.EMAIL_WRONG_FORMAT}
            });
        } else {
            snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
        }
    }

    const titleOptions = Object.keys(ContactAffiliationTitle)
            .filter((title) => title !== ContactAffiliationTitle.OTHER)
            .map((title) => ({
                value: title,
                label: ContactAffiliationTitleFormatter.translateTitle(title, t)
            }));

    const contactTitleOptions = Object.keys(ContactTitle)
            .map((title) => ({
                value: title,
                label: ContactTitle[title as keyof typeof ContactTitle]
            }));

    if (formState === RegistrationFormState.SUCCESSFULLY_SUBMITTED) {
        return (
                <Stack alignItems={"center"} spacing={1}>
                    <CheckCircleOutline color={"success"} sx={{fontSize: 60}}/>
                    <Typography variant={"body1"}>{t(RegistrationTranslKey.REGISTRATION_SUCCESSFULLY_SENT)}</Typography>
                </Stack>
        );
    }

    return (
        <>
            <Typography variant="h6" gutterBottom color="primary">
                {t(RegistrationTranslKey.REGISTRATION_FORM_INTRO_TEXT_1)}
            </Typography>
            <Typography>
                {t(RegistrationTranslKey.REGISTRATION_FORM_INTRO_TEXT_2)}
            </Typography>
            <Typography sx={{mt: 2}}>
                {t(RegistrationTranslKey.REGISTRATION_FORM_INTRO_TEXT_3)}<Link
                    href="/login">{t(RegistrationTranslKey.REGISTRATION_FORM_INTRO_TEXT_4)}</Link>
            </Typography>
            <Stack direction={"column"} spacing={2}>
                <Box>
                    <Grid container spacing={2} mt={0}>
                        <Grid item xs={12} sm={6}>
                            <TextField label={t(RegistrationTranslKey.FIRST_NAME)}
                                       required={true}
                                       value={registrationModel.firstName.value}
                                       onChange={(value) => setRegistrationModel({
                                           ...registrationModel,
                                           firstName: {...registrationModel.firstName, value: value}
                                       })}
                                       errorMessage={translateFromErrorCode(registrationModel.firstName.errorMessageCode)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label={t(RegistrationTranslKey.LAST_NAME)}
                                       required={true}
                                       value={registrationModel.lastName.value}
                                       onChange={(value) => setRegistrationModel({
                                           ...registrationModel,
                                           lastName: {...registrationModel.lastName, value: value}
                                       })}
                                       errorMessage={translateFromErrorCode(registrationModel.lastName.errorMessageCode)}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SelectField label={t(RegistrationTranslKey.TITLE)}
                                         value={registrationModel.title.value}
                                         options={contactTitleOptions}
                                         acceptFreeText={true}
                                         helperText={t(CommonTranslKey.SELECT_FROM_LIST_OR_TYPE_OWN)}
                                         onChange={(value) => setRegistrationModel({
                                             ...registrationModel,
                                             title: {...registrationModel.title, value: value as string}
                                         })}/>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField label={t(RegistrationTranslKey.EMAIL)}
                                       required={true}
                                       value={registrationModel.email.value}
                                       onChange={(value) => setRegistrationModel({
                                           ...registrationModel,
                                           email: {...registrationModel.email, value: value}
                                       })}
                                       errorMessage={translateFromErrorCode(registrationModel.email.errorMessageCode)}/>
                        </Grid>
                        <Grid item xs={12} mt={2} mb={1}>
                            <RegistrationFormHint
                                    message={<>
                                        {t(RegistrationTranslKey.CHOOSE_PRIMARY_ORGANIZATION_HINT_PART_1)}
                                        <Link href={RITA_CONTACT_URL} target="_blank" rel="noopener noreferrer">
                                            {t(RegistrationTranslKey.CHOOSE_PRIMARY_ORGANIZATION_HINT_PART_2)}
                                        </Link>.</>
                                    }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectField label={t(RegistrationTranslKey.ORGANIZATION)}
                                         value={registrationModel.primaryOrganizationId.value}
                                         options={organizationOptions}
                                         required={true}
                                         onChange={(value) => setRegistrationModel({
                                             ...registrationModel,
                                             primaryOrganizationId: {
                                                 ...registrationModel.primaryOrganizationId,
                                                 value: value as string
                                             }
                                         })}
                                         helperText={t(CommonTranslKey.SELECT_FROM_LIST)}
                                         errorMessage={translateFromErrorCode(registrationModel.primaryOrganizationId.errorMessageCode)}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SelectField label={t(RegistrationTranslKey.PRIMARY_ORGANIZATION_TITLE)}
                                         options={titleOptions}
                                         acceptFreeText={true}
                                         required={true}
                                         value={registrationModel.primaryOrganizationTitle.value}
                                         onChange={(value) => {
                                             setRegistrationModel({
                                                 ...registrationModel,
                                                 primaryOrganizationTitle: {
                                                     ...registrationModel.primaryOrganizationTitle,
                                                     value: value as ContactAffiliationTitle | string
                                                 }
                                             })
                                         }}
                                         helperText={t(CommonTranslKey.SELECT_FROM_LIST_OR_TYPE_OWN)}
                                         errorMessage={translateFromErrorCode(registrationModel.primaryOrganizationTitle.errorMessageCode)}
                            />
                        </Grid>
                        <Grid item xs={12} mt={2} mb={1}>
                            <RegistrationFormHint
                                    message={t(RegistrationTranslKey.FILL_IN_EXPERTISE_HINT)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={t(RegistrationTranslKey.EXPERTISE)}
                                       value={registrationModel.expertise.value}
                                       onChange={(value) => setRegistrationModel({
                                           ...registrationModel,
                                           expertise: {...registrationModel.expertise, value: value}
                                       })}
                                       errorMessage={translateFromErrorCode(registrationModel.expertise.errorMessageCode)}/>
                        </Grid>
                    </Grid>
                </Box>

                <DiseaseSpecializationPanel diseases={diseases}
                                            onChange={setDiseases}/>

                <TopicPanel topics={topics}
                            onChange={setTopics}/>

                <Box>
                    <Checkbox
                            checked={termsAgreed}
                            onChange={(event) => setTermsAgreed(event.target.checked)}
                            name="termsAgreed"
                            color="primary"
                            sx={{ padding: 0 }}
                    />
                    <Box component="label" htmlFor="termsAgreed" ml={1}>
                        {t(CommonTranslKey.TERMS_AND_CONDITIONS_AGREEMENT)}
                    </Box>
                </Box>

                <ButtonBar>
                    <SubmitButton variant={"primary"}
                                  onClick={save}
                                  disabled={!termsAgreed || formState === RegistrationFormState.SUBMITTING}
                                  inProgress={formState === RegistrationFormState.SUBMITTING}/>
                </ButtonBar>
            </Stack>
        </>
    );
}
