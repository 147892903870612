import {useClientLocator} from "../../client/ApiClientLocator";
import {OrganizationDto} from "../../client/organization/OrganizationApiClient";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import {Typography} from "@mui/material";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../common/button/CommonButtons";
import {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {OrganizationEventType} from "./OrganizationEventType";

enum DeleteDialogState {
    IDLE,
    DELETE_IN_PROGRESS,
}

export interface OrganizationDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    organization: OrganizationDto;
}

export default function OrganizationDeleteDialog(props: Readonly<OrganizationDeleteDialogProps>) {
    const {open, onClose, organization} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.IDLE);

    const {organizationClient} = useClientLocator();
    const snackbar = useSnackbar();
    const eventDispatcher = useEventDispatcher();

    const {t} = useTranslation();

    function deleteOrganization() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        organizationClient.invalidateOrganization(organization.id)
            .then(() => {
                snackbar.success(t(CommonTranslKey.DATA_DELETED));
                setDialogState(DeleteDialogState.IDLE);
                onClose(true);
                eventDispatcher.dispatchEvent(new Event(OrganizationEventType.ORGANIZATION_DELETED));
            })
            .catch((error) => {
                snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                setDialogState(DeleteDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(OrganizationTranslKey.DELETE_ORGANIZATION)}/>
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={OrganizationTranslKey.DELETE_ORGANIZATION_PROMPT} values={{organizationName: organization?.name}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteOrganization}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
