export enum AffiliationTranslKey {
    ADD_NEW_AFFILIATION = "affiliation:addNewAffiliation",
    UPDATE_AFFILIATION = "affiliation:updateAffiliation",
    CONTACT = "affiliation:contact",
    CONTACT_AFFILIATION_ROLE = "affiliation:contactAffiliationRole",
    CONTACT_AFFILIATION_ROLE_NAMESPACE = "affiliation:catalog:contactAffiliationRole:",
    CONTACT_AFFILIATION_ROLE_ORGANIZATION_ADMINISTRATOR = "affiliation:contactAffiliationRole.organizationAdministrator",
    CONTACT_AFFILIATION_ROLE_ORGANIZATION_MEMBER = "affiliation:contactAffiliationRole.organizationMember",
    CONTACT_AFFILIATION_TITLE = "affiliation:contactAffiliationTitle",
    CONTACT_AFFILIATION_TITLE_NAMESPACE = "affiliation:catalog:contactAffiliationTitle:",
    EXPERTISE = "affiliation:expertise",
    INVALIDATE_AFFILIATION = "affiliation:invalidateAffiliation",
    INVALIDATE_AFFILIATION_CONFIRMATION = "affiliation:invalidateAffiliationConfirmation",
    NO_AFFILIATIONS = "affiliation:noAffiliations",
    ORGANIZATION = "affiliation:organization",
    PRIMARY = "affiliation:primary",
    PRIMARY_ORGANIZATION_NAME = "affiliation:primaryOrganizationName",
    PRIMARY_ORGANIZATION_ROLE = "affiliation:primaryOrganizationRole",
    PRIMARY_ORGANIZATION_EXPERTISE = "affiliation:primaryOrganizationExpertise",
    PRIMARY_BADGE = "affiliation:primaryBadge",
    SECONDARY_BADGE = "affiliation:secondaryBadge",
}
