import {Axios} from "axios";

export interface TopicAclDto {
    canUpdate: boolean;
}

export interface TopicDto {
    id: string;
    name: string;
    description?: string;
    type?: string;
    typeGroup?: string;
    acl: TopicAclDto;
}

export interface TopicFilterDto {
    nameLike?: string;
    types?: string[];
}

export interface TopicTypeUpdateDto {
    type: string;
}

export enum TopicType {
    STEM_CELL_AND_GENE_THERAPY = "STEM_CELL_AND_GENE_THERAPY",
    TRANSITION_CARE = "TRANSITION_CARE",
    COMMUNICATIONS = "COMMUNICATIONS",
    RESEARCH = "RESEARCH",
    MOLECULAR_TESTING = "MOLECULAR_TESTING",
    REGISTRIES = "REGISTRIES",
    EDUCATION = "EDUCATION",
    PHARMACOVIGILANCE_BIOLOGICAL_THERAPY = "PHARMACOVIGILANCE_BIOLOGICAL_THERAPY",
    IT_E_HEALTH = "IT_E_HEALTH",
    PATIENT_PARTICIPATION = "PATIENT_PARTICIPATION",
    BOARD = "BOARD",
    STREAM_LEADS = "STREAM_LEADS",
    COORDINATION_TEAM = "COORDINATION_TEAM",
    HEADQUARTERS = "HEADQUARTERS",
}

export class TopicApiClient {

    constructor(private readonly axios: Axios) {
    }

    findById(topicId: string): Promise<TopicDto | undefined> {
        return new Promise((resolve, reject) => {
            this.axios.get<TopicDto | undefined>(`/topics/${topicId}`)
                .then((response) => resolve(response.data))
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        resolve(undefined);
                    }
                    reject(error);
                });
        });
    }

    findByFilter(filter: TopicFilterDto): Promise<TopicDto[]> {
        return new Promise((resolve, reject) => {
            this.axios.post<TopicDto[]>("/topics:search", filter)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }

    updateTopicType(topicId: string, update: TopicTypeUpdateDto): Promise<void> {
        return new Promise((resolve, reject) => {
            this.axios.put<void>(`/topics/${topicId}/type`, update)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
        });
    }
}