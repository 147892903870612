import {List, Stack, Typography} from "@mui/material";
import {DataArray} from "@mui/icons-material";
import {useState} from "react";
import {AddButton} from "../../../common/button/CommonButtons";
import ButtonBar from "../../../components/button/ButtonBar";
import {useTranslation} from "react-i18next";
import {UserDto, UserRoleDto} from "../../../client/user/UserApiClient";
import {UserTranslKey} from "../UserTranslKey";
import UserRoleListItem from "./UserRoleListItem";
import UserRoleInvalidateDialog from "./UserRoleInvalidateDialog";
import UserRoleCreateDialog from "./UserRoleCreateDialog";

export interface UserRolePanelProps {
    user?: UserDto;
    onUpdated?: () => void;
}

export default function UserRolePanel(props: Readonly<UserRolePanelProps>) {
    const {user, onUpdated} = props;

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [invalidateDialogOpen, setInvalidateDialogOpen] = useState(false);
    const [userRoleToInvalidate, setUserRoleToInvalidate] = useState<UserRoleDto>();

    const {t} = useTranslation();

    function showCreateDialog() {
        setCreateDialogOpen(true);
    }

    function onCreateDialogClosed(createdRelationId?: string) {
        setCreateDialogOpen(false);
        if (createdRelationId) {
            onUpdated?.();
        }
    }

    function showInvalidateDialog(userRole: UserRoleDto) {
        setInvalidateDialogOpen(true);
        setUserRoleToInvalidate(userRole);
    }

    function onInvalidateDialogClosed(invalidated: boolean) {
        setInvalidateDialogOpen(false);
        if (invalidated) {
            onUpdated?.();
        }
    }

    if (!user) {
        return null;
    }

    const validUserRoles = user.activeRoles ?? [];

    let content: React.ReactNode;
    if (validUserRoles.length > 0) {
        content = (
            <List component={"nav"}>
                {validUserRoles.map(
                    (userRole) =>
                        <UserRoleListItem userRole={userRole}
                                          key={userRole.id}
                                          disabled={!userRole.acl.canInvalidate}
                                          onInvalidateClick={(userRole) => showInvalidateDialog(userRole)}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <DataArray/>
                <Typography variant={"body2"}>{t(UserTranslKey.NO_ROLES)}</Typography>
            </Stack>
        );
    }
    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!user?.acl?.canCreateUserRoles} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>

            <UserRoleCreateDialog open={createDialogOpen}
                                  onClose={onCreateDialogClosed}
                                  user={user} />

            <UserRoleInvalidateDialog open={invalidateDialogOpen}
                                      onClose={(invalidated: boolean) => onInvalidateDialogClosed(invalidated)}
                                      userRole={userRoleToInvalidate}
                                      user={user} />
        </>
    );
}
