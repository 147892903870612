import {Tab as MuiTab, TabProps as MuiTabProps} from "@mui/material";

export interface TabProps extends MuiTabProps {
}

export default function Tab(props: TabProps) {
    const {children, ...rest} = props;

    return (
        <MuiTab {...rest}>
            {children}
        </MuiTab>
    );
}