import {Box} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import TabPanel from "../../components/tab/TabPanel";
import Tab from "../../components/tab/Tab";
import Tabs from "../../components/tab/Tabs";
import Dialog from "../../components/dialog/Dialog";
import DialogContent from "../../components/dialog/DialogContent";
import DialogTitle from "../../components/dialog/DialogTitle";
import {OrganizationDto, OrganizationType} from "../../client/organization/OrganizationApiClient";
import OrganizationBasicDetailPanel from "./OrganizationBasicDetailPanel";
import OrganizationBasicDetailPanelSkeleton from "./OrganizationBasicDetailPanelSkeleton";
import {useTranslation} from "react-i18next";
import {OrganizationTranslKey} from "./OrganizationTranslKey";
import OrganizationAffiliationPanel from "./affiliation/OrganizationAffiliationPanel";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import DiseaseSpecializationPanel, {
    DiseaseSpecializationClient
} from "../disease/specialization/DiseaseSpecializationPanel";
import {OrganizationSpecializationClientAdapter} from "./specialization/OrganizationSpecializationClientAdapter";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {OrganizationEventType} from "./OrganizationEventType";
import OrganizationCpmsStatisticsPanel from "./cpms-statistics/OrganizationCpmsStatisticsPanel";

enum TabKey {
    BASIC = "basic",
    AFFILIATIONS = "affiliations",
    SPECIALIZATIONS = "specializations",
    CPMS_STATISTICS = "cpms_statistics"
}

export interface OrganizationDetailDialogProps {
    open: boolean;
    onClose: (updated: boolean) => void;
    organizationId: string | undefined;
}

export default function OrganizationDetailDialog(props: Readonly<OrganizationDetailDialogProps>) {
    const {open, onClose, organizationId} = props;

    const {organizationClient} = useClientLocator();
    const snackbar = useSnackbar();

    const [organization, setOrganization] = useState<OrganizationDto>();
    const [selectedTab, setSelectedTab] = useState(TabKey.BASIC);
    const [refresh, setRefresh] = useState(Date.now());
    const [updated, setUpdated] = useState(false);

    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();

    const specializationClient: DiseaseSpecializationClient = useMemo(
        () => new OrganizationSpecializationClientAdapter(organization?.id, organizationClient),
        [organizationClient, organization]);

    useEffect(() => {
        if (open && organizationId) {
            organizationClient.findById(organizationId)
                .then((organization) => {
                    setOrganization(organization);
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        }
        if (!open) {
            setOrganization(undefined);
            setUpdated(false);
            setSelectedTab(TabKey.BASIC);
        }
    }, [open, organizationId, organizationClient, snackbar, t, refresh]);

    function onUpdated() {
        setRefresh(Date.now());
        setUpdated(true);
        eventDispatcher.dispatchEvent(new Event(OrganizationEventType.ORGANIZATION_UPDATED));
    }

    let content: React.JSX.Element;
    if (organization) {
        content = (
            <OrganizationBasicDetailPanel organization={organization} onUpdate={onUpdated} />
        );
    } else {
        content = (
            <OrganizationBasicDetailPanelSkeleton />
        );
    }

    function isHcp(organization: OrganizationDto | undefined): boolean {
        return organization?.typeDetail?.organizationType === OrganizationType.HEALTHCARE_PROVIDER;
    }

    return (
        <Dialog open={open} onClose={() => onClose(updated)} maxWidth={"lg"}>
            <DialogTitle title={t(OrganizationTranslKey.ORGANIZATION)} onClose={() => onClose(updated)} />
            <DialogContent>
                <Tabs value={selectedTab}
                      onChange={(e, tab) => setSelectedTab(tab)}
                      variant={"scrollable"}>
                    <Tab label={t(CommonTranslKey.DETAILS)} value={TabKey.BASIC} />
                    <Tab label={t(OrganizationTranslKey.AFFILIATIONS)} value={TabKey.AFFILIATIONS} disabled={organization === undefined} />
                    <Tab label={t(OrganizationTranslKey.SPECIALIZATIONS)} value={TabKey.SPECIALIZATIONS} disabled={organization === undefined} />
                    {isHcp(organization) &&
                        <Tab label={t(OrganizationTranslKey.CPMS_STATISTICS)} value={TabKey.CPMS_STATISTICS} disabled={organization === undefined}/>
                    }
                </Tabs>
                <TabPanel value={TabKey.BASIC} selectedValue={selectedTab}>
                    <Box sx={{mt: 1}}>
                        {content}
                    </Box>
                </TabPanel>
                <TabPanel value={TabKey.AFFILIATIONS} selectedValue={selectedTab}>
                    <OrganizationAffiliationPanel organization={organization} onUpdated={onUpdated} />
                </TabPanel>
                <TabPanel value={TabKey.SPECIALIZATIONS} selectedValue={selectedTab}>
                    <DiseaseSpecializationPanel canUpdate={organization?.acl.canUpdate ?? false}
                                                onUpdated={onUpdated}
                                                specializationClient={specializationClient}/>
                </TabPanel>
                <TabPanel value={TabKey.CPMS_STATISTICS} selectedValue={selectedTab}>
                    {organization && <OrganizationCpmsStatisticsPanel organizationId={organization.id}/>}
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
}
