import {useEffect, useState} from "react";
import {Grid, IconButton} from "@mui/material";
import {DiseaseDto} from "../../../client/disease/DiseaseApiClient";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../common/button/CommonButtons";
import TextField from "../../../components/field/TextField";
import {Clear, MoreHoriz} from "@mui/icons-material";
import DiseaseSearchDialog from "../../disease/search/DiseaseSearchDialog";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {DiseaseSpecializationTranslKey} from "./DiseaseSpecializationTranslKey";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

export interface DiseaseSpecializationCreateClient {
    createSpecialization(diseaseId: string): Promise<string>;
}

export interface DiseaseSpecializationCreateDialogProps {
    open: boolean;
    onClose: (createdSpecializationId?: string) => void;
    specializationClient: DiseaseSpecializationCreateClient;
}

export default function DiseaseSpecializationCreateDialog(props: Readonly<DiseaseSpecializationCreateDialogProps>) {
    const {open, onClose, specializationClient} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [disease, setDisease] = useState<DiseaseDto>();
    const [diseaseErrorMessage, setDiseaseErrorMessage] = useState<string>();

    const [diseaseSearchDialogOpen, setDiseaseSearchDialogOpen] = useState(false);

    const snackbar = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open) {
            setDisease(undefined);
            setDialogState(CreateDialogState.IDLE);
            setDiseaseErrorMessage(undefined);
        }
    }, [open]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            specializationClient.createSpecialization(disease!.id)
                .then((specializationId) => {
                    snackbar.success(t(CommonTranslKey.DATA_SAVED));
                    setDiseaseErrorMessage(undefined);
                    onClose(specializationId);
                })
                .catch((error) => {
                    if (error.response?.data?.code === "CONFLICT") {
                        snackbar.warning(t(CommonTranslKey.CONTAINS_VALIDATION_ERRORS));
                        setDiseaseErrorMessage(t(DiseaseSpecializationTranslKey.DISEASE_SPECIALIZATION_ALREADY_EXISTS));
                    } else {
                        setDiseaseErrorMessage(undefined);
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                    }
                    setDialogState(CreateDialogState.IDLE);
                });
        }
    }

    function validate(): boolean {
        return (disease !== undefined);
    }

    function clearDisease() {
        setDisease(undefined);
    }

    function showDiseaseSearchDialog() {
        setDiseaseSearchDialogOpen(true);
    }

    function onDiseaseSearchDialogClosed(disease?: DiseaseDto) {
        setDiseaseSearchDialogOpen(false);
        if (disease) {
            setDiseaseErrorMessage(undefined);
            setDisease(disease);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={t(DiseaseSpecializationTranslKey.ADD_NEW_DISEASE_SPECIALIZATION)}/>
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12}>
                            <TextField label={t(DiseaseSpecializationTranslKey.DISEASE_SPECIALIZATION)}
                                       value={disease?.name ?? ""}
                                       readOnly={true}
                                       required={true}
                                       errorMessage={diseaseErrorMessage}
                                       endAdornment={(
                                           <>
                                                {disease && <IconButton onClick={clearDisease}><Clear/></IconButton>}
                                                <IconButton onClick={showDiseaseSearchDialog}><MoreHoriz/></IconButton>
                                           </>
                                       )} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!validate()}
                                    inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <DiseaseSearchDialog open={diseaseSearchDialogOpen} onClose={onDiseaseSearchDialogClosed}/>
        </>
    );
}
