export enum OrganizationTranslKey {
    ABBREVIATION = "organization:abbreviation",
    ADDRESS = "organization:address",
    AFFILIATED_SINCE = "organization:affiliatedSince",
    AFFILIATED_UNTIL = "organization:affiliatedUntil",
    AFFILIATION = "organization:affiliation",
    AFFILIATIONS = "organization:affiliations",
    CITY = "organization:city",
    COUNTRY = "organization:country",
    CREATE_NEW_ORGANIZATION = "organization:createNewOrganization",
    DELETE_ORGANIZATION = "organization:deleteOrganization",
    DELETE_ORGANIZATION_PROMPT = "organization:deleteOrganizationPrompt",
    DESCRIPTION = "organization:description",
    DISEASE = "organization:disease",
    ERN = "organization:ern",
    ERN_NAMESPACE = "organization:catalog:ern:",
    GPS = "organization:gps",
    HEALTHCARE_PROVIDER_AFFILIATION_NAMESPACE = "organization:catalog:healthcareProviderAffiliation:",
    INTERNATIONAL = "organization:international",
    MERITA_MEMBER = "organization:meritaMember",
    NAME = "organization:name",
    NAME_OR_ABBREVIATION = "organization:nameOrAbbreviation",
    ORGANIZATION = "organization:organization",
    ORGANIZATION_TYPE = "organization:organizationType",
    ORGANIZATION_TYPE_NAMESPACE = "organization:catalog:organizationType:",
    ORGANIZATIONS = "organization:organizations",
    PANEL_LEAD_COUNT = "organization:panelLeadCount",
    PANEL_MEMBER_COUNT = "organization:panelMemberCount",
    REFERRED_PHYSICIAN_COUNT = "organization:referredPhysicianCount",
    RIPAG_MEMBER = "organization:ripagMember",
    RITA_AFFILIATION = "organization:ritaAffiliation",
    CPMS_ROLES = "organization:roles",
    SEARCH_ORGANIZATION = "organization:searchOrganization",
    SPECIALIZATIONS = "organization:specializations",
    STATUSES = "organization:statuses",
    CPMS_STATISTICS = "organization:cpmsStatistics",
    UNIT = "organization:unit",
    UNITS = "organization:units",
    UPDATE_ORGANIZATION = "organization:updateOrganization",
    WEBSITE = "organization:website",
}
