import {Unit} from "../../client/contact/ContactApiClient";

const UNIT_LABEL_DICT = new Map<Unit, string>([
    [Unit.ADULT, "Adult"],
    [Unit.PAEDIATRIC, "Paediatric"],
]);

export function formatUnit(unit: Unit): string {
    const label = UNIT_LABEL_DICT.get(unit);
    return label ?? unit;
}

export function formatUnits(units: Unit[] | undefined): string | null {
    if (!units || units.length === 0) {
        return null;
    }
    return units.map(formatUnit).sort().join(", ");
}