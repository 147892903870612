import {OrganizationDto} from "../../../client/organization/OrganizationApiClient";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SelectButton} from "../../../common/button/CommonButtons";
import OrganizationSearchPanel from "./OrganizationSearchPanel";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {OrganizationTranslKey} from "../OrganizationTranslKey";


export interface OrganizationSearchDialogProps {
    open: boolean;
    onClose: (selectedOrganization?: OrganizationDto) => void;
}

export default function OrganizationSearchDialog(props: Readonly<OrganizationSearchDialogProps>) {
    const {open, onClose} = props;

    const [selected, setSelected] = useState<OrganizationDto>();
    const {t} = useTranslation();

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"lg"}>
            <DialogTitle title={t(OrganizationTranslKey.SEARCH_ORGANIZATION)} onClose={() => onClose(undefined)} />
            <DialogContent>
                <OrganizationSearchPanel onSelected={setSelected} />
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)} />
                    <SelectButton variant={"primary"}
                                  disabled={selected === undefined}
                                  onClick={() => onClose(selected)} />
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}