import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {CopyUtils} from "../../../util/CopyUtils";
import {UserFilterDto} from "../../../client/user/UserApiClient";

export interface UserFilterFormProps {
    filter: UserFilterDto;
    onChange: (filter: UserFilterDto) => void;
}

export function UserFilterForm(props: UserFilterFormProps) {
    const {filter, onChange} = props;

    function onLoginChange(login: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.loginLike = normalizeString(login);
        onChange(updatedFilter);
    }

    function onFirstNameChange(firstName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.firstNameLike = normalizeString(firstName);
        onChange(updatedFilter);
    }

    function onLastNameChange(lastName: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.lastNameLike = normalizeString(lastName);
        onChange(updatedFilter);
    }

    function onEmailChange(email: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.emailLike = normalizeString(email);
        onChange(updatedFilter);
    }

    function normalizeString(value: string): string | undefined {
        const normalizedValue = value.trim();
        return (normalizedValue.length > 0) ? normalizedValue : undefined;
    }

    return (
        <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={"Login"}
                           value={filter.loginLike ?? ""}
                           onChange={onLoginChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={"E-mail"}
                           value={filter.emailLike ?? ""}
                           onChange={onEmailChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={"First Name"}
                           value={filter.firstNameLike ?? ""}
                           onChange={onFirstNameChange} />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <TextField label={"Last Name"}
                           value={filter.lastNameLike ?? ""}
                           onChange={onLastNameChange} />
            </Grid>

        </Grid>
    );
}

