export enum TopicTranslKey {
    DESCRIPTION = "topic:description",
    GROUP = "topic:group",
    NAME = "topic:name",
    SEARCH_TOPIC = "topic:searchTopic",
    TOPIC = "topic:topic",
    TOPICS = "topic:topics",
    TOPIC_GROUP_NAMESPACE = "topic:catalog:topicGroup:",
    TOPIC_TYPE_NAMESPACE = "topic:catalog:topicType:",
    TYPE = "topic:type",
    UPDATE_TOPIC = "topic:updateTopic",
}