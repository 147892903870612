import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {Delete, Shield} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {UserRoleDto} from "../../../client/user/UserApiClient";
import {UserRoleFormatter} from "./UserRoleFormatter";
import {UserTranslKey} from "../UserTranslKey";
import {DateTimeFormatter} from "../../../common/formatter/DateTimeFormatter";


export interface UserRoleListItemProps {
    userRole: UserRoleDto;
    disabled?: boolean;
    onInvalidateClick: (userRole: UserRoleDto) => void;
}

export default function UserRoleListItem(props: Readonly<UserRoleListItemProps>) {
    const {userRole, disabled, onInvalidateClick} = props;
    const {t} = useTranslation();

    function formatSecondaryText(userRole: UserRoleDto): string {
        return `${t(UserTranslKey.FROM)}: ${DateTimeFormatter.formatDateTime(userRole.validFrom)}`;
    }

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    <Shield />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={UserRoleFormatter.formatUserRole(userRole, t)} secondary={formatSecondaryText(userRole)} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onInvalidateClick(userRole)}>
                        <Delete />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}