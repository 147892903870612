import AppMenu from "../AppMenu";
import {Outlet} from "react-router-dom";
import {useEffect, useState} from "react";
import CatalogProvider from "../catalog/CatalogProvider";
import UserContext from "./user/UserContext";
import {useClientLocator} from "../client/ApiClientLocator";
import {UserDto} from "../client/user/UserApiClient";
import {useAuth} from "../auth/AuthContext";


export interface ContentProps {
}

export default function Content() {
    const {userClient} = useClientLocator();

    const [user, setUser] = useState<UserDto>();

    const { authClient, initialized} = useAuth();

    useEffect(() => {
        if (initialized && authClient?.authenticated) {
            userClient.findMe()
                .then((user) => {
                    setUser(user);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, [userClient, authClient, initialized]);

    if (!initialized || !authClient?.authenticated) {
        return null;
    }

    return (
        <CatalogProvider>
            <UserContext.Provider value={user}>
                <AppMenu />
                <Outlet />
            </UserContext.Provider>
        </CatalogProvider>
    );
}