import ActionButton, {ActionButtonProps} from "../../components/button/ActionButton";
import {Add, Delete, Description} from "@mui/icons-material";

type CommonActionButtonProps = Omit<ActionButtonProps, "tooltip" | "icon">;

export function AddActionButton(props: CommonActionButtonProps) {
    return (
        <ActionButton tooltip={"Add"} icon={<Add/>} {...props}></ActionButton>
    );
}

export function DeleteActionButton(props: CommonActionButtonProps) {
    return (
        <ActionButton tooltip={"Delete"} icon={<Delete/>} {...props}></ActionButton>
    );
}

export function DetailActionButton(props: CommonActionButtonProps) {
    return (
        <ActionButton tooltip={"Show detail"} icon={<Description/>} {...props}></ActionButton>
    );
}
