import {DiseaseDto} from "../../client/disease/DiseaseApiClient";
import {Grid} from "@mui/material";
import TextField from "../../components/field/TextField";
import ButtonBar from "../../components/button/ButtonBar";
import {EditButton} from "../../common/button/CommonButtons";
import React, {useState} from "react";
import DiseaseUpdateDialog from "./DiseaseUpdateDialog";
import {formatHierarchyLevel} from "./DiseaseHierarchyLevelFormatter";
import {DiseaseTranslKey} from "./DiseaseTranslKey";
import {useTranslation} from "react-i18next";

export interface DiseaseDetailPanelProps {
    disease: DiseaseDto;
    onUpdate?: () => void;
    hideActions?: boolean;
}

export default function DiseaseBasicDetailPanel(props: DiseaseDetailPanelProps) {
    const {disease, onUpdate, hideActions} = props;

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const {t} = useTranslation();

    function showUpdateDialog() {
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClosed(updated: boolean) {
        setUpdateDialogOpen(false);
        if (updated) {
            onUpdate?.();
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                    <TextField label={t(DiseaseTranslKey.NAME)}
                               value={disease.name}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label={t(DiseaseTranslKey.ABBREVIATION)}
                               value={disease.abbreviation}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={t(DiseaseTranslKey.ORPHACODE)}
                               value={disease.orphacode}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label={t(DiseaseTranslKey.LEVEL)}
                               value={formatHierarchyLevel(disease.hierarchyLevel)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12}>
                    <TextField label={t(DiseaseTranslKey.DESCRIPTION)}
                               value={disease.description}
                               readOnly={true}
                               multiline={true}
                               minRows={3}
                               maxRows={7} />
                </Grid>
                {!hideActions &&
                    <Grid item xs={12}>
                        <ButtonBar>
                            <EditButton disabled={!disease.acl.canUpdate} onClick={showUpdateDialog} />
                        </ButtonBar>
                    </Grid>
                }
            </Grid>

            <DiseaseUpdateDialog open={updateDialogOpen}
                                 onClose={onUpdateDialogClosed}
                                 disease={disease} />
        </>
    );
}
