import {CopyUtils} from "../../../util/CopyUtils";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import {RegistrationFormModel} from "./RegistrationFormModel";
import {EmailValidator} from "../../../common/validator/EmailValidator";

export interface ValidationResult {
    isValid: boolean;
    formModel: RegistrationFormModel;
}

export default function validateRegistrationFormModel(formModel: RegistrationFormModel): ValidationResult {
    const validatedFormModel = CopyUtils.deepCopy(formModel);
    let isValid = true;

    if (formModel.firstName.value.trim().length === 0) {
        isValid = false;
        validatedFormModel.firstName.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    } else {
        validatedFormModel.firstName.errorMessageCode = undefined;
    }

    if (formModel.lastName.value.trim().length === 0) {
        isValid = false;
        validatedFormModel.lastName.errorMessageCode = CommonTranslKey.VALUE_MUST_NOT_BE_EMPTY_OR_BLANK;
    } else {
        validatedFormModel.lastName.errorMessageCode = undefined;
    }

    if (!EmailValidator.validate(formModel.email.value)) {
        isValid = false;
        validatedFormModel.email.errorMessageCode = CommonTranslKey.EMAIL_WRONG_FORMAT;
    } else {
        validatedFormModel.email.errorMessageCode = undefined;
    }

    if (formModel.primaryOrganizationId.value === undefined) {
        isValid = false;
        validatedFormModel.primaryOrganizationId.errorMessageCode = CommonTranslKey.ITEM_MUST_BE_SELECTED;
    } else {
        validatedFormModel.primaryOrganizationId.errorMessageCode = undefined;
    }

    if (!formModel.primaryOrganizationTitle.value) {
        isValid = false;
        validatedFormModel.primaryOrganizationTitle.errorMessageCode = CommonTranslKey.ITEM_MUST_BE_SELECTED;
    } else {
        validatedFormModel.primaryOrganizationTitle.errorMessageCode = undefined;
    }

    if (!formModel.primaryOrganizationRole.value) {
        isValid = false;
        validatedFormModel.primaryOrganizationRole.errorMessageCode = CommonTranslKey.ITEM_MUST_BE_SELECTED;
    } else {
        validatedFormModel.primaryOrganizationRole.errorMessageCode = undefined;
    }

    return {
        isValid: isValid,
        formModel: validatedFormModel
    };
}
