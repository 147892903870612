import {Autocomplete, Checkbox, Icon} from "@mui/material";
import StyledMuiTextField from "./StyledMuiTextField";
import {Check} from "@mui/icons-material";

export interface FreetextSelectFieldProps {
    label: string;
    value?: unknown;
    onChange?: (value: unknown) => void;

    options: string[];

    required?: boolean;
    errorMessage?: string;
    readOnly?: boolean;
    limitTags?: number;
}

export default function FreetextSelectField(props: Readonly<FreetextSelectFieldProps>) {
    const {label, value, onChange, options} = props;
    const {required, errorMessage, readOnly, limitTags} = props;

    return (
        <Autocomplete multiple={false}
                      value={value ?? null}
                      freeSolo={true}
                      autoSelect={true}
                      forcePopupIcon
                      onChange={(event, value) => onChange?.(value)}
                      options={options}
                      getOptionLabel={(option => option as string ?? "")}
                      readOnly={readOnly}
                      disableClearable={(required ?? readOnly)}
                      limitTags={limitTags}
                      size={"small"}
                      renderOption={(props, option, {selected}) => (
                          <li {...props}>
                              <Checkbox
                                  icon={<Icon fontSize="small"/>}
                                  checkedIcon={<Check color="primary" fontSize="small"/>}
                                  style={{marginRight: 8}}
                                  checked={selected}
                              />
                              {option as string ?? ""}
                          </li>
                      )}
                      renderInput={(params) =>
                          <StyledMuiTextField {...params}
                                              label={label}
                                              required={required}
                                              error={errorMessage !== undefined}
                                              helperText={errorMessage}
                                              onChange={(event) => onChange?.(event.target.value)}
                          />
                      }/>
    );
}
