import {useContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../common/button/CommonButtons";
import TextField from "../../components/field/TextField";
import SelectField, {SelectOption} from "../../components/field/SelectField";
import {
    ContactBriefDto,
    ContactCreateDto,
    ContactDto,
    ContactSummaryDto, ContactTitle,
    Unit
} from "../../client/contact/ContactApiClient";
import CountryContext from "../../catalog/CountryContext";
import {formatUnit} from "./UnitFormatter";
import MultiSelectField from "../../components/field/MultiSelectField";
import {ContactEventType} from "./ContactEventType";
import {useEventDispatcher} from "../../components/EventDispatcher";
import {useTranslation} from "react-i18next";
import {ContactTranslKey} from "./ContactTranslKey";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import TextFieldWithMore from "../../components/field/TextFieldWithMore";
import {ContactFormatter} from "./ContactFormatter";
import ContactSearchDialog from "./search/ContactSearchDialog";
import Checkbox from "../../components/field/Checkbox";
import {CountryFormatter} from "../country/CountryFormatter";

enum UpdateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

interface ContactCreateModel extends Omit<ContactDto, "id" | "hasCpms" | "acl" | "country" | "standIn"> {
    countryId?: number;
    standIn?: ContactDto | ContactSummaryDto | ContactBriefDto | null;
}

const DEFAULT_CONTACT_CREATE_MODEL: ContactCreateModel = {
    firstName: "",
    lastName: "",
    email: "",
    title: "",
    phone: "",
    note: "",
    survey: false,
    cpg: false,
    cpgJia: false,
    standIn: undefined,
    webinars: [],
    countryId: undefined,
    units: []
};

export interface ContactUpdateDialogProps {
    open: boolean;
    onClose: (updatedContactId: string | undefined) => void;
    contact?: ContactDto;
}

export default function ContactUpdateDialog(props: ContactUpdateDialogProps) {
    const {open, onClose, contact} = props;

    const [dialogState, setDialogState] = useState(UpdateDialogState.IDLE);
    const [model, setModel] = useState(DEFAULT_CONTACT_CREATE_MODEL);
    const [contactEmailErrorMessage, setContactEmailErrorMessage] = useState<string>();
    const [contactSearchDialogOpen, setContactSearchDialogOpen] = useState(false);

    const countries = useContext(CountryContext);

    const {contactClient} = useClientLocator();
    const snackbar = useSnackbar();
    const {t} = useTranslation();
    const eventDispatcher = useEventDispatcher();

    useEffect(() => {
        setContactEmailErrorMessage(undefined);

        if (!open) {
            setModel(DEFAULT_CONTACT_CREATE_MODEL);
            setDialogState(UpdateDialogState.IDLE);
        } else if (contact) {
            setModel({
                ...contact,
                countryId: contact.country ? contact.country.id : CountryFormatter.COUNTRY_INTERNATIONAL_ID,
                standIn: contact.standIn
            });
        }
    }, [open, contact]);

    function save() {
        setDialogState(UpdateDialogState.SAVE_IN_PROGRESS);

        if (validate()) {
            const create = normalize(model);
            setContactEmailErrorMessage(undefined);

            if (contact) {
                contactClient.updateContact(contact.id, create)
                    .then(() => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(contact.id);
                    })
                    .catch((error) => {
                        processCreateOrUpdateContactError(error);
                    });
            } else {
                contactClient.createContact(create)
                    .then((contactId) => {
                        snackbar.success(t(CommonTranslKey.DATA_SAVED));
                        onClose(contactId);
                        eventDispatcher.dispatchEvent(new Event(ContactEventType.CONTACT_CREATED));
                    })
                    .catch((error) => {
                        processCreateOrUpdateContactError(error);
                    });
            }
        }

        function processCreateOrUpdateContactError(error: any) {
            if (error.response?.data?.code === "EMAIL_CONFLICT") {
                snackbar.warning(t(CommonTranslKey.CONTAINS_VALIDATION_ERRORS));
                setContactEmailErrorMessage(t(ContactTranslKey.EMAIL_ALREADY_EXISTS));
            } else if (error.response?.data?.code === "EMAIL_WRONG_FORMAT") {
                snackbar.warning(t(CommonTranslKey.CONTAINS_VALIDATION_ERRORS));
                setContactEmailErrorMessage(t(ContactTranslKey.EMAIl_WRONG_FORMAT));
            } else {
                setContactEmailErrorMessage(undefined);
                snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
            }
            setDialogState(UpdateDialogState.IDLE);
        }
    }

    function validate(): boolean {
        let isValid = true;
        if (model.firstName.trim().length === 0) {
            isValid = false;
        }
        if (model.lastName.trim().length === 0) {
            isValid = false;
        }
        if (model.email.trim().length === 0) {
            isValid = false;
        }
        if (model.countryId === undefined) {
            return false;
        }

        return isValid;
    }

    function normalize(model: ContactCreateModel): ContactCreateDto {
        return {
            firstName: model.firstName.trim(),
            lastName: model.lastName.trim(),
            email: model.email.trim(),
            title: model.title ? model.title.trim() : undefined,
            phone: model.phone ? model.phone.trim() : undefined,
            note: model.note ? model.note.trim() : undefined,
            survey: model.survey,
            cpg: model.cpg,
            cpgJia: model.cpgJia,
            standInId: model.standIn?.id,
            webinars: model.webinars,
            countryId: model.countryId === CountryFormatter.COUNTRY_INTERNATIONAL_ID ? null : model.countryId,
            units: model.units
        };
    }

    function onContactSearchDialogChange(contact?: ContactSummaryDto) {
        setContactSearchDialogOpen(false);

        setModel({...model, standIn: contact});
    }


    const countryOptions: SelectOption[] = [
        {value: CountryFormatter.COUNTRY_INTERNATIONAL_ID, label: CountryFormatter.COUNTRY_INTERNATIONAL_LABEL},
        ...countries.map((country) => ({
            value: country.id,
            label: `${country.name} [${country.code}]`
        }))
    ];

    const unitOptions = Object.keys(Unit).map((unit) => ({
        value: unit as Unit,
        label: formatUnit(unit as Unit)
    }));

    const webinarOptions = [
        {value: "2002", label: "2002"},
        {value: "2003", label: "2003"},
    ];

    const contactTitleOptions = Object.keys(ContactTitle)
            .map((title) => ({
                value: title,
                label: ContactTitle[title as keyof typeof ContactTitle]
            }));

    const dialogTitle = contact ? t(ContactTranslKey.UPDATE_CONTACT) : t(ContactTranslKey.CREATE_NEW_CONTACT);

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={dialogTitle} />
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12} sm={6} lg={5}>
                            <TextField label={t(ContactTranslKey.FIRST_NAME)}
                                       required={true}
                                       value={model.firstName}
                                       onChange={(value) => setModel({...model, firstName: value})} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={5}>
                            <TextField label={t(ContactTranslKey.LAST_NAME)}
                                       value={model.lastName}
                                       required={true}
                                       onChange={(value) => setModel({...model, lastName: value})} />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                            <SelectField label={t(ContactTranslKey.TITLE)}
                                         value={model.title}
                                         options={contactTitleOptions}
                                         acceptFreeText={true}
                                         onChange={(value) => setModel({...model, title: value as string})}/>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <TextField label={t(ContactTranslKey.EMAIL)}
                                       value={model.email}
                                       required={true}
                                       errorMessage={contactEmailErrorMessage}
                                       onChange={(value) => setModel({...model, email: value})} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField label={t(ContactTranslKey.PHONE)}
                                       value={model.phone}
                                       onChange={(value) => setModel({...model, phone: value})} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SelectField label={t(ContactTranslKey.COUNTRY)}
                                         required={true}
                                         value={model.countryId}
                                         onChange={(value) => setModel({
                                             ...model,
                                             countryId: value ? Number(value) : undefined
                                         })}
                                         options={countryOptions}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextFieldWithMore label={t(ContactTranslKey.STAND_IN)}
                                               value={ContactFormatter.formatContactName(model.standIn)}
                                               onClear={() => onContactSearchDialogChange(undefined)}
                                               onMoreClick={() => setContactSearchDialogOpen(true)}
                                               required={true} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MultiSelectField label={t(ContactTranslKey.UNITS)}
                                              value={model.units}
                                              onChange={(value) => setModel({...model, units: value as Unit[]})}
                                              options={unitOptions} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <MultiSelectField label={t(ContactTranslKey.WEBINARS)}
                                              value={model.webinars}
                                              onChange={(value) => setModel({...model, webinars: value as string[]})}
                                              options={webinarOptions}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={t(ContactTranslKey.NOTE)}
                                       value={model.note}
                                       multiline={true}
                                       minRows={3}
                                       maxRows={7}
                                       onChange={(value) => setModel({...model, note: value})}/>
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Checkbox label={t(ContactTranslKey.SURVEY)}
                                      checked={model.survey}
                                      onChange={(value) => setModel({...model, survey: value})} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Checkbox label={t(ContactTranslKey.CPG)}
                                      checked={model.cpg}
                                      onChange={(value) => setModel({...model, cpg: value})} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <Checkbox label={t(ContactTranslKey.CPG_JIA)}
                                      checked={model.cpgJia}
                                      onChange={(value) => setModel({...model, cpgJia: value})} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!model || !validate()}
                                    inProgress={dialogState === UpdateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <ContactSearchDialog open={contactSearchDialogOpen} onClose={onContactSearchDialogChange} />
        </>
    );
}
