import {Grid, Skeleton} from "@mui/material";

export default function DiseaseBasicDetailPanelSkeleton() {
    return (
        <Grid container spacing={2} mt={-1}>
            <Grid item sm={12} md={12}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item sm={12} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item sm={12} md={6}>
                <Skeleton variant={"rounded"} height={40} />
            </Grid>
            <Grid item xs={12}>
                <Skeleton variant={"rounded"} height={86} />
            </Grid>
        </Grid>
    );
}