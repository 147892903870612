import {IconButton, IconButtonProps, Tooltip} from "@mui/material";

export interface ActionButtonProps {
    tooltip: string;
    icon: React.ReactNode;
    color?: IconButtonProps["color"];
    disabled?: boolean;
    onClick?: () => void;
}

export default function ActionButton(props: ActionButtonProps) {
    const {tooltip, icon, color, disabled, onClick} = props;

    return (
        <Tooltip title={tooltip} arrow={true}>
            <span>
                <IconButton onClick={onClick}
                            size={"small"}
                            color={color ?? "primary"}
                            disabled={disabled}>
                    {icon}
                </IconButton>
            </span>
        </Tooltip>
    );
}