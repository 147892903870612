import {IconButton, styled, Tooltip} from "@mui/material";
import React, {MouseEvent} from "react";
import './AppBarIconButton.css'

export interface AppBarIconButtonProps {
    onClick?: (event: MouseEvent) => void;
    tooltip?: string;
    isActive?: boolean;
    children: React.JSX.Element;
}

const StyledIconButton = styled(IconButton)(({theme}) => ({
    "&": {
        color: "white"
    },
    "&.active": {
        color: theme.palette.primary.main,
        backgroundColor: "white"
    }
}));

export default function AppBarIconButton(props: AppBarIconButtonProps) {
    const {onClick, tooltip, isActive, children} = props;

    const className = isActive ? "active" : undefined;

    return (
        <Tooltip title={tooltip}
                 arrow={true}>
            <StyledIconButton className={className} size={"medium"} onClick={onClick}>
                {children}
            </StyledIconButton>
        </Tooltip>
    );
}