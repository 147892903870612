import {DatePicker as MuiDatePicker} from "@mui/x-date-pickers-pro";
import dayjs, {Dayjs} from "dayjs";

export interface DatePickerProps {
    label: string;
    value: string | undefined;
    onChange?: (value: string | undefined) => void;

    required?: boolean;
    errorMessage?: string;
    readOnly?: boolean;
}

export default function DatePicker(props: Readonly<DatePickerProps>) {
    const {label, value, onChange, required, errorMessage, readOnly} = props;

    function handleOnChange(value: Dayjs | null) {
        onChange?.(value?.toISOString() ?? undefined);
    }

    const dayjsValue = value ? dayjs(value, ) : null;

    return (
          <MuiDatePicker label={label}
                         value={dayjsValue}
                         onChange={handleOnChange}
                         readOnly={readOnly}
                         sx={{width: "100%"}}
                         slotProps={{
                             textField: {
                                 size: "small",
                                 required: required,
                                 helperText: errorMessage,
                             }
                         }}
          />
    );
}