import {TopicMembershipRole} from "../../../client/contact/TopicMembershipApiClient";
import {TranslationFunction} from "../../../i18n/i18n";
import {ContactTranslKey} from "../ContactTranslKey";

export class TopicMembershipRoleFormatter {
    public static formatRole(role: TopicMembershipRole | string | null | undefined, t: TranslationFunction): string {
        if (!role) {
            return "";
        }

        const translKey = ContactTranslKey.TOPIC_MEMBERSHIP_ROLE_NAMESPACE + role;
        return t(translKey);
    }
}