import Dialog from "../../../components/dialog/Dialog";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, DeleteButton} from "../../../common/button/CommonButtons";
import {useEffect, useState} from "react";
import {Typography} from "@mui/material";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";

enum DeleteDialogState {
    PROMPT,
    DELETE_IN_PROGRESS,
}

export interface DiseaseAlternativeNameDeleteDialogProps {
    open: boolean;
    onClose: (deleted: boolean) => void;
    alternativeNameId: string;
    diseaseId: string;
}

export default function DiseaseAlternativeNameDeleteDialog(props: DiseaseAlternativeNameDeleteDialogProps) {
    const {open, onClose, alternativeNameId, diseaseId} = props;

    const [dialogState, setDialogState] = useState(DeleteDialogState.PROMPT);

    const {diseaseClient} = useClientLocator();
    const snackbar = useSnackbar();

    useEffect(() => {
        if (!open) {
            setDialogState(DeleteDialogState.PROMPT);
        }
    }, [open]);

    function deleteAltName() {
        setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
        diseaseClient.deleteAlternativeName(alternativeNameId, diseaseId)
            .then(() => {
                snackbar.success("Data deleted");
                onClose(true);
            })
            .catch((error) => {
                snackbar.error("", error);
                setDialogState(DeleteDialogState.DELETE_IN_PROGRESS);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={"Delete alternative name?"} />
            <DialogContent>
                <Typography variant={"body1"}>
                    Do you really want to delete a selected alternative name?
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <DeleteButton variant={"primary"}
                                  onClick={deleteAltName}
                                  inProgress={dialogState === DeleteDialogState.DELETE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}