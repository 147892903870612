import {useRef} from "react";

export type EventListenerUnsubscriber = () => void;

interface EventDispatcher {

    addEventListener: (eventType: string, callback: EventListener) => EventListenerUnsubscriber;

    dispatchEvent: (event: Event) => boolean;

}

export class BrowserEventDispatcher implements EventDispatcher {

    constructor(private readonly window: Window) {
    }

    addEventListener(eventType: string, callback: EventListener): EventListenerUnsubscriber {
        this.window.addEventListener(eventType, callback);
        return () => this.window.removeEventListener(eventType, callback);
    }

    dispatchEvent(event: Event): boolean {
        return this.window.dispatchEvent(event);
    }
}

export function useEventDispatcher() {
    const eventDispatcher = useRef(new BrowserEventDispatcher(window));
    return eventDispatcher.current;
}