import {RegistrationApiClient} from "../../../../client/registration/RegistrationApiClient";
import {DiseaseSpecializationClient} from "../../../disease/specialization/DiseaseSpecializationPanel";
import {DiseaseSpecializationDto} from "../../../../client/disease/DiseaseSpecializationDto";

export class RegistrationSpecializationClientAdapter implements DiseaseSpecializationClient {
    private readonly registrationId: string | undefined;
    private readonly registrationClient: RegistrationApiClient;

    constructor(registrationId: string | undefined, registrationClient: RegistrationApiClient) {
        this.registrationId = registrationId;
        this.registrationClient = registrationClient;
    }

    findSpecializations(): Promise<DiseaseSpecializationDto[]> {
        if (this.registrationId) {
            return this.registrationClient.findContactDataSpecializations(this.registrationId);
        } else {
            return Promise.resolve([]);
        }
    }

    createSpecialization(diseaseId: string): Promise<string> {
        if (this.registrationId) {
            return this.registrationClient.createContactDataSpecialization(this.registrationId, diseaseId);
        } else {
            return Promise.reject(new Error("Registration not initialized"));
        }
    }

    deleteSpecialization(specializationId: string): Promise<void> {
        if (this.registrationId) {
            return this.registrationClient.deleteContactDataSpecialization(this.registrationId, specializationId);
        } else {
            return Promise.reject(new Error("Registration not initialized"));
        }

    }

}