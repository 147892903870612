import {DiseaseDto} from "../../../client/disease/DiseaseApiClient";
import Dialog from "../../../components/dialog/Dialog";
import DialogTitle from "../../../components/dialog/DialogTitle";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import {CancelButton, SelectButton} from "../../../common/button/CommonButtons";
import DiseaseSearchPanel from "./DiseaseSearchPanel";
import {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {DiseaseTranslKey} from "../DiseaseTranslKey";


export interface DiseaseSearchDialogProps {
    open: boolean;
    onClose: (selectedDisease?: DiseaseDto) => void;
}



export default function DiseaseSearchDialog(props: DiseaseSearchDialogProps) {
    const {open, onClose} = props;
    const {t} = useTranslation();

    const [selectedDisease, setSelectedDisease] = useState<DiseaseDto>();


    const searchPanel = useMemo(() => {
        return (<DiseaseSearchPanel treeMode onSelected={setSelectedDisease} />);
    }, []);

    return (
        <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"lg"}>
            <DialogTitle title={t(DiseaseTranslKey.SEARCH_DISEASE)} onClose={() => onClose(undefined)} />
            <DialogContent>
                {searchPanel}
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(undefined)} />
                    <SelectButton variant={"primary"}
                                  disabled={!selectedDisease}
                                  onClick={() => {
                                     onClose(selectedDisease);
                                  }} />
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}
