import {useEffect, useState} from "react";
import {Grid, IconButton} from "@mui/material";
import {useSnackbar} from "../../../../components/snackbar/Snackbar";
import Dialog from "../../../../components/dialog/Dialog";
import DialogTitle from "../../../../components/dialog/DialogTitle";
import DialogContent from "../../../../components/dialog/DialogContent";
import DialogActions from "../../../../components/dialog/DialogActions";
import ButtonBar from "../../../../components/button/ButtonBar";
import {CancelButton, SaveButton} from "../../../../common/button/CommonButtons";
import TextField from "../../../../components/field/TextField";
import {Clear, MoreHoriz} from "@mui/icons-material";
import {CommonTranslKey} from "../../../../common/CommonTranslKey";
import {useTranslation} from "react-i18next";
import {TopicDto} from "../../../../client/topic/TopicApiClient";
import {useClientLocator} from "../../../../client/ApiClientLocator";
import TopicSearchDialog from "../../../topic/search/TopicSearchDialog";
import {RegistrationTranslKey} from "../../RegistrationTranslKey";

enum CreateDialogState {
    IDLE,
    SAVE_IN_PROGRESS,
}

export interface TopicInterestCreateDialogProps {
    open: boolean;
    registrationRequestId: string;
    onClose: (createdSpecializationId?: string) => void;
}

export default function TopicInterestCreateDialog(props: Readonly<TopicInterestCreateDialogProps>) {
    const {open, registrationRequestId, onClose} = props;

    const [dialogState, setDialogState] = useState(CreateDialogState.IDLE);
    const [topic, setTopic] = useState<TopicDto>();
    const [topicErrorMessage, setTopicErrorMessage] = useState<string>();

    const [topicSearchDialogOpen, setTopicSearchDialogOpen] = useState(false);

    const {registrationClient} = useClientLocator();

    const snackbar = useSnackbar();
    const {t} = useTranslation();

    useEffect(() => {
        if (!open) {
            setTopic(undefined);
            setDialogState(CreateDialogState.IDLE);
            setTopicErrorMessage(undefined);
        }
    }, [open]);

    function save() {
        setDialogState(CreateDialogState.SAVE_IN_PROGRESS);
        if (validate()) {
            registrationClient.createContactDataTopicInterest(registrationRequestId, topic!.id)
                .then((interestId) => {
                    snackbar.success(t(CommonTranslKey.DATA_SAVED));
                    setTopicErrorMessage(undefined);
                    onClose(interestId);
                })
                .catch((error) => {
                    if (error.response?.data?.code === "CONFLICT") {
                        setTopicErrorMessage(t(RegistrationTranslKey.TOPIC_INTEREST_ALREADY_EXISTS));
                    } else {
                        setTopicErrorMessage(undefined);
                        snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error);
                    }
                    setDialogState(CreateDialogState.IDLE);
                });
        }
    }

    function validate(): boolean {
        return (topic !== undefined);
    }

    function clearDisease() {
        setTopic(undefined);
    }

    function showDiseaseSearchDialog() {
        setTopicSearchDialogOpen(true);
    }

    function onTopicSearchDialogClosed(topic?: TopicDto) {
        setTopicSearchDialogOpen(false);
        if (topic) {
            setTopicErrorMessage(undefined);
            setTopic(topic);
        }
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose(undefined)} maxWidth={"md"}>
                <DialogTitle title={t(RegistrationTranslKey.ADD_NEW_TOPIC_INTEREST)}/>
                <DialogContent>
                    <Grid container mt={0} spacing={2}>
                        <Grid item xs={12}>
                            <TextField label={t(RegistrationTranslKey.TOPIC)}
                                       value={topic?.name ?? ""}
                                       readOnly={true}
                                       required={true}
                                       errorMessage={topicErrorMessage}
                                       endAdornment={(
                                           <>
                                                {topic && <IconButton onClick={clearDisease}><Clear/></IconButton>}
                                                <IconButton onClick={showDiseaseSearchDialog}><MoreHoriz/></IconButton>
                                           </>
                                       )} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <CancelButton onClick={() => onClose(undefined)}/>
                        <SaveButton variant={"primary"}
                                    onClick={save}
                                    disabled={!validate()}
                                    inProgress={dialogState === CreateDialogState.SAVE_IN_PROGRESS}/>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <TopicSearchDialog open={topicSearchDialogOpen} onClose={onTopicSearchDialogClosed}/>
        </>
    );
}
