import {TranslationFunction} from "../../i18n/i18n";
import {CommonTranslKey} from "../CommonTranslKey";

export class BooleanFormatter {
    static formatAsYesNo(value: boolean | undefined | null, t: TranslationFunction): string {
        if (value === undefined || value === null) {
            return '';
        } else {
            return value ? t(CommonTranslKey.YES) : t(CommonTranslKey.NO);
        }
    }
}
