import {Typography} from "@mui/material";
import {useState} from "react";
import {AffiliationDto} from "../../client/affiliation/AffiliationApiClient";
import {useClientLocator} from "../../client/ApiClientLocator";
import {useSnackbar} from "../../components/snackbar/Snackbar";
import Dialog from "../../components/dialog/Dialog";
import DialogTitle from "../../components/dialog/DialogTitle";
import DialogContent from "../../components/dialog/DialogContent";
import DialogActions from "../../components/dialog/DialogActions";
import ButtonBar from "../../components/button/ButtonBar";
import {CancelButton, InvalidateButton} from "../../common/button/CommonButtons";
import {OrganizationDto} from "../../client/organization/OrganizationApiClient";
import {ContactDto} from "../../client/contact/ContactApiClient";
import {CommonTranslKey} from "../../common/CommonTranslKey";
import {Trans, useTranslation} from "react-i18next";
import {ContactFormatter} from "../contact/ContactFormatter";
import {AffiliationTranslKey} from "./AffiliationTranslKey";

enum InvalidateDialogState {
    IDLE,
    INVALIDATE_IN_PROGRESS,
}

export interface AffiliationInvalidateDialogProps {
    open: boolean;
    onClose: (invalidated: boolean) => void;
    affiliation?: AffiliationDto;
    organization?: OrganizationDto;
    contact?: ContactDto;
}

export default function AffiliationInvalidateDialog(props: Readonly<AffiliationInvalidateDialogProps>) {
    const {open, onClose, affiliation, organization, contact} = props;

    const [dialogState, setDialogState] = useState(InvalidateDialogState.IDLE);

    const {affiliationClient} = useClientLocator();

    const {t} = useTranslation();
    const snackbar = useSnackbar();

    function deleteAffiliation() {
        setDialogState(InvalidateDialogState.INVALIDATE_IN_PROGRESS);
        affiliationClient.invalidateAffiliation(affiliation!.id)
            .then(() => {
                snackbar.success(t(CommonTranslKey.DATA_INVALIDATED));
                setDialogState(InvalidateDialogState.IDLE);
                onClose(true);
            })
            .catch((error) => {
                snackbar.error("", error);
                setDialogState(InvalidateDialogState.IDLE);
            });
    }

    return (
        <Dialog open={open} onClose={() => onClose(false)} maxWidth={"sm"}>
            <DialogTitle title={t(AffiliationTranslKey.INVALIDATE_AFFILIATION)}/>
            <DialogContent>
                <Typography variant={"body1"}>
                    <Trans i18nKey={AffiliationTranslKey.INVALIDATE_AFFILIATION_CONFIRMATION}
                           values={{organizationName: organization?.name, contactName: ContactFormatter.formatContactName(contact)}} />
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonBar>
                    <CancelButton onClick={() => onClose(false)}/>
                    <InvalidateButton variant={"primary"}
                                      onClick={deleteAffiliation}
                                      inProgress={dialogState === InvalidateDialogState.INVALIDATE_IN_PROGRESS}/>
                </ButtonBar>
            </DialogActions>
        </Dialog>
    );
}