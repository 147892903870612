import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack
} from "@mui/material";
import {Delete, Folder} from "@mui/icons-material";
import {DiseaseDto, DiseaseHierarchyRelationDto} from "../../../client/disease/DiseaseApiClient";
import {formatHierarchyLevel} from "../DiseaseHierarchyLevelFormatter";
import {Theme} from "@mui/material/styles/createTheme";
import {SystemStyleObject} from "@mui/system/styleFunctionSx/styleFunctionSx";


export interface DiseaseRelationListItemProps {
    relation: DiseaseHierarchyRelationDto;
    parent: DiseaseDto;
    disabled?: boolean;
    onDeleteClick: (relation: DiseaseHierarchyRelationDto, parent: DiseaseDto) => void;
}

export default function DiseaseRelationListItem(props: DiseaseRelationListItemProps) {
    const {relation, parent, disabled, onDeleteClick} = props;

    function formatSecondaryText(parent: DiseaseDto, relation: DiseaseHierarchyRelationDto) {
        const hierarchyLevel = formatHierarchyLevel(parent.hierarchyLevel);
        const type = relation.isPrimary ? "Primary" : "Secondary";
        return `${hierarchyLevel} | ${type}`;
    }

    function buildAvatarStyle(relation: DiseaseHierarchyRelationDto, theme: Theme): SystemStyleObject<Theme> {
        return relation.isPrimary
            ? {backgroundColor: theme.palette.primary.main}
            : {};
    }

    const secondaryText = formatSecondaryText(parent, relation);

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar sx={(theme) => buildAvatarStyle(relation, theme)}>
                    <Folder/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={parent.name} secondary={secondaryText} />
            <ListItemSecondaryAction>
                <Stack direction={"row"} spacing={0}>
                    <IconButton disabled={disabled} onClick={() => onDeleteClick(relation, parent)}>
                        <Delete />
                    </IconButton>
                </Stack>
            </ListItemSecondaryAction>
        </ListItem>
    );
}