import {DiseaseFilterDto, DiseaseHierarchyLevel} from "../../../client/disease/DiseaseApiClient";
import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import {CopyUtils} from "../../../util/CopyUtils";
import SelectField from "../../../components/field/SelectField";
import {formatHierarchyLevel} from "../DiseaseHierarchyLevelFormatter";
import {useTranslation} from "react-i18next";
import {DiseaseTranslKey} from "../DiseaseTranslKey";

export interface DiseaseFilterFormProps {
    filter: DiseaseFilterDto;
    onChange: (filter: DiseaseFilterDto) => void;
}

export function DiseaseFilterForm(props: DiseaseFilterFormProps) {
    const {filter, onChange} = props;
    const {t} = useTranslation();

    function onNameChange(nameOrAbbreviation: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        const normalizedNameOrAbbreviation = nameOrAbbreviation.trim();
        updatedFilter.nameOrAbbreviationLike = (normalizedNameOrAbbreviation.length > 0) ? normalizedNameOrAbbreviation : undefined;
        onChange(updatedFilter);
    }

    function onOrphacodeChange(orphacode: string) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        const normalizedOrphacode = orphacode.trim();
        updatedFilter.orphacodeLike = (normalizedOrphacode.length > 0) ? normalizedOrphacode : undefined;
        onChange(updatedFilter);
    }

    function onLevelChange(level: unknown) {
        const updatedFilter = CopyUtils.deepCopy(filter);
        updatedFilter.level = level as DiseaseHierarchyLevel;
        onChange(updatedFilter);
    }

    const hierarchyLevelOptions = Object.keys(DiseaseHierarchyLevel).map((level) => ({
        value: level as DiseaseHierarchyLevel,
        label: formatHierarchyLevel(level as DiseaseHierarchyLevel)
    }));

    return (
        <Grid container mt={0} spacing={2}>
            <Grid item xs={12} sm={5} lg={6}>
                <TextField label={t(DiseaseTranslKey.NAME_OR_ABBREVIATION)}
                           value={filter.nameOrAbbreviationLike ?? ""}
                           onChange={onNameChange} />
            </Grid>
            <Grid item xs={7} sm={4} lg={4}>
                <TextField label={t(DiseaseTranslKey.ORPHACODE)}
                           value={filter.orphacodeLike}
                           onChange={onOrphacodeChange} />
            </Grid>
            <Grid item xs={5} sm={3} lg={2}>
                <SelectField label={t(DiseaseTranslKey.LEVEL)}
                             value={filter.level}
                             onChange={onLevelChange}
                             options={hierarchyLevelOptions} />
            </Grid>
        </Grid>
    );
}

