import {Box, Divider} from "@mui/material";
import React, {useEffect, useMemo, useState} from "react";
import {useClientLocator} from "../../../client/ApiClientLocator";
import {useSnackbar} from "../../../components/snackbar/Snackbar";
import TabPanel from "../../../components/tab/TabPanel";
import Tab from "../../../components/tab/Tab";
import Tabs from "../../../components/tab/Tabs";
import Dialog from "../../../components/dialog/Dialog";
import DialogContent from "../../../components/dialog/DialogContent";
import DialogTitle from "../../../components/dialog/DialogTitle";
import {ContactRegistrationRequestDto} from "../../../client/registration/RegistrationApiClient";
import RegistrationRequestBasicDetailPanel from "./RegistrationRequestBasicDetailPanel";
import RegistrationRequestBasicDetailPanelSkeleton from "./RegistrationRequestBasicDetailPanelSkeleton";
import {useTranslation} from "react-i18next";
import {RegistrationTranslKey} from "../RegistrationTranslKey";
import {CommonTranslKey} from "../../../common/CommonTranslKey";
import OrganizationBasicDetailPanel from "../../organization/OrganizationBasicDetailPanel";
import {OrganizationDto} from "../../../client/organization/OrganizationApiClient";
import DiseaseSpecializationPanel, {
    DiseaseSpecializationClient
} from "../../disease/specialization/DiseaseSpecializationPanel";
import {RegistrationSpecializationClientAdapter} from "./specialization/RegistrationSpecializationClientAdapter";
import DialogActions from "../../../components/dialog/DialogActions";
import ButtonBar from "../../../components/button/ButtonBar";
import Button from "../../../components/button/Button";
import {Cancel, Verified} from "@mui/icons-material";
import RegistrationRequestRejectDialog from "./workflow/RegistrationRequestRejectDialog";
import RegistrationRequestApproveDialog from "./workflow/RegistrationRequestApproveDialog";
import TopicInterestPanel from "./topic/TopicInterestPanel";
import {RegistrationEventType} from "../RegistrationEventType";
import {useEventDispatcher} from "../../../components/EventDispatcher";

enum TabKey {
    BASIC = "basic",
    ORGANIZATION = "organization",
    DISEASES = "diseases",
    TOPICS = "topics",
}

export interface RegistrationRequestDetailDialogProps {
    open: boolean;
    onClose: () => void;
    registrationRequestId: string | undefined;
}

export default function RegistrationRequestDetailDialog(props: Readonly<RegistrationRequestDetailDialogProps>) {
    const {open, onClose, registrationRequestId} = props;

    const {registrationClient, organizationClient} = useClientLocator();
    const eventDispacher = useEventDispatcher();
    const snackbar = useSnackbar();

    const [registrationRequest, setRegistrationRequest] = useState<ContactRegistrationRequestDto>();
    const [organization, setOrganization] = useState<OrganizationDto>();
    const [selectedTab, setSelectedTab] = useState(TabKey.BASIC);
    const [refresh, setRefresh] = useState(Date.now());

    const [approveDialogOpen, setApproveDialogOpen] = useState(false);
    const [rejectDialogOpen, setRejectDialogOpen] = useState(false);


    const {t} = useTranslation();

    const specializationClient: DiseaseSpecializationClient = useMemo(
        () => new RegistrationSpecializationClientAdapter(registrationRequest?.id, registrationClient),
        [registrationClient, registrationRequest]);

    useEffect(() => {
        if (open && registrationRequestId) {
            registrationClient.findById(registrationRequestId)
                .then((registrationRequest) => {
                    setRegistrationRequest(registrationRequest);
                    const organizationId = registrationRequest?.contactRegistrationData.primaryOrganizationId;
                    if (organizationId) {
                        organizationClient.findById(organizationId)
                            .then((organization) => setOrganization(organization))
                            .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
                    }
                })
                .catch((error) => snackbar.error(t(CommonTranslKey.UNEXPECTED_ERROR), error));
        }
        if (!open) {
            setRegistrationRequest(undefined);
            setOrganization(undefined);
            setSelectedTab(TabKey.BASIC);
        }
    }, [open, registrationRequestId, refresh, registrationClient, organizationClient, snackbar, t]);

    function onUpdated() {
        setRefresh(Date.now());
        eventDispacher.dispatchEvent(new Event(RegistrationEventType.REQUEST_DATA_UPDATED));
    }

    function showApproveDialog() {
        setApproveDialogOpen(true);
    }

    function onApproveDialogClose(approved: boolean) {
        setApproveDialogOpen(false);
        if (approved) {
            onClose();
        }
    }

    function showRejectDialog() {
        setRejectDialogOpen(true);
    }

    function onRejectDialogClose(rejected: boolean) {
        setRejectDialogOpen(false);
        if (rejected) {
            onClose();
        }
    }

    let content: JSX.Element;
    if (registrationRequest) {
        content = (
            <RegistrationRequestBasicDetailPanel registrationRequest={registrationRequest} onUpdate={onUpdated} />
        );
    } else {
        content = (
            <RegistrationRequestBasicDetailPanelSkeleton />
        );
    }

    return (
        <>
            <Dialog open={open} onClose={() => onClose()} maxWidth={"lg"}>
                <DialogTitle title={t(RegistrationTranslKey.REGISTRATION)} onClose={() => onClose()} />
                <DialogContent>
                    <Tabs value={selectedTab}
                          onChange={(_, tab) => setSelectedTab(tab)}
                          variant={"scrollable"}>
                        <Tab label={t(CommonTranslKey.DETAILS)} value={TabKey.BASIC} />
                        <Tab label={t(RegistrationTranslKey.ORGANIZATION)} value={TabKey.ORGANIZATION} disabled={organization === undefined} />
                        <Tab label={t(RegistrationTranslKey.DISEASES)} value={TabKey.DISEASES} />
                        <Tab label={t(RegistrationTranslKey.TOPICS)} value={TabKey.TOPICS} disabled={registrationRequestId === undefined} />
                    </Tabs>
                    <TabPanel value={TabKey.BASIC} selectedValue={selectedTab}>
                        <Box sx={{mt: 1}}>
                            {content}
                        </Box>
                    </TabPanel>
                    <TabPanel value={TabKey.ORGANIZATION} selectedValue={selectedTab}>
                        {organization &&
                            <OrganizationBasicDetailPanel organization={organization}
                                                          hideActions={true} />
                        }
                    </TabPanel>
                    <TabPanel value={TabKey.DISEASES} selectedValue={selectedTab}>
                        <DiseaseSpecializationPanel canUpdate={registrationRequest?.acl.canUpdate ?? false}
                                                    onUpdated={onUpdated}
                                                    specializationClient={specializationClient} />
                    </TabPanel>
                    <TabPanel value={TabKey.TOPICS} selectedValue={selectedTab}>
                        {registrationRequestId &&
                            <TopicInterestPanel registrationRequestId={registrationRequestId}
                                                canUpdate={registrationRequest?.acl.canUpdate ?? false}
                                                onUpdated={onUpdated} />
                        }
                    </TabPanel>
                </DialogContent>
                <DialogActions>
                    <ButtonBar>
                        <Divider orientation={"vertical"} />
                        <Button icon={<Cancel />}
                                variant={"secondary"}
                                color={"error"}
                                disabled={!registrationRequest?.acl.canReject}
                                onClick={showRejectDialog}>
                            {t(RegistrationTranslKey.REJECT)}
                        </Button>
                        <Button icon={<Verified />}
                                variant={"secondary"}
                                color={"success"}
                                disabled={!registrationRequest?.acl.canApprove}
                                onClick={showApproveDialog}>
                            {t(RegistrationTranslKey.APPROVE)}
                        </Button>
                    </ButtonBar>
                </DialogActions>
            </Dialog>

            <RegistrationRequestApproveDialog open={approveDialogOpen}
                                              onClose={onApproveDialogClose}
                                              registrationRequest={registrationRequest} />

            <RegistrationRequestRejectDialog open={rejectDialogOpen}
                                             onClose={onRejectDialogClose}
                                             registrationRequest={registrationRequest} />
        </>
    );
}