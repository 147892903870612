import {TranslationFunction} from "../../i18n/i18n";
import {AffiliationTranslKey} from "./AffiliationTranslKey";
import {ContactAffiliationTitle} from "../../client/affiliation/AffiliationApiClient";
import {TopicMembershipTitle} from "../../client/contact/TopicMembershipApiClient";

export class ContactAffiliationTitleFormatter {
    public static formatTitle(title: ContactAffiliationTitle | string, otherTitle: string | undefined | null, t: TranslationFunction): string {
        if (!title) {
            return "";
        }

        if (title === ContactAffiliationTitle.OTHER) {
            return otherTitle ?? "";
        }

        return this.translateTitle(title, t);
    }

    public static translateTitle(title: ContactAffiliationTitle | string, t: TranslationFunction){
        const translKey = AffiliationTranslKey.CONTACT_AFFILIATION_TITLE_NAMESPACE + title;
        return t(translKey);
    }

    public static decomposeTitleAndOtherTitle(title: string): [ContactAffiliationTitle, string | undefined] {
        const catalogTitles = Object.keys(ContactAffiliationTitle)
                .filter((title) => title !== TopicMembershipTitle.OTHER);
        if (catalogTitles.includes(title)) {
            return [title as ContactAffiliationTitle, undefined];
        } else {
            return [ContactAffiliationTitle.OTHER, title];
        }
    }

    public static composeTitleAndOtherTitle(title: ContactAffiliationTitle, otherTitle: string | undefined): ContactAffiliationTitle | string {
        if (title === ContactAffiliationTitle.OTHER) {
            return otherTitle ?? "";
        } else {
            return title;
        }
    }
}
