import {Grid} from "@mui/material";
import TextField from "../../../components/field/TextField";
import ButtonBar from "../../../components/button/ButtonBar";
import {EditButton} from "../../../common/button/CommonButtons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {RegistrationTranslKey} from "../RegistrationTranslKey";
import {ContactRegistrationRequestDto} from "../../../client/registration/RegistrationApiClient";
import RegistrationContactDataUpdateDialog from "./RegistrationContactDataUpdateDialog";
import {UserFormatter} from "../../user/UserFormatter";
import {PersonSearch} from "@mui/icons-material";
import Button from "../../../components/button/Button";
import RegistrationReviwerAssignmentDialog from "./workflow/RegistrationRequestReviewerAssignmentDialog";
import {ContactAffiliationTitleFormatter} from "../../affiliation/ContactAffiliationTitleFormatter";
import {ContactAffiliationRoleFormatter} from "../../affiliation/ContactAffiliationRoleFormatter";

export interface RegistrationRequestDetailPanelProps {
    registrationRequest: ContactRegistrationRequestDto;
    onUpdate?: () => void;
    hideActions?: boolean;
}

export default function RegistrationRequestBasicDetailPanel(props: Readonly<RegistrationRequestDetailPanelProps>) {
    const {registrationRequest, hideActions, onUpdate} = props;
    const {t} = useTranslation();

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [reviewerAssignmentDialogOpen, setReviewerAssignmentDialogOpen] = useState(false);

    function showUpdateDialog() {
        setUpdateDialogOpen(true);
    }

    function onUpdateDialogClosed(updated: boolean) {
        setUpdateDialogOpen(false);
        if (updated) {
            onUpdate?.();
        }
    }

    function showReviewerAssignmentDialog() {
        setReviewerAssignmentDialogOpen(true);
    }

    function onReviewerAssignmentDialogClose(assigned: boolean) {
        setReviewerAssignmentDialogOpen(false);
        if (assigned) {
            onUpdate?.();
        }
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5} md={5}>
                    <TextField label={t(RegistrationTranslKey.FIRST_NAME)}
                               value={registrationRequest.contactRegistrationData.firstName}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={5} md={5}>
                    <TextField label={t(RegistrationTranslKey.LAST_NAME)}
                               value={registrationRequest.contactRegistrationData.lastName}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <TextField label={t(RegistrationTranslKey.TITLE)}
                               value={registrationRequest.contactRegistrationData.title}
                               readOnly={true} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(RegistrationTranslKey.EMAIL)}
                               value={registrationRequest.contactRegistrationData.email}
                               readOnly={true} />
                </Grid>

                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(RegistrationTranslKey.PRIMARY_ORGANIZATION_ROLE)}
                               value={ContactAffiliationRoleFormatter.formatRole(registrationRequest.contactRegistrationData.primaryOrganizationRole, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(RegistrationTranslKey.PRIMARY_ORGANIZATION_TITLE)}
                               value={ContactAffiliationTitleFormatter.formatTitle(registrationRequest.contactRegistrationData.primaryOrganizationTitle,
                                       registrationRequest.contactRegistrationData.otherPrimaryOrganizationTitle, t)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <TextField label={t(RegistrationTranslKey.EXPERTISE)}
                               value={registrationRequest.contactRegistrationData.expertise}
                               readOnly={true} />
                </Grid>


                {!hideActions &&
                    <Grid item xs={12}>
                        <ButtonBar>
                            <EditButton disabled={!registrationRequest.acl.canUpdate} onClick={showUpdateDialog} />
                        </ButtonBar>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField label={t(RegistrationTranslKey.REVIEWER)}
                               value={UserFormatter.formatUser(registrationRequest.assignedReviewer)}
                               readOnly={true} />
                </Grid>
                <Grid item xs={12}>
                    <ButtonBar>
                        <Button icon={<PersonSearch />}
                                variant={"secondary"}
                                disabled={!registrationRequest?.acl.canAssign}
                                onClick={showReviewerAssignmentDialog}>
                            {registrationRequest.assignedReviewer ? t(RegistrationTranslKey.REASSIGN) : t(RegistrationTranslKey.ASSIGN)}
                        </Button>
                    </ButtonBar>
                </Grid>
            </Grid>

            <RegistrationContactDataUpdateDialog open={updateDialogOpen}
                                                 onClose={(updatedId) => onUpdateDialogClosed(updatedId !== undefined)}
                                                 registrationRequest={registrationRequest} />

            <RegistrationReviwerAssignmentDialog open={reviewerAssignmentDialogOpen}
                                                 onClose={onReviewerAssignmentDialogClose}
                                                 registrationRequest={registrationRequest} />
        </>
    );
}
