import {DiseaseAlternativeNameDto, DiseaseDetailDto} from "../../../client/disease/DiseaseApiClient";
import {List, Stack, Typography} from "@mui/material";
import DiseaseAlternativeNameListItem from "./DiseaseAlternativeNameListItem";
import {DataArray} from "@mui/icons-material";
import ButtonBar from "../../../components/button/ButtonBar";
import {AddButton} from "../../../common/button/CommonButtons";
import {useState} from "react";
import DiseaseAlternativeNameDeleteDialog from "./DiseaseAlternativeNameDeleteDialog";
import DiseaseAlternativeNameUpdateDialog from "./DiseaseAlternativeNameUpdateDialog";

export interface DiseaseAlternativeNamesPanelProps {
    disease?: DiseaseDetailDto;
    onUpdated?: () => void;
}

export default function DiseaseAlternativeNamesPanel(props: DiseaseAlternativeNamesPanelProps) {
    const {disease, onUpdated} = props;

    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [altNameToUpdate, setAltNameToUpdate] = useState<DiseaseAlternativeNameDto>();

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [altNameIdToDelete, setAltNameIdToDelete] = useState<string>("");

    function showCreateDialog() {
        setUpdateDialogOpen(true);
        setAltNameToUpdate(undefined);
    }

    function showUpdateDialog(altName: DiseaseAlternativeNameDto) {
        setUpdateDialogOpen(true);
        setAltNameToUpdate(altName);
    }

    function onCreateDialogClosed(createdAltNameId?: string) {
        setUpdateDialogOpen(false);
        if (createdAltNameId) {
            onUpdated?.();
        }
    }

    function showDeleteDialog(altName: DiseaseAlternativeNameDto) {
        setDeleteDialogOpen(true);
        setAltNameIdToDelete(altName.id);
    }

    function onDeleteDialogClosed(deleted: boolean) {
        setDeleteDialogOpen(false);
        if (deleted) {
            onUpdated?.();
        }
    }

    if (!disease) {
        return null;
    }

    let content: React.ReactNode;
    if (disease.alternativeNames && disease.alternativeNames.length > 0) {
        content = (
            <List component={"nav"} dense={true}>
                {disease.alternativeNames.map(
                    (altName, index) =>
                        <DiseaseAlternativeNameListItem alternativeName={altName}
                                                        key={altName.id}
                                                        disabled={!disease.info.acl.canUpdate}
                                                        onUpdateClick={showUpdateDialog}
                                                        onDeleteClick={showDeleteDialog}/>
                )}
            </List>
        );
    } else {
        content = (
            <Stack direction={"row"}
                   spacing={1}
                   alignItems={"center"}
                   sx={{m: 2}} >
                <DataArray/>
                <Typography variant={"body2"}>No alternative names</Typography>
            </Stack>
        );
    }

    return (
        <>
            <Stack direction={"column"}>
                {content}
                <ButtonBar>
                    <AddButton disabled={!disease.info.acl.canUpdate} onClick={showCreateDialog} />
                </ButtonBar>
            </Stack>
            <DiseaseAlternativeNameUpdateDialog open={updateDialogOpen}
                                                onClose={onCreateDialogClosed}
                                                alternativeName={altNameToUpdate}
                                                diseaseId={disease.info.id} />

            <DiseaseAlternativeNameDeleteDialog open={deleteDialogOpen}
                                                onClose={onDeleteDialogClosed}
                                                alternativeNameId={altNameIdToDelete}
                                                diseaseId={disease.info.id} />
        </>
    );
}